import {
    Box, TableCell, TableHead, TableRow, Typography, TableContainer, Table, TableBody, CircularProgress, Tooltip, IconButton
} from '@mui/material';
import { colTablePermission } from '../../helpers/tableConfigs';
import { useManageUsers } from '../../hooks/useManageUsers';
import { useDataAuth } from '../../hooks/useDataAuth';
import { Delete } from "@mui/icons-material";

export const TablePermissionsUsers = ({ isCreate }) => {
    const [UsersManager] = useManageUsers();
    const [{ userSession }] = useDataAuth();
    const {
        isLoadingPermissions,
        listAllPermissions,
        DeletePermission
    } = UsersManager;

    const DeleteReg = (idUsAccess) => {
        DeletePermission(userSession.token, idUsAccess);
    }

    return (
        <TableContainer
            style={{ height: '35vh', overflow: 'auto', borderBottom: 'solid 1px' }}
        >
            <Table
                aria-labelledby='tableTitle'
                size='small'
                stickyHeader
            >
                <TableHead>
                    <TableRow sx={{ px: 2 }}>
                        <TableCell
                            align='center'
                            style={{ width: 30 }}
                        >
                        </TableCell>
                        {
                            colTablePermission.map((col, index) => (
                                col.visible &&
                                <TableCell
                                    padding='normal'
                                    align='center'
                                    key={`tc-${index}`}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography sx={{ fontWeight: 'bold', marginRight: '8px' }} component='span'>
                                            {col.label}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        isLoadingPermissions ? (
                            <TableRow sx={{ height: 150, py: 2 }}>
                                <TableCell
                                    colSpan={colTablePermission.length + 2}
                                    align='center'
                                >
                                    <CircularProgress
                                        color='primary'
                                    />
                                </TableCell>
                            </TableRow>
                            // ) : isCreate || (!isLoadingPermissions && !listAllPermissions.length > 0) ? (
                        ) : !isLoadingPermissions && !listAllPermissions.length > 0 ? (
                            <TableRow sx={{ height: 150, py: 2 }}>
                                <TableCell
                                    colSpan={colTablePermission.length + 2}
                                    align='center'
                                >
                                    <Typography>No hay registros para mostrar</Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            listAllPermissions.map((item, cont) => (
                                <TableRow
                                    tabIndex={cont}
                                    key={item.id_user_access}
                                >
                                    <TableCell align='center'>
                                        <Tooltip title={'Eliminar'}>
                                            <IconButton
                                                aria-label="close"
                                                onClick={() => DeleteReg(item.id_user_access)}
                                                sx={{ color: 'red' }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    {colTablePermission.map((col, index) => (
                                        col.visible &&
                                        <TableCell
                                            key={`tc-${index}`}
                                            padding='normal'
                                            align='center'
                                        >
                                            <Typography>
                                                {item[col.id]}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
