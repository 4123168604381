
import { deleteDataFlows, fetchDataFlows, insertDataFlows, fetchDataExecutionsByIdFlow, updatedDataFlows, deleteDataComponentFlow, fetcSchemeFlows, GetDataFlow, fetchDataConfig, UpdateConfig } from "../../services/flows.service";
import { actionTypes } from "../contants/actionTypes"
import { logout } from "./auth";

//#region LOAD_ALL_FLOWS
export const startLoadingAllFlows = () => ({
    type: actionTypes.floStartLoadingAllFlows
});
export const finishLoadingAllFlows = () => ({
    type: actionTypes.floFinishLoadingAllFlows
});
export const getAllFlows = (idCompany, accessToken) => {
    return (dispatch) => {
        dispatch(startLoadingAllFlows());
        fetchDataFlows(idCompany, accessToken)
            .then(({ data }) => {
                // console.log("Data __ ", data)
                dispatch(loadAllFlows(data));
            }).catch((e) => {
                console.log('error', e)
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, 'Ha ocurrido un error cargando la lista de flujos, intentálo de nuevo más tarde'));
                    dispatch(loadAllFlows(null));
                }
            }).finally(() => {
                dispatch(finishLoadingAllFlows());
            });
    }
};
export const loadAllFlows = (data) => ({
    type: actionTypes.floLoadAllFlows,
    payload: !data ? [] : data
});

//#endregion

//#region UPDATED_FLOWS



export const startUpdatingFlows = () => ({
    type: actionTypes.floStartUpdatingFlow
});

export const finishUpdatingFlows = () => ({
    type: actionTypes.floFinishUpdatingFlow
});

export const updateFlows = (data, idFlow, accessToken) => {
    console.log('data', data)
    return (dispatch) => {
        dispatch(startUpdatingFlows());
        updatedDataFlows(data, accessToken)
            .then((response) => {
                dispatch(getDataFlow(idFlow, accessToken));
                dispatch(setNotificaction({ isNotification: true, msg: 'Registro actualizado con exito', typeMsg: 'success' }));
            }).catch((e) => {
                console.log('error', e);
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al actualizar el registro', typeMsg: 'error' }));
                    // dispatch(errorManageFlows(true, 'Ha ocurrido un error guardando los cambios, intentálo de nuevo más tarde'));
                }
            }).finally(() => {
                dispatch(finishUpdatingFlows());
                dispatch(getAllFlows(idFlow, accessToken));
            });
    }
};

// export const updateFlows = (data, idCompany, accessToken) => {
//     console.log('data', data)
//     return (dispatch) => {
//         dispatch(startUpdatingFlows());
//         updatedDataFlows(data, accessToken)
//             .then((response) => {
//                 console.log("updateFlows_response __", response);
//             }).catch((e) => {
//                 console.log('error', e);
//                 if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
//                     dispatch(logout());
//                 } else {
//                     dispatch(errorManageFlows(true, 'Ha ocurrido un error guardando los cambios, intentálo de nuevo más tarde'));
//                 }
//             }).finally(() => {
//                 dispatch(finishUpdatingFlows());
//                 dispatch(getAllFlows(idCompany, accessToken));
//             });
//     }
// };
//#endregion

//#region ADD_NEW_FLOW
export const startAddingNewFlow = () => ({
    type: actionTypes.floStartAddingNewFlow
});

export const finishAddingNewFlow = () => ({
    type: actionTypes.floFinishAddingNewFlow
});

export const addNewFlow = (data, idCompany, accessToken) => {
    return (dispatch) => {
        dispatch(startAddingNewFlow());
        insertDataFlows(data, accessToken)
            .then((response) => {
            }).catch((e) => {
                console.log('error', e);
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, 'Ha ocurrido un error agregando el flujo, intentálo de nuevo más tarde'));
                }
            }).finally(() => {
                dispatch(finishAddingNewFlow());
                dispatch(getAllFlows(idCompany, accessToken));
            });
    }
};
//#endregion

//#region DELETE_FLOWS
export const startDeletingFlows = () => ({
    type: actionTypes.floStartDeletingFlows
});
export const finishDeletingFlows = () => ({
    type: actionTypes.floFinishDeletingFlows
});
export const deleteFlows = (data, idCompany, accessToken) => {
    return (dispatch) => {
        dispatch(startDeletingFlows());
        deleteDataFlows(data, accessToken)
            .then((response) => {
                console.log(response);
            }).catch((e) => {
                console.log('error', e);
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, 'Ha ocurrido un error eliminando el(los) flujo(s), intentálo de nuevo más tarde'));
                }
            }).finally(() => {
                dispatch(finishDeletingFlows());
                // dispatch(getAllFlows(idCompany, accessToken));
            });
    }
};
//#endregion

//#region DELETE_COMPONENT_FLOW
export const startDeletingComponentFlow = () => ({
    type: actionTypes.floStartDeletingComponentFlow
});
export const finishDeletingComponentFlow = () => ({
    type: actionTypes.floFinishDeletingComponentFlow
});
export const deleteComponentFlow = (data, accessToken) => {
    return (dispatch) => {
        dispatch(startDeletingComponentFlow());
        deleteDataComponentFlow(data, accessToken)
            .then((response) => {
                console.log(response);
            }).catch((e) => {
                console.log('error', e);
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, 'Ha ocurrido un error eliminando el componente, intentálo de nuevo más tarde'));
                    // dispatch(loadAllFlows(null));
                }
            }).finally(() => {
                dispatch(finishDeletingComponentFlow());
                // dispatch(getAllFlows(idCompany, accessToken));
            });
    }
};
//#endregion

//#region CHANGES_PENDING_FLOWS
export const changeFlowInEdition = (flow) => ({
    type: actionTypes.floChangeFlowInEdition,
    payload: flow
});
export const changeComponentFlowInEdition = (componentFlow) => ({
    type: actionTypes.floChangeComponentFlowInEdition,
    payload: componentFlow
});
export const changeSubComponentFlowInEdition = (subComponentFlow) => ({
    type: actionTypes.floChangeSubComponentFlowInEdition,
    payload: subComponentFlow
});

export const changesPendingFlows = (isChangesPending) => ({
    type: actionTypes.floIsChangesPendingFlow,
    payload: isChangesPending
});
export const changesPendingComponentFlows = (isChangesPending) => ({
    type: actionTypes.floIsChangesPendingComponentFlow,
    payload: isChangesPending
});
export const changesPendingSubComponentFlows = (isChangesPending) => ({
    type: actionTypes.floIsChangesPendingSubComponentFlow,
    payload: isChangesPending
});
//#endregion

//#region GET_EXECUTIONS_BY_ID_FLOW
export const startLoadingExecutionsByIdFlow = () => ({
    type: actionTypes.floStartLoadingExecutionsByFlow
});

export const finishLoadingExecutionsByIdFlow = () => ({
    type: actionTypes.floFinishLoadingExecutionsByFlow
});

export const getExecutionsByIdFlow = (idFlow, accessToken) => {
    return (dispatch) => {
        dispatch(startLoadingExecutionsByIdFlow());
        fetchDataExecutionsByIdFlow(idFlow, accessToken)
            .then(({ data }) => {
                console.log('dataExecutions', data)
                dispatch(loadExecutionsByIdFlow(data));
            }).catch((e) => {
                console.log('error', e);
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, e));
                }
                dispatch(errorManageFlows(true, e));
            }).finally(() => {
                dispatch(finishLoadingExecutionsByIdFlow());
            });
    }
};

export const loadExecutionsByIdFlow = (data) => {

    if (!data || !data?.result) {
        return {
            type: actionTypes.floLoadExecutionsByFlow,
            payload: []
        }
    } else {

        const executions = data.result.map(cycle => {
            if (!cycle.executions) {
                return {
                    ...cycle,
                    status: 'unloaded',
                    executions: []
                }
            } else {
                let status = 'successful';
                cycle.executions.forEach(exec => {
                    if (exec.status !== 'successful') {
                        status = 'error';
                    }
                });

                return {
                    ...cycle,
                    status
                }
            }
        })

        console.log('executionsModify', executions)
        return {
            type: actionTypes.floLoadExecutionsByFlow,
            payload: executions
        };
    }
}
//#endregion

//#region ERROR_GENERAL_FLOWS
export const errorManageFlows = (isError, messageError) => ({
    type: actionTypes.floErrorManageFlows,
    payload: {
        messageError: !messageError ? '' : messageError,
        error: isError
    }
});
//#endregion


export const getSchemes = (accessToken) => {
    return (dispatch) => {
        dispatch(startLoadingAllFlows());
        fetcSchemeFlows(accessToken)
            .then(({ data }) => {
                dispatch(loadSchemesFlows(data));
            }).catch((e) => {
                console.log('error', e)
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, 'Ha ocurrido un error cargando la lista de flujos, intentálo de nuevo más tarde'));
                    dispatch(loadAllFlows(null));
                }
            }).finally(() => {
                dispatch(finishLoadingAllFlows());
            });
    }
};

export const loadSchemesFlows = (data) => ({
    type: actionTypes.GetSchemes,
    payload: !data ? [] : data
});
export const getDataFlow = (idFlow, accessToken) => {
    return (dispatch) => {
        GetDataFlow(idFlow, accessToken)
            .then(({ data }) => {
                console.log('getDataFlow ', data);
                dispatch(loadsimulatedData(data));
            }).catch((e) => {
                console.log('error', e)
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, 'Ha ocurrido un error el detalle del flujo, intentálo de nuevo más tarde'));
                    dispatch(loadAllFlows(null));
                }
            }).finally(() => {
                dispatch(finishLoadingAllFlows());
            });
    }
}

export const loadsimulatedData = (data) => ({
    type: actionTypes.LoadloadsimulatedData,
    payload: !data ? [] : data
});

export const setNotificaction = (data) => ({
    type: actionTypes.notificaction,
    payload: data
})

export const getAllConfig = (idFlow, accessToken) => {
    return (dispatch) => {
        // dispatch(startLoadingAllConfig());
        fetchDataConfig(idFlow, accessToken)
            .then(({ data }) => {
                console.log("Data __ ", data)
                dispatch(loadAllConfig(data));
            }).catch((e) => {
                console.log('error', e)
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, 'Ha ocurrido un error cargando la lista de flujos, intentálo de nuevo más tarde'));
                    dispatch(loadAllConfig(null));
                }
            }).finally(() => {
                // dispatch(finishLoadingAllConfig());
            });
    }
};

export const startLoadingAllConfig = () => ({
    type: actionTypes.floStartLoadingAllConfig
});
export const finishLoadingAllConfig = () => ({
    type: actionTypes.floFinishLoadingAllConfig
});

export const loadAllConfig = (data) => ({
    type: actionTypes.floLoadAllConfig,
    payload: !data ? [] : data
});


export const updateConfig = (idFlow, dto, accessToken) => {
    return (dispatch) => {
        // dispatch(startLoadingAllConfig());
        UpdateConfig(idFlow, dto, accessToken)
            .then(({ data }) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Registro actualizado con exito', typeMsg: 'success' }));
            }).catch((e) => {
                console.log('error', e)
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageFlows(true, 'Ha ocurrido un error cargando la lista de flujos, intentálo de nuevo más tarde'));
                    dispatch(loadAllConfig(null));
                }
            }).finally(() => {
                // dispatch(finishLoadingAllConfig());
            });
    }
};