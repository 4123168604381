import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { encrypt } from '../security/cryptography/encrypt';

const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;
const baseUrlFlow = process.env.REACT_APP_AWS_API_GATEWAY_URL_EXECUTE_FLOW;
const awsCredentialsExecuteApi = process.env.REACT_APP_AWS_CREDENTIALS_EXECUTE_API_GATEWAY;
const resetPasswordid_flow = process.env.REACT_APP_RESETPASSWORD;

export const getSession = async (userPool) => {
    // const storageToken = localStorage.getItem('REFRESH_TOKEN');

    return new Promise((resolve, reject) => {
        userPool.getSession((error, session) => {
            if (error) {
                console.log('errorGetSession', error)
                reject(error);
            }

            // const sessionToken = session?.refreshToken?.token;
            // console.log('sessionTokenGetSession', sessionToken)
            const sessionIsValid = session.isValid();
            // console.log('sessionIsValid', sessionIsValid)
            if (sessionIsValid) {
                resolve({
                    session: session,
                    // userData: userPool.getUserData(),
                    username: userPool.getUsername()
                });
            } else {
                reject();
            }
        });
    });
}


// export const validateLogin = async (cognitoUser, cognitoPool, authDetails) => {
//     return new Promise((resolve, reject) => {
//         cognitoUser.authenticateUser(authDetails, {
//             onSuccess: response => {   
//                 console.log('Resolve __ ', response);                 
//                 resolve(response);
//             },    
//             onFailure: error => {
//                 reject(error);
//             },
//             newPasswordRequired: (userAttributes, requiredAttributes) => {                
//                 reject({
//                     name: 'PasswordChangeRequired'
//                 });
//             }
//         });
//     })
// }

export const createNewPassword = async (TokenTmp, newPassword) => {

    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${TokenTmp}`
        },
        data: {
            process: 'user_alter',
            action: 'UPDATEPASSWORD',
            variables: JSON.stringify({ 'password': newPassword })
        }
    })
}

export const sendEmailRecoverPassword = async (userName) => {

    return axios({
        method: 'POST',
        url: baseUrlFlow,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Basic ${awsCredentialsExecuteApi}`
        },
        data: {
            'id_flow': resetPasswordid_flow,
            'variables': JSON.stringify({ "user_name": userName })
        }
    })
}

export const validateLogin = (user, password) => {

    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Basic ${awsCredentialsExecuteApi}`
        },
        data: {
            process: 'user_select',
            action: 'LOGIN',
            variables: JSON.stringify({ 'user_name': user, 'password': password })
        }
    })
    // return axios({
    //     method: 'POST',
    //     url: baseUrl,
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     data: {
    //         procedure_name: 'dbo.sp_manage_user',
    //         action: 'SELECT',
    //         user: {
    //             user_name: user,
    //             password: encrypt(password)
    //         }
    //     }
    // })
    // return new Promise((resolve, reject) =>{
    //     if(user === 'admin' && password === 'admin'){

    //         setTimeout(() => {
    //             resolve({
    //                 uid: '123456789',
    //                 name: 'Miguel',
    //                 token: '123456789asdfghj'
    //             });
    //         }, 2000);

    //     }else{

    //         setTimeout(() => {
    //             reject('Usuario o password invalido');
    //         }, 4000);
    //     }
    // })
}


export const checkAuthToken = (token) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            token: token
        }
    })
}