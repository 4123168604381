import React, { useState } from 'react'
import { Navbar } from './Navbar';
import { Sidebar } from './Sidebar';

export const NavigationBar = () => {

    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

  return (
    <>
        <Navbar openDrawer={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
        <Sidebar openDrawer={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
    </>
  )
}
