import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Constants } from '../../../../helpers/constants';
import { useManageSettings } from '../../../../hooks/useManageSettings';
import { CustomTable } from '../../../../components/tables/tableNavigate/CustomTable';
import { headersTypesStatus } from '../../utils/headersTypesStatus';
import { CreateTypeStatus } from './CreateTypeStatus';
import { DetailsTypesStatus } from './DetailsTypesStatus';

export const TypesStatus = () => {

    const [settingsManager] = useManageSettings(Constants.TYPES_STATUS);
    const {
        flowVariables,
        isChangedSettings,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        addingNewSettingsFlows,
        handleUpdatingSettingsFlows,
        isErrorFetch,
        messageErrorFetch,
        handleDeleteTypeStatus,
        handleRefreshSettingsFlow
    } = settingsManager;
    
    return (
        <Box>
            <Routes>
                <Route path="/" exact="true" element={
                    <CustomTable
                        nameItems="Types Status"
                        nameItem="type status"
                        valueOrder={"asc"}
                        keyItemSearch="name"
                        headers={headersTypesStatus} 
                        rows={!flowVariables ? [] : flowVariables}
                        initialOrderSort="id_status"
                        idRow="id_status"
                        toNavigateRow="/settings/flows/types-status/types-status-:id_status"
                        toNavigateCreateItem="/settings/flows/types-status/create-type-status"
                        isLoadingItems={loadingSettingsFlows}
                        isSavingItems={updatingSettingsFlows}
                        isDeletingItems={deletingSettingsFlows}
                        isErrorLoad={isErrorFetch}
                        messageError={messageErrorFetch}
                        handleDeleteItems={handleDeleteTypeStatus}
                        handleClickReferesh={handleRefreshSettingsFlow}
                        rowsByPage={7}
                    />
                }/>
                <Route path="/types-status-:idTypeStatus" element={
                    <DetailsTypesStatus settingsManager={settingsManager}/>
                }/>
                <Route  exact="true" path="/create-type-status" element={
                    <CreateTypeStatus settingsManager={settingsManager}/>
                }/>
            </Routes>
        </Box>
    )
}
