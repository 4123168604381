import axios from 'axios';

const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;

export const fetchUrlBoard = (accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl + '/dashboard',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        data: {
            'dashboardId': '13083715-3c9a-4ee1-b17a-f6ab11a207d9'
        }
    })
}