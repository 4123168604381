import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Paper, Typography } from '@mui/material';
import { JSONTextEditor } from './modalEditor/components/editors/JSONTextEditor';
import { JsonTree } from './jsontree/JsonTree';
import { Box } from '@mui/system';
import { config } from '../../helpers/config';

export const JsonEditorV2 = ({
  titleKey,
  contentJson,
  handleChange,
  viewCheckBoxSelect,
  viewEditorResult
}) => {

  const [checked, setChecked] = useState(!contentJson ? "empty" : "");

  const handleChangeCkecked = (e) => {
    if(e.target.name === "empty"){
      handleChange({});
      setChecked("empty");
    }else{
      setChecked(e.target.name);
    }
  }

  useEffect(() => {
    if(checked !== "" && checked !== "empty"){
      handleChange(config.flowVariables.jsonTemplates.find(template => (template.idTemplate === parseInt(checked))).contentTemplate)
    }
  }, [checked])
  
  return (
    <Grid container>
      {
        viewCheckBoxSelect && <Grid item md={12} sx={{px: 2, pb: 2}}>
          <Grid item md={8}>
            <Box>
              <Typography>
                Choose a json template22
              </Typography>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  {/* <FormControlLabel
                      control={
                          <Checkbox 
                              checked={checked === "empty"} 
                              size="small" 
                              name="empty"
                              onChange={handleChangeCkecked}
                          />
                      }
                      label={"Empty"}
                      value={"empty"}
                  /> */}
                  {
                      config.flowVariables.jsonTemplates.map(({idTemplate, typeTemplate, contentTemplate}, index) => {
                          return (
                              <FormControlLabel
                                  key={index} 
                                  control={
                                      <Checkbox 
                                          checked={checked === idTemplate.toString()} 
                                          size="small" 
                                          name={idTemplate.toString()}
                                          onChange={handleChangeCkecked}
                                      />
                                  }
                                  label={typeTemplate}
                                  value={idTemplate}
                              />
                          )
                      })
                  }                              
                </FormGroup>
              </FormControl>
            </Box> 
          </Grid>
        </Grid>
      }      
      <Grid item md={12}>
        <Grid container>
          <Grid 
            item 
            xs={12}
            sm={!viewEditorResult ? 6 : 12}
            sx={{height: '100%', maxHeight: 400, px: 2}}
          >
            {/* <ModalEditor value={data} onChange={setData} /> */}
            <Paper 
              elevation={0}  
              sx={{
                height: '100%', 
                overflowY: 'auto', 
                overflowX: 'hidden',
                bgcolor: (theme) => theme.palette.paper.background
              }}
            >
              <JsonTree
                keyParent={titleKey}
                contentJson={!contentJson ? {} : contentJson}
                handleChange={handleChange}
              />
            </Paper>
          </Grid>
          {
            !viewEditorResult && (
              <Grid 
                item 
                xs={12} 
                sm={6}
                sx={{height: '100%', maxHeight: 400, px: 2}}
              >
                <JSONTextEditor value={contentJson} onChange={handleChange} />
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

