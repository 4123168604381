import { actionTypes } from "../contants/actionTypes";

const initialState = {
    updatingData: false,
    deletingDocs: false,
    deletingComponentDocs: false,
    addingNewDoc: false,
    listAllDocs: [],
    isLoading: true,
    loadingExecutions: false,
    historyDoc: [],
    listDeatils: [],
    columsTableDeatails: [],
    isLoadingDetails: true,
    rowsChecked: [],
    filters: {},
    pagination: {
        pageNumber: 1,
        pageSize: 100
    }
}

export const sharedViewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.isLoadingLoadAllDocs:
            return {
                ...state,
                isLoading: action.payload,
            }
        case actionTypes.loadAllDocs:
            return {
                ...state,
                listAllDocs: action.payload,
            }
        case actionTypes.loadHistory:
            return {
                ...state,
                historyDoc: action.payload,
            }
        case actionTypes.floStartUpdatingFlow:
            return {
                ...state,
                updatingData: action.payload
            }
        case actionTypes.floStartDeletingFlows:
            return {
                ...state,
                deletingDocs: true,
            }
        case actionTypes.floFinishDeletingFlows:
            return {
                ...state,
                deletingDocs: false,
            }
        case actionTypes.floStartDeletingComponentFlow:
            return {
                ...state,
                deletingComponentDocs: true,
            }
        case actionTypes.floFinishDeletingComponentFlow:
            return {
                ...state,
                deletingComponentDocs: false,
            }
        case actionTypes.floStartAddingNewFlow:
            return {
                ...state,
                addingNewDoc: true,
            }
        case actionTypes.floFinishAddingNewFlow:
            return {
                ...state,
                addingNewDoc: false,
            }
        case actionTypes.floStartLoadingExecutionsByFlow:
            return {
                ...state,
                loadingExecutions: true,
            }
        case actionTypes.floFinishLoadingExecutionsByFlow:
            return {
                ...state,
                loadingExecutions: false,
            }
        case actionTypes.getListDetails:
            return {
                ...state,
                listDeatils: action.payload,
            }
        case actionTypes.getColumnsTableDetails:
            return {
                ...state,
                columsTableDeatails: action.payload,
            }
        case actionTypes.isLoadingListDetails:
            return {
                ...state,
                isLoadingDetails: action.payload,
            }
        case actionTypes.rowsChecked:
            return {
                ...state,
                rowsChecked: action.payload,
            }
        case actionTypes.filterAndPagination:
            return {
                ...state,
                filters: action.payload.filters,
                pagination: action.payload.pagination,
            }
        default:
            return state;
    }
}