import styled from '@emotion/styled';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar as MuiAppBar, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { useDataAuth } from '../../hooks/useDataAuth';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: `linear-gradient( to right, ${theme.palette.navigationBar.main}, ${theme.palette.navigationBar.secondary} )`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Navbar = ({ openDrawer, handleDrawerOpen }) => {

  const dispatch = useDispatch();
  const [{ handleLogout, userSession }] = useDataAuth();
  const [anchorElMenuUser, setAnchorElMenuUser] = useState(null);
  const openMenuUser = Boolean(anchorElMenuUser);
  const handleClickMenuUser = (event) => {
    setAnchorElMenuUser(event.currentTarget);
  };
  const handleCloseMenuUser = () => {
    setAnchorElMenuUser(null);
  };

  const handleClickLogout = () => {
    setAnchorElMenuUser(null);
    handleLogout();
    dispatch(logout());
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      open={openDrawer}
    >
      <Toolbar
        variant="dense"
        sx={{ borderBottom: '1px solid #414141' }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(openDrawer && { display: 'none' }),
          }}
        >
          <MenuIcon color="primary" />
        </IconButton>

        <Grid
          container
        >
          <Grid
            item md={6}
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            {/* ACA VA A IR LA IMAGEN DE SUBIEN */}
          </Grid>

          <Grid
            item md={6}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Typography>
              {`${userSession.name}  ${userSession.last_name}`}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls={openMenuUser ? 'user-menu' : undefined}
              aria-expanded={openMenuUser ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClickMenuUser}
              color="inherit"
            >
              <AccountCircle color="primary" />
            </IconButton>
            <Menu
              keepMounted
              id="user-menu"
              anchorEl={anchorElMenuUser}
              open={openMenuUser}
              onClose={handleCloseMenuUser}
              MenuListProps={{
                'aria-labelledby': 'user-menu',
              }}
            >
              {/* <MenuItem onClick={handleCloseMenuUser}>Profile</MenuItem>
              <MenuItem onClick={handleCloseMenuUser}>My account</MenuItem> */}
              <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
