import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changePassword, getAuthSession, initializingLogin, RecoverPassword } from '../redux/actions/auth';

export const useDataAuth = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [sendNotification] = useNotification();
    const {
        checkingSession,
        checkingLogin,
        errorLogging,
        isAuth,
        username,
        // dataUser,
        userSession,
        tokenTemporal,
        passwordChangeRequired,
        creatingNewPassword,
        isNotification,
        msg,
        typeMsg
    } = useSelector(state => state.auth);

    const { companySelected, } = useSelector(state => state.company)

    const [cognitoUser, setCognitoUser] = useState(null);

    // const [isUserAuth, setIsUserAuth] = useState(localStorage.getItem('isUserAuth') || false);

    // useEffect(() => {
    //     const userPool = cognitoPool.getCurrentUser();
    //     if(userPool) {
    //         validateAuthSession(userPool);
    //         // handleGetUserSession(userPool);
    //     } 
    //     else {
    //         console.log("Redirect __ ", userPool);
    //         navigate('/login')
    //     }        
    // }, []);

    useEffect(() => {
        if (!isAuth) {
            navigate('/login');
        }
        // else 
        // if(!companySelected){
        //     navigate('/login/company')
        // }
    }, [isAuth])

    useEffect(() => {
        if (passwordChangeRequired) {
            navigate('/login/newPassword')
        }
    }, [passwordChangeRequired])



    // const {username, token} = JSON.parse(localStorage.getItem('user'));
    // return [companySelected.idCompanySelected, username, token];

    // useEffect(() => {
    //     if (!isUserAuth) {
    //         navigate('/login/auth');
    //     }
    // //   } else{
    // //     navigate('/login/company')
    // //   }
    // }, [])

    // useEffect(() => {
    //     localStorage.setItem('isUserAuth', isAuth);
    //     if(isAuth && user){
    //         setIsUserAuth(true);
    //         localStorage.setItem('user', JSON.stringify(user));
    //     }else{
    //         setIsUserAuth(false);
    //         localStorage.removeItem('user');
    //         localStorage.removeItem('companySelected');
    //     }
    // }, [isAuth]);

    // useEffect(() => {
    //   if(passwordChangeRequired){
    //     navigate('login/newPassword')
    //   }
    // }, [passwordChangeRequired])


    const validateAuthSession = (userPool) => {
        dispatch(getAuthSession(userPool));
    }

    // const handleLogin = (username, password) => {
    //     const newCognitoUser = new CognitoUser({
    //         Username: username,
    //         Pool: cognitoPool
    //     });
    //     setCognitoUser(newCognitoUser);

    //     dispatch(initializingLogin(
    //         newCognitoUser,
    //         cognitoPool,
    //         new AuthenticationDetails({
    //             Username: username,
    //             Password: password,
    //         })
    //     ));
    // };
    const handleLogin = (username, password) => {
        dispatch(initializingLogin(username, password));
    };

    const handleRecoverPassword = (username) => {
        dispatch(RecoverPassword(username));
    };

    const handleCreateNewPassword = (TokenTmp, newPassword) => {
        dispatch(changePassword(TokenTmp, newPassword));
    }

    const handleLogout = () => {
        localStorage.clear();
    }

    // const handleGetUserSession = async () =>         
    //     await getSession(userPool)
    //         .then(userSession => userSession)
    //         .catch(error => console.log("error: ", error));


    return [{
        checkingSession,
        checkingLogin,
        errorLogging,
        isAuth,
        username,
        userSession,
        tokenTemporal,
        // idCompany: localStorage.getItem('companySelected') ? JSON.parse(localStorage.getItem('companySelected')).idCompanySelected : null,
        idCompany: companySelected?.idCompanySelected,
        passwordChangeRequired,
        creatingNewPassword,
        handleLogin,
        handleCreateNewPassword,
        handleRecoverPassword,
        handleLogout,
        isNotification,
        msg,
        typeMsg
    }];


    // const {companySelected} = useSelector(state => state.company);
    // const {username, token} = JSON.parse(localStorage.getItem('user'));
    // return [companySelected.idCompanySelected, username, token];
}