import { actionTypes } from "../contants/actionTypes";

const initialState = {
    listAllDocs: [],
    isLoading: true,
    historyDoc: []
}

export const docQueryReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.isLoadingLoadAllDocs:
            return {
                ...state,
                isLoading: action.payload,
            }
        case actionTypes.loadAllDocs:
            return {
                ...state,
                listAllDocs: action.payload,
            }
        case actionTypes.loadHistory:
            return {
                ...state,
                historyDoc: action.payload,
            }
        default:
            return state;
    }
}