import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Icons from '@mui/icons-material';
import {
    Box, darken, TablePagination, TableCell, TableHead as MuiTableHead, TableRow, Typography, TableContainer, Table, TableBody, Paper, Tooltip, CircularProgress,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent
} from '@mui/material';
import styled from '@emotion/styled';
import { useDataAuth } from '../../hooks/useDataAuth';
import { useSharedViews } from '../../hooks/useSharedViews';
import { getDetails, getColumns } from '../../redux/actions/sharedViews';
import { LoadButtons } from '../sharedViews/loadActions/LoadButtons';
import { FilterTable } from '../others/FilterTable';
import { Close } from '@mui/icons-material';

const TableHead = styled(MuiTableHead)(({ theme }) => ({
    '& .css-x6proc-MuiTableCell-root': {
        backgroundColor: '#b4b4b4',
        borderRight: `thin dotted   ${darken(theme.palette.text.main, 0.8)}`,
    },
    '& .MuiTableCell-root': {
        borderRight: `thin dotted   ${darken(theme.palette.text.main, 0.8)}`,
    },
    color: theme.palette.primary.main,
    '& .MuiTableCell-root span': {
        fontWeight: 'bold',
        '&.Mui-inactive, .MuiTableSortLabel-icon ': {
            color: theme.palette.text.main,
            opacity: 0.2,
        },
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    '& .MuiTableCell-root span.MuiSvgIcon-root:hover': {
        color: theme.palette.text.main,
    }
}));

export const PopUpSharedTable = ({ item, data }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [idUserAction, setIdUserAction] = useState('');
    const [{ userSession }] = useDataAuth();
    const [filters] = useState({
        "id_unique": {
            "value": data.id,
            "operator": "=",
            "type": "string"
        }
    });
    const [pagination] = useState(
        {
            pageNumber: 1,
            pageSize: 25
        }
    );
    const [sharedViews] = useSharedViews();
    const {
        listDeatils,
        columsTableDeatails,
        isLoadingDetails
    } = sharedViews;

    const GetDetails = useCallback((id, variables) => {
        dispatch(getDetails(userSession.token, id, variables));
    }, [dispatch, userSession.token]);

    const handleChangePage = (event, newPage) => {
        pagination['pageNumber'] = newPage + 1;
        GetDetails(idUserAction, { filters, pagination });
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        pagination['pageNumber'] = 1;
        pagination['pageSize'] = newRowsPerPage;
        GetDetails(idUserAction, { filters, pagination });
    };

    const Search = (param, param2) => {
        filters[param2] = param;
        pagination['pageNumber'] = 1;
        GetDetails(idUserAction, { filters, pagination });
    }

    const ResetSearch = (param) => {
        delete filters[param];
        GetDetails(idUserAction, { filters, pagination });
    }

    const GetColumns = (config) => {
        setIdUserAction(config.id_user_action);
        dispatch(getColumns(userSession.token, config.id_user_action));
        GetDetails(config.id_user_action, { filters, pagination });
        setOpen(true);
    }

    return (
        <React.Fragment>
            <Tooltip title={item.accion}>
                <IconButton
                    id={`id_${item.accion}`}
                    size="small"
                    sx={{ p: 0 }}
                    onClick={() => GetColumns(item?.config)}
                >
                    <span style={{ fontSize: 25, color: item.color }}>
                        {React.createElement(Icons[item.icon])}
                    </span>
                </IconButton>
            </Tooltip >
            <Dialog
                open={open}
                fullWidth
                maxWidth='Large'
                PaperProps={{
                    style: { height: '90%' },
                }}
            >
                <DialogTitle>
                    <IconButton
                        aria-label='close'
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box className='custom-hard-table-zd-nix' sx={{ width: '100%' }}>
                        <Paper
                            sx={{
                                width: '100%',
                                mb: 2,
                                mt: 2,
                                boxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                                WebkitBoxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                                bgcolor: (theme) => theme.palette.paper.background,
                                borderTopRightRadius: '14px',
                                borderTopLeftRadius: '14px',
                            }}
                            elevation={3}
                        >
                            <TableContainer
                                style={{ height: '72vh', overflow: 'auto', borderBottom: 'solid 1px' }}
                            >
                                <Table
                                    aria-labelledby='tableTitle'
                                    size='small'
                                    stickyHeader
                                >
                                    <TableHead>
                                        <TableRow sx={{ px: 2 }}>
                                            <TableCell
                                                padding='normal'
                                                align='center'
                                                style={{ minWidth: 120, borderTopLeftRadius: '14px' }}
                                            >
                                                <Typography sx={{ fontWeight: 'bold' }} component='span'>
                                                    Actions
                                                </Typography>
                                            </TableCell>
                                            {
                                                (columsTableDeatails && columsTableDeatails.length) > 0 &&
                                                columsTableDeatails.map((col, index) => (
                                                    col.visible &&
                                                    <TableCell
                                                        padding='normal'
                                                        align='center'
                                                        key={`tc-${index}`}
                                                        style={{
                                                            ...(index === columsTableDeatails.length - 1 && {
                                                                borderTopRightRadius: '14px',
                                                            })
                                                        }}
                                                    >
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Typography sx={{ fontWeight: 'bold', marginRight: '8px' }} component='span'>
                                                                {col.label}
                                                            </Typography>
                                                            {col.filterable && (
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <FilterTable colum={col} Search={Search} ResetSearch={ResetSearch} />
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            isLoadingDetails ? (
                                                <TableRow sx={{ height: 200, py: 2 }}>
                                                    <TableCell
                                                        colSpan={columsTableDeatails ? columsTableDeatails.length + 2 : 2}
                                                        align='center'
                                                    >
                                                        <CircularProgress
                                                            color='primary'
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ) : !isLoadingDetails && !listDeatils.length > 0 ? (
                                                <TableRow sx={{ height: 200, py: 2 }}>
                                                    <TableCell
                                                        colSpan={columsTableDeatails ? columsTableDeatails.length + 2 : 2}
                                                        align='center'
                                                    >
                                                        <Typography>No hay registros para mostrar</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                listDeatils.map((item) => (
                                                    <TableRow
                                                        tabIndex={-1}
                                                        key={item.id}
                                                    >
                                                        <TableCell padding='normal' align='center'>
                                                            <Typography>
                                                                <LoadButtons data={item} handleChange={GetDetails} />
                                                            </Typography>
                                                        </TableCell>
                                                        {columsTableDeatails.map((col, index) => (
                                                            col.visible &&
                                                            <TableCell
                                                                key={`tc-${index}`}
                                                                padding='normal'
                                                                align='center'
                                                            >
                                                                <Typography>
                                                                    {item[col.id]}
                                                                </Typography>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ display: 'table-row', verticalAlign: 'bottom' }}
                                rowsPerPageOptions={[25, 50, 100]}
                                component='Box'
                                count={10000}
                                rowsPerPage={pagination.pageSize}
                                page={pagination.pageNumber - 1}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}