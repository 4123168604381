import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Close from '@mui/icons-material/Close';
import FlipCameraAndroid from '@mui/icons-material/FlipCameraAndroid';
import Check from '@mui/icons-material/Check';
import DataObject from '@mui/icons-material/DataObject';
import TableRows from '@mui/icons-material/TableRows';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import DynamicForm from '../../../components/dynamicForm/DynamicForm';
import { JsonEditor } from '../../../components/jsonEditorV2/modalEditor/components/editors/JsonEditor';
import { ParserEditor } from '../../../components/htmlEditor/ParserEditor';
import { TableParser } from '../../../components/tables/TableParser';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const FlowEditor = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        customFunction() {
            console.log('Open true');
            setOpen(true);
        }
    }));

    const {
        title,
        contentJson,
        scheme,
        onChange,
        setChanges,
        setNewValue,
        disabledForm,
        isParser
    } = props;

    // const childRefQuill = useRef(null);
    // const childRefAce = useRef(null);
    const [open, setOpen] = useState(false);
    const [valueTab, setValueTab] = useState(disabledForm ? 0 : 1);

    const OnChengeSetValue = (event, newValue) => {
        setValueTab(newValue);
    };

    // const handleOnChange = (content) => {
    //     setValue(content);
    //     handleChange(field.id, content);
    // };

    // const ResetChanges = () => {
    //     if (valueTab === 0 && childRefQuill.current) {
    //         childRefQuill.current.ResetChanges();
    //     }
    //     if (valueTab === 1 && childRefAce.current) {
    //         childRefAce.current.ResetChanges();
    //     }
    // }
    // const AcceptChanges = () => {
    //     if (valueTab === 0 && childRefQuill.current) {
    //         childRefQuill.current.AcceptChanges();
    //     }
    //     if (valueTab === 1 && childRefAce.current) {
    //         childRefAce.current.AcceptChanges();
    //     }
    // }

    useEffect(() => {
        setValueTab(disabledForm ? 0 : 1);
    }, [disabledForm])


    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                PaperProps={{
                    style: { height: '95%' },
                }}
            >
                <DialogTitle>
                    <Typography variant='h5' component='h5'>
                        {title}
                    </Typography>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
                        <Tabs
                            sx={{ width: '70%' }}
                            value={valueTab}
                            onChange={OnChengeSetValue}
                            aria-label='basic tabs example'
                        >
                            <Tab icon={<DataObject color='success' />} label='Ver en formato Json' />
                            <Tab icon={<DynamicFormIcon color='info' />} label='Ver en formulario' disabled={disabledForm} />
                            <Tab icon={<TableRows color='warning-' />} label='Visualizar resultado' disabled={isParser} />
                            {/* <Tab icon={<DataObject color='success' />} label='Editar parser' /> */}
                        </Tabs>
                        {/* <Box
                            display="flex"
                            sx={{ mb: 2, position: 'sticky', top: 0, zIndex: 1, width: '30%' }}
                            justifyContent="flex-end"
                        >
                            <Tooltip title='Actualizar cambios'>
                                <IconButton
                                    color="success"
                                    onClick={() => AcceptChanges()}
                                >
                                    <Check />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Desacer cambios'>
                                <IconButton
                                    color="error"
                                    onClick={() => ResetChanges()}
                                >
                                    <FlipCameraAndroid />
                                </IconButton>
                            </Tooltip>
                        </Box> */}
                    </Box>
                    <IconButton
                        aria-label='close'
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TabPanel value={valueTab} index={0} height={1200}>
                        <JsonEditor
                            value={contentJson}
                            onChange={setNewValue}
                        />
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <DynamicForm
                            values={contentJson}
                            scheme={scheme}
                            onChange={onChange}
                            setChanges={setChanges}
                        />
                    </TabPanel>
                    <TabPanel value={valueTab} index={2}>
                        <TableParser data={contentJson} disabled={isParser} onChange={setNewValue} />
                    </TabPanel>
                    {/* <TabPanel value={valueTab} index={3}>
                        <ParserEditor data={contentJson} />
                    </TabPanel> */}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
})

FlowEditor.displayName = 'FlowEditor';
export default FlowEditor;