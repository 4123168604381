import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Constants } from '../../../../helpers/constants';
import { useManageSettings } from '../../../../hooks/useManageSettings';
import { CustomTable } from '../../../../components/tables/tableNavigate/CustomTable';
import { headersTypesExecute } from '../../utils/headersTypeExecute';
import { CreateTypeExecute } from './CreateTypeExecute';
import { DetailsTypesExecute } from './DetailsTypesExecute';

export const TypesExecute = () => {

    const [settingsManager] = useManageSettings(Constants.TYPES_EXECUTE);
    const {
        flowVariables,
        isChangedSettings,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        addingNewSettingsFlows,
        handleUpdatingSettingsFlows,
        isErrorFetch,
        messageErrorFetch,
        handleDeleteTypeFlow,
        handleRefreshSettingsFlow
    } = settingsManager;

    
    return (
        <Box>
            <Routes>
                <Route path="/" exact="true" element={
                    <CustomTable
                        nameItems="Types Execute"
                        nameItem="type execute"
                        valueOrder={"asc"}
                        keyItemSearch="type"
                        headers={headersTypesExecute} 
                        rows={flowVariables}
                        initialOrderSort="id"
                        idRow="id"
                        toNavigateRow="/settings/flows/types-execute/types-execute-:id"
                        toNavigateCreateItem="/settings/flows/types-execute/create-type-execute"
                        isLoadingItems={loadingSettingsFlows}
                        isSavingItems={updatingSettingsFlows}
                        isDeletingItems={deletingSettingsFlows}
                        isErrorLoad={isErrorFetch}
                        messageError={messageErrorFetch}
                        handleDeleteItems={handleDeleteTypeFlow}
                        handleClickReferesh={handleRefreshSettingsFlow}
                        rowsByPage={7}
                    />
                }/>
                <Route path="/types-execute-:idTypeExecute" element={
                    <DetailsTypesExecute settingsManager={settingsManager}/>
                }/>
                <Route  exact="true" path="/create-type-execute" element={
                    <CreateTypeExecute settingsManager={settingsManager}/>
                }/>
            </Routes>
        </Box>
    )
}
