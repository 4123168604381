import { Edit, MoreVert, Delete, InfoOutlined } from '@mui/icons-material';
import { Avatar, Button, Divider, Grid, IconButton, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Dialog } from '../../../components/others/Dialog';

export const ItemListSubComponentFlow = ({
    handleDeleteSubComponentFlow,
    subComponentFlow
}) => {

    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const params = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmDeleteSubComponentFlow = () => {
        handleDeleteSubComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            !isNaN(subComponentFlow.id_flow_component) ? parseInt(subComponentFlow.id_flow_component) : subComponentFlow.id_flow_component,
            true
        );
        setOpenDialogConfirmDelete(false);
    }

    return (
        <>
            <ListItem
                secondaryAction={
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip
                            title="Info"
                        >
                            <IconButton
                                onClick={() => setShowDescription(!showDescription)}
                            >
                                <InfoOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip 
                            title="Edit"
                        >
                            <IconButton 
                                component={Link}
                                to={`/flows/flow-${params.idFlow}/component-flow-${params.idComponentFlow}/sub-component-flow-${subComponentFlow.id_flow_component}`}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip 
                            title="More"
                        >
                            <IconButton 
                                size="small" 
                                color="inherit"
                                onClick={handleClick}
                                aria-controls={open ? 'sub-component-flow-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <MoreVert />
                            </IconButton>
                        </Tooltip>                        
                    </Box>
                }
            >
                <ListItemAvatar>
                    <Avatar
                        sizes="small"
                        className="bg-color-primary"
                    >
                        {subComponentFlow.order_in_flow} 
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={`Id Sub Flow Component: ${subComponentFlow.id_flow_component}`}
                    secondary={`Type Component: ${subComponentFlow.type}`}
                />
            </ListItem>
            <Divider/>
            {
                showDescription && <ListItem
                    className="paper.root"
                >
                    <ListItemText
                        primary={"Description"}
                        secondary={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"}
                    />
                </ListItem>
            }

            <Menu
                anchorEl={anchorEl}
                id="sub-component-flow-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                elevation: 3,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    },
                    '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                    },
                },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() => setOpenDialogConfirmDelete(true)}
                >
                    <Delete /> Eliminar sub component flow
                </MenuItem>
            </Menu>
            <Dialog
                openDialog={openDialogConfirmDelete}
                widthDialog="xs"
                content={
                    <Typography
                        variant="h7"
                    >
                        Are you sure you want to remove this component?
                    </Typography>
                }
                styleContent={{px: 3, py: 3}}
                actions={
                    <Grid
                        item md={12} 
                        sx={{
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            py: 2
                        }}
                        >
                        <Button
                            variant="outlined" 
                            color="error" 
                            autoFocus
                            size="small"
                            onClick={() => setOpenDialogConfirmDelete(false)}
                            sx={{
                                color: (theme) => theme.palette.text.main,
                                mx: 3
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained"
                            color="error"
                            onClick={handleConfirmDeleteSubComponentFlow}
                            size="small"
                        >
                            Delete sub component flow
                        </Button>
                    </Grid>
                }
            />
        </>
    );
}
