import { actionTypes } from "../contants/actionTypes";

const initialState = {
    boardUrl: ''
}

export const boardsReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.GetBoardUrl:
            return {
                ...state,
                boardUrl: action.payload,
            }
        default:
            return state;
    }
}