import { Box, Grid, Button } from '@mui/material';
import React from 'react';
import { EdiItemTabs } from './EdiItemTabs';
import { EditItemSummary } from './EditItemSummary';
import { EditItemToolbar } from './EditItemToolbar';

export const EditItem = ({
    isLoadingItems,
    titleEditItem,
    nameItem,
    isSaving,
    statusButtonSave,
    handleSaveChanges,
    isDeleting,
    statusButtonDelete,
    handleClickDelete,
    contentSummary,
    tabs
}) => {

    const Print = () =>{
        console.log('tabs  __ ', tabs);
    }

    return (
        <>
            <Button className='btn-succes' onClick={() => Print()}>Click EditItem</Button>
            <Grid container sx={{ mb: 2 }} >
                <EditItemToolbar
                    isLoadingItems={isLoadingItems}
                    titleEditItem={titleEditItem}
                    nameItem={nameItem}
                    isSaving={isSaving}
                    statusButtonSave={statusButtonSave}
                    isDeleting={isDeleting}
                    handleSaveChanges={handleSaveChanges}
                    statusButtonDelete={statusButtonDelete}
                    handleClickDelete={handleClickDelete}
                />
            </Grid>
            {/* <Grid 
                container
                sx={{mb:2}}
            >
                <EditItemSummary
                    isLoadingItems={isLoadingItems}
                    contentSummary={contentSummary}
                />
            </Grid> */}

            {
                !tabs || 
                <Grid container>
                    <EdiItemTabs
                        isLoadingItems={isLoadingItems}
                        elevation={4}
                        tabs={tabs}
                    />
                </Grid>
            }
        </>
    )
}
