import React, { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Tooltip, Button, Dialog, DialogTitle, DialogContent, Grid, TextField, FormControlLabel, Switch, MenuItem } from '@mui/material';
import { AddTask, Close, Edit, PersonAdd } from '@mui/icons-material';
import { useForm } from '../../hooks/useForm';
import { useErrorInputs } from '../../hooks/useErrorInputs';
import { TablePermissionsUsers } from '../users/TablePermissionsUsers';
import { useDispatch } from 'react-redux';
import { useDataAuth } from '../../hooks/useDataAuth';
import { useManageUsers } from '../../hooks/useManageUsers';
import { actionTypes } from "../../redux/contants/actionTypes";


export const ModalFormsEditCreate = ({ data, isCreate = false, handleClick }) => {
    const [{ userSession }] = useDataAuth();
    const [UsersManager] = useManageUsers();
    const dispatch = useDispatch();
    const {
        listCompaniesPermissions,
        listGroupPermissions,
        getCompaniesAcces,
        getGroupsAcces,
        AddPermissions,
        SaveData,
        loadData
    } = UsersManager;

    const [{ user_nameError, nameError, last_nameError }, handleErrorInput, resetErrors] = useErrorInputs({});

    const [{ id_user, user_name, name, last_name, status }, handleInputChange, resetFormValues] = useForm({
        id_user: data?.id_user || 0,
        user_name: data?.user_name,
        name: data?.name,
        last_name: data?.last_name,
        status: data?.status || 1
    });

    const [open, setOpen] = useState(false);
    const [idCompany, setIdCompany] = useState('');
    const [idPermission, setIdPermission] = useState('');

    const handleSubmit = (e) => {
        console.log('handleSubmit');
        e.preventDefault();
        let isErrors = false;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!user_name && isCreate) {
            handleErrorInput("user_nameError", {
                isError: true,
                messageError: 'Campo requerido'
            });
            isErrors = true;
        }
        else if (!emailRegex.test(user_name) && isCreate) {
            handleErrorInput("user_nameError", {
                'isError': true,
                'messageError': 'El email ingresado no es valido'
            });
            isErrors = true;
        }
        else {
            handleErrorInput("user_nameError", {
                'isError': false,
                'messageError': ''
            });
        }

        if (!name) {
            handleErrorInput("nameError", {
                isError: true,
                messageError: 'Campo requerido'
            });
            isErrors = true;
        }
        else if (name.trim().length < 4) {
            handleErrorInput("nameError", {
                'isError': true,
                'messageError': 'El campo debe contener al menos 4 caracteres'
            });
            isErrors = true;
        }
        else {
            handleErrorInput("nameError", {
                'isError': false,
                'messageError': ''
            });
        }

        if (!last_name) {
            handleErrorInput("last_nameError", {
                isError: true,
                messageError: 'Campo requerido'
            });
            isErrors = true;
        }
        else if (last_name.trim().length < 8) {
            isErrors = true;
            handleErrorInput("last_nameError", {
                'isError': true,
                'messageError': 'El campo debe contener al menos 8 caracteres'
            });
            isErrors = true;
        }
        else {
            handleErrorInput("last_nameError", {
                'isError': false,
                'messageError': ''
            });
        }
        if (isErrors) {
            console.log({ isErrors });
            return false;
        }
        SaveChanges();
    }

    const getPermission = (idUser) => {
        Reset();
        dispatch(handleClick(userSession.token, idUser));
    }

    useEffect(() => {
        dispatch(getCompaniesAcces(userSession.token));
    }, [dispatch, getCompaniesAcces, userSession])

    const handleChangeEmpresa = (e) => {
        setIdCompany(e.target.value);
        dispatch(getGroupsAcces(userSession.token, e.target.value));
    }

    const handleAddPermission = () => {
        let dto = {
            id_user: id_user,
            id_company: idCompany,
            user_access_type: 'group',
            id_access: idPermission
        };
        AddPermissions(userSession.token, dto, id_user, isCreate);
        setIdCompany('');
        setIdPermission('');
    }

    const Reset = () => {
        dispatch(loadData(actionTypes.loadAllPermissions, []));
        setIdCompany('');
        setIdPermission('');
        setOpen(true);
        resetErrors();
        resetFormValues();
    }

    const SaveChanges = () => {
        let dto = { id_user, user_name, name, last_name, status };
        SaveData(userSession.token, dto, isCreate);
        setOpen(false);
    }

    return (
        <React.Fragment>
            {
                isCreate ?
                    <Tooltip title={'Agregar'}>
                        <IconButton
                            aria-label="close"
                            onClick={() => Reset()}
                            sx={{ color: 'blue' }}
                        >
                            <PersonAdd />
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title={'Editar'}>
                        <IconButton
                            aria-label='close'
                            onClick={() => getPermission(data.id_user)}
                            sx={{ color: 'gray' }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
            }

            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                PaperProps={{
                    style: { height: '90%' },
                }}
            >
                <DialogTitle>
                    <Typography variant='h5' component='h5'>
                        Crear/Editar usuario
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form noValidate autoComplete='off' onSubmit={handleSubmit}>
                        <Box sx={{ marginTop: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        error={user_nameError?.isError}
                                        color={(user_nameError?.isError) ? 'error' : 'text'}
                                        helperText={user_nameError?.messageError || ''}
                                        required
                                        fullWidth
                                        label='Correo/Usuario'
                                        placeholder='correo@gmail.com'
                                        variant='outlined'
                                        name='user_name'
                                        size='small'
                                        disabled={!isCreate}
                                        value={user_name}
                                        onChange={handleInputChange}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        error={nameError?.isError}
                                        color={(nameError?.isError) ? 'error' : 'text'}
                                        helperText={nameError?.messageError || ''}
                                        required
                                        fullWidth
                                        label='Nombres'
                                        InputLabelProps={{ shrink: true }}
                                        size='small'
                                        variant='outlined'
                                        name='name'
                                        value={name}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        error={last_nameError?.isError}
                                        color={(last_nameError?.isError) ? 'error' : 'text'}
                                        helperText={last_nameError?.messageError || ''}
                                        required
                                        fullWidth
                                        label='Apellidos'
                                        InputLabelProps={{ shrink: true }}
                                        size='small'
                                        variant='outlined'
                                        name='last_name'
                                        value={last_name}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        labelPlacement="top"
                                        control={
                                            <Switch
                                                id='status'
                                                name='status'
                                                onChange={(e) => handleInputChange(
                                                    {
                                                        target: {
                                                            name: e.target.name,
                                                            value: e.target.checked ? 1 : 0
                                                        }
                                                    }
                                                )}
                                                checked={status === 1}
                                            />
                                        }
                                        label={'Activo'}
                                    />
                                </Grid>
                            </Grid>

                            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
                                <Typography variant='h6' component='h6'>
                                    Permisos otrogados
                                </Typography>
                                <Grid sx={{ marginTop: 5 }} container spacing={2}>
                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            id={'IdCompanies'}
                                            name={'InputCompanies'}
                                            select
                                            fullWidth
                                            label='Empresa'
                                            variant="outlined"
                                            value={idCompany}
                                            onChange={handleChangeEmpresa}
                                            size="small"
                                            defaultValue="none"
                                            placeholder="select an option"
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                                disabled
                                                sx={{ fontWeight: 'bold' }}
                                            >
                                                select an option
                                            </MenuItem>
                                            {
                                                listCompaniesPermissions.map((option) => (
                                                    <MenuItem
                                                        key={option.id_company}
                                                        value={option.id_company}
                                                        sx={{ fontWeight: 'bold' }}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            id={'IdPermissions'}
                                            name={'InputPermissions'}
                                            select
                                            fullWidth
                                            label='Permiso'
                                            variant="outlined"
                                            value={idPermission}
                                            onChange={(e) => setIdPermission(e.target.value)}
                                            size="small"
                                            defaultValue="none"
                                            placeholder="select an option"
                                            disabled={idCompany === ''}
                                        >
                                            <MenuItem
                                                key=""
                                                value=""
                                                disabled
                                                sx={{ fontWeight: 'bold' }}
                                            >
                                                select an option
                                            </MenuItem>
                                            {
                                                listGroupPermissions.map((option) => (
                                                    <MenuItem
                                                        key={option.id_user_group}
                                                        value={option.id_user_group}
                                                        sx={{ fontWeight: 'bold' }}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Tooltip title={'Agregar'}>
                                            <IconButton
                                                aria-label="close"
                                                onClick={() => handleAddPermission()}
                                                disabled={idPermission === '' || idCompany === ''}
                                                sx={{
                                                    color: 'blue',
                                                }}
                                            >
                                                <AddTask />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
                                <TablePermissionsUsers
                                    isCreate={isCreate}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <Button
                                    onClick={() => setOpen(false)}
                                    variant='contained'
                                    color='primary'
                                    sx={{
                                        mx: 3
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant='contained'
                                    color='success'
                                    type="submit"
                                >
                                    Guardar
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}