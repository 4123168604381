import { SnackbarProvider } from 'notistack';
import React from 'react'
import { Provider } from 'react-redux';
import { ColorModeProvider } from './context/ColorModeProvider';
import { store } from './redux/store';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';

export const AdminRpaApp = () => {
    return (
        <ColorModeProvider>
            <Provider store={store}>
                <SnackbarProvider 
                    maxSnack={4}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <AppRouter/>
                </SnackbarProvider>
            </Provider>
        </ColorModeProvider>
    )
}
