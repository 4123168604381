import { createNewPassword, validateLogin, getSession, sendEmailRecoverPassword } from "../../services/auth.service";
import { actionTypes } from "../contants/actionTypes";

//#region CHECKING_SESSION
export const startCheckingAuthSession = () => ({
    type: actionTypes.authStartCheckingSession
});

export const finishCheckingAuthSession = () => ({
    type: actionTypes.authFinishCheckingSession
});

export const getAuthSession = (userPool) => (
    async (dispatch) => {
        dispatch(startCheckingAuthSession());
        await getSession(userPool)
            .then((userSession) => {
                // console.log('responseUserData', userData)
                // console.log('responseUsername', username)
                // if(userData)
                // dispatch(loadDataLogin(userData, username))
                dispatch(loadDataLogin(userSession))
                // else
                //     dispatch(errorCheckingAuthSession());
            }
            ).catch((e) => {
                console.log('error', e)
                dispatch(errorCheckingAuthSession());
            }).finally(() => {
                dispatch(finishCheckingAuthSession());
            });
    }
);

export const errorCheckingAuthSession = () => ({
    type: actionTypes.authErrorCheckingSession
});
//#endregion

//#region CHECKING_LOGIN
export const startCheckingLogin = () => ({
    type: actionTypes.authStartCheckingLogin
});

export const finishCheckingLogin = () => ({
    type: actionTypes.authFinishCheckingLogin
});

// export const initializingLogin = (cognitoUser, cognitoPool, authDetails) => (
//     async (dispatch) => {
//         console.log("cognitoUser: ", cognitoUser);
//         console.log("authDetails: ", authDetails);
//         dispatch(errorLogging(''));
//         dispatch(startCheckingLogin());
//         await validateLogin(cognitoUser, cognitoPool, authDetails)
//             .then(response => {
//                     console.log('response', response)
//                     const token = response?.refreshToken?.token;
//                     localStorage.setItem('REFRESH_TOKEN', token);
//                     // localStorage.setItem('user', JSON.stringify({username : cognitoUser.username, token : response.idToken.jwtToken}));
//                     dispatch(getCompanies(response.idToken.jwtToken));
//                     return dispatch(loadDataLogin({
//                         session: response,
//                         username: cognitoUser.username
//                     }))
//                 }
//             ).catch((e) => {
//                 console.log('error', e)
//                 switch (e.name) {
//                     case 'UserNotConfirmedException':
//                         return dispatch(errorLogging('Please confirm your email address.'));
//                     case 'NotAuthorizedException':
//                         return dispatch(errorLogging('Incorrect email or password.'));
//                     case 'PasswordChangeRequired':
//                         return dispatch(requiredPasswordChange(true));
//                     default:
//                         return dispatch(errorLogging('Oops! Looks like something went wrong. Please try again later.'));
//                 }
//             }).finally( () => {
//                 dispatch(finishCheckingLogin());
//             });
//     }
// )

export const initializingLogin = (username, password) => (
    async (dispatch) => {
        // const [sendNotification] = useNotification();
        dispatch(errorLogging(''));
        dispatch(startCheckingLogin());
        await validateLogin(username, password)
            .then(
                response => {
                    if (response?.status === 202) {
                        return dispatch(requiredPasswordChange(true, response?.data?.token));
                    }
                    return dispatch(loadDataLogin({
                        session: response.data,
                        username: username
                    }))
                }
            ).catch((e) => {
                // console.log('error Login', e)
                // dispatch(setNotificaction({ isNotification: true, msg: 'Usuario o contrasena incorrectos, por favor revise las credenciales', typeMsg: 'info' }));
                if (e?.response?.status === 401) {
                    dispatch(setNotificaction({ isNotification: true, msg: 'Usuario o contrasena incorrectos, por favor revise las credenciales', typeMsg: 'info' }));
                }
                if (e?.response?.status === 402) {
                    dispatch(setNotificaction({ isNotification: true, msg: 'Su cuenta se encuentra bloqueada, por favor comuníquese con el área encargada', typeMsg: 'info' }));
                }
            }).finally(() => {
                dispatch(finishCheckingLogin());
            });
    }
)

export const loadDataLogin = (userSession) => {
    return {
        type: actionTypes.authLoadDataLogin,
        payload: {
            accessDate: new Date().getTime(),
            username: userSession.username,
            ...userSession.session
        }
    }
};

export const errorLogging = (messageError) => ({
    type: actionTypes.authErrorLogging,
    payload: messageError
});
//#endregion

//#region PASSWORD_CHANGE
export const requiredPasswordChange = (passwordChangeRequired, tokenTemporal) => ({
    type: actionTypes.authPasswordChangeRequired,
    payload: {
        tokenTemporal,
        passwordChangeRequired
    }
});
export const startCreatingNewPassword = () => ({
    type: actionTypes.authStartCreatingNewPassword
});

export const finishCreatingNewPassword = () => ({
    type: actionTypes.authFinishCreatingNewPassword
});

export const changePassword = (TokenTmp, newPassord) => (
    async (dispatch) => {
        dispatch(startCreatingNewPassword());
        await createNewPassword(TokenTmp, newPassord)
            .then(response => {
                dispatch(requiredPasswordChange(false, ''));
            }
            ).catch((e) => {
                console.log('error', e)
            }).finally(() => {
                dispatch(finishCreatingNewPassword());
            });
    }
);

export const RecoverPassword = (userName) => (
    async (dispatch) => {
        // dispatch(startCreatingNewPassword());
        await sendEmailRecoverPassword(userName)
            .then(response => {
                console.log('response', response)
            }
            ).catch((e) => {
                console.log('error', e)
            }).finally(() => {
                dispatch(finishCreatingNewPassword());
            });
    }
)

//#endregion

export const logout = () => ({
    type: actionTypes.authLogout
});

export const checkAuthToken = () => {

    return async (dispatch) => {
        const user = localStorage.getItem('user');

        if (!user || !user?.token) {
            return dispatch(logout());
        }
        await checkAuthToken(user.token)
            .then(
                response => {
                    // dispatch(getCompanies(response.data.result.access_token));
                    // return dispatch(login(response.data, username))
                }
            ).catch((e) => {
                // console.log('error', e)
                // dispatch(errorLogin(e.message));
            }).finally(() => {
                // dispatch(checkingFinish());
            });
    }

}

export const setNotificaction = (data) => ({
    type: actionTypes.notificaction,
    payload: data
})