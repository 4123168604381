import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { labelCronExpression } from './utils/labelCronExpression';
import { useForm } from '../../hooks/useForm';

export const CronMonths = ({setValue, value}) => {
    
    const [listChecked, setListChecked] = useState(value && value.split(',').length > 1 ? value.split(',').map(x => x) : []);
    const [formValues, handleSelectChange] = useForm({
      selectEveryMonths: value.split('/').length > 1 ? value.split('/')[1] : 1,
      selectStartMonths: value.split('/').length > 1 ? value.split('/')[0] : 1,
      selectBetweenMonth1: value.split('-').length > 1 ? value.split('-')[0] : 1,
      selectBetweenMonth2: value.split('-').length > 1 ? value.split('-')[1] : 2
    });
    const {selectEveryMonths, selectStartMonths, selectBetweenMonth1, selectBetweenMonth2} = formValues;
    
    const [valueRadio, setValueRadio] = useState('1');
    useEffect(() => {
        if(value === '*'){setValueRadio('1');}
        if(value.split(',').length > 1 || !isNaN(value)){setValueRadio('3');}
        if(value.split('/').length > 1){setValueRadio('2');}
        if(value.split('-').length > 1 ){setValueRadio('4');}
    }, [])

    useEffect(() => {
        if(valueRadio === '2'){
            setValue(`${selectStartMonths}/${selectEveryMonths}`);
        }
    }, [selectEveryMonths, selectStartMonths])
    
    useEffect(() => {
        if(valueRadio === '3'){
            setValue(listChecked.length === 0 ? '*' : listChecked.sort((a,b)=>a-b).toString());
        }
    }, [listChecked])

    useEffect(() => {
        if(valueRadio === '4'){
            if(selectBetweenMonth1 >= selectBetweenMonth2){
                if(selectBetweenMonth1 === 12){
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenMonth1',
                            value: 11
                        }
                    });
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenMonth2',
                            value: 12
                        }
                    });
                }else{
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenMonth2',
                            value: selectBetweenMonth1+1
                        }
                    })
                }
            } else{
                setValue(`${selectBetweenMonth1}-${selectBetweenMonth2}`);
            }

            // setValue(
            //     selectBetweenMonth1 >= selectBetweenMonth2 && selectBetweenMonth1 === 12
            //     ? `${11}-${12}` 
            //     : selectBetweenMonth1 >= selectBetweenMonth2 ?
            //     `${selectBetweenMonth1}-${selectBetweenMonth1+1}`
            //     : `${selectBetweenMonth1}-${selectBetweenMonth2}`
            // );
        }
    }, [selectBetweenMonth1, selectBetweenMonth2])

    const handleChange = (e) => {
        setValueRadio(e.target.value);
        if(e.target.value === '1'){
            setValue('*');
        }else if(e.target.value === '2'){
            setValue(`${selectStartMonths}/${selectEveryMonths}`);
        }else if(e.target.value === '3'){
            setValue('JAN');
        }else if(e.target.value === '4'){
            setValue(`${selectBetweenMonth1}-${selectBetweenMonth2}`);
        }else{
            setValue('0');
        }
        handleReset();
    }

    const handleChangeCkecked = (e) => {
        if(!listChecked.includes(e.target.value)){
          setListChecked([...listChecked, e.target.value])
        }else{
            setListChecked(listChecked.filter((x, i) => x !== e.target.value))
        }
    }

    const handleReset = () => {
        setListChecked([])
    }

    return (
        <Paper 
            elevation={3} 
            sx={{
                px: '4%', 
                pb: '17%',
                bgcolor: (theme) => theme.palette.paper.background
            }}
        >
            <FormControl>
                <RadioGroup
                    aria-labelledby="months-radio-buttons-group-label"
                    value={valueRadio}
                    onChange={handleChange} 
                    name="radio-buttons-group"
                >
                    {/*Todos los MEses*/}
                    <FormControlLabel 
                        value="1"
                        control={<Radio size="small"/>} 
                        label="Todos los meses"
                    />

                    {/*Apartir del*/}                    
                    <FormControlLabel 
                        value="2"
                        control={<Radio size="small"/>}
                        sx={{mt: 2}}
                        label={
                            <Box 
                                display="flex"
                                alignItems="center" 
                                justifyContent="start"
                            >
                                <Typography>
                                    {'Cada\u00A0'}
                                </Typography>
                                <FormControl 
                                    variant="outlined" 
                                    sx={{ m: 0}}
                                >
                                    <Select
                                        value={selectEveryMonths}
                                        size="small"
                                        name="selectEveryMonths"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "2"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'months')
                                                .months.map(({value2}, i) => {
                                                return (
                                                    <MenuItem key={i} value={value2}>{value2}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography>
                                    {'\u00A0 mes(es), a partir del mes \u00A0'}
                                </Typography>
                                <FormControl variant="outlined" sx={{ m: 0}}>
                                    <Select
                                        value={selectStartMonths}
                                        size="small"
                                        name="selectStartMonths"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "2"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'months')
                                                .months.map(({label, value2}, i) => {
                                                return (
                                                    <MenuItem key={i}  value={value2}>{label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />

                    {/*MEses Especificos*/}
                    <FormControlLabel 
                        value="3"
                        control={<Radio size="small" sx={{pt: 0}}/>} 
                        sx={{
                            mt: 2,
                            alignItems: 'flex-start'
                        }}
                        label={
                            <Box>
                                <Typography>
                                    Meses especificos (selecciona uno o varios)
                                </Typography>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'months')
                                                .months.map(({label, value}, i) => {
                                                return (
                                                    <FormControlLabel
                                                        key={i} 
                                                        control={
                                                            <Checkbox 
                                                                checked={
                                                                    listChecked.includes(value)
                                                                } 
                                                                size="small" 
                                                                value={value}
                                                                onChange={handleChangeCkecked}
                                                                disabled={valueRadio !== "3"}
                                                            />
                                                        }
                                                        label={label}
                                                        value={value}
                                                    />
                                                )
                                            })
                                        }                                
                                    </FormGroup>
                                </FormControl>
                            </Box>   
                        }
                    />

                    {/*Meses Entre*/}
                    <FormControlLabel 
                        value="4"
                        control={<Radio size="small"/>} 
                        sx={{mt: 2}}
                        label={
                            <Box
                                display="flex"
                                alignItems="center" 
                                justifyContent="start"
                            >
                                <Typography>
                                    {'Todos los meses entre el mes \u00A0'}
                                </Typography>
                                <FormControl 
                                    variant="outlined" 
                                    sx={{ m: 0}}
                                >
                                    <Select
                                        value={selectBetweenMonth1}
                                        name="selectBetweenMonth1"
                                        size="small"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "4"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'months')
                                                .months.map(({label, value2}, i) => {
                                                return (
                                                    <MenuItem key={i} value={value2}>
                                                        {label}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography>
                                    {'\u00A0 y el mes \u00A0'}
                                </Typography>
                                <FormControl variant="outlined" sx={{ m: 0}}>
                                    <Select
                                        value={selectBetweenMonth2}
                                        name="selectBetweenMonth2"
                                        size="small"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "4"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'months')
                                                .months.map(({label, value2}, i) => {
                                                return (
                                                    <MenuItem 
                                                        key={i}  
                                                        value={value2}
                                                        disabled={
                                                            selectBetweenMonth1 >= value2
                                                        }
                                                    >
                                                        {label}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />

                </RadioGroup>
            </FormControl>
        </Paper>
  )
}
