import { actionTypes } from "../contants/actionTypes";

const initialState = {
    checkingSession: false,
    checkingLogin: false,
    errorLogging: null,
    isAuth: localStorage.getItem('isUserAuth') || false,
    username: undefined,
    passwordChangeRequired: false,
    creatingNewPassword: false,
    userSession: null,
    tokenTemporal: '',
    isNotification: false,
    msg: '',
    typeMsg: 'info'
}

export const authReducer = (state = initialState, action) => {

    switch (action.type) {
        //#region CHECKING_SESSION
        case actionTypes.authStartCheckingSession:
            return {
                ...state,
                checkingSession: true
            }
        case actionTypes.authFinishCheckingSession:
            return {
                ...state,
                checkingSession: false
            }
        case actionTypes.authErrorCheckingSession:
            return {
                ...state,
                isAuth: false,
                username: undefined,
            }
        //#endregion
        //#region CHECKING_LOGIN
        case actionTypes.authStartCheckingLogin:
            return {
                ...state,
                checkingLogin: true,
                passwordChangeRequired: false,
                isAuth: false,
                username: undefined,
            }
        case actionTypes.authFinishCheckingLogin:
            return {
                ...state,
                checkingLogin: false,
            }
        case actionTypes.authLoadDataLogin:
            return {
                ...state,
                isAuth: true,
                userSession: action.payload
            }
        case actionTypes.authErrorLogging:
            return {
                ...state,
                isAuth: false,
                username: undefined,
                errorLogging: action.payload
            }
        //#endregion
        //region CREATING_NEW_PASSWORD
        case actionTypes.authPasswordChangeRequired:
            return {
                ...state,
                tokenTemporal: action.payload.tokenTemporal,
                passwordChangeRequired: action.payload.passwordChangeRequired
            }
        case actionTypes.authStartCreatingNewPassword:
            return {
                ...state,
                creatingNewPassword: true
            }
        case actionTypes.authFinishCreatingNewPassword:
            return {
                ...state,
                creatingNewPassword: false
            }
        case actionTypes.authLogout:
            return {
                ...state,
                isAuth: false,
                username: undefined,
            }
        case actionTypes.notificaction:
            return {
                ...state,
                isNotification: action.payload?.isNotification || false,
                msg: action.payload?.msg,
                typeMsg: action.payload?.typeMsg
            }
        default:
            return state;
    }
}