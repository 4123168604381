import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { logout } from '../redux/actions/auth';
import { LoginScreen } from '../screens/login/LoginScreen';
import { MainRouter } from './MainRouter';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export default function AppRouter() {

  const { isAuth } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if (!user || !user?.token) {
      dispatch(logout());
    }

    if (moment(user?.accessDate).diff(moment(), 'hours') > 0) {
      dispatch(logout());
    }
  }, []);


  return (
    <Router>
      <Routes>
        <Route
          exact={true}
          path="/*"
          element={
            <PrivateRoute isAuth={isAuth}>
              <MainRouter />
            </PrivateRoute>
          }
        />
        <Route
          exact={true}
          path="/login/*"
          element={
            <PublicRoute isAuth={isAuth}>
              <LoginScreen />
            </PublicRoute>
          }
        />
      </Routes>
    </Router>
  );
}

