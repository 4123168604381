import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

export const useNotification = () => {
    const [conf, setConf] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const action = key => (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => { closeSnackbar(key) }}
            >
                <Close fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    useEffect(() => {
        if (conf?.msg) {
            let variant = 'info';
            if (conf.variant)
                variant = conf.variant;
            enqueueSnackbar(conf.msg, {
                variant: variant,
                autoHideDuration: 5000,
                action
            });
        }
    }, [conf])
    return [setConf];
}
