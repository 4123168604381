import React, { useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LoadingButton from '@mui/lab/LoadingButton';
import Close from '@mui/icons-material/Close';
import FlipCameraAndroid from '@mui/icons-material/FlipCameraAndroid';
import Check from '@mui/icons-material/Check';
import LoadHtml from '../htmlEditor/Loadhtml';
import AceEditorHtml from '../htmlEditor/AceEditorHtml';
import ReactQuillEditor from '../htmlEditor/ReactQuillEditor';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const HtmlEditor = ({ field, html, handleChange }) => {
    const childRefQuill = useRef(null);
    const childRefAce = useRef(null);
    const [value, setValue] = useState(html);
    const [open, setOpen] = useState(false);
    const [valueTab, setValueTab] = useState(0);
    const [disableTab, setDisableTab] = useState('');

    const OnChengeSetValue = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleOnChange = (content) => {
        setValue(content);
        handleChange(field.id, content);
    };

    const ResetChanges = () => {
        if (valueTab === 0 && childRefQuill.current) {
            childRefQuill.current.ResetChanges();
        }
        if (valueTab === 1 && childRefAce.current) {
            childRefAce.current.ResetChanges();
        }
    }
    const AcceptChanges = () => {
        if (valueTab === 0 && childRefQuill.current) {
            childRefQuill.current.AcceptChanges();
        }
        if (valueTab === 1 && childRefAce.current) {
            childRefAce.current.AcceptChanges();
        }
    }

    return (
        <React.Fragment>
            <LoadingButton
                size='small'
                variant='outlined'
                color='info'
                onClick={() => setOpen(true)}
            >
                Html templete
            </LoadingButton>
            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                PaperProps={{
                    style: { height: '95%' },
                }}
            >
                <DialogTitle>
                    <Typography variant='h5' component='h5'>
                        Editor Html
                    </Typography>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
                        <Tabs
                            sx={{ width: '70%' }}
                            value={valueTab}
                            onChange={OnChengeSetValue}
                            aria-label='basic tabs example'
                        >
                            <Tab label='Crear/Editar html apartir dexto' disabled={disableTab === 'one'} />
                            <Tab label='Crear/Editar html' disabled={disableTab === 'two'} />
                            <Tab label='Visualizar resultado' />
                        </Tabs>
                        <Box
                            display="flex"
                            sx={{ mb: 2, position: 'sticky', top: 0, zIndex: 1, width: '30%' }}
                            justifyContent="flex-end"
                        >
                            <Tooltip title='Actualizar cambios'>
                                <IconButton
                                    color="success"
                                    onClick={() => AcceptChanges()}
                                >
                                    <Check />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Desacer cambios'>
                                <IconButton
                                    color="error"
                                    onClick={() => ResetChanges()}
                                >
                                    <FlipCameraAndroid />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <IconButton
                        aria-label='close'
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TabPanel value={valueTab} index={0}>
                        <ReactQuillEditor
                            html={value}
                            handleChange={handleOnChange}
                            disabled={setDisableTab}
                            ref={childRefQuill}
                        />
                    </TabPanel>
                    <TabPanel value={valueTab} index={1}>
                        <AceEditorHtml
                            html={value}
                            handleChange={handleOnChange}
                            disabled={setDisableTab}
                            ref={childRefAce}
                        />
                    </TabPanel>
                    <TabPanel value={valueTab} index={2}>
                        <LoadHtml htmlProp={value} />
                    </TabPanel>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};
