import React from 'react';

class LoadHtml extends React.Component {
    render() {
        const { htmlProp } = this.props;

        return (
            <div dangerouslySetInnerHTML={{ __html: htmlProp }} />
        );
    }
}

export default LoadHtml;