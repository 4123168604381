import AWS from 'aws-sdk';
window.Buffer = window.Buffer || require("buffer").Buffer;

const s3Client = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_S3_BUCKET },
    region: process.env.AWS_REGIONAWS_REGION,
});

export const uploadToS3 = async (file) => {
    return await s3Client.putObject({
        ACL: 'public-read',
        Body: file,
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
        Key: file.name
    }).promise();
}