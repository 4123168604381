import { useSelector, useDispatch } from 'react-redux';
import { LoadData, changesPendingComponent, changesPendingSubComponent, deleteDocs } from '../redux/actions/sharedViews';
import { useEffect, useState } from 'react';
import { useDataAuth } from "./useDataAuth";
import { actionTypes } from "../redux/contants/actionTypes";

export const useSharedViews = () => {
    const dispatch = useDispatch();

    const {
        updatingData,
        deletingDocs,
        deletingComponentDocs,
        addingNewDoc,
        listAllDocs,
        isLoading,
        loadingExecutions,
        historyDoc,
        listDeatils,
        columsTableDeatails,
        isLoadingDetails,
        rowsChecked,
        filters,
        pagination
    } = useSelector(state => state.sharedViews);
    const [{ userSession, idCompany }] = useDataAuth();


    const [listData, setListData] = useState([]);
    const [isChangeDataComponent, setIsChangeDataComponent] = useState(false);
    const [isChangeDataSubComponent, setIsChangeDataSubComponent] = useState(false);


    useEffect(() => {
        setIsChangeDataComponent(updatingData);
        setIsChangeDataSubComponent(updatingData);
    }, [updatingData]);

    useEffect(() => {
        dispatch(changesPendingComponent(isChangeDataComponent));
    }, [isChangeDataComponent]);

    useEffect(() => {
        dispatch(changesPendingSubComponent(isChangeDataSubComponent));
    }, [isChangeDataSubComponent]);

    useEffect(() => {
        setListData(listAllDocs);
    }, [listAllDocs]);

    const handleDeleteDocs = (arrayByDeleted) => {
        dispatch(deleteDocs(arrayByDeleted, idCompany, userSession.token));
    }

    const handleChange = (idFlow, key, value) => {
        setListData(listData => (
            listData.map(data => {
                if (data.id_flow === idFlow) {
                    return {
                        ...data,
                        [key]: value
                    };
                } else {
                    return data
                }
            })
        ));
    }

    const handleRowsChecked = (obj) => {
        dispatch(LoadData(actionTypes.rowsChecked, obj));
    }

    return [{
        updatingData,
        deletingDocs,
        deletingComponentDocs,
        addingNewDoc,
        listData,
        listAllDocs,
        isLoading,
        loadingExecutions,
        historyDoc,
        listDeatils,
        columsTableDeatails,
        isLoadingDetails,
        rowsChecked,
        filters,
        pagination,
        handleChange,
        handleDeleteDocs,
        handleRowsChecked
    }];
}

