import { MenuItem, MenuList, Popper, Paper, ClickAwayListener } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import ChipBreadcrumb from './ChipBreadcrumb';


export function LevelBreadcrumb({ items, onSelect, icon, item }) {
  const [anchorEl, setAnchorEl] = useState(undefined);

  const openMenu = (e) => {
    setAnchorEl(e.target);
  };

  const closeMenu = () => {
    setAnchorEl(undefined);
  };

  const selectMenuItem = (selectedItem) => {
    closeMenu();
    onSelect(selectedItem);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      closeMenu();
    } else if (e.key === 'Escape') {
      closeMenu();
    }
  };

  return items?.length ? (
    <div style={{ position: 'relative' }}>
      <ChipBreadcrumb
        icon={icon}
        label={item}
        onClick={() => onSelect(item)}
        deleteIcon={<ExpandMoreIcon />}
        onDelete={openMenu}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
      />

      <Popper open={Boolean(anchorEl)} role={undefined} anchorEl={anchorEl} placement="bottom">
        <Paper 
          sx={{
            bgcolor: (theme) => theme.palette.paper.background
          }}
        >
          <ClickAwayListener onClickAway={closeMenu}>
            <MenuList dense autoFocusItem={Boolean(anchorEl)} onKeyDown={handleListKeyDown}>
              {items?.map((listItem) => (
                <MenuItem key={listItem} onClick={() => selectMenuItem(listItem)}>
                  {listItem}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  ) : (
    <ChipBreadcrumb icon={icon} label={item} onClick={() => onSelect(item)} />
  );
}
