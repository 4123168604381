import { KeyboardArrowDown, KeyboardArrowUp, VisibilityOutlined } from '@mui/icons-material';
import { Box, Checkbox, Chip, Collapse, darken, IconButton, Link as MuiLink, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomTable } from './CustomTable';

export const CustomTableRowBody = ({
    headers,
    headersDetails,
    keyDetails,
    initialOrderSortDetail,
    idRowDetail,
    row,
    idRow,
    handleClick,
    widthParent,
    isItemSelected,
    labelId,
    index,
    startOpenCollapse,
    toNavigateRow,
    isCollapsible,
    isManageable,
    ...props
}) => {

    const navigate = useNavigate();
    const [openCollapse, setOpenCollapse] = useState(false);

    const handleClickOpenCollapse = (index) => {
        setOpenCollapse(!openCollapse);
        startOpenCollapse(index);
    }

    return (
        <>
            <TableRow
                tabIndex={-1}
                key={row[idRow]}
            >
                {/* {
                    isCollapsible &&
                    <TableCell
                        padding="normal"
                        align="center"
                    >
                        <Tooltip
                            title={(openCollapse) ? "Contraer" : "Expandir"}
                        >
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleClickOpenCollapse(!openCollapse ? index : -1)}
                            >
                                {openCollapse ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                } */}
                {/* LAS LINEAS SIGUIENTES MUESTRAN EL CHECK SOBRE CADA REGISTRO */}
                {
                    isManageable &&
                    <TableCell
                        padding="checkbox"
                    >
                        <Checkbox
                            onClick={(event) => handleClick(event, row[idRow])}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </TableCell>
                }
                {
                    headers.map((header, indexHeader) => (
                        header.visible && <TableCell
                            key={`trc-${indexHeader}`}
                            align={header.align}
                            padding="normal"
                        >
                            {
                                header.isLinkeable ? (
                                    <MuiLink
                                        component={Link}
                                        color="text.primary"
                                        to={toNavigateRow.replace(`:${toNavigateRow?.split(':')[1]}`, row[toNavigateRow?.split(':')[1]])}
                                    >
                                        {row[header.id]}
                                    </MuiLink>
                                ) : (
                                    React.cloneElement(header.customComponent, {
                                        value: row[header.id],
                                        ...props
                                    })
                                )
                            }
                            {/* { 
                                header.customComponent ? (
                                    React.cloneElement(header.customComponent, {
                                        value: row[header.id]
                                    })
                                ) : (
                                    header.type === "date" ?
                                        <Typography>
                                            {moment(row[header.id]).format('DD/MM/YYYY h:mm A')}
                                        </Typography>
                                    : <Typography>
                                        { 
                                            header.isLinkeable 
                                            ? <MuiLink
                                                component={Link}
                                                color="text.primary"
                                                to={toNavigateRow.replace(`:${toNavigateRow?.split(':')[1]}`, row[toNavigateRow?.split(':')[1]])}
                                            >
                                                {row[header.id]}
                                            </MuiLink>
                                            : row[header.id]
                                        }
                                    </Typography>
                                )
                            } */}
                        </TableCell>
                    ))
                }

                {/* LAS LINEAS SIGUIENTES MUESTRAN LAS ACCIONES */}
                {
                    isManageable &&
                    <TableCell
                        padding="normal"
                        align="center"
                    >
                        <Tooltip title="Expand">
                            <IconButton
                                size="small"
                                sx={{ p: 0 }}
                                onClick={() => navigate(toNavigateRow.replace(`:${toNavigateRow?.split(':')[1]}`, row[toNavigateRow?.split(':')[1]]))}
                            >
                                <VisibilityOutlined color="primary" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                }
            </TableRow>
            {/* {
                isCollapsible && (
                    <TableRow
                        key={`Collapsed-${row[idRow]}`}
                        sx={{
                            backgroundColor: (theme) => darken(theme.palette.paper.background, 0.3)
                        }}
                    >
                        <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0}}
                            colSpan={3}
                        >
                            <Box>
                                <Collapse
                                    sx={{
                                        overflowX: 'auto', 
                                        width: widthParent ? `${widthParent-32}px` : '500px',
                                        py: 2
                                    }}
                                    // sx={{overflowX: 'auto'}}
                                    in={openCollapse}
                                    timeout="auto" 
                                    unmountOnExit
                                >
                                    <CustomTable
                                        isLoadingItems={false}
                                        valueOrder={"desc"}
                                        headers={headersDetails} 
                                        rows={row[keyDetails]}
                                        initialOrderSort={initialOrderSortDetail}
                                        idRow={idRowDetail}
                                        isManageable={false}
                                        elevationTable={9}
                                        rowsByPage={3}
                                    />
                                </Collapse>
                            </Box>
                        </TableCell>
                    </TableRow>
                )
            } */}
        </>
    )
}
