import { fetchUrlBoard } from '../../services/boards.service';
import { actionTypes } from "../contants/actionTypes"

export const getUrlBoard = (accessToken) => {
    return (dispatch) => {
        fetchUrlBoard(accessToken)
            .then(
                response => {
                    dispatch(SetBoardUrl(response.data))
                }
            ).catch((e) => {
                console.log('error', e)
            }).finally(() => {
                console.log('finally');
            });
    }
};

export const SetBoardUrl = (data) => ({
    type: actionTypes.GetBoardUrl,
    payload: data?.body,
});