import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useSharedViews } from '../../../hooks/useSharedViews';
import { getAllDocs } from '../../../redux/actions/sharedViews';
import { LoadButtons } from '../../../components/sharedViews/loadActions/LoadButtons';
import { FilterTable } from '../../../components/others/FilterTable';
import { LoadButtonHeaderTable } from '../../../components/sharedViews/loadActionsHeader/LoadButtonHeaderTable';

export const LayoutSharedViews = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [columns, setColums] = useState([]);
    const [buttons, setButtons] = useState([]);
    const [configuration, setConfiguration] = useState(null);
    const [isFirstLoadDone, setIsFirstLoadDone] = useState(true);
    let [conf, setConf] = useState({});
    const [{ userSession }] = useDataAuth();
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 100 });

    const [DocManager] = useSharedViews();
    const {
        listAllDocs,
        isLoading,
        rowsChecked,
        handleRowsChecked,
    } = DocManager;

    const GetAllDocs = useCallback((filters, pagination) => {
        dispatch(getAllDocs(userSession.token, conf, filters, pagination));
    }, [dispatch, userSession.token, conf]);

    useEffect(() => {
        if (location.state && location.state.configuration) {
            setFilters({});
            setPagination({ pageNumber: 1, pageSize: 100 });
            const { configuration, title, config } = location.state || {};
            setConf({ 'id_user_action': config.id_user_action.toString() });
            setColums(configuration ? JSON.parse(configuration)?.table : []);
            setButtons(configuration ? JSON.parse(configuration)?.button : []);
            setConfiguration({ configuration, title });
            setIsFirstLoadDone(false);
        }
    }, [location.state]);

    useEffect(() => {
        if (!isFirstLoadDone)
            GetAllDocs(filters, pagination);
    }, [GetAllDocs, filters, pagination, isFirstLoadDone]);

    const handleChangePage = (event, newPage) => {
        pagination['pageNumber'] = newPage + 1;
        GetAllDocs(filters, pagination);
        handleRowsChecked([]);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        pagination['pageNumber'] = 1;
        pagination['pageSize'] = newRowsPerPage;
        GetAllDocs(filters, pagination);
        handleRowsChecked([]);
    };

    const Search = (param, param2) => {
        filters[param2] = param;
        pagination['pageNumber'] = 1;
        GetAllDocs(filters, pagination);
        handleRowsChecked([]);
    }

    const ResetSearch = (param) => {
        delete filters[param];
        GetAllDocs(filters, pagination);
        handleRowsChecked([]);
    }

    const RefreshTable = () => GetAllDocs(filters, pagination);

    const handleCheckAll = ({ target: { checked } }) => {
        if (checked)
            handleRowsChecked(listAllDocs.map((item) => item.id));
        else
            handleRowsChecked([]);
    };

    const handleCheck = (id) => {
        const isSelected = rowsChecked.some(row => row === id);
        if (isSelected)
            handleRowsChecked(rowsChecked.filter(row => row !== id));
        else
            return handleRowsChecked([...rowsChecked, id]);
    };

    const isCheck = (id) => !!rowsChecked.find((i) => i === id);

    return (
        <Box sx={{ width: '100%' }} className='custom-hard-table-zd-nix'>
            <Box sx={{ mb: 2, pt: 2, pl: 2, pr: 2 }}>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                    <Breadcrumbs
                        aria-label='breadcrumb'
                        separator='›'
                        sx={{ mb: 2 }}
                    >
                        <Link
                            underline='hover'
                            color='text.main'
                            sx={{ cursor: 'pointer' }}
                        >
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                            >
                                {configuration?.title ? configuration.title : 'Consultar documentos'}
                            </Typography>
                        </Link>
                    </Breadcrumbs>

                    <Grid>
                        <LoadButtonHeaderTable
                            buttons={buttons}
                            id_user_action={conf.id_user_action}
                            DocManager={DocManager}
                            columns={columns}
                            handleChange={RefreshTable}
                        />
                    </Grid>
                </Box>

                <Box sx={{ width: '100%' }}>
                    <Paper
                        sx={{
                            width: '100%',
                            mb: 2,
                            boxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                            WebkitBoxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                            bgcolor: (theme) => theme.palette.paper.background
                        }}
                        elevation={3}
                    >
                        <TableContainer
                            style={{ height: '72vh', overflow: 'auto', borderBottom: 'solid 1px' }}
                        >
                            <Table
                                aria-labelledby='tableTitle'
                                size='small'
                                stickyHeader
                            >
                                <TableHead>
                                    <TableRow sx={{ px: 2 }}>
                                        <TableCell
                                            sx={{
                                                minWidth: 40,
                                                position: 'sticky',
                                                zIndex: 1000,
                                                left: 0
                                            }}
                                            id='TcellCheck'
                                        >
                                            <Checkbox
                                                indeterminate={rowsChecked.length > 0 && rowsChecked.length < listAllDocs.length}
                                                checked={listAllDocs.length > 0 && rowsChecked.length === listAllDocs.length}
                                                onChange={handleCheckAll}
                                                sx={{
                                                    color: 'black'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            padding='normal'
                                            align='center'
                                            sx={{ minWidth: 120, position: 'sticky', left: 52, zIndex: 1000, top: '-1px' }}
                                        >
                                            <Typography sx={{ fontWeight: 'bold' }} component='span'>
                                                Actions
                                            </Typography>
                                        </TableCell>
                                        {
                                            columns.map((col, index) => (
                                                col.visible &&
                                                <TableCell
                                                    padding='normal'
                                                    align='center'
                                                    key={`tc-${index}`}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Typography sx={{ fontWeight: 'bold', marginRight: '8px' }} component='span'>
                                                            {col.label}
                                                        </Typography>
                                                        {col.filterable && (
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <FilterTable colum={col} Search={Search} ResetSearch={ResetSearch} filter={filters.hasOwnProperty(col.id)} />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        isLoading ? (
                                            <TableRow sx={{ height: 200, py: 2 }}>
                                                <TableCell
                                                    colSpan={columns.length + 2}
                                                    align='center'
                                                >
                                                    <CircularProgress
                                                        color='primary'
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : !isLoading && !listAllDocs.length > 0 ? (
                                            <TableRow sx={{ height: 200, py: 2 }}>
                                                <TableCell
                                                    colSpan={columns.length + 2}
                                                    align='center'
                                                >
                                                    <Typography>No hay registros para mostrar</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            listAllDocs.map((item) => (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={item.id}
                                                >
                                                    <TableCell
                                                        style={{ position: 'sticky', left: 0, backgroundColor: 'white' }}
                                                    >
                                                        <Checkbox
                                                            key={item.id}
                                                            onChange={() => handleCheck(item.id)}
                                                            checked={isCheck(item.id)}
                                                            inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${item.id}` }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        padding='normal'
                                                        align='center'
                                                        sx={{ position: 'sticky', left: 52, backgroundColor: 'white' }}
                                                    >
                                                        <Typography>
                                                            <LoadButtons data={item} handleChange={RefreshTable} />
                                                        </Typography>
                                                    </TableCell>
                                                    {
                                                        columns.map((col, index) => (
                                                            col.visible &&
                                                            (
                                                                col.type === 'money' ?
                                                                    <TableCell
                                                                        key={`tc-${index}`}
                                                                        padding='normal'
                                                                        align='center'
                                                                    >
                                                                        <Typography>
                                                                            {new Intl.NumberFormat('en-US').format(item[col.id])}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell
                                                                        key={`tc-${index}`}
                                                                        padding='normal'
                                                                        align='center'
                                                                    >
                                                                        <Typography>
                                                                            {item[col.id]}
                                                                        </Typography>
                                                                    </TableCell>
                                                            )
                                                        ))
                                                    }
                                                </TableRow>
                                            ))
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            sx={{ display: 'table-row', verticalAlign: 'bottom' }}
                            rowsPerPageOptions={[25, 50, 100]}
                            component='Box'
                            count={10000}
                            rowsPerPage={pagination.pageSize}
                            page={pagination.pageNumber - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Box>
        </Box >
    )
}