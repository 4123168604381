import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import * as Icons from '@mui/icons-material';
import { ModalForms } from './ModalForms';
import { ModalHistory } from './ModalHistory';
import { ShowDocument } from './ShowDocument';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useNotification } from '../../../hooks/useNotification';
import { DialogConfirmAction } from '../../dialogs/DialogConfirmAction';
import { DownloadAllFiles } from '../../others/DownloadAllFiles';
import { RedirectLayout } from '../../others/RedirectLayout';
import { Configuration } from '../../others/Configuration';
import { PopUpSharedTable } from '../../tables/PopUpSharedTable';

export const LoadButtons = ({ data, handleChange }) => {
    const [{ userSession }] = useDataAuth();
    const [sendNotification] = useNotification();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [tittleDiealog, setTittleDiealog] = useState('');
    const [trigger, setTrigger] = useState(null);
    const [idReg, setIdReg] = useState('');
    const [flowProcess, setFlowProcess] = useState([]);
    const [openLoadingOverlay, setopenLoadingOverlay] = useState(false);

    useEffect(() => {
        setFlowProcess(data?.configuration ? JSON.parse(data?.configuration)?.flow_process : []);
    }, [data])

    const CallApi = async () => {
        setopenLoadingOverlay(true);
        let bodyData = trigger[0]?.bodyData;
        let variables = bodyData?.variables;
        const url = trigger[0]?.url;
        const method = trigger[0]?.method;
        const dynamicVars = {
            id: idReg
        };
        if (variables)
            for (let key in dynamicVars) {
                const value = dynamicVars[key];
                variables = variables.toString().replace(`{${key}}`, value);
            }
        bodyData.variables = variables || "{}";
        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        try {
            const response = await axios.request(config);
            console.log('response ', response);
            if (response.status === 200) {
                setOpenConfirmation(false);
                sendNotification({ msg: 'Evento realizado correctamente!', variant: 'success' });
                setTimeout(() => {
                    handleChange();
                }, 500);
            }
            else if (response.status === 504 && response.status === 408) {
                sendNotification({ msg: 'El proceso se esta ejecutando', variant: 'info' });
                setOpenConfirmation(false);
            }
            else
                sendNotification({ msg: 'Ocurrio un error realizando la acción, por favor comuniquese con el area encargada!', variant: 'error' });
        } catch (error) {
            if (error.response?.status === 504)
                sendNotification({ msg: 'El proceso se está ejecutando', variant: 'info' });
            else
                sendNotification({ msg: 'Ocurrio un error realizando la acción, por favor comuniquese con el area encargada!', variant: 'error' });
            console.error('ERROR ==> ', error);
        }
        setopenLoadingOverlay(false);
    }

    const OpenConfirmation = (param, param2, param3) => {
        setOpenConfirmation(true);
        setTrigger(param);
        setIdReg(param2);
        setTittleDiealog(param3);
    }

    return (
        <React.Fragment>
            {
                flowProcess && flowProcess.length > 0 &&
                flowProcess.map((item, index) => (
                    (!item?.visible || eval(item.visible)) &&
                    <React.Fragment key={index} >
                        {
                            item.type === 'showDocument' && (
                                <ShowDocument data={item} />
                            )
                        }
                        {
                            item.type === 'trigger' && (
                                <Tooltip title={item.accion}>
                                    <IconButton
                                        id={`id_${item.accion}`}
                                        size="small"
                                        sx={{ p: 0 }}
                                        onClick={() => OpenConfirmation(item.trigger, data?.id, item.accion)}
                                    >
                                        <span style={{ fontSize: 25, color: item.color }}>
                                            {React.createElement(Icons[item.icon])}
                                        </span>
                                    </IconButton>
                                </Tooltip >
                            )
                        }
                        {
                            item.type === 'form' && (
                                <ModalForms data={item} dtoReg={data} handleChange={handleChange} />
                            )
                        }
                        {
                            item.type === 'HistoricoDocumentos' && (
                                <ModalHistory idReg={data?.id} />
                            )
                        }
                        {
                            item.type === 'DownloadFile' && (
                                <DownloadAllFiles data={item} />
                            )
                        }
                        {
                            item.type === 'Redirect' && (
                                <RedirectLayout item={item} data={data} />
                            )
                        }
                        {
                            item.type === 'detailsTable' && (
                                <PopUpSharedTable item={item} data={data} />
                            )
                        }
                        {data?.id_flow && data.id_flow !== '' && (
                            <Configuration item={item} data={data} />
                        )}
                    </React.Fragment>
                ))
            }

            <DialogConfirmAction
                openDialogConfirm={openConfirmation}
                openLoadingOverlay={openLoadingOverlay}
                setOpenDialogConfirm={() => setOpenConfirmation(false)}
                titleDialog={tittleDiealog}
                textConfirmAction={"¿Esta seguro que desea continuar?"}
                textButtonConfirm={"Aceptar"}
                textButtonCancel={"Cancelar"}
                handleConfirmAction={CallApi}
            />
        </React.Fragment >
    )
}