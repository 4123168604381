import { ArrowBackRounded, LockReset, Mail } from '@mui/icons-material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, FormGroup, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { useNotification } from '../../../hooks/useNotification';

export const FormRecoverPassword = ({ authData }) => {
    const [sendNotification] = useNotification();
    const navigate = useNavigate();
    const {
        creatingNewPassword,
        handleRecoverPassword
    } = authData;

    const [{ email }, handleInputChange] = useForm({
        email: ''
    });

    const [errorUser, setErrorUser] = useState({
        isError: false,
        messageError: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email) {
            setErrorUser({
                isError: true,
                messageError: 'Debes ingresar un email'
            });
            return;
        }
        else if (!emailRegex.test(email)) {
            setErrorUser({
                isError: true,
                messageError: 'El email ingresado no es valido'
            });
            return;
        }
        else {
            setErrorUser({
                isError: false,
                messageError: ''
            });
        }

        handleRecoverPassword(email);
        sendNotification({ msg: 'La contraseña temporal se envio al correo electronico ingresado', variant: 'info' });
        setTimeout(() => {
            navigate('/');
        }, 2000);
    }

    const handleClickBack = (e) => {
        navigate(-1)
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
                marginBottom="1.4rem"
            >
                <LoadingButton
                    className='custom-button-back-zd-nx'
                    onClick={handleClickBack}
                    variant="contained"
                    type="submit"
                    size="small"
                    fullWidth
                    style={{ borderRadius: "20px", backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.main }}
                >
                    <ArrowCircleLeftOutlinedIcon
                        className="pointer"
                        fontSize="small"
                        color="#ffffff"
                        sx={{ marginRight:"6px" }}
                        
                    />
                    Volver
                </LoadingButton>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
                sx={{ mb: 2, textAlign:'left' }}
            >
                <Typography
                    color="#ffffff"
                    variant="h6"
                    sx={{ fontFamily: 'Afacad Flux Light', fontSize: '4rem', textAlign: 'left', lineHeight:'39px' }}
                >
                    Recuperar
                </Typography>
                <Typography
                    color="#ffffff"
                    variant="h6"
                    sx={{ fontFamily: 'Afacad Flux Black', fontSize: '4.25rem', textAlign: 'left', lineHeight:'1', marginBottom:'10px' }}
                >
                    Contraseña
                </Typography>

                <Grid
                    xs={12} sm={12} md={12} lg={12}
                    display={{ xs: "flex", sm: "flex" }}
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ marginBottom: '20px' }}
                    >
                    
                    <NotificationsNoneOutlinedIcon
                        className="pointer bellWhite"
                        fontSize="small"
                        color="#ffffff"
                        fill="#9C32F9"
                        sx={{ marginRight:"2px" }}
                    />
                    <Typography
                        color="#9C32F9"
                        variant="h6"
                        sx={{ fontFamily: 'Afacad Flux Medium', fontWeight: 'bold', fontSize: '15px', textAlign: 'left', lineHeight:'39px' }}
                    >Enviaremos un email <span style={{ fontFamily: 'Afacad Flux Light', fontSize: '16px', fontWeight:'normal', color: '#FFFFFF', textAlign: 'left', lineHeight:'39px' }}>para restaurar tu acceso.</span></Typography>
                </Grid>
                
            </Box>

            <form onSubmit={handleSubmit}>
                <FormGroup sx={{ my: 2 }}>
                    <TextField
                        className="custom-btn-zd-nix"
                        error={errorUser.isError}
                        color={(errorUser.isError) ? "error" : "text"}
                        label="Email"
                        variant="outlined"
                        name="email"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Mail className='email-recovery-icon' color={(errorUser.isError) ? "error" : "text"} />
                                </InputAdornment>
                            ),
                        }}
                        value={email}
                        helperText={errorUser.messageError}
                        onChange={handleInputChange}
                    />
                </FormGroup>

                <LoadingButton
                    loading={creatingNewPassword}
                    variant="contained"
                    type="submit"
                    size="small"
                    fullWidth
                    style={{ marginTop: "1rem" }}
                >
                    Recover
                </LoadingButton>
            </form>
        </>
    )
}