import styled from '@emotion/styled';
import { ArrowDropDown, ArrowRight, Delete } from '@mui/icons-material';
import { TreeItem, treeItemClasses } from '@mui/lab';
import { alpha, Button, Grid, IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { ModalEditor } from '../../modalEditor/ModalEditor';
import { TreeItemBoolean } from './TreeItemBoolean';
import { TreeItemListArray } from './TreeItemListArray';
import { TreeItemNullUndefined } from './TreeItemNullUndefined';
import { TreeItemNumber } from './TreeItemNumber';
import { TreeItemObject } from './TreeItemObject';
import { TreeItemString } from './TreeItemString';

export const TreeItemArrayItem = ({
    value,
    indexItem,
    valueItem,
    indentation,
    arrayKeysPath,
    onChange,
    onDelete
}) => {

    const [showActions, setShowActions] = useState(false);
    const [isCollapse, setIsCollapse] = useState(true);

    return (
        <>
            <ListItem
                disableGutters
                sx={{
                    py: 0.3,
                    marginLeft: indentation
                }}
            >        
                <Grid container>
                    <Grid 
                        item 
                        md={12}
                        onMouseEnter={() => setShowActions(true)}
                        onMouseLeave={() => setShowActions(false)}
                        alignItems="center" 
                        display="inline-flex" 
                        justifyContent="flex-start"
                    >
                        <Box
                            alignItems="center" 
                            display="inline-flex" 
                            justifyContent="flex-end"
                        >
                            {
                                (typeof(valueItem) === 'object' || Array.isArray(valueItem)) && (
                                    <IconButton
                                        sx={{p:0}}
                                        onClick={() => setIsCollapse(!isCollapse)}
                                    >
                                        {
                                            isCollapse ? <ArrowRight 
                                                sx={{fontSize: 17}}
                                                color="disabled"
                                            /> 
                                            : <ArrowDropDown
                                                sx={{fontSize: 17}}
                                                color="disabled"
                                            />
                                        }
                                    </IconButton> 
                                )
                            }
                        </Box>
                        <Box
                            alignItems="center" 
                            display="inline-flex" 
                            justifyContent="flex-start"
                            sx={{
                                overflow: 'hidden', 
                                ml: (typeof(valueItem) === 'object' || Array.isArray(valueItem)) ? 0 : 2
                            }}
                        >
                            <Typography 
                                component="span"
                                variant="console"
                                sx={{
                                    height: '100%',
                                    display: 'inline-block',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start'
                                }}
                            >
                                {`${indexItem}:`}
                            </Typography>
                            {
                                typeof(valueItem) === 'object' ? (
                                    <>
                                        {
                                            isCollapse ? 
                                                <Typography 
                                                    component="span"
                                                    variant="console"
                                                    sx={{fontSize: 12, cursor: 'pointer', px: 0.5}}
                                                    onClick={() => setIsCollapse(!isCollapse)}
                                                >
                                                    {'{'}
                                                </Typography>  
                                            :   <Typography 
                                                    component="span"
                                                    variant="console"
                                                    color="darkgray"
                                                    sx={{fontSize: 12, cursor: 'pointer', px: 0.5}}
                                                    onClick={() => setIsCollapse(!isCollapse)}
                                                >
                                                    {'{...}'}
                                                </Typography>
                                        }
                                        <Typography 
                                            component="span" 
                                            color="darkgray"
                                            variant="console"
                                            sx={{fontSize: 12}}
                                        >
                                            {`${Object.keys(valueItem).length} items`}
                                        </Typography> 
                                    </>
                                ) : Array.isArray(valueItem) ? (
                                    <>
                                        {
                                            isCollapse ? 
                                                <Typography 
                                                    component="span"
                                                    variant="console"
                                                    sx={{fontSize: 12, cursor: 'pointer', px: 0.5}}
                                                    onClick={() => setIsCollapse(!isCollapse)}
                                                >
                                                    {'['}
                                                </Typography>  
                                            :   <Typography 
                                                    component="span"
                                                    variant="console"
                                                    color="darkgray"
                                                    sx={{fontSize: 12, cursor: 'pointer', px: 0.5}}
                                                    onClick={() => setIsCollapse(!isCollapse)}
                                                >
                                                    {'[...]'}
                                                </Typography>
                                        }
                                        <Typography 
                                            component="span" 
                                            color="darkgray"
                                            variant="console"
                                            sx={{fontSize: 12}}
                                        >
                                            {`${Object.keys(valueItem).length} items`}
                                        </Typography> 
                                    </>
                                ) : (
                                    <>
                                        { valueItem !== null && valueItem !== undefined &&
                                            <Typography 
                                                component="span" 
                                                color="lightgray"
                                                variant="console"
                                                sx={{
                                                    fontSize: 11,
                                                    height: '100%',
                                                    display: 'flex',
                                                    opacity: 0.2,
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-start',
                                                    px: 0.5
                                                }}
                                            >
                                                {typeof(valueItem)}
                                            </Typography>
                                        }
                                        <Typography 
                                            component="span"
                                            sx={{
                                                pl: 0.5
                                            }}
                                        >
                                            <Typography 
                                                component="span"
                                                color={
                                                    valueItem === null || valueItem === undefined ?
                                                        'lightgray'
                                                    : typeof(valueItem) === 'string' ?
                                                        "darkorange"
                                                    : typeof(valueItem) === 'number' ?
                                                        "lightgreen"
                                                    : typeof(valueItem) === 'boolean' ?
                                                        "red"
                                                    : "lightgray"
                                                }
                                                variant="console"
                                            >
                                                {
                                                    valueItem === null ? 
                                                        " null" 
                                                    : valueItem === undefined ? 
                                                        " undefined" 
                                                    : typeof(valueItem) === 'string' ?  
                                                        ` "${valueItem.replace(/['"]+/g, "'")}"`
                                                    : valueItem
                                                }
                                            </Typography>
                                        </Typography>
                                    </>
                                )
                            }                                                  
                        </Box> 
                        <Box>
                            {
                                showActions && (
                                    <Stack direction="row" spacing={1}>
                                        <ModalEditor
                                            arrayKeysPath={[...arrayKeysPath, indexItem]}
                                            isEditorAdd={false}
                                            isEditorEdit={true}
                                            value={value}
                                            onChange={onChange}
                                        />
                                        <Tooltip title="Delete">
                                            <IconButton
                                                size="small"
                                                sx={{p:0}}
                                                onClick={() => onDelete([...arrayKeysPath, indexItem])}
                                            >
                                                <Delete sx={{fontSize: 17}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )
                            }
                        </Box>
                    </Grid>                    
                    {
                        isCollapse && (typeof(valueItem) === 'object' || Array.isArray(valueItem)) &&
                        (
                            <Grid 
                                item 
                                md={12}
                                sx={{ml: 1}}
                            >
                                <List
                                    sx={{
                                        p: 0,
                                        borderLeft: (theme) => `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
                                    }}
                                >
                                    {                
                                        typeof(valueItem) === 'object' ? (
                                            Object.entries(valueItem).map(([keyChild, valueChild], index) =>{
                                                
                                                if(Array.isArray(valueChild)){
                                                    return <TreeItemListArray
                                                        key={index}
                                                        value={value}
                                                        indentation={indentation+1}
                                                        keyItem={keyChild}
                                                        valueItem={valueChild}
                                                        arrayKeysPath={[...arrayKeysPath, indexItem]}
                                                        onChange={onChange}
                                                        onDelete={onDelete}
                                                        />
                                                    }else{
                                                        switch(typeof(valueChild)){
                                                            case 'string':
                                                            return <TreeItemString
                                                                key={index}
                                                                value={value}
                                                                indentation={indentation+1}
                                                                keyItem={keyChild}
                                                                valueItem={valueChild}
                                                                arrayKeysPath={[...arrayKeysPath, indexItem]}
                                                                onChange={onChange}
                                                                onDelete={onDelete}
                                                            />;
                                                        case 'number':
                                                            return <TreeItemNumber
                                                                key={index}
                                                                value={value}
                                                                indentation={indentation+1}
                                                                keyItem={keyChild}
                                                                valueItem={valueChild}
                                                                arrayKeysPath={[...arrayKeysPath, indexItem]}
                                                                onChange={onChange}
                                                                onDelete={onDelete}
                                                            />;
                                                        case 'object':
                                                            return <TreeItemObject
                                                                key={index}
                                                                value={value}
                                                                indentation={indentation+1}
                                                                keyItem={keyChild}
                                                                valueItem={valueChild}
                                                                arrayKeysPath={[...arrayKeysPath, indexItem]}
                                                                onChange={onChange}
                                                                onDelete={onDelete}
                                                            />;
                                                        case 'boolean':
                                                            return <TreeItemBoolean
                                                                key={index}
                                                                value={value}
                                                                indentation={indentation+1}
                                                                keyItem={keyChild}
                                                                valueItem={valueChild}
                                                                arrayKeysPath={[...arrayKeysPath, indexItem]}
                                                                onChange={onChange}
                                                                onDelete={onDelete}
                                                            />;
                                                        default:
                                                            return <TreeItemNullUndefined
                                                                key={index}
                                                                value={value}
                                                                indentation={indentation+1}
                                                                keyItem={keyChild}
                                                                valueItem={valueChild}
                                                                arrayKeysPath={[...arrayKeysPath, indexItem]}
                                                                onChange={onChange}
                                                                onDelete={onDelete}
                                                            />;
                                                        }
                                                    }
                                            })
                                        ) : Array.isArray(valueItem) && (
                                            valueItem.map((item, index) => {
                                                return <TreeItemArrayItem
                                                    key={index}
                                                    value={value}
                                                    indentation={indentation+1}
                                                    indexItem={index}
                                                    valueItem={item}
                                                    arrayKeysPath={[...arrayKeysPath, indexItem]}
                                                    onChange={onChange}
                                                    onDelete={onDelete}
                                                />;
                                            })
                                        )
                                    }
                                </List>
                            </Grid>
                        )
                    }
                    {
                        isCollapse && (typeof(valueItem) === 'object'  || Array.isArray(valueItem)) && 
                        (
                            <Grid item md={12}>
                                <Typography 
                                    component="span"
                                    variant="console"
                                    sx={{fontSize: 12, ml: 1}}
                                    onClick={() => setIsCollapse(!isCollapse)}
                                >
                                    {typeof(valueItem) === 'object' ? '}': ']'}
                                </Typography>                   
                            </Grid>
                        )
                    }
                </Grid>                
            </ListItem>
        </>
    )
}
