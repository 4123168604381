import { SchemaOutlined, SyncAltOutlined } from '@mui/icons-material';
import { Grid, InputAdornment, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { config } from '../../../helpers/config';
import moment from 'moment';

export const DetailsSummaryComponentFlow = ({
    componentFlowSelected,
    handleChangeSelect,
}) => {
    return (
        <Grid
            container
        >
            <Grid 
                item
                md={7}
            >
                <Grid container>
                    <Grid 
                        item
                        md={5}
                        sx={{p:2}}
                    >
                        <InputLabel id="label-type-component">
                            <Typography 
                                color="text.main"
                                sx={{fontWeight: 'bold'}}
                            >
                                Type Component Flow
                            </Typography>
                        </InputLabel>                                                        
                        <TextField
                            id="type-component-flow"
                            name="type"
                            value={!componentFlowSelected.type ? "" : componentFlowSelected.type}
                            select
                            fullWidth
                            size="small"
                            onChange={handleChangeSelect}                                                            
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SchemaOutlined color="primary"/>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{mb: 1}}
                        >
                            <MenuItem value="" disabled>
                                <em>Type Component Flow</em>
                            </MenuItem>
                            {
                                config.flowVariables.typesComponentFlows.map(({type}, index) => (
                                    <MenuItem
                                        key={index}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid 
                        item
                        md={5}
                        sx={{p:2}}
                    >
                        <InputLabel id="label-type-execute">
                            <Typography 
                                color="text.main"
                                sx={{fontWeight: 'bold'}}
                            >
                                Type Execute
                            </Typography>
                        </InputLabel> 
                        <TextField
                            id="type-execute-flow"
                            name="type_execute"
                            value={!componentFlowSelected.type_execute ? "" : componentFlowSelected.type_execute}
                            select
                            fullWidth
                            size="small"
                            onChange={handleChangeSelect}                                                            
                            autoComplete="off"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SyncAltOutlined color="primary"/>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{mb: 1}}
                        >
                            <MenuItem value="" disabled>
                                <em>Type Execute</em>
                            </MenuItem>
                            {
                                config.flowVariables.typesExecute.map(({type}, index) => (
                                    <MenuItem
                                        key={index}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                md={5}
                sx={{borderLeft: '0.5px solid gray', pl: 2}}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Grid 
                    container
                >
                    <Grid 
                        item
                        md={6}
                    >
                        <Box
                            id="created-flow"
                            sx={{pb:1}}
                        >
                            <Typography 
                                component="div"
                                color="text.main"
                                sx={{fontWeight: 'bold'}}
                            >
                                Created:
                            </Typography>
                            <Typography 
                                component="div"
                            >
                                {componentFlowSelected && moment(componentFlowSelected.created).format('DD/MM/YYYY h:mm')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid 
                        item
                        md={6}
                    >
                        <Box
                            id="createdby-flow"
                            sx={{pb:1}}
                        >
                            <Typography 
                                component="div"
                                color="text.main"
                                sx={{fontWeight: 'bold'}}
                            >
                                Created By:
                            </Typography>
                            <Typography 
                                component="div"
                            >
                                {componentFlowSelected && componentFlowSelected.created_by}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid 
                        item
                        md={6}
                    >
                        <Box
                            id="updated-flow"
                            sx={{pb:1}}
                        >
                            <Typography 
                                component="div"
                                color="text.main"
                                sx={{fontWeight: 'bold'}}
                            >
                                Updated:
                            </Typography>
                            <Typography 
                                component="div"
                            >
                                {componentFlowSelected && moment(componentFlowSelected.updated).format('DD/MM/YYYY h:mm')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid 
                        item
                        md={6}
                    >
                        <Box
                            id="updatedby-flow"
                            sx={{pb:1}}
                        >
                            <Typography 
                                component="div"
                                color="text.main"
                                sx={{fontWeight: 'bold'}}
                            >
                                Updated By:
                            </Typography>
                            <Typography 
                                component="div"
                            >
                                {componentFlowSelected && componentFlowSelected.updated_by}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>                         
            </Grid>
        </Grid>
    )
}
