import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useManageFlows } from "../../../hooks/useManageFlows";
import { useNotification } from '../../../hooks/useNotification';
import { useDispatch } from 'react-redux';
import { JsonTree } from '../../../components/jsonEditorV2/jsontree/JsonTree';
// import { updateConfig } from "../../redux/actions/flows";


export const DetailsModal = ({ open, onClose, updateConfiguration }) => {

    const dispatch = useDispatch();
    const [sendNotification] = useNotification();
    const [json, setJson] = useState({});
    const [selectedKey, setSelectedKey] = useState('');
    const [formData, setFormData] = useState({});
    // const [selectedKey, setSelectedKey] = useState('');
    const [flowManager] = useManageFlows();

    const {
        configFlowData
    } = flowManager;

    // useEffect(() => {
    //     if (data && data[0]) {
    //         console.log('data deatils modal ', JSON.parse(data[0]?.configuration));
    //         const dto = JSON.parse(data[0]?.configuration);
    //         if (dto.hasOwnProperty('basic_authorization')) {
    //             setSelectedKey('basic_authorization');
    //         }
    //         else if (dto.hasOwnProperty('jwt_authorization')) {
    //             setSelectedKey('jwt_authorization');
    //         }
    //         else if (dto.hasOwnProperty('authorized')) {
    //             setSelectedKey('authorized');
    //         }
    //         else {
    //             setSelectedKey('');
    //         }
    //         // setFormData(json || {});
    //         console.log(json?.environment_variables);
    //         if (json && json?.environment_variables) {
    //             setFormData(json.environment_variables);
    //         }
    //         else {
    //             setFormData({});
    //             console.log('limpio');
    //         }
    //         setJson(dto);
    //     }
    // }, [data])

    const handleSave = () => {
        console.log('json save', json)
        updateConfiguration(json);
    };

    const handleChange = (e) => {
        setSelectedKey(e.target.value);
    };

    // const handleEnvVarChange = (e, key) => {
    //     const { value } = e.target;
    //     setJson(prev => ({
    //         ...prev,
    //         environment_variables: {
    //             ...prev.environment_variables,
    //             [key]: value
    //         }
    //     }));
    // };

    const handleChanges = (value) => {
        setFormData(value);
        setJson(prev => ({
            ...prev,
            ['environment_variables']: value
        }));
    }

    // setJson({ ...json, [name]: type === 'switch' ? checked : value });

    useEffect(() => {
        if (configFlowData && configFlowData[0]) {
            const dto = JSON.parse(configFlowData[0]?.configuration);
            if (dto.hasOwnProperty('basic_authorization')) {
                setSelectedKey('basic_authorization');
            }
            else if (dto.hasOwnProperty('jwt_authorization')) {
                setSelectedKey('jwt_authorization');
            }
            else if (dto.hasOwnProperty('authorized')) {
                setSelectedKey('authorized');
            }
            else {
                setSelectedKey('');
            }
            if (dto && dto?.environment_variables) {
                setFormData(dto.environment_variables);
            }
            else {
                setFormData({});
            }
            setJson(dto);
        }
    }, [configFlowData]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit configuration flow</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ mt: 2 }}>
                    <Typography variant='h6' sx={{ mt: 2 }}>Seguridad</Typography>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Security</InputLabel>
                        <Select
                            value={selectedKey}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        >
                            <MenuItem value="basic_authorization">Basic Authorization</MenuItem>
                            <MenuItem value="jwt_authorization">JWT Authorization</MenuItem>
                            <MenuItem value="authorized">Authorized</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        margin="dense"
                        label="Value"
                        value={json[selectedKey] || ''}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />

                    <Typography variant='h6' sx={{ mt: 2 }}>Response</Typography>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="response"
                        name="response"
                        value={json?.response || ''}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />

                    <FormControl fullWidth margin="dense">
                        <InputLabel>Response Type</InputLabel>
                        <Select
                            value={json?.response_type || 'json'}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        >
                            <MenuItem value="base64/json">base64/json</MenuItem>
                            <MenuItem value="json">JSON</MenuItem>
                        </Select>
                    </FormControl>


                    <Typography variant='h6' sx={{ mt: 2 }}>Enviroment Variables</Typography>
                    <JsonTree
                        keyParent={'environment_variables'}
                        contentJson={formData}
                        handleChange={handleChanges}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

