import React, { useState } from 'react';
import { Box, IconButton, Typography, Tooltip, Button, Modal } from '@mui/material';
import DynamicForm from '../../dynamicForm/DynamicForm';
import * as Icons from '@mui/icons-material';
import axios from 'axios';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useNotification } from '../../../hooks/useNotification';

export const ModalForms = ({ data, dtoReg, handleChange }) => {
    const [{ userSession }] = useDataAuth();
    const [sendNotification] = useNotification();
    const [open, setOpen] = useState(false);
    const [fileBytes, setFileBytes] = useState(null);
    const [file, setFile] = useState(null);
    const [contentJson, setContentJson] = useState(() =>
        data?.form?.reduce((json, element) => {
            if (dtoReg.hasOwnProperty(element.id)) {
                json[element.id] = dtoReg[element.id];
            }
            return json;
        }, { id: dtoReg.id }) || { id: dtoReg.id }
    );

    const onChange = (e) => {
        const { name, value, type, checked } = e.target;
        setContentJson({ ...contentJson, [name]: type === 'switch' ? checked : value });
    }

    const handleFileChange = (event, field) => {
        setContentJson({ ...contentJson, [field]: event.target.files[0].name });
        const asfile = event.target.files[0];
        setFile(asfile);
        if (asfile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const arrayBuffer = e.target.result;
                const bytes = new Uint8Array(arrayBuffer);
                setFileBytes(bytes);
            };
            reader.readAsArrayBuffer(asfile);
        }
    };

    const handleUploadClick = async (buket, path) => {
        if (!fileBytes) {
            return;
        }
        const u8 = new Uint8Array(fileBytes);
        var b64 = Buffer.from(u8).toString('base64');

        const bodyData = {
            "id_flow": "260",//valor quemado
            "id": dtoReg.id,//id del documento
            "name": file.name.replace(/\s+/g, '_'),
            "file": b64
        }

        const baseUrlFlow = process.env.REACT_APP_AWS_API_GATEWAY_URL_EXECUTE_FLOW;
        let config = {
            method: 'POST',
            url: baseUrlFlow,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                const res = response.data;
                for (let key in res) {
                    const value = res[key];
                    contentJson[key] = value;
                    sendNotification({ msg: 'Archivo subido correctamente!', variant: 'info' });
                }
            }
            else if (response.status === 504 && response.status === 408)
                sendNotification({ msg: 'El proceso se esta ejecutando', variant: 'info' });
            else
                sendNotification({ msg: 'Ocurrio un error realizando la acción, por favor comuniquese con el area encargada!', variant: 'error' });
        } catch (error) {
            if (error.response?.status === 504)
                sendNotification({ msg: 'El proceso se está ejecutando', variant: 'info' });
            else
                sendNotification({ msg: 'Ocurrió un error realizando la acción, por favor comuníquese con el área encargada!', variant: 'error' });
            console.error('ERROR ==> ', error);
        }
    };

    const CallApi = async (param) => {
        setOpen(false);
        const url = param[0]?.url;
        const method = param[0]?.method;
        let bodyData = param[0]?.bodyData;
        let str = JSON.stringify(bodyData);

        for (let key in contentJson) {
            const value = contentJson[key];
            str = str.toString().replace(`{${key}}`, value);
        }

        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: str
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                sendNotification({ msg: 'Evento realizado correctamente!', variant: 'success' });
                setTimeout(() => {
                    handleChange();
                }, 500);
            }
            else
                sendNotification({ msg: 'Ocurrio un error realizando la acción, por favor comuniquese con el area encargada!', variant: 'error' });
        } catch (error) {
            sendNotification({ msg: 'Ocurrio un error realizando la acción, por favor comuniquese con el area encargada!', variant: 'error' });
            console.error(error);
        }
    }

    return (
        <React.Fragment>
            <Tooltip title={data.accion}>
                <IconButton
                    id={`id_${data.accion}`}
                    size="small"
                    sx={{ p: 0 }}
                    onClick={() => setOpen(true)}
                >
                    <span style={{ fontSize: 25, color: data.color }}>
                        {React.createElement(Icons[data.icon])}
                    </span>
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                aria-labelledby="form-modal-title"
                aria-describedby="form-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4
                    }}
                >
                    <Typography id="form-modal-title" variant="h6" component="h2">
                        Formulario {data.accion}
                    </Typography>

                    <DynamicForm
                        values={contentJson}
                        scheme={data.form}
                        onChange={onChange}
                        handleFileChange={handleFileChange}
                        handleUploadClick={handleUploadClick}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button
                            onClick={() => setOpen(false)}
                            variant="contained"
                            color="primary"
                            sx={{
                                mx: 3
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => CallApi(data.trigger)}
                        >
                            Guardar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    )
}