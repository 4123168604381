import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Dialog, Typography, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useManageDocQuery } from '../../../hooks/useManageDocQuery';
import { Close } from '@mui/icons-material';
import { DownloadFile } from '../../others/DownloadFile';
import { GetHistoryDoc } from '../../../redux/actions/docQuery';

export const ModalHistory = ({ idReception }) => {
    const dispatch = useDispatch();
    const [DocManager] = useManageDocQuery();
    const {
        historyDoc
    } = DocManager;
    const [{ userSession }] = useDataAuth();
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <Tooltip title={'Historico del documento'}>
                <IconButton
                    id={`id_`}
                    size="small"
                    sx={{ p: 0 }}
                    onClick={() => {
                        dispatch(GetHistoryDoc(userSession.token, idReception));
                        setOpen(true);
                    }}
                >
                    <span style={{ fontSize: 25, color: 'black' }}>
                        {React.createElement(Icons['ManageSearch'])}
                    </span>
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                fullWidth
                maxWidth="lg"
                PaperProps={{
                    style: { height: '80%' },
                }}
            >
                <DialogTitle>
                    <Typography variant="h5" component="h5">
                        Historico del documento
                    </Typography>

                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Estado</TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Información estado</TableCell>
                                <TableCell>Descripción</TableCell>
                                <TableCell>Adjuntos</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                historyDoc.map((item) => (
                                    <TableRow>
                                        <TableCell>{item.status}</TableCell>
                                        <TableCell>{item.created}</TableCell>
                                        <TableCell>{item.info_status}</TableCell>
                                        <TableCell>{item.description}</TableCell>
                                        <TableCell>
                                            <Typography>
                                                <DownloadFile path={item.path} bucket={item.bucket} />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}