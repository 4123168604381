import { useState } from "react"

export const useErrorInputs = (initialState = {}) => {
    const [errorInputs, setErrorInputs] = useState(initialState);

    const resetErrors = () => {
        setErrorInputs(initialState);
    }

    const handleErrorInput = (input, { isError = false, messageError = '' }) => {
        setErrorInputs((prevErrorInputs) => ({
            ...prevErrorInputs,
            [input]: {
                isError,
                messageError
            }
        }));
    }
    return [errorInputs, handleErrorInput, resetErrors];
}