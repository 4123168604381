import React from "react";
import { DownloadFile } from '../others/DownloadFile';

export const DownloadAllFiles = ({ data }) => {
    let item = data?.trigger[0]?.bodyData;

    return (
        <DownloadFile
            path={item?.path}
            bucket={item?.bucket}
            idFlow={item?.id_flow}
            data={data}
        />
    );
}