import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { EventNote, GridView, Settings } from '@mui/icons-material';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import moment from 'moment';
import { headersFlowsTable } from '../utils/headersFlowsTable';
import { useManageFlows } from '../../../hooks/useManageFlows';
import { StepperCreateNewItem } from '../../../components/createNewItem/StepperCreateNewItem';
import { StepBasicInformation } from '../createFlow/stepBasicInformation/StepBasicInformation';
import { StepComponentsFlow } from '../createFlow/stepComponentFlows/StepComponentsFlow';
import { StepCronExpression } from '../createFlow/stepCronExpression/StepCronExpression';
import { changesPendingComponentFlows, changesPendingFlows, changesPendingSubComponentFlows, errorManageFlows, getAllFlows, updateFlows } from '../../../redux/actions/flows';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { DetailsFlow } from '../detailsFlow/DetailsFlow';
import { DetailsComponentsFlow } from '../detailsComponentFlow/DetailsComponentFlow';
import { DetailsSubComponentFlow } from '../detailsSubComponentsFlow/DetailsSubComponentFlow';
import { useNotification } from '../../../hooks/useNotification';
import { CustomTable } from '../../../components/tables/tableNavigate/CustomTable';
import { DialogConfirmAction } from '../../../components/dialogs/DialogConfirmAction';
import { useManageStatus } from '../../../hooks/useManageStatus';

// const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export const LayoutFlows = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [openDialogConfirmNotSaveChanges, setOpenDialogConfirmNotSaveChanges] = useState(false);
    const [pathIndexTemp, setPathIndexTemp] = useState('');
    const [isValidStep, setIsValidStep] = useState(false);
    const {
        isChangesPendingFlow,
        isChangesPendingComponentFlow,
        isChangesPendingSubComponentFlow,
        flowInEdition,
        componentFlowInEdition,
        subComponentFlowInEdition
    } = useSelector(state => state.flows);
    const [{ userSession, idCompany }] = useDataAuth();
    const [sendNotification] = useNotification();
    const patharray = location.pathname.split('/').filter((x) => x);
    const pathnames = patharray.slice(1, patharray.length + 1);

    const [statusManager] = useManageStatus();
    const [flowManager] = useManageFlows();
    const {
        loadingAllFlows: isLoadingFlows,
        updatingFlows: isUpdatingFlows,
        deletingFlows: isDeletingFlows,
        addingNewFlow: isAddingFlow,
        isErrorFetch,
        messageErrorFetch,
        listFlows,
        listSchemes,
        setIsChangeDataFlows,
        setIsChangeDataComponentFlows,
        setIsChangeDataSubComponentFlows,
        handleDeleteFlows,
        handleDeleteComponentFlow,
        handleDeleteSubComponentFlow,
        handleReplaceFlow,
        handleReplaceComponentFlow,
        handleReplaceSubComponentFlow,
        handleAddFlow,
        currentBreadCrumb
    } = flowManager;

    useEffect(() => {
        dispatch(getAllFlows(idCompany, userSession.token));
        // dispatch(getDataStatus(userSession.token));
    }, []);

    useEffect(() => {
        if (isErrorFetch) {
            sendNotification({ msg: messageErrorFetch, variant: 'error' });
            dispatch(errorManageFlows(false));
        }
    }, [isErrorFetch]);

    const handleLinkBreadcrumbClick = (event, path) => {
        navigate(path);
    }

    const validateChangesPending = (path) => {
        if (isChangesPendingFlow && currentBreadCrumb === 1) {
            setOpenDialogConfirmNotSaveChanges(true);
            setPathIndexTemp(path);
        } else if (isChangesPendingComponentFlow && currentBreadCrumb === 2) {
            setOpenDialogConfirmNotSaveChanges(true);
            setPathIndexTemp(path);
        } else if (isChangesPendingSubComponentFlow && currentBreadCrumb === 3) {
            setOpenDialogConfirmNotSaveChanges(true);
            setPathIndexTemp(path);
        } else {
            handleLinkBreadcrumbClick(null, path);
        }
    }

    const handleConfirmeNotSaveChanges = () => {
        if (currentBreadCrumb === 3) {
            if (subComponentFlowInEdition.is_new_component) {
                handleDeleteSubComponentFlow(
                    flowInEdition.id_flow,
                    componentFlowInEdition.id_flow_component,
                    subComponentFlowInEdition.id_flow_component,
                    true
                );
                setIsChangeDataSubComponentFlows(false);
            } else {
                handleReplaceSubComponentFlow(flowInEdition.id_flow, componentFlowInEdition.id_flow_component, subComponentFlowInEdition);
                setIsChangeDataSubComponentFlows(false);
            }
        }
        if (currentBreadCrumb === 2) {
            if (componentFlowInEdition.is_new_component) {
                handleDeleteComponentFlow(
                    flowInEdition.id_flow,
                    componentFlowInEdition.id_flow_component,
                    true
                );
                setIsChangeDataComponentFlows(false);
            } else {
                handleReplaceComponentFlow(flowInEdition.id_flow, componentFlowInEdition);
                setIsChangeDataComponentFlows(false);
            }
        }
        if (currentBreadCrumb === 1) {
            handleReplaceFlow(flowInEdition);
            setIsChangeDataFlows(false);
        }
        setOpenDialogConfirmNotSaveChanges(false);
        handleLinkBreadcrumbClick(null, pathIndexTemp);
        dispatch(changesPendingFlows(false));
        dispatch(changesPendingComponentFlows(false));
        dispatch(changesPendingSubComponentFlows(false));
    }

    const updateFlow = (data) => {
        dispatch(updateFlows(data, idCompany, userSession.token));
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 2, pt: 2, pl: 2, pr: 2 }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator="›"
                    sx={{ mb: 2 }}
                >
                    <Link
                        underline="hover"
                        color="text.main"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => validateChangesPending('/flows')}
                    >
                        <Typography
                            sx={{ fontWeight: pathnames.length === 0 && "bold" }}
                        >
                            Flows
                        </Typography>
                    </Link>
                    {
                        pathnames.length > 0 && pathnames.map((value, index) => {
                            const last = index === pathnames.length - 1;
                            const to = `/flows/${pathnames.slice(0, index + 1).join('/')}`;

                            return last ? (
                                <Typography
                                    sx={{ fontWeight: "bold" }}
                                    color="primary"
                                    key={to}
                                >
                                    {/* { 
                                        index === 0 && parseInt(value) ? `Flow: ${value}` 
                                        : index === 1 && parseInt(value) ? `Component Flow: ${value}` 
                                        : index === 2 && parseInt(value) ? `Sub Component Flow: ${value}` 
                                        : value
                                    } */}
                                    {
                                        value.split("-").map(item => (
                                            item.charAt(0).toUpperCase() + item.slice(1)
                                        )).join(" ")
                                    }
                                </Typography>
                            ) : (
                                <Link
                                    key={to}
                                    underline="hover"
                                    color="text.main"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => validateChangesPending(to)}
                                >
                                    {
                                        value.split("-").map(item => (
                                            item.charAt(0).toUpperCase() + item.slice(1)
                                        )).join(" ")
                                    }
                                </Link>
                            );
                        })
                    }
                </Breadcrumbs>
                {/* <Box
                    sx={{mb:2}}
                >
                    <Typography
                        color="text.main"
                        variant="h4"
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        Flows
                    </Typography>
                </Box> */}
                <Routes>
                    <Route path="/" exact="true" element={
                        <CustomTable
                            nameItems="Flows"
                            nameItem="flow"
                            valueOrder={"desc"}
                            keyItemSearch="name"
                            headers={headersFlowsTable}
                            rows={!listFlows ? [] : listFlows}
                            initialOrderSort="created"
                            idRow="id_flow"
                            toNavigateRow="/flows/flow-:id_flow"
                            toNavigateCreateItem="/flows/create-flow"
                            isLoadingItems={isLoadingFlows}
                            isSavingItems={isUpdatingFlows}
                            isDeletingItems={isDeletingFlows}
                            isErrorLoad={isErrorFetch}
                            messageError={messageErrorFetch}
                            handleDeleteFlows={handleDeleteFlows}
                            handleClickReferesh={() => dispatch(getAllFlows(idCompany, userSession.token))}
                            rowsByPage={7}
                            statusManager={statusManager}
                        />
                    } />
                    <Route path="/flow-:idFlow" element={
                        <DetailsFlow
                            flowManager={flowManager}
                            statusManager={statusManager}
                            updateFlow={updateFlow}
                        />
                    } />
                    <Route path="/flow-:idFlow/component-flow-:idComponentFlow" element={
                        <DetailsComponentsFlow
                            flowManager={flowManager}
                        />
                    } />
                    <Route path="/flow-:idFlow/component-flow-:idComponentFlow/sub-component-flow-:idSubComponentFlow" element={
                        <DetailsSubComponentFlow
                            flowManager={flowManager}
                        />
                    } />
                    <Route exact="true" path="/create-flow" element={
                        <StepperCreateNewItem
                            isValidStep={isValidStep}
                            steps={[
                                {
                                    idStep: 1,
                                    orderStep: 1,
                                    nameStep: 'Basic Information',
                                    isOptional: false,
                                    contentStep: <StepBasicInformation
                                        setIsValidStep={setIsValidStep}
                                    />,
                                    iconType: <Settings />
                                },
                                {
                                    idStep: 2,
                                    orderStep: 2,
                                    nameStep: 'Cron Expression',
                                    isOptional: false,
                                    contentStep: <StepCronExpression />,
                                    iconType: <EventNote />
                                },
                                {
                                    idStep: 3,
                                    orderStep: 3,
                                    nameStep: 'Components',
                                    isOptional: true,
                                    contentStep: <StepComponentsFlow />,
                                    iconType: <GridView />
                                }
                            ]}
                            handleClickAdd={handleAddFlow}
                            isAdding={isAddingFlow}
                            valueInitial={{
                                id_flow: 0,
                                name: '',
                                id_company: 1,
                                type: '',
                                configuration: {},
                                cron_expression: '* * ? * * *',
                                next_execute: '',
                                status: '',
                                created: moment().toDate(),
                                created_by: userSession.username,
                                updated: moment().toDate(),
                                updated_by: userSession.username,
                                flow_components: []
                            }}
                        />
                    } />
                </Routes>
            </Box>
            <DialogConfirmAction
                openDialogConfirm={openDialogConfirmNotSaveChanges}
                setOpenDialogConfirm={setOpenDialogConfirmNotSaveChanges}
                titleDialog={"Changes Pending To Save"}
                textConfirmAction={"You have unsaved changes, do you want to continue?"}
                textButtonConfirm={"Continue"}
                textButtonCancel={"Cancel"}
                handleConfirmAction={handleConfirmeNotSaveChanges}
            />
        </Box>
    )

}
