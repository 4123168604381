
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useDispatch } from 'react-redux';
import { getSchemes } from '../../../redux/actions/flows';
import { EditItemToolbar } from '../../../components/editItem/EditItemToolbar';
import FlowEditor from './FlowEditor';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SmsIcon from '@mui/icons-material/Sms';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TransformIcon from '@mui/icons-material/Transform';
import WebhookIcon from '@mui/icons-material/Webhook';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import StorageIcon from '@mui/icons-material/Storage';
import BackupIcon from '@mui/icons-material/Backup';
import EmailIcon from '@mui/icons-material/Email';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import GradingIcon from '@mui/icons-material/Grading';
import BackpackIcon from '@mui/icons-material/Backpack';
import MediationIcon from '@mui/icons-material/Mediation';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HelpIcon from '@mui/icons-material/Help';

import ReactFlow, {
    addEdge,
    Background,
    Controls,
    MiniMap,
    Handle,
} from 'react-flow-renderer';
import dagre from 'dagre';
import { useManageFlows } from '../../../hooks/useManageFlows';
import { useManageUsers } from '../../../hooks/useManageUsers';
import { updateFlows } from '../../../redux/actions/flows';
import { setNotificaction } from '../../../redux/actions/users';
import { useNotification } from '../../../hooks/useNotification';

export const DetailsFlow = () => {
    const [fatherIconState, setFatherIconState] = useState({});
    const childRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [flowManager] = useManageFlows();
    const [sendNotification] = useNotification();
    const params = useParams();
    const {
        updatingFlows: isUpdatingFlows,
        deletingFlows: isDeletingFlows,
        listSchemes,
        isChangeDataFlows,
        handleDeleteFlows,
        handleChangeFlow,
        setIsChangeDataFlows,
        simulatedData,
        getDataFlow
    } = flowManager;

    const [UsersManager] = useManageUsers();
    const {
        isNotification,
        msg,
        typeMsg,
    } = UsersManager;

    useEffect(() => {
        if (isNotification) {
            sendNotification({ msg: msg, variant: typeMsg });
            dispatch(setNotificaction({}));
        }
    }, [isNotification, msg, typeMsg, dispatch, sendNotification]);

    const [isLoadingFlows] = useState(false);
    const [{ userSession }] = useDataAuth();

    useEffect(() => {
        if (location.state && location.state.config) {
            const { data, id } = location.state.config || {};
            dispatch(getDataFlow(id, userSession.token));
        }
    }, [location.state]);

    const handleClickDeleteFlow = () => {
        handleDeleteFlows(
            [!isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow]
        )
        setTimeout(() => {
            navigate(`/flows/`)
        }, 500);
    }

    const [contentJson, setContentJson] = useState({});
    const [Json, setJson] = useState({});
    const [identifier, setIdentifier] = useState();
    const [isParser, setIsParser] = useState(false);
    const [itemEdit, setItemEdit] = useState({});
    const [scheme, setEscheme] = useState([]);

    function setItem(item) {
        setIsParser(item.type_component === 'parser');
        setItemEdit(item);
        setContentJson(item.body);
        setJson(item.body);
        setIdentifier(item.order);
        let sch = JSON.parse(listSchemes.find(s => s.type_component === item.type_component)?.json_schema)
        setEscheme(sch ? sch : []);
        if (childRef.current) {
            childRef.current.customFunction();
        }
    }

    const SetNewValue = (data) => {
        setContentJson(data);
        if (Json !== data)
            setIsChangeDataFlows(true);
    }

    const Update = () => {
        const flowC = JSON.parse(simulatedData[0]?.component).flow_component;
        const index = JSON.parse(simulatedData[0]?.component).flow_component.findIndex(p => p.order === identifier);
        JSON.parse(simulatedData[0]?.component).flow_component[index].body = contentJson;
        flowC[index].body = contentJson;
        JSON.parse(simulatedData[0]?.component).flow_component = flowC;
        const data = JSON.stringify({ 'id_flow': simulatedData[0]?.id_flow, 'component': { 'flow_component': flowC } });
        dispatch(updateFlows(data, simulatedData[0]?.id_flow, userSession.token));
    }

    useEffect(() => {
        dispatch(getSchemes(userSession.token));
    }, []);

    const onChange = (e) => {
        const { name, value, type, checked } = e.target;
        setContentJson({ ...contentJson, [name]: type === 'switch' ? checked : value });
        if (Json !== contentJson)
            setIsChangeDataFlows(true);
    }

    const SetChanges = (key, value) => {
        setContentJson({ ...contentJson, [key]: value });
        setTimeout(() => {
            if (Json !== contentJson)
                setIsChangeDataFlows(true);
        }, 500);
    }

    //#region wor-flow
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const nodeWidth = 310;
    const nodeHeight = 36;

    const getLayoutedElements = (nodes, edges, direction = 'LR') => {
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });

        nodes.forEach((node) => {
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        nodes.forEach((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? 'left' : 'top';
            node.sourcePosition = isHorizontal ? 'right' : 'bottom';
            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
        });

        return { nodes, edges };
    };

    const getIconByType = (type_component) => {
        switch (type_component) {
            case 'parser':
                return <TransformIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'api_integration':
                return <WebhookIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'decision':
                return <AltRouteIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'sql_server':
                return <StorageIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'notify_sms':
                return <SmsIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 's3_aws':
                return <BackupIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'email':
                return <EmailIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'pdf':
                return <PictureAsPdfIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'zip_file':
                return <FolderZipIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'electronic_document':
                return <GradingIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'mongo_db':
                return <BackpackIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'analysis_document':
                return <MediationIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'whatsapp':
                return <WhatsAppIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'report':
                return <EqualizerIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'sqs':
                return <LibraryBooksIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            case 'sftp':
                return <UploadFileIcon fontSize="large" style={{ color: '#FF2A2A' }} />;
            default:
                return <HelpIcon fontSize="large" style={{ color: '#FF2A2A' }} />; // Default icon
        }
    };

    const CustomNodeWithEdit = ({ data }) => {
        const { isFather } = data;
        const iconState = fatherIconState[data.order];

        // Add 'highlight' when all children are hidden, and 'flash' when temporarily showing
        const customClassName = `
            ${isFather && iconState ? 'highlightNode' : ''}
            ${flashNode === data.order ? 'flash' : ''}
        `;

        return (
            <div className={customClassName} style={{ maxWidth: 300, padding: 3, backgroundColor: '#fff', border: '1px solid #ddd', borderRadius: 5, whiteSpace: 'pre-wrap' }}>
                <Handle type="target" position="left" style={{ background: '#555' }} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {getIconByType(data.type_component)}
                    <span style={{ marginLeft: 8, marginRight: 8, fontSize: 12 }} >{data.label}</span>
                    {isFather &&
                        <IconButton
                            onClick={() => HideNodes(data.order)}
                            size="small"
                        >
                            {iconState ?
                                <VisibilityOff />
                                :
                                <VisibilityIcon />
                            }
                        </IconButton>
                    }
                    <IconButton
                        onClick={() => setItem(data)}
                        size="small"
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                </div>
                <Handle type="source" position="right" style={{ background: '#555' }} />
            </div>
        );
    };

    const nodeTypes = {
        customNodeWithEdit: CustomNodeWithEdit,
    };

    const getParentOrder = (order) => {
        const parts = order.split('.');
        if (parts.length > 1 && (parts[parts.length - 2] === '0' || parts[parts.length - 2] === '1')) {
            return parts.slice(0, -2).join('.');
        }
        return parts.slice(0, -1).join('.');
    };

    const generateFlowElements = (data, name) => {
        const nodes = [];
        const edges = [];

        nodes.push({
            id: '00',
            data: { label: name, type_component: 'root' },
            position: { x: 0, y: 0 },
            type: 'input',
            sourcePosition: 'right',
            targetPosition: 'left',
        });

        data.flow_component.forEach((component) => {
            const { type_component, description, order, subtype_component, input } = component;

            // Check if node has children
            const nodeId = component.order;
            // Check if the node has children
            const children = data.flow_component.filter(child => child.order.startsWith(nodeId + "."));
            const hasChildren = children.length > 0;
            // console.log("generateFlowElements nodeId: " + nodeId + " hasChildren: " + hasChildren );

            const formattedLabel = [
                [order, type_component, subtype_component, input].filter(Boolean).join(' - '), // First line
                [description].filter(Boolean).join(' '),
            ].join('\n');

            nodes.push({
                id: order,
                data: { label: formattedLabel, ...component, isFather: hasChildren, },
                position: { x: 0, y: 0 },
                type: 'customNodeWithEdit',
                sourcePosition: 'right',
                targetPosition: 'left',
            });

            if (!order.includes('.')) {
                edges.push({
                    id: `e00-${order}`,
                    source: '00',
                    target: order,
                });
            }

            const parentOrder = getParentOrder(order);
            if (parentOrder) {
                edges.push({
                    id: `e${parentOrder}-${order}`,
                    source: parentOrder,
                    target: order,
                });
            }
        });

        return { nodes, edges };
    };

    const [nodesHide, setNodesHide] = useState([]);
    const [flashNode, setFlashNode] = useState(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [isInitiallyCentered, setIsInitiallyCentered] = useState(false);

    function HideNodes(node) {
        const nodes = [];
        const edges = [];
        let array = [];
        let nodeHidden = `${node}.`;

        // Check if we're showing the node's children
        const isShowingChildren = nodesHide.includes(nodeHidden);

        if (isShowingChildren) {
            // Apply flash effect
            setFlashNode(node);

            // Clear flash effect after a delay
            setTimeout(() => setFlashNode(null), 800); // 500 ms matches the animation duration
        }

        const isIncluded = nodesHide.includes(nodeHidden);
        if (isIncluded) {
            array = nodesHide.filter((p) => p !== nodeHidden);
            setNodesHide(nodesHide.filter((p) => p !== nodeHidden));
            // setNodesHide(array);
        }
        else {
            array = nodesHide;
            array.push(nodeHidden);
            setNodesHide((noHide) => [
                ...noHide,
                nodeHidden
            ]);
            // array.push(nodeHidden);
            // setNodesHide(prev => [...prev, nodeHidden]);
        }

        nodes.push({
            id: '00',
            data: { label: `${simulatedData[0]?.name}`, type_component: 'root' },
            position: { x: 0, y: 0 },
            type: 'input',
            sourcePosition: 'right',
            targetPosition: 'left',
        });

        const data = JSON.parse(simulatedData[0].component);
        data.flow_component.forEach((component) => {
            const { type_component, description, order, subtype_component, input } = component;
            const formattedLabel = [
                [order, type_component, subtype_component, input].filter(Boolean).join(' - '), // First line
                [description].filter(Boolean).join(' '),
            ].join('\n');

            // Check if node has children
            const nodeId = component.order;
            // Check if the node has children
            const children = data.flow_component.filter(child => child.order.startsWith(nodeId + "."));
            const hasChildren = children.length > 0;
            // console.log("HideNodes nodeId: " + nodeId + " hasChildren: " + hasChildren );
            // Update the specific father icon state for only the selected node
            if (nodeId === node && hasChildren) {
                setFatherIconState(prevState => ({
                    ...prevState,
                    [node]: !prevState[node]  // Toggle the icon state only for this node
                }));
            }


            let itemHidden = false;
            array.some((item) => {
                if (order.includes(item)) {
                    itemHidden = true;
                    return true;
                } else if (order.startsWith(item)) {
                    itemHidden = true;
                    return true;
                }
                else {
                    itemHidden = false;
                    return false;
                }
            });



            nodes.push({
                id: order,
                data: { label: formattedLabel, ...component, isFather: hasChildren, hidden: itemHidden, },
                position: { x: 0, y: 0 },
                type: 'customNodeWithEdit',
                sourcePosition: 'right',
                targetPosition: 'left',
                hidden: itemHidden,
            });

            if (!order.includes('.')) {
                edges.push({
                    id: `e00-${order}`,
                    source: '00',
                    target: order,
                });
            }

            const parentOrder = getParentOrder(order);
            if (parentOrder) {
                edges.push({
                    id: `e${parentOrder}-${order}`,
                    source: parentOrder,
                    target: order,
                    hidden: itemHidden
                });
            }
        });


        // Filter out hidden nodes by added property
        const visibleNodes = nodes.filter(node => !node.hidden);
        // Filter out hidden edges by added property
        const visibleEdges = edges.filter(edge => !edge.hidden);
        // Pass only the visible nodes and edges to the layout function
        // const layoutedElements = getLayoutedElements(visibleNodes, visibleEdges);
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(visibleNodes, visibleEdges);

        // const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges);
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);

        // Find the node's current position to center the viewport on it
        const clickedNode = nodes.find(n => n.id === node);
        if (clickedNode) {
            const { x, y } = clickedNode.position;
            // Center the view on the clicked node, adjusting zoom as needed
            if (reactFlowInstance) {
                // reactFlowInstance.setCenter(x, y, { zoom: 0.8, duration: 500 });
                reactFlowInstance.setCenter(x, y, { zoom: 0.9, duration: 500 });
            }
            // reactFlowInstance.setCenter(x, y, { zoom: 1.5, duration: 500 });
        }
    }
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    useEffect(() => {
        if (simulatedData.length > 0 && simulatedData[0]?.component) {
            const { nodes: generatedNodes, edges: generatedEdges } = generateFlowElements(JSON.parse(simulatedData[0].component), simulatedData[0].name);
            const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(generatedNodes, generatedEdges);
            setNodes(layoutedNodes);
            setEdges(layoutedEdges);
        }
    }, [simulatedData]);

    // Runs ONCE ONLY, Centering effect that waits for both nodes and reactFlowInstance to be ready
    useEffect(() => {
        if (!isInitiallyCentered) {
            if (reactFlowInstance && nodes.length > 0) {
                const initialRootNode = nodes.find(n => n.id === '00');
                if (initialRootNode) {
                    const { x, y } = initialRootNode.position;
                    reactFlowInstance.setCenter(x, y, { zoom: 0.9, duration: 500 });
                    setIsInitiallyCentered(true);
                }
            }
        }
    }, [reactFlowInstance, nodes, isInitiallyCentered]);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
    //#endregion

    return (
        // <Box>
        //     <Grid container sx={{ mb: 2 }} >
        //         <EditItemToolbar
        //             isLoadingItems={isLoadingFlows}
        //             titleEditItem={{
        //                 label: `Id Flow: ${simulatedData[0]?.id_flow} / `,
        //                 name: 'name',
        //                 value: simulatedData[0]?.name,
        //                 handleChange: handleChangeFlow,
        //                 isEditable: true
        //             }}
        //             nameItem={"Flow"}
        //             isSaving={isUpdatingFlows}
        //             statusButtonSave={isChangeDataFlows}
        //             isDeleting={isDeletingFlows}
        //             // handleSaveChanges={handleClickSaveChanges}
        //             handleSaveChanges={() => Update()}
        //             statusButtonDelete={true}
        //             handleClickDelete={handleClickDeleteFlow}
        //         />

        //         <FlowEditor
        //             ref={childRef}
        //             title={itemEdit?.description}
        //             contentJson={contentJson}
        //             scheme={scheme}
        //             onChange={onChange}
        //             setChanges={SetChanges}
        //             setNewValue={SetNewValue}
        //             disabledForm={scheme && scheme.length > 0 ? false : true}
        //         />
        //     </Grid>
        //     <Grid container item style={{ display: "flex", alignItems: "stretch" }}>
        //         <Grid item md={12} elevation={4} style={{ overflow: 'auto', height: 400, padding: 20, flex: 1 }}>
        //             <Box>
        //                 <div style={{ height: 600, minHeight: 400, maxHeight: 600 }}>
        //                     <ReactFlow
        //                         nodes={nodes}
        //                         edges={edges}
        //                         onConnect={onConnect}
        //                         nodeTypes={nodeTypes}
        //                         deleteKeyCode={46}
        //                         onInit={(instance) => setReactFlowInstance(instance)}
        //                     >
        //                         <MiniMap />
        //                         <Controls />
        //                         <Background color="#aaa" gap={16} />
        //                     </ReactFlow>
        //                 </div>
        //             </Box>
        //         </Grid>
        //     </Grid>
        // </Box>

        <Box sx={{ height: '85vh', display: 'flex', flexDirection: 'column' }}>
            <Grid container sx={{ mb: 2, flexShrink: 0 }}>
                <EditItemToolbar
                    isLoadingItems={isLoadingFlows}
                    titleEditItem={{
                        label: `Id Flow: ${simulatedData[0]?.id_flow} / `,
                        name: 'name',
                        value: simulatedData[0]?.name,
                        handleChange: handleChangeFlow,
                        isEditable: true
                    }}
                    nameItem={"Flow"}
                    isSaving={isUpdatingFlows}
                    statusButtonSave={isChangeDataFlows}
                    isDeleting={isDeletingFlows}
                    handleSaveChanges={() => Update()}
                    statusButtonDelete={true}
                    handleClickDelete={handleClickDeleteFlow}
                />

                <FlowEditor
                    ref={childRef}
                    title={itemEdit?.description}
                    contentJson={contentJson}
                    scheme={scheme}
                    onChange={onChange}
                    setChanges={SetChanges}
                    setNewValue={SetNewValue}
                    disabledForm={scheme && scheme.length > 0 ? false : true}
                />
            </Grid>

            <Grid container item style={{ display: "flex", alignItems: "stretch", flex: 1, overflow: 'hidden' }}>
                <Grid item md={12} elevation={4} style={{ overflow: 'hidden', padding: 20, flex: 1 }}>
                    <Box sx={{ height: '100%' }}>
                        <div style={{ height: '100%', minHeight: 400 }}>
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                // onConnect={onConnect}
                                nodeTypes={nodeTypes}
                                deleteKeyCode={46}
                                onInit={(instance) => setReactFlowInstance(instance)}
                            >
                                <MiniMap />
                                <Controls />
                                <Background color="#aaa" gap={16} />
                            </ReactFlow>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Box >

    )
}