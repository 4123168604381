import { Description, Dns } from '@mui/icons-material';
import { FormGroup, Grid, InputAdornment, TextField } from '@mui/material';
import React from 'react'
import { useErrorInputs } from '../../../../hooks/useErrorInputs';
import { useFocusInputs } from '../../../../hooks/useFocusInputs';

export const DetailsSummaryTypesFlow = ({
    typeFlowSelected,
    settingsManager
}) => {

    const {
        handleChangeTypeFlow
    } = settingsManager;

    const [{typeError, descriptionError}, handleErrorInput] = useErrorInputs({
        typeError:{
            isError: false,
            messageError: ''
        },
        descriptionError:{
            isError: false,
            messageError: ''
        },
    });  
    const [{isTypeFocus, isDescriptionFocus}, handleFocusInputs] = useFocusInputs({
        isTypeFocus: false,
        isDescriptionFocus: false
    });

    return (
        <Grid container>
            <Grid item md={2}></Grid>
            <Grid item md={8}>
                <FormGroup sx={{my: 2}}>
                    <TextField
                        error={!isTypeFocus && typeError.isError}
                        color={(isTypeFocus) ? "text" : (typeError.isError) ? "error" : "text"}
                        label="Name Type Flow"
                        variant="outlined"
                        name="type"
                        placeholder="Enter name type flow"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Dns color={(isTypeFocus) ? "text" : (typeError.isError) ? "error" : "text"}/>
                                </InputAdornment>
                            ),
                        }}                        
                        value={typeFlowSelected.type}
                        helperText={typeError.messageError}
                        onChange={(e) => handleChangeTypeFlow(e.target, typeFlowSelected.id)}
                        onFocus={handleFocusInputs}
                        onBlur={handleFocusInputs}
                    />
                </FormGroup>

                <FormGroup>
                    <TextField
                        multiline
                        rows={2}
                        error={!isDescriptionFocus && descriptionError.isError}
                        color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}
                        label="Description"
                        variant="outlined"
                        name="description"
                        placeholder="Enter description"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Description color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}/>
                                </InputAdornment>
                            )
                        }}                        
                        value={typeFlowSelected.description} 
                        helperText={descriptionError.messageError}
                        onChange={(e) => handleChangeTypeFlow(e.target, typeFlowSelected.id)}
                        onFocus={handleFocusInputs}
                        onBlur={handleFocusInputs}
                    />
                </FormGroup>    
            </Grid>
            <Grid item md={2}></Grid>
        </Grid>
    )
}
