import { Add, Description, Dns } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormGroup, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useErrorInputs } from '../../../../hooks/useErrorInputs';
import { useFocusInputs } from '../../../../hooks/useFocusInputs';
import { useForm } from '../../../../hooks/useForm';

export const CreateTypeFlow = ({
  settingsManager
}) => {

  const {
    handleAddTypeFlow,
    addingNewSettingsFlows
  } = settingsManager;

  const[{type, description}, handleInputChange] = useForm({
    type: '',
    description: ''
  });

  const [{typeError, descriptionError}, handleErrorInput] = useErrorInputs({
    typeError:{
        isError: false,
        messageError: ''
    },
    descriptionError:{
        isError: false,
        messageError: ''
    },
  });  
  const [{isTypeFocus, isDescriptionFocus}, handleFocusInputs] = useFocusInputs({
      isTypeFocus: false,
      isDescriptionFocus: false
  });

  const handleSubmit = (e) =>{
    e.preventDefault();
    if(type.length <= 0){
        handleErrorInput('typeError', {
            isError: true,
            messageError: 'The field type is required'
        });
        return;
    }

    if(description.length <= 0){
        handleErrorInput('descriptionError', {
            isError: true,
            messageError: 'The field description is required'
        });
        return;
    }

    handleAddTypeFlow({
      type,
      description 
    })
  }

  return (
    <Grid container>
        <Grid 
          item
          md={12}
          sx={{
            mb: 2
          }}
        >
          <Typography 
            variant="h5"
            color="primary"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold'
            }}
          >
            Create New Type Flow
          </Typography>
        </Grid>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
            <form onSubmit={handleSubmit}>
              <FormGroup sx={{my: 2}}>
                  <TextField
                      error={!isTypeFocus && typeError.isError}
                      color={(isTypeFocus) ? "text" : (typeError.isError) ? "error" : "text"}
                      label="Name Type Flow"
                      variant="outlined"
                      name="type"
                      placeholder="Enter name type flow"
                      size="small"
                      autoComplete="off"
                      InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                  <Dns color={(isTypeFocus) ? "text" : (typeError.isError) ? "error" : "text"}/>
                              </InputAdornment>
                          ),
                      }}                        
                      value={type}
                      helperText={typeError.messageError}
                      onChange={handleInputChange}
                      onFocus={handleFocusInputs}
                      onBlur={handleFocusInputs}
                  />
              </FormGroup>

              <FormGroup>
                  <TextField
                      multiline
                      rows={2}
                      error={!isDescriptionFocus && descriptionError.isError}
                      color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}
                      label="Description"
                      variant="outlined"
                      name="description"
                      placeholder="Enter description"
                      size="small"
                      autoComplete="off"
                      InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                  <Description color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}/>
                              </InputAdornment>
                          )
                      }}                        
                      value={description} 
                      helperText={descriptionError.messageError}
                      onChange={handleInputChange}
                      onFocus={handleFocusInputs}
                      onBlur={handleFocusInputs}
                  />
              </FormGroup>
              <Box sx={{
                mt: 2,
                textAlign: 'center'
              }}>
                <LoadingButton
                    loading={addingNewSettingsFlows}
                    type="submit"
                    loadingPosition="start"
                    startIcon={<Add />}
                    size="small"
                    variant="contained"
                    color="primary"
                >
                    Add New Type Flow
                </LoadingButton>
              </Box>
            </form>
        </Grid>
        <Grid item md={2}></Grid>
    </Grid>
  )
}
