import { Grid, InputLabel, MenuItem, Paper, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';

import typeFromValue from '../../utils/typeFromValue';
import valueFromType from '../../utils/valueFromType';
import { ArrayEditor } from './ArrayEditor';
import { BooleanEditor } from './BooleanEditor';
import { NumberEditor } from './NumberEditor';
import { ObjectEditor } from './ObjectEditor';
import { StringEditor } from './StringEditor';

const types = [
  { name: 'Number', value: 'number' },
  { name: 'String', value: 'string' },
  { name: 'Boolean', value: 'boolean' },
  { name: 'Array', value: 'array' },
  { name: 'Object', value: 'object' },
  { name: 'Null', value: 'null' },
];

const typeByValue = types.reduce((acc, item) => {
  acc[item.value] = item;
  return acc;
}, {});

export function ValueEditor({
  keyValue,
  value,
  onChange,
  newArrayItemValue,
  onEdit,
  newObjectItemValue,
  newObjectItemKey,
}) {

  useEffect(() => {
    'string'
  })

  const updateValue = (type) => {
    onChange(valueFromType(type));
  };

  const valueType = 'string';//typeFromValue(value);

  const valueEditor = (() => {
    switch (valueType) {
      case 'number':
        return <Grid item md={4} sx={{ px: 1 }}>
          <NumberEditor label="Value" value={value} onChange={onChange} />
        </Grid>;
      case 'string':
        return <Grid item md={4} sx={{ px: 1 }}>
          <StringEditor label="Value" value={value} onChange={onChange} />
        </Grid>;
      case 'boolean':
        return <Grid item md={4} sx={{ px: 1 }}>
          <BooleanEditor label="Value" value={value} onChange={onChange} />
        </Grid>;
      case 'array':
        return (
          <Grid item md={12}>
            <Stack
              display="flex"
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              width="100%"
              flex="1"
              spacing={1}
              sx={{ px: 1, pt: 2 }}
            >
              <InputLabel>Items ({(value).length})</InputLabel>

              <Paper elevation={3} sx={{ width: '100%' }}>
                <ArrayEditor
                  newItemValue={newArrayItemValue}
                  onEdit={onEdit}
                  value={value}
                  onChange={onChange}
                />
              </Paper>
            </Stack>
          </Grid>
        );
      case 'object':
        return (
          <Grid item md={12}>
            <Stack
              display="flex"
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              width="100%"
              flex="1"
              spacing={1}
              sx={{ px: 1, pt: 2 }}
            >
              <InputLabel>Properties ({Object.keys(value).length})</InputLabel>

              <Paper
                sx={{
                  width: '100%',
                  bgcolor: (theme) => theme.palette.paper.background
                }}
              >
                <ObjectEditor
                  newItemValue={newObjectItemValue}
                  newItemKey={newObjectItemKey}
                  value={value}
                  onEdit={onEdit}
                  onChange={onChange}
                />
              </Paper>
            </Stack>
          </Grid>
        );
      default:
        return '';
    }
  })();

  return (
    <>
      <Grid item md={4}>
        <TextField
          select
          label="Type"
          size="small"
          fullWidth
          // value={typeByValue[valueType].value}
          value={'string'}
          onChange={(e) => updateValue(e.target.value)}
          disabled
        >
          {types.map((typeObj) => (
            <MenuItem key={typeObj.value} value={typeObj.value}>
              {typeObj.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {valueEditor}
    </>
  );
}
