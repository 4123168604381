import styled from '@emotion/styled';
import { ChevronLeft, ExpandMore, ExpandLess, PlaylistPlay } from '@mui/icons-material';
import {
    Divider as MuiDivider, Drawer as MuiDrawer, Grid, IconButton, List as MuiList, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Collapse
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import img from '../../assets/images/IaAutomationLogoNabvar.png';
import img from '../../assets/images/AIautomation-logo-2.png';
import { config } from '../../helpers/config'
import * as Icons from '@mui/icons-material'
import { Box } from '@mui/system';
import { darken } from '@mui/system'
import { DialogConfirmAction } from '../dialogs/DialogConfirmAction';
import { useSelector, useDispatch } from 'react-redux';
import { changesPendingComponentFlows, changesPendingFlows, changesPendingSubComponentFlows } from '../../redux/actions/flows';
import { useMenu } from '../../hooks/useMenu';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: `linear-gradient( ${theme.palette.navigationBar.main}, ${theme.palette.navigationBar.secondary} )`,
    overflowX: 'hidden'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    background: `linear-gradient( ${theme.palette.navigationBar.main}, ${theme.palette.navigationBar.secondary} )`,
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const List = styled(MuiList)(({ theme }) => ({
    // selected and (selected + hover) states
    '& .MuiListItem-root': {
        '& .MuiListItemIcon-root': {
            color: theme.palette.navigationBar.text
        },
        '& .MuiListItemText-root span': {
            color: theme.palette.navigationBar.text,
            fontWeight: 'bold'
        },
    },
    '& .MuiListItem-root.Mui-selected': {
        backgroundColor: theme.palette.navigationBar.selected,
        borderRadius: '8px 8px 8px 8px',
        boxShadow: 'px 0px 9px 4px rgba(222,222,222,1)',
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main
        },
        '& .MuiListItemText-root span': {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
    },
    '& .MuiListItemButton-root': {
        color: theme.palette.navigationBar.text,
    },
    // hover states
    '& .MuiListItemButton-root:hover': {
        backgroundColor: theme.palette.navigationBar.hover,
        color: theme.palette.primary.main,
        borderRadius: '8px 8px 8px 8px',
        boxShadow: 'px 0px 9px 1px rgba(0,0,0,0.75)',
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main,
        },
        '& .MuiListItemText-root span': {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
    },
}));

export const Sidebar = ({ item, openDrawer, handleDrawerOpen, handleDrawerClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedIndexTemp, setSelectedIndexTemp] = useState('');
    const [pathIndexTemp, setPathIndexTemp] = useState('');
    const [openDialogConfirmNotSaveChanges, setOpenDialogConfirmNotSaveChanges] = useState(false);
    const { isChangesPendingFlow, isChangesPendingComponentFlow, isChangesPendingSubComponentFlow } = useSelector(state => state.flows)

    const [menuList] = useMenu();
    const {
        menu
    } = menuList;

    const handleListItemClick = (event, index, path) => {
        navigate(path);
    }

    const validateChangesPending = (index, path) => {
        if (isChangesPendingFlow || isChangesPendingComponentFlow || isChangesPendingSubComponentFlow) {
            setOpenDialogConfirmNotSaveChanges(true);
            setSelectedIndexTemp(index);
            setPathIndexTemp(path);
        } else {
            handleListItemClick(null, index, path);
        }
    }

    const handleConfirmeNotSaveChanges = () => {
        setOpenDialogConfirmNotSaveChanges(false);
        handleListItemClick(null, selectedIndexTemp, pathIndexTemp);
        dispatch(changesPendingFlows(false));
        dispatch(changesPendingComponentFlows(false));
        dispatch(changesPendingSubComponentFlows(false));
    }

    const [open, setOpen] = useState({});
    const handleToggle = (name) => {
        setOpen((prevOpen) => ({ ...prevOpen, [name]: !prevOpen[name] }));
    };

    const [activeOption, setActiveOption] = useState(location.pathname);
    const handleClick = (item) => {
        setActiveOption(item.name);
        navigate(item.path, { state: { configuration: item.configuration, title: item.name, config: item } });
    };

    return (
        <React.Fragment>
            <Drawer
                variant="permanent"
                open={openDrawer}
            >
                <DrawerHeader>
                    {
                        openDrawer && (
                            <Grid
                                container
                                sx={{ pt: 1, pb: 2 }}
                            >
                                <Grid
                                    item
                                    md={9}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img src={img} alt="imagen" style={{ height: '120px', }} />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="flex-start"
                                >
                                    <IconButton onClick={handleDrawerClose}>
                                        <ChevronLeft color="primary" fontSize="large" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    }
                </DrawerHeader>

                <div key={1}>
                    {/* <MuiDivider
                        textAlign="left"
                        open={openDrawer}
                        variant="middle"
                        sx={{
                            "&::before, &::after": {
                                borderColor: (theme) => darken(theme.palette.navigationBar.text, 0.7)
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                color: (theme) => darken(theme.palette.navigationBar.text, 0.5)
                            }}
                        >
                            {(openDrawer) && "General"}
                        </Typography>
                    </MuiDivider> */}
                    <List sx={{ mx: 0.7 }}>
                        {menu.map((item, index) => (
                            <React.Fragment>
                                {item.type === 'unique' ? (
                                    <ListItem
                                        key={index}
                                        onClick={() => handleClick(item)}
                                        disablePadding
                                        sx={{ my: 0.5 }}
                                    >
                                        <ListItemButton
                                            className='sidebarMenuItemButton'
                                            sx={{
                                                justifyContent: openDrawer ? 'initial' : 'center',
                                                pl: 2.5,
                                                backgroundColor: activeOption === item.name ? '#000000' : 'transparent',
                                                color: activeOption === item.name ? '#8942DB' : '#6728AF',
                                            }}
                                        >
                                            <ListItemIcon
                                                // className='sidebarMenuIcon'
                                                className={ openDrawer ? 'mr14' : 'mr0' }
                                                sx={{
                                                    minWidth: 0,
                                                    // mr: openDrawer ? 3 : 'auto',
                                                    mr: openDrawer ? 2 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {React.createElement(Icons[item.icon])}
                                            </ListItemIcon>
                                            <ListItemText
                                                sx={{
                                                    opacity: openDrawer ? 1 : 0,
                                                    display: 'block',
                                                    textDecoration: 'none',
                                                    color: (theme) => theme.palette.text.main,
                                                    fontFamily: (theme) => theme.palette.customTableZDNix.tableHeaderFontFamily,
                                                }}
                                            >
                                                {item.name}
                                            </ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                ) : (
                                    <>
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            sx={{ my: 0.5 }}
                                        >
                                            <ListItemButton
                                                onClick={() => handleToggle(item.menu)}
                                                sx={{
                                                    justifyContent: openDrawer ? 'initial' : 'center',
                                                    pl: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: openDrawer ? 3 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <PlaylistPlay />
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={{
                                                        opacity: openDrawer ? 1 : 0,
                                                        display: 'block',
                                                        textDecoration: 'none',
                                                        color: (theme) => theme.palette.text.main
                                                    }}
                                                >
                                                    {item.menu}
                                                </ListItemText>
                                                <IconButton
                                                    edge="end"
                                                    size="small"
                                                >
                                                    {open[item.menu] ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                                                </IconButton>
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={open[item.menu]} timeout="auto" unmountOnExit>
                                            <List component="div">
                                                {item.body.map((subItem, subIndex) => (
                                                    <ListItem
                                                        key={subIndex}
                                                        onClick={() => handleClick(subItem)}
                                                        disablePadding
                                                        sx={{ pl: 2 }}
                                                    >
                                                        <ListItemButton
                                                            sx={{
                                                                justifyContent: openDrawer ? 'initial' : 'center',
                                                                pl: 2,
                                                                backgroundColor: activeOption === subItem.name ? '#000000' : 'transparent'
                                                            }}
                                                        >
                                                            <ListItemIcon
                                                                sx={{
                                                                    minWidth: 0,
                                                                    mr: openDrawer ? 1.5 : 'auto',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                {React.createElement(Icons[subItem.icon])}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                sx={{
                                                                    opacity: openDrawer ? 1 : 0,
                                                                    display: 'block',
                                                                    textDecoration: 'none',
                                                                    color: (theme) => theme.palette.text.main
                                                                }}
                                                            >
                                                                {subItem.name}
                                                            </ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </List>
                </div>
                {
                    config.sidebarData.map(({ idSection, titleSection, items }, index) => {
                        return (
                            <div key={idSection}>
                                <MuiDivider
                                    textAlign="left"
                                    open={openDrawer}
                                    variant="middle"
                                    sx={{
                                        "&::before, &::after": {
                                            borderColor: (theme) => darken(theme.palette.navigationBar.text, 0.7)
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            color: (theme) => darken(theme.palette.navigationBar.text, 0.5)
                                        }}
                                    >
                                        {(openDrawer) && titleSection}
                                    </Typography>
                                </MuiDivider>
                                <List sx={{ mx: 0.7 }}>
                                    {
                                        items.map((item, subIndex) => {
                                            return (
                                                <ListItem
                                                    key={item.idItem}
                                                    onClick={() => validateChangesPending(`${idSection}-${item.idItem}`, item.path)}
                                                    disablePadding
                                                    sx={{ my: 0.5 }}
                                                >
                                                    <ListItemButton
                                                        sx={{
                                                            justifyContent: openDrawer ? 'initial' : 'center',
                                                            pl: 2.5,
                                                        }}
                                                    >
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: 0,
                                                                mr: openDrawer ? 3 : 'auto',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            {React.createElement(Icons[item.icon])}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            sx={{
                                                                opacity: openDrawer ? 1 : 0,
                                                                display: 'block',
                                                                textDecoration: 'none',
                                                                // color: (theme) => theme.palette.text.main
                                                                color: activeOption === item.name ? '#8942DB' : '#6728AF',
                                                            }}
                                                        >
                                                            {item.title}
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })
                                    }
                                </List>
                            </div>
                        )
                    })
                }
                <Box sx={{ flexGrow: 1 }} />
            </Drawer>
            <DialogConfirmAction
                openDialogConfirm={openDialogConfirmNotSaveChanges}
                setOpenDialogConfirm={setOpenDialogConfirmNotSaveChanges}
                titleDialog={"Changes Pending To Save"}
                textConfirmAction={"You have unsaved changes, do you want to continue?"}
                textButtonConfirm={"Continue"}
                textButtonCancel={"Cancel"}
                handleConfirmAction={handleConfirmeNotSaveChanges}
            />
        </React.Fragment>
    )
}
