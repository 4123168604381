import { Box, Grid, InputAdornment, InputLabel, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { Badge, SchemaOutlined } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { config } from '../../../../helpers/config';
import { useFocusInputs } from '../../../../hooks/useFocusInputs';
import { useErrorInputs } from '../../../../hooks/useErrorInputs';

export const StepBasicInformation = ({
    setIsValidStep,
    valueItem,
    handleChange
}) => {

    const [focusInputs, handleFocusInputs] = useFocusInputs({
        isNameFocus: false,
        isTypeFocus: false
        // isStatusFocus: false
    });
    // const {isNameFocus,isTypeFocus, isStatusFocus } = focusInputs;
    const {isNameFocus,isTypeFocus} = focusInputs;

    const [errorInputs, handleErrorInput] = useErrorInputs({
        nameError:{
            isError: false,
            messageError: ''
        },
        typeError:{
            isError: false,
            messageError: ''
        }
        // statusError:{
        //     isError: false,
        //     messageError: ''
        // }
    });
    // const {nameError, typeError, statusError} = errorInputs;
    const {nameError, typeError} = errorInputs;

    const handleChangeValidate = (value) => {
        // if(value.name === '' || value.type === '' || value.status === ''){
        if(value.name === '' || value.type === ''){
            setIsValidStep(false);
        }else{
            setIsValidStep(true);
        }
    }

    const handleFocus = (e, name) => {        
        if(e.type === "blur"){
            if(valueItem[!e.target.name ? name : e.target.name] === ''){
                handleErrorInput(`${!e.target.name ? name : e.target.name}Error`, {
                    isError: true,
                    messageError: getErrorFields(!e.target.name ? name : e.target.name)
                });
            }            
        }else{
            handleErrorInput(`${!e.target.name ? name : e.target.name}Error`, {
                isError: false,
                messageError: ''
            });
        }

        handleFocusInputs({
            ...e,
            target: {
                ...e.target,
                name: !e.target.name ? name : e.target.name
            }
        });
    }    

    const getErrorFields = (name) => {
        switch(name){
            case "name":
                return "The field name is required";
            case "type":
                return "The field type is required";
            // case "status":
            //     return "The field status is required";
            default: 
                return "The field is required";
        }
    }

    useEffect(() => {
        handleChangeValidate(valueItem);
    }, [valueItem]);

    return (
        <Grid container sx={{p: 3}}>
            <Grid item md={3}></Grid>
            <Grid item md={6}>
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Typography 
                        variant="h6"
                        color="primary"
                        sx={{fontWeight: 'bold'}}
                    >
                        Create New Flow
                    </Typography>
                </Box>
                <Paper  
                    elevation={4}
                    sx={{
                        borderRadius: 4,
                        bgcolor: (theme) => theme.palette.paper.background
                    }}
                >
                    <Box
                        component="form"            
                        noValidate
                        sx={{px: 3, py:4}}
                        autoComplete="off"
                    >
                        <InputLabel id="name">
                            <Typography color="text.main" sx={{fontWeight: 'bold'}}>
                                Name Flow
                            </Typography>
                        </InputLabel>
                        <TextField
                            autoFocus 
                            error={!isNameFocus && nameError.isError}
                            color={(isNameFocus) ? "text" : (nameError.isError) ? "error" : "text"}
                            required
                            fullWidth
                            variant="outlined"
                            id="name"
                            name="name"
                            value={valueItem.name}
                            placeholder="Enter name flow"
                            size="small"
                            autoComplete="off"
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            onFocus={handleFocus}
                            onBlur={handleFocus}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Badge color={(isNameFocus) ? "primary" : (nameError.isError) ? "error" : "primary"}/>
                                    </InputAdornment>
                                ),
                            }}                        
                            sx={{mb: 1}}
                            helperText={nameError.messageError && !isNameFocus ? nameError.messageError : "Enter a name that describes the purpose of your function."}
                        />

                        <InputLabel id="type">
                            <Typography color="text.main" sx={{fontWeight: 'bold'}}>
                                Type Flow
                            </Typography>
                        </InputLabel>
                        <TextField
                            error={!isTypeFocus && typeError.isError}
                            color={(isTypeFocus) ? "text" : (typeError.isError) ? "error" : "text"}
                            required
                            fullWidth
                            id="type"
                            select
                            variant="outlined"
                            name="type"
                            placeholder="Select type flow"
                            size="small"
                            autoComplete="off"
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            onFocus={(e) => handleFocus(e, "type")}
                            onBlur={(e) => handleFocus(e, "type")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SchemaOutlined color={(isTypeFocus) ? "primary" : (typeError.isError) ? "error" : "primary"}/>
                                    </InputAdornment>
                                ),
                            }}
                            value={valueItem.type}
                            sx={{mb: 1}}
                            helperText={typeError.messageError ? typeError.messageError : "Select the type of flow to run"}
                        >
                            {/* <MenuItem disabled value="">
                                <em>Type Flow</em>
                            </MenuItem> */}
                            {
                                config.flowVariables.typesFlows.map(({type}, index) => (
                                    <MenuItem
                                        key={index}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>
                                ))
                            }
                        </TextField>

                        {/* <InputLabel id="status">
                            <Typography color="primary" sx={{fontWeight: 'bold'}}>
                                Status Flow
                            </Typography>
                        </InputLabel>
                        <TextField
                            error={!isStatusFocus && statusError.isError}
                            color={(isStatusFocus) ? "primary" : (statusError.isError) ? "error" : "secondary"}
                            required
                            fullWidth
                            id="status"
                            select
                            variant="outlined"
                            name="status"
                            placeholder="Select status flow"
                            size="small"
                            autoComplete="off"
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            onFocus={(e) => handleFocus(e, "status")}
                            onBlur={(e) => handleFocus(e, "status")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <WatchOutlined color={(isTypeFocus) ? "primary" : (statusError.isError) ? "error" : "third"}/>
                                    </InputAdornment>
                                ),
                            }}
                            value={valueItem.status}
                            helperText={statusError.messageError ? statusError.messageError : "Select the state of the flow, it is recommended that you start it stopped"}
                        >
                            <MenuItem disabled value="">
                                <em>Status Flow</em>
                            </MenuItem>
                            {
                                config.flowVariables.status.map(({name}, index) => (
                                    <MenuItem
                                        key={index}
                                        value={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))
                            }
                        </TextField> */}
                    </Box>
                </Paper>        
            </Grid>
            <Grid item md={3}></Grid>
        </Grid>        
    )
}
