import { Delete } from '@mui/icons-material';
import { Box, Grid, IconButton, ListItem, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ModalEditor } from '../../modalEditor/ModalEditor';

export const TreeItemString = ({
    value,
    keyItem,
    valueItem,
    indentation,
    arrayKeysPath,
    onChange,
    onDelete
}) => {

    const [showActions, setShowActions] = useState(false);

    return (
        <ListItem
            disableGutters
            sx={{
                py: 0.3,
                marginLeft: indentation
            }}
        >
            <Grid container>
                <Grid
                    item
                    md={12}
                    onMouseEnter={() => setShowActions(true)}
                    onMouseLeave={() => setShowActions(false)}
                    alignItems="center"
                    display="inline-flex"
                    justifyContent="flex-start"
                >
                    <Box
                        alignItems="center"
                        display="inline-flex"
                        justifyContent="flex-start"
                        sx={{ overflow: 'hidden', ml: 2 }}
                    >
                        <Typography
                            component="span"
                            variant="console"
                            sx={{
                                height: '100%',
                                display: 'inline-block',
                                justifyContent: 'center',
                                alignItems: 'flex-start'
                            }}
                        >
                            {`${keyItem}:`}
                        </Typography>
                        <Typography
                            component="span"
                            color="lightgray"
                            variant="console"
                            sx={{
                                fontSize: 11,
                                height: '100%',
                                display: 'flex',
                                opacity: 0.2,
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                px: 0.5
                            }}
                        >
                            {typeof (valueItem)}
                        </Typography>
                        <Typography
                            component="span"
                            color="darkorange"
                            variant="console"
                        >
                            {valueItem.replace(/['"]+/g, "'")}
                        </Typography>
                    </Box>
                    <Box>
                        {
                            showActions && (
                                <Stack direction="row" spacing={1}>
                                    <ModalEditor
                                        arrayKeysPath={[...arrayKeysPath, keyItem]}
                                        isEditorAdd={false}
                                        isEditorEdit={true}
                                        value={value}
                                        onChange={onChange}
                                    />
                                    <Tooltip title="Delete">
                                        <IconButton
                                            size="small"
                                            sx={{ p: 0 }}
                                            onClick={() => onDelete([...arrayKeysPath, keyItem])}
                                        >
                                            <Delete sx={{ fontSize: 17 }} />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </ListItem>
    )
}
