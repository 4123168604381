import React, { useEffect } from 'react';
import { getUrlBoard } from '../../redux/actions/boards';
import { useSelector, useDispatch } from 'react-redux';
import { useDataAuth } from '../../hooks/useDataAuth';
import { useBoards } from '../../hooks/useBoards';


export const QuicksightHome = () => {
  const dispatch = useDispatch();
  const [{ userSession, idCompany }] = useDataAuth();
  const [{ boardUrl }] = useBoards();
  useEffect(() => {
    dispatch(getUrlBoard(userSession.token));
  }, []);

  return (
    <div>
      <iframe
        id="EmbedUrl"
        width="960"
        height="720"
        src={boardUrl}
        title="Dashboard"
      ></iframe>
    </div>
  );
};