import {  Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import React, {  useState } from 'react';
import { TypesComponentFlowV2 } from './typescomponentflow/TypesComponentFlowV2';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { TypesFlow } from './typesflow/TypesFlow';
import { TypesStatus } from './typesstatus/TypesStatus';
import { TypesExecute } from './typesexecute/TypesExecute';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        sx={{height: '100%'}}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, height: '100%' }}>
            {children}
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        style: {fontSize: 14},
        sx: {
            '&.MuiButtonBase-root': {
                alignItems: 'flex-start',
                borderRadius: 2,
                '&.Mui-selected':{
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.palette.navigationBar.selected
                },
                '&:hover':{
                    backgroundColor: (theme) => theme.palette.navigationBar.hover
                }
            }
        }
    };
}

export const SettingsFlows = () => {

    const navigate = useNavigate();
    const [valueTab, setValueTab] = useState(
        localStorage.getItem('lastTabSettingsFlow') ? parseInt(localStorage.getItem('lastTabSettingsFlow')) : 0
    );

    useEffect(() => {
        navigate(localStorage.getItem('lastPathSettingsFlow') || "/settings/flows/types-flow");
    }, []);

    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleClickTabs = (tabPathNavigate) => {
        navigate(tabPathNavigate);
        localStorage.setItem('lastPathSettingsFlow', tabPathNavigate);
    }

    return (
        <Grid container>
            <Grid item md={3}>
                <Paper 
                    variant="outlined" 
                    sx={{
                        height: '100%',
                        borderRadius: 2
                    }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={valueTab}
                        onChange={handleChange}
                        aria-label="vertical-tabs"
                        TabIndicatorProps={{
                            style: {display: 'none'}
                        }}
                    >
                        <Tab 
                            label="Types Flow"
                            onClick={() => handleClickTabs("/settings/flows/types-flow")}
                            {...a11yProps(0)} 
                        />
                        <Tab 
                            label="Types Component Flow"
                            onClick={() => handleClickTabs("/settings/flows/types-component-flow")}
                            {...a11yProps(1)} 
                        />
                        <Tab 
                            label="Types Execute"
                            onClick={() => handleClickTabs("/settings/flows/types-execute")}
                            {...a11yProps(2)} 
                        />
                        <Tab 
                            label="Types Status" 
                            onClick={() => handleClickTabs("/settings/flows/types-status")}
                            {...a11yProps(3)} 
                        />
                    </Tabs>
                </Paper>
            </Grid>
            <Grid item md={9} sx={{pl: 1}}>
                <Paper variant="outlined">
                    <Routes>
                        <Route path="/types-flow/*" element={
                                <TabPanel value={valueTab} index={0}>
                                    <TypesFlow/>
                                </TabPanel>
                            }
                        />
                        <Route path="/types-component-flow/*" element={
                                <TabPanel value={valueTab} index={1}>
                                    <TypesComponentFlowV2 />
                                </TabPanel>
                            }
                        />
                        <Route path="/types-execute/*" element={
                                <TabPanel value={valueTab} index={2}>
                                    <TypesExecute />
                                </TabPanel>
                            }
                        />
                        <Route path="/types-status/*" element={
                                <TabPanel value={valueTab} index={3}>
                                    <TypesStatus />
                                </TabPanel>
                            }
                        />
                    </Routes>
                </Paper>
            </Grid>
        </Grid>
    )
}
