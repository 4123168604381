import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Box, Divider, FormGroup, IconButton, InputAdornment, TextField, Typography, Link } from '@mui/material';
import { AccountCircleRounded, Password, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import { useFocusInputs } from '../../../hooks/useFocusInputs';
import { useErrorInputs } from '../../../hooks/useErrorInputs';
import { useForm } from '../../../hooks/useForm';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { SignInButton } from '../../../components/ADAzure/SignInButton';
import { useNotification } from '../../../hooks/useNotification';
import { setNotificaction } from '../../../redux/actions/auth';
import infoIcon from '../../../assets/images/infoIcon.png';

export const FormAuthV2 = ({ authData }) => {
    const navigate = useNavigate();
    const [sendNotification] = useNotification();
    const dispatch = useDispatch();

    const {
        checkingLogin,
        errorLogin: errorLogging,
        handleLogin,
        isNotification,
        msg,
        typeMsg,
    } = authData;
    const [showPassword, setShowPassword] = useState(false);

    const [{ username, password }, handleInputChange] = useForm({
        username: '',
        password: ''
    });
    const [{ isUsernameFocus, isPasswordFocus }, handleFocusInputs] = useFocusInputs({
        isUsernameFocus: false,
        isPasswordFocus: false
    });
    const [{ usernameError, passwordError }, handleErrorInput] = useErrorInputs({
        usernameError: {
            isError: false,
            messageError: ''
        },
        passwordError: {
            isError: false,
            messageError: ''
        },
    });

    const handleFocus = (e) => {
        handleFocusInputs(e);
        handleErrorInput(`${e.target.name}Error`, {
            isError: false,
            messageError: ''
        });
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        if (username.length <= 0) {
            handleErrorInput('usuarioError', {
                isError: true,
                messageError: 'The field username is required'
            });
            return;
        }

        if (password.length <= 0) {
            handleErrorInput('passwordError', {
                isError: true,
                messageError: 'The field password is required'
            });
            return;
        }
        handleLogin(username, password);
    }

    const handleSubmitRecoverPassword = () => {
        navigate('/login/recoverPassword')
    }

    const [host, setHost] = useState('');
    useEffect(() => {
        let hostName = window.location.hostname;
        setHost(hostName);
    }, [setHost])

    useEffect(() => {
        if (isNotification) {
            sendNotification({ msg: msg, variant: typeMsg });
            dispatch(setNotificaction({}));
        }
    }, [isNotification, msg, typeMsg, dispatch, sendNotification]);


    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
                sx={{ mb: 2, textAlign:'left' }}
            >
                
                
                    <Typography
                        color="#ffffff"
                        variant="h6"
                        sx={{ fontFamily: 'Afacad Flux Light', fontSize: '4rem', textAlign: 'left', lineHeight:'39px' }}
                    >
                        Hola,
                    </Typography>
                    <Typography
                        color="#ffffff"
                        variant="h6"
                        sx={{ fontFamily: 'Afacad Flux Black', fontSize: '4.25rem', textAlign: 'left', lineHeight:'1', marginBottom:'30px' }}
                    >
                        Bienvenido!
                    </Typography>
                
            </Box>


            {(errorLogging) &&
                <Alert severity="error">{errorLogging}</Alert>
            }

            <form onSubmit={handleSubmit} method="POST">
                <FormGroup
                    sx={{ my: 3, marginBottom: '40px' }}
                >
                    <TextField
                        className="custom-btn-zd-nix"  // Added className
                        error={!isUsernameFocus && usernameError.isError}
                        color={(isUsernameFocus) ? "text" : (usernameError.isError) ? "error" : "text"}
                        label="Username"
                        variant="outlined"
                        name="username"
                        placeholder="Enter username"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {/* <Person color={(isUsernameFocus) ? "text" : (usernameError.isError) ? "error" : "text"} /> */}
                                    <Person color={"#6728AF"} sx={{ fill:'#6728AF' }} />
                                </InputAdornment>
                            ),
                        }}
                        value={username}
                        helperText={usernameError.messageError}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                    />
                </FormGroup>

                <FormGroup>
                    <TextField
                        className="custom-btn-zd-nix"
                        error={!isPasswordFocus && passwordError.isError}
                        color={(isPasswordFocus) ? "text" : (passwordError.isError) ? "error" : "text"}
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        name="password"
                        placeholder="Enter password"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Password color={(isPasswordFocus) ? "text" : (passwordError.isError) ? "error" : "text"} sx={{ fill:'#6728AF' }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <IconButton
                                    color={(isPasswordFocus) ? "text" : (passwordError.isError) ? "error" : "text"}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ padding:'0px 4px 0px 4px' }}
                                >
                                    {showPassword ? <VisibilityOff sx={{ fill:'#6728AF' }} /> : <Visibility sx={{ fill:'#6728AF' }} />}
                                </IconButton>
                            )
                        }}

                        value={password}
                        helperText={passwordError.messageError}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                    />
                </FormGroup>

                <LoadingButton
                    loading={checkingLogin}
                    variant="contained"
                    type="submit"
                    size="small"
                    fullWidth
                    style={{ borderRadius: "11px", paddingTop:"6px", marginTop: "1.5rem", backgroundColor: (theme) => theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.main }}
                >
                    Login
                </LoadingButton>
            </form>

            {
                host === 'gemm.iaautomation.com' &&
                <SignInButton callRegularLogin={handleLogin} />
            }

            <Box
                sx={{ marginTop: 1 }}
                display='flex'
                justifyContent='flex-end'
            >
                <Link
                    underline='hover'
                    color='text.main'
                    sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'center', justifyContent: 'space-between', alignItems: 'center', width: '139px', }}
                    onClick={() => handleSubmitRecoverPassword()}
                >
                    <img src={infoIcon} alt="" width='15' height='15' />
                    <Typography
                        sx={{ fontFamily: 'Afacad Flux Medium', fontSize: 14, color: '#FBCFFF' }}
                    >
                        Olvidé mi contraseña
                    </Typography>
                </Link>
            </Box>
        </>
    )
}
