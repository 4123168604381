import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addNewStatus, deleteStatus, errorManageStatus, getDataStatus, updateStatus } from "../redux/actions/status";
import { useDataAuth } from "./useDataAuth";
import { useNotification } from "./useNotification";

export const useManageStatus = () => {

    const dispatch = useDispatch();
    const [sendNotification] = useNotification();
    const {
        listAllStatus,
        loadingStatus,
        updatingStatus,
        deletingStatus,
        addingNewStatus,
        isErrorFetch,
        messageErrorFetch,
    } = useSelector(state => state.status);
    const [{ userSession }] = useDataAuth();
    const [listStatus, setListStatus] = useState([]);

    useEffect(() => {
        handleGetStatus();
    }, []);

    useEffect(() => {
        setListStatus(listAllStatus);
    }, [listAllStatus]);

    useEffect(() => {
        if (isErrorFetch) {
            sendNotification({ msg: messageErrorFetch, variant: 'error' });
            dispatch(errorManageStatus(false));
        }
    }, [isErrorFetch]);

    const handleGetStatus = () => {
        dispatch(getDataStatus(userSession.token));
    }

    const handleChangeStatus = ({ name, value }, idTypeStatus) => {
        console.log('idTypeStatus', idTypeStatus)
        setListStatus(listStatus => listStatus.map((item, index) => (
            item.id_status === idTypeStatus ? {
                ...item,
                [name]: value,
                is_updated: true,
                updated: moment().toDate(),
                updated_by: userSession.username
            } : item
        ))
        );
    }

    const handleUpdateStatus = async () => {
        dispatch(updateStatus(userSession.token, listStatus.find(status => status.is_updated)));
    }

    const handleAddStatus = (typeStatus) => {
        const newStatus = [
            {
                ...typeStatus,
                created: moment().toDate(),
                created_by: userSession.username,
                updated: moment().toDate(),
                updated_by: userSession.username
            }
        ];
        dispatch(addNewStatus(newStatus));
    }

    const handleDeleteStatus = (idsStatus) => {
        console.log('idsStatus', idsStatus)
        dispatch(deleteStatus(idsStatus));
    }

    return [{
        loadingStatus,
        updatingStatus,
        deletingStatus,
        addingNewStatus,
        listStatus,
        isErrorFetch,
        messageErrorFetch,
        setListStatus,
        handleGetStatus,
        handleChangeStatus,
        handleUpdateStatus,
        handleAddStatus,
        handleDeleteStatus
    }];

}
