import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { labelCronExpression } from './utils/labelCronExpression';
import { useForm } from '../../hooks/useForm';

export const CronDays = ({valueDaysOfMonth, setValueDaysOfMonth, valueDaysOfWeek, setValueDaysOfWeek}) => {

    const [listCheckedDaysOfWeek, setListCheckedDaysOfWeek] = useState(valueDaysOfWeek.split(',').length > 1 ? valueDaysOfWeek.split(',') : !isNaN(valueDaysOfWeek) ? [valueDaysOfWeek] : []);
    const [listCheckedDaysOfMonth, setListCheckedDaysOfMonth] = useState(valueDaysOfMonth.split(',').length > 1 ? valueDaysOfMonth.split(',').map((x, i) => (parseInt(x))) : !isNaN(valueDaysOfMonth) ? [parseInt(valueDaysOfMonth)] : []);

    const [formValues, handleSelectChange] = useForm({
      selectEveryDaysOfWeek: valueDaysOfWeek.split('/').length > 1 ? valueDaysOfWeek.split('/')[1] : 1,
      selectStartDaysOfWeek: valueDaysOfWeek.split('/').length > 1 ? valueDaysOfWeek.split('/')[0] : 1,
      selectEveryDaysOfMonth: valueDaysOfMonth.split('/').length > 1 ? valueDaysOfMonth.split('/')[1] : 1,
      selectStartDaysOfMonth: valueDaysOfMonth.split('/').length > 1 ? valueDaysOfMonth.split('/')[0] : 1,
      selectLastDayOfWeekByMonth: valueDaysOfWeek.includes('L') || valueDaysOfWeek.includes('l') ? parseInt(valueDaysOfWeek.slice(0,1)) : 1,
      selectDaysOfMonthBeforeEnd: valueDaysOfMonth.split('-').length > 1 ? parseInt(valueDaysOfMonth.split('-')[1]) : 1,
      selectDaysOfWeekNearest: (!valueDaysOfMonth.includes('L') || !valueDaysOfMonth.includes('l')) 
      && (valueDaysOfMonth.includes('W') || valueDaysOfMonth.includes('w')) ? parseInt(valueDaysOfMonth.split('W')[0]) : 1,
      selectDaysOfWeekByMonth: valueDaysOfWeek.split('#').length > 1 ? valueDaysOfWeek.split('#')[1] : 1,
      selectDaysOfWeekSpecifiedByMonth: valueDaysOfWeek.split('#').length > 1 ? valueDaysOfWeek.split('#')[0] : 1,
    });
    const {selectEveryDaysOfWeek, selectStartDaysOfWeek, selectEveryDaysOfMonth,
      selectStartDaysOfMonth, selectLastDayOfWeekByMonth, selectDaysOfMonthBeforeEnd,
      selectDaysOfWeekNearest, selectDaysOfWeekByMonth, selectDaysOfWeekSpecifiedByMonth} = formValues;

    const [valueRadio, setValueRadio] = useState('1');
    useEffect(() => {
      if(valueDaysOfMonth && valueDaysOfMonth !== '?'){
        if(valueDaysOfMonth.split('/').length > 1) {setValueRadio('3');}
        if(!isNaN(valueDaysOfMonth) || valueDaysOfMonth.split(',').length > 1){setValueRadio('5');}
        if(valueDaysOfMonth.split('-').length > 1){setValueRadio('9');} 
        if(valueDaysOfMonth === 'L') {setValueRadio('6');}
        if(valueDaysOfMonth === 'LW') {setValueRadio('7');}
        if((!valueDaysOfMonth.includes('L') || !valueDaysOfMonth.includes('l')) 
        && (valueDaysOfMonth.includes('W') || valueDaysOfMonth.includes('w'))) {setValueRadio('10');}
      }else{
        if(valueDaysOfWeek === '*') {setValueRadio('1');}
        if(valueDaysOfWeek.split('/').length > 1) {setValueRadio('2');}
        if(valueDaysOfWeek.split(',').length > 1) {setValueRadio('4');}
        if((valueDaysOfWeek.includes('L') || valueDaysOfWeek.includes('l'))) {setValueRadio('8');}
        if(valueDaysOfWeek.split('#').length > 1 ) {setValueRadio('11');}
      }
    }, [])

    useEffect(() => {
        if(valueRadio === '2'){
          setValueDaysOfWeek(`${selectStartDaysOfWeek}/${selectEveryDaysOfWeek}`);
          setValueDaysOfMonth('?');
        }
    }, [selectEveryDaysOfWeek, selectStartDaysOfWeek])

    useEffect(() => {
      if(valueRadio === '3'){
        setValueDaysOfMonth(`${parseInt(selectStartDaysOfMonth)}/${selectEveryDaysOfMonth}`);
        setValueDaysOfWeek('?');
      }
    }, [selectEveryDaysOfMonth, selectStartDaysOfMonth])

    useEffect(() => {
      if(valueRadio === '4'){
        setValueDaysOfWeek(listCheckedDaysOfWeek.length === 0 ? 'SUN' : 
          listCheckedDaysOfWeek.sort((a,b)=>
          (labelCronExpression.find(tab => tab.tab === 'days').daysOfWeek.find(day => day.value2 === a).value1) -
          (labelCronExpression.find(tab => tab.tab === 'days').daysOfWeek.find(day => day.value2 === b).value1)
          ).toString());
        setValueDaysOfMonth('?');
      }
    }, [listCheckedDaysOfWeek])

    useEffect(() => {
      if(valueRadio === '5'){
        setValueDaysOfWeek('?');
        setValueDaysOfMonth(
          listCheckedDaysOfMonth.length === 0 
          ? '1' 
          : listCheckedDaysOfMonth.sort((a,b)=>a-b).toString());
      }
    }, [listCheckedDaysOfMonth])


    useEffect(() => {
      if(valueRadio === '8'){
        setValueDaysOfMonth('?');
        setValueDaysOfWeek(`${selectLastDayOfWeekByMonth}L`);
      }
    }, [selectLastDayOfWeekByMonth])

    useEffect(() => {
      if(valueRadio === '9'){
        setValueDaysOfMonth(`L-${selectDaysOfMonthBeforeEnd}`);
        setValueDaysOfWeek('?');
      }
    }, [selectDaysOfMonthBeforeEnd])
    
    useEffect(() => {
      if(valueRadio === '10'){
        setValueDaysOfMonth(`${selectDaysOfWeekNearest}W`);
        setValueDaysOfWeek('?');
      }
    }, [selectDaysOfWeekNearest])

    useEffect(() => {
      if(valueRadio === '11'){
        setValueDaysOfMonth('?');
        setValueDaysOfWeek(`${selectDaysOfWeekSpecifiedByMonth}#${selectDaysOfWeekByMonth}`);
      }
    }, [selectDaysOfWeekSpecifiedByMonth, selectDaysOfWeekByMonth])

    const handleChange = (e) => {
        const valueRadioButton = e.target.value;
        setValueRadio(valueRadioButton);
        if(valueRadioButton === '1'){
          setValueDaysOfMonth('?');
          setValueDaysOfWeek('*');
        }else if(valueRadioButton === '2'){
          setValueDaysOfWeek(`${selectStartDaysOfWeek}/${selectEveryDaysOfWeek}`);
          setValueDaysOfMonth('?');
        }else if(valueRadioButton === '3'){
          setValueDaysOfMonth(`${parseInt(selectStartDaysOfMonth)}/${selectEveryDaysOfMonth}`);
          setValueDaysOfWeek('?');
        }else if(valueRadioButton === '5'){
          setValueDaysOfMonth(
            listCheckedDaysOfMonth.length === 0 
            ? '1'
            : listCheckedDaysOfMonth.sort((a,b)=>a-b).toString());
            setValueDaysOfWeek('?');
        }else if(valueRadioButton === '6'){
          setValueDaysOfMonth('L');
          setValueDaysOfWeek('?');
        }else if(valueRadioButton === '7'){
          setValueDaysOfMonth('LW');
          setValueDaysOfWeek('?');
        }else if(valueRadioButton === '8'){
          setValueDaysOfMonth('?');
          setValueDaysOfWeek(`${selectLastDayOfWeekByMonth}L`);
        }else if(valueRadioButton === '9'){
          setValueDaysOfMonth(`L-${selectDaysOfMonthBeforeEnd}`);
          setValueDaysOfWeek('?');
        }else if(valueRadioButton === '10'){
          setValueDaysOfMonth(`${selectDaysOfWeekNearest}W`);
          setValueDaysOfWeek('?');
        }else if(valueRadioButton === '11'){
          setValueDaysOfMonth('?');
          setValueDaysOfWeek(`${selectDaysOfWeekSpecifiedByMonth}#${selectDaysOfWeekByMonth}`);
        }
        handleReset();
    }

    const handleChangeCkeckedDaysOfWeek = (e) => {
        if(!listCheckedDaysOfWeek.includes(e.target.name)){
            setListCheckedDaysOfWeek([...listCheckedDaysOfWeek, e.target.name])
        }else{
            setListCheckedDaysOfWeek(listCheckedDaysOfWeek.filter((x, i) => x !== e.target.name))
        }
    }

    const handleChangeCkeckedDaysOfMonth = (e) => {
      if(!listCheckedDaysOfMonth.includes(parseInt(e.target.name))){
        setListCheckedDaysOfMonth([...listCheckedDaysOfMonth, parseInt(e.target.name)])
      }else{
        setListCheckedDaysOfMonth(listCheckedDaysOfMonth.filter((x, i) => x !== parseInt(e.target.name)))
      }
  }

    const handleReset = () => {
        setListCheckedDaysOfWeek([]);
        setListCheckedDaysOfMonth([]);
    }

    return (
        <Paper 
          elevation={3} 
          sx={{
            px: '4%', 
            pb: '10%', 
            height: 'calc(100%)', 
            overflowY: 'auto',
            bgcolor: (theme) => theme.palette.paper.background
          }}>
            <FormControl>
                <RadioGroup
                    aria-labelledby="days-radio-buttons-group-label"
                    value={valueRadio}
                    onChange={handleChange} 
                    name="radio-buttons-group"
                >
                  {/*Todos los dias*/}
                  <FormControlLabel 
                      value="1"
                      control={<Radio size="small"/>} 
                      label="Todos los dias"
                  />

                  {/*Apartir del dia de la semana*/}
                  <FormControlLabel 
                      value="2"
                      control={<Radio size="small"/>}
                      sx={{mt: 2}}
                      label={
                          <Box 
                              display="flex"
                              alignItems="center" 
                              justifyContent="start"
                          >
                              <Typography>
                                  {'Cada\u00A0'}
                              </Typography>
                              <TextField
                                  select
                                  variant="outlined" 
                                  value={selectEveryDaysOfWeek}
                                  size="small"
                                  name="selectEveryDaysOfWeek"
                                  sx={{py:0, m: 0}}
                                  onChange={handleSelectChange}
                                  disabled={valueRadio !== "2"}
                              >
                                  {
                                      labelCronExpression.find(tab => tab.tab === 'days')
                                          .daysOfWeek.map(({value1}, i) => {
                                          return (
                                              <MenuItem key={i} value={value1}>{value1}</MenuItem>
                                          )
                                      })
                                  }
                              </TextField>
                              <Typography>
                                  {'\u00A0 día(s), a partir del día \u00A0'}
                              </Typography>
                              <FormControl variant="outlined" sx={{ m: 0}}>
                                  <Select
                                      value={selectStartDaysOfWeek}
                                      size="small"
                                      name="selectStartDaysOfWeek"
                                      sx={{py:0}}
                                      onChange={handleSelectChange}
                                      disabled={valueRadio !== "2"}
                                  >
                                      {
                                          labelCronExpression.find(tab => tab.tab === 'days')
                                            .daysOfWeek.map(({label, value1}, i) => {
                                                return (
                                                  <MenuItem key={i} value={value1}>
                                                    {label}
                                                  </MenuItem>
                                                );
                                            })
                                      }
                                  </Select>
                              </FormControl>
                          </Box>
                      }
                  />

                  {/*Apartir del dia del mes*/}
                  <FormControlLabel 
                    value="3"
                    control={<Radio size="small"/>}
                    sx={{mt: 2}}
                    label={
                      <Box 
                          display="flex"
                          alignItems="center" 
                          justifyContent="start"
                      >
                        <Typography>
                          {'Cada\u00A0'}
                        </Typography>
                        <FormControl 
                          variant="outlined" 
                          sx={{ m: 0}}
                        >
                          <Select
                              value={selectEveryDaysOfMonth}
                              size="small"
                              name="selectEveryDaysOfMonth"
                              sx={{py:0}}
                              onChange={handleSelectChange}
                              disabled={valueRadio !== "3"}
                          >
                              {
                                Object.keys(labelCronExpression.find(tab => tab.tab === 'days').daysOfMonth)
                                  .map( (x, i) => {
                                      return (
                                        <MenuItem key={i} value={x}>
                                          {x}
                                        </MenuItem>
                                      );
                                  })
                              }
                          </Select>
                        </FormControl>
                        <Typography>
                            {'\u00A0 día(s), a partir del \u00A0'}
                        </Typography>
                        <FormControl variant="outlined" sx={{ m: 0}}>
                          <Select
                            value={selectStartDaysOfMonth}
                            size="small"
                            name="selectStartDaysOfMonth"
                            sx={{py:0}}
                            onChange={handleSelectChange}
                            disabled={valueRadio !== "3"}
                          >
                            {
                              Object.keys(labelCronExpression.find(tab => tab.tab === 'days').daysOfMonth)
                                .map( (x, i) => {
                                    return (
                                      <MenuItem key={i} value={x}>
                                        {labelCronExpression.find(tab => tab.tab === 'days').daysOfMonth[x]}
                                      </MenuItem>
                                    );
                                })
                            }
                          </Select>
                        </FormControl>
                        <Typography>
                            {'\u00A0 día del mes'}
                        </Typography>
                      </Box>
                    }
                  />

                  {/*Días de la semana Especificos*/}
                  <FormControlLabel 
                    value="4"
                    control={<Radio size="small" sx={{pt: 0}}/>} 
                    sx={{
                        mt: 2,
                        alignItems: 'flex-start'
                    }}
                    label={
                      <Box>
                        <Typography>
                            Días especificos de la semana (selecciona uno o varios)
                        </Typography>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            {
                               labelCronExpression.find(tab => tab.tab === 'days')
                               .daysOfWeek.map(({label, value2}, i) => {
                                  return (
                                    <FormControlLabel
                                      key={i} 
                                      control={
                                        <Checkbox 
                                          checked={
                                              listCheckedDaysOfWeek.includes(value2)
                                          } 
                                          size="small" 
                                          name={value2}
                                          onChange={handleChangeCkeckedDaysOfWeek}
                                          disabled={valueRadio !== "4"}
                                        />
                                      }
                                      label={label}
                                      value={value2}
                                    />
                                  )
                                })
                              }                              
                          </FormGroup>
                        </FormControl>
                      </Box>   
                    }
                  />

                  {/*Días del mes especificos*/}
                  <FormControlLabel 
                    value="5"
                    control={<Radio size="small" sx={{pt: 0}}/>} 
                    sx={{
                        mt: 2,
                        alignItems: 'flex-start'
                    }}
                    label={
                      <Box>
                        <Typography>
                            Días especificos del mes (selecciona uno o varios)
                        </Typography>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            {
                               Object.keys(labelCronExpression.find(tab => tab.tab === 'days').daysOfMonth)
                               .map( (x, i) => {
                                  return (
                                    <FormControlLabel
                                      key={i} 
                                      control={
                                        <Checkbox 
                                          checked={
                                              listCheckedDaysOfMonth.includes(parseInt(x))
                                          } 
                                          size="small" 
                                          name={x}
                                          onChange={handleChangeCkeckedDaysOfMonth}
                                          disabled={valueRadio !== "5"}
                                        />
                                      }
                                      label={x < 10 ? `0${x}` : x}
                                      value={x}
                                    />
                                  )
                                })
                              }                              
                          </FormGroup>
                        </FormControl>
                      </Box>   
                    }
                  />

                  {/*Ultimo dia del mes*/}
                  <FormControlLabel 
                    value="6"
                    control={<Radio size="small"/>} 
                    label="Último día del mes"
                  />

                  {/*Ultimo dia entre semana del mes*/}
                  <FormControlLabel 
                    value="7"
                    control={<Radio size="small"/>} 
                    label="Último día entre semana (LUN-VIE) del mes"
                  />

                  {/*Cada ultimo {x} del mes*/}
                  <FormControlLabel 
                    value="8"
                    control={<Radio size="small"/>}
                    sx={{mt: 2}}
                    label={
                      <Box 
                          display="flex"
                          alignItems="center" 
                          justifyContent="start"
                      >
                        <Typography>
                          {'El último\u00A0'}
                        </Typography>
                        <FormControl 
                          variant="outlined" 
                          sx={{ m: 0}}
                        >
                          <Select
                              value={selectLastDayOfWeekByMonth}
                              size="small"
                              name="selectLastDayOfWeekByMonth"
                              sx={{py:0}}
                              onChange={handleSelectChange}
                              disabled={valueRadio !== "8"}
                          >
                              {
                                labelCronExpression.find(tab => tab.tab === 'days')
                                .daysOfWeek.map(({label, value1}, i) => {
                                      return (
                                        <MenuItem key={i} value={value1}>
                                          {label}
                                        </MenuItem>
                                      );
                                  })
                              }
                          </Select>
                        </FormControl>
                        <Typography>
                            {'\u00A0 del mes'}
                        </Typography>
                      </Box>
                    }
                  />

                  {/*{x} dias antes de fin del mes*/}
                  <FormControlLabel 
                    value="9"
                    disabled={true}
                    control={<Radio size="small"/>}
                    sx={{mt: 2}}
                    label={
                      <Box 
                          display="flex"
                          alignItems="center" 
                          justifyContent="start"
                      > 
                        <FormControl 
                          variant="outlined" 
                          sx={{ m: 0}}
                        >
                          <Select
                              value={selectDaysOfMonthBeforeEnd}
                              size="small"
                              name="selectDaysOfMonthBeforeEnd"
                              sx={{py:0}}
                              onChange={handleSelectChange}
                              disabled={true}
                          >
                              {
                                Object.keys(labelCronExpression.find(tab => tab.tab === 'days').daysOfMonth)
                                .map( (x, i) => {
                                   return (
                                    <MenuItem key={i} value={x}>
                                      {x}
                                    </MenuItem>
                                   )
                                 })
                              }
                          </Select>
                        </FormControl>
                        <Typography>
                            {'\u00A0 día(s) antes del fin de mes'}
                        </Typography>
                      </Box>
                    }
                  />


                  {/*Los días entre semana (LUN-VIE) mas cercanos al {x} dia del mes*/}
                  <FormControlLabel 
                    value="10"
                    control={<Radio size="small"/>}
                    sx={{mt: 2}}
                    label={
                      <Box 
                          display="flex"
                          alignItems="center" 
                          justifyContent="start"
                      >
                        <Typography>
                            {'Los días entre semana (LUN-VIE) mas cercanos al\u00A0'}
                        </Typography>
                        <FormControl 
                          variant="outlined" 
                          sx={{ m: 0}}
                        >
                          <Select
                              value={selectDaysOfWeekNearest}
                              size="small"
                              name="selectDaysOfWeekNearest"
                              sx={{py:0}}
                              onChange={handleSelectChange}
                              disabled={valueRadio !== "10"}
                          >
                              {
                                Object.keys(labelCronExpression.find(tab => tab.tab === 'days').daysOfMonth)
                                .map( (x, i) => {
                                   return (
                                    <MenuItem key={i} value={x}>
                                      {labelCronExpression.find(tab => tab.tab === 'days').daysOfMonth[x]}
                                    </MenuItem>
                                   )
                                 })
                              }
                          </Select>
                        </FormControl>
                        <Typography>
                            {'\u00A0 día del mes'}
                        </Typography>
                      </Box>
                    }
                  />

                  {/*El {} dia del mes */}
                  <FormControlLabel 
                    value="11"
                    control={<Radio size="small"/>}
                    sx={{mt: 2}}
                    label={
                      <Box 
                          display="flex"
                          alignItems="center" 
                          justifyContent="start"
                      >
                        <Typography>
                          {'El\u00A0'}
                        </Typography>
                        <FormControl 
                          variant="outlined" 
                          sx={{ m: 0}}
                        >
                          <Select
                              value={selectDaysOfWeekByMonth}
                              size="small"
                              name="selectDaysOfWeekByMonth"
                              sx={{py:0}}
                              onChange={handleSelectChange}
                              disabled={valueRadio !== "11"}
                          >
                              {
                                ['1° primer', '2° segundo', '3° tercer', '4° cuarto', '5° quinto'].map( (x, i) => {
                                      return (
                                        <MenuItem key={i} value={(i+1)}>
                                          {x}
                                        </MenuItem>
                                      );
                                  })
                              }
                          </Select>
                        </FormControl>
                        <FormControl 
                          variant="outlined" 
                          sx={{ m: 0}}
                        >
                          <Select
                              value={selectDaysOfWeekSpecifiedByMonth}
                              size="small"
                              name="selectDaysOfWeekSpecifiedByMonth"
                              sx={{py:0}}
                              onChange={handleSelectChange}
                              disabled={valueRadio !== "11"}
                          >
                              {
                                labelCronExpression.find(tab => tab.tab === 'days')
                                .daysOfWeek.map(({label, value1}, i) => {
                                      return (
                                        <MenuItem key={i} value={value1}>
                                          {label}
                                        </MenuItem>
                                      );
                                  })
                              }
                          </Select>
                        </FormControl>
                        <Typography>
                            {'\u00A0 del mes'}
                        </Typography>
                      </Box>
                    }
                  />

                </RadioGroup>
            </FormControl>
        </Paper>
  )
}
