import { useSelector } from 'react-redux';

export const useBoards = () => {
    const {
        boardUrl
    } = useSelector(state => state.board);

    return [{
        boardUrl
    }];
}