import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import * as Icons from '@mui/icons-material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { DetailsFlow } from "../../screens/flows/detailsFlow/DetailsFlow";

export const RedirectLayout = ({ item, data }) => {
    const navigate = useNavigate();
    const [selectedFlow, setSelectedFlow] = useState(null);
    const handleRedirect = (path) => {
        const id = data?.id;
        if (id) {
            setSelectedFlow(id);
            navigate(`${path}${id}`, { state: { config: { id, data } } });
        }
    };

    return (
        <>
            <Tooltip title={"Ver detalle"}>
                <IconButton
                    id={`id_${item.accion}`}
                    size="small"
                    sx={{ p: 0 }}
                    onClick={() => handleRedirect(item.config.path)}
                >
                    <span style={{ fontSize: 25, color: item.color }}>
                        {React.createElement(Icons[item.icon])}
                    </span>
                </IconButton>
            </Tooltip>

            <Routes>
                {selectedFlow && (
                    <Route
                        path={`/flow-idFlow`}
                        element={
                            <DetailsFlow />
                        }
                    />
                )}
            </Routes>
        </>
    )
};