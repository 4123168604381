import {
    fetchDataUsers, Delete, fetchUserAcces, DeletePermission, fetchCompaniesAcces, fetchGroupsAcces, AddPermission, UpdateUser, SaveUser
} from "../../services/users.service";
import { actionTypes } from "../contants/actionTypes";

export const getAllUsers = (accessToken, variables) => {
    return (dispatch) => {
        dispatch(setIsLoading(actionTypes.isLoadingLoadAllUsers, true));
        fetchDataUsers(accessToken, variables)
            .then(({ data }) => {
                dispatch(loadData(actionTypes.loadAllUsers, data ?? []));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al cargar los usuarios', typeMsg: 'error' }));
                console.error('getAllUsers', e)
            }).finally(() => {
                dispatch(setIsLoading(actionTypes.isLoadingLoadAllUsers, false));
            });
    }
};

export const DeleteUser = (accessToken, id) => {
    return (dispatch) => {
        Delete(accessToken, id)
            .then(({ data }) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Registro eliminado con exito', typeMsg: 'success' }));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al eliminar el registro', typeMsg: 'error' }));
                console.error('DeleteUser', e)
            }).finally(() => {
            });
    }
};

export const updateUser = (accessToken, dto) => {
    return (dispatch) => {
        UpdateUser(accessToken, dto)
            .then(({ data }) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Registro actualizado con exito', typeMsg: 'success' }));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al actualizar el registro', typeMsg: 'error' }));
                console.error('updateUser', e)
            }).finally(() => {
            });
    }
}

export const saveUser = (accessToken, dto) => {
    return (dispatch) => {
        SaveUser(accessToken, dto)
            .then(({ data }) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Registro guardado con exito', typeMsg: 'success' }));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al guardar el registro', typeMsg: 'error' }));
                console.error('saveUser', e)
            }).finally(() => {
            });
    }
}

export const getAllPermissions = (accessToken, idUser) => {
    return (dispatch) => {
        dispatch(setIsLoading(actionTypes.isLoadingAllPermissions, true));
        fetchUserAcces(accessToken, idUser)
            .then(({ data }) => {
                dispatch(loadData(actionTypes.loadAllPermissions, data ?? []));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al cargar los permisos', typeMsg: 'error' }));
                console.error('getAllUPermissions', e)
            }).finally(() => {
                dispatch(setIsLoading(actionTypes.isLoadingAllPermissions, false));
            });
    }
};

export const addPermissions = (accessToken, dto) => {
    return (dispatch) => {
        AddPermission(accessToken, dto)
            .then(({ data }) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Registro agregado con exito', typeMsg: 'success' }));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al agregar el registro', typeMsg: 'error' }));
                console.error('AddPermissions', e)
            }).finally(() => {
            });
    }
}

export const deletePermissions = (accessToken, id) => {
    return (dispatch) => {
        DeletePermission(accessToken, id)
            .then(({ data }) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Registro eliminado con exito', typeMsg: 'success' }));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al eliminar el registro', typeMsg: 'error' }));
                console.error('DeletePermissions', e)
            }).finally(() => {
            });
    }
};

export const getCompaniesAcces = (accessToken) => {
    return (dispatch) => {
        dispatch(setIsLoading(actionTypes.isLoadingAllPermissions, true));
        fetchCompaniesAcces(accessToken)
            .then(({ data }) => {
                dispatch(loadData(actionTypes.loadCompaniesPermissions, data));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al cargar las empresas', typeMsg: 'error' }));
                console.error('getCompaniesAcces', e)
            }).finally(() => {
                dispatch(setIsLoading(actionTypes.isLoadingAllPermissions, false));
            });
    }
};

export const getGroupsAcces = (accessToken, idCom) => {
    return (dispatch) => {
        dispatch(setIsLoading(actionTypes.isLoadingAllPermissions, true));
        fetchGroupsAcces(accessToken, idCom)
            .then(({ data }) => {
                dispatch(loadData(actionTypes.loadGroupsPermissions, data));
            }).catch((e) => {
                dispatch(setNotificaction({ isNotification: true, msg: 'Ocurrio un error al cargar los permisos', typeMsg: 'error' }));
                console.error('getGroupsAcces', e)
            }).finally(() => {
                dispatch(setIsLoading(actionTypes.isLoadingAllPermissions, false));
            });
    }
};

export const loadData = (action, data) => ({
    type: action,
    payload: data
})

const setIsLoading = (action, value) => ({
    type: action,
    payload: value
});

export const setNotificaction = (data) => ({
    type: actionTypes.notificaction,
    payload: data
})