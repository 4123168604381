import { TablePagination } from '@mui/material'
import React from 'react'

export const CustomTableFooter = ({
    countRows, 
    rowsPerPage, 
    page, 
    handleChangePage, 
    handleChangeRowsPerPage
}) => {


    return (
        countRows > 0 &&
            <TablePagination
                sx={{display: 'table-row', verticalAlign: 'bottom' }}
                rowsPerPageOptions={countRows > rowsPerPage ? [rowsPerPage,rowsPerPage*2,rowsPerPage*3] : [0]}
                component="div"
                count={countRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            
    )
}
