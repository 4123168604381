import React from 'react';
import { Box, Tooltip } from '@mui/material';
import axios from 'axios';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useNotification } from '../../../hooks/useNotification';
import { ModalForms } from './ModalForms';
import moment from 'moment';

export const LoadButtonHeaderTable = ({ buttons, id_user_action, DocManager, columns, handleChange }) => {
    const [{ userSession }] = useDataAuth();
    const [sendNotification] = useNotification();
    const { filters, rowsChecked, handleRowsChecked } = DocManager;

    const CallApi = async (trigger, name, requires_check) => {
        const filt = JSON.stringify({
            "filters": {
                "id_unique": {
                    "value": rowsChecked.join(","),
                    "operator": "IN",
                    "type": "string"
                }
            }
        })
        let ext = name.split('.').pop();
        let bodyData = trigger[0]?.bodyData;
        const url = trigger[0]?.url;
        const method = trigger[0]?.method;
        if (requires_check === 'true') {
            if (rowsChecked.length > 0)
                bodyData.variables = filt
            else {
                sendNotification({ msg: 'Debes selecionar por lo menos una fila', variant: 'error' });
                return false;
            }
        }
        else {
            if (rowsChecked.length > 0)
                bodyData.variables = filt
            else {
                bodyData.variables = JSON.stringify({ filters });
            }
        }

        const header = columns.reduce((acc, col) => {
            if (col.visible)
                acc[col.label] = `{${col.id}}`;
            return acc;
        }, {});

        bodyData.schema = header;
        bodyData.id_user_action = id_user_action;

        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        sendNotification({ msg: 'Se esta generando el archivo, esto puede tomar unos segundos!', variant: 'success' });
        handleRowsChecked([]);
        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                const byteCharacters = atob(response.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: `application/${ext}` });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name.replace('{date}', moment().format('YYYY-MM-DDTHH:mm:ssZ')));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else
                sendNotification({ msg: 'Ocurrió un error generando el archivo, por favor comuníquese con el area encargada', variant: 'error' });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            {
                buttons && buttons.length > 0 &&
                buttons.map((item, index) => (
                    <>
                        {
                            item.type === 'download' && (
                                <Tooltip
                                    title={item.accion}
                                    key={index}
                                >
                                    <Box
                                        component="img"
                                        key={index}
                                        src={item.icon}
                                        alt={item.accion}
                                        sx={{
                                            height: 30,
                                            width: 'auto',
                                            cursor: 'pointer',
                                            marginInline: '3px'
                                        }}
                                        onClick={() => CallApi(item.trigger, item.name_file, item.requires_check)}
                                    />
                                </Tooltip >
                            )
                        }
                        {
                            item.type === 'form' && (
                                <ModalForms data={item} id_user_action={id_user_action} handleChange={handleChange} />
                            )
                        }
                    </>
                ))
            }
        </>
    )
}