import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { EditItem } from '../../../../components/editItem/EditItem';
import { DetailsSummaryTypesStatus } from './DetailsSummaryTypesStatus';

export const DetailsTypesStatus = ({
    settingsManager
}) => {

    const {
        flowVariables,
        isChangedSettings,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        addingNewSettingsFlows,
        handleUpdatingSettingsFlows,
        isErrorFetch,
        messageErrorFetch,
        handleDeleteTypeStatus,
        handleRefreshSettingsFlow
    } = settingsManager;
    const params = useParams();
    const [typeStatusSelected, setTypeStatusSelected] = useState({});
    const [isLoadingTypeStatus, setIsLoadingTypeStatus] = useState(false);

    useEffect(() => {
        setIsLoadingTypeStatus(true);
        setTimeout(() => {
            const typeStatusSelected = flowVariables.find(typeStatus => typeStatus.id_status === parseInt(params.idTypeStatus));
            setTypeStatusSelected(typeStatusSelected);
            setIsLoadingTypeStatus(false);
        }, 500);
    }, []);
   
    useEffect(() => {
        const typeStatusSelected = flowVariables.find(typeStatus => typeStatus.id_status === parseInt(params.idTypeStatus));
        setTypeStatusSelected(typeStatusSelected);
    }, [flowVariables]);

    return (
        <EditItem
            isLoadingItems={isLoadingTypeStatus}
            titleEditItem={{
                label: `Id Type Status: ${typeStatusSelected.id_status}`,
                isEditable: false
            }}
            nameItem={"Type Status"}
            isSaving={updatingSettingsFlows}
            statusButtonSave={isChangedSettings}
            handleSaveChanges={handleUpdatingSettingsFlows}
            isDeleting={deletingSettingsFlows}
            statusButtonDelete={true}
            handleClickDelete={handleDeleteTypeStatus}
            contentSummary={<DetailsSummaryTypesStatus
                typeStatusSelected={typeStatusSelected}
                settingsManager={settingsManager}
            />}
        />
    )
}
