import axios from 'axios';

const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;

export const fetchDataStatus = (accessToken) => {
    // return axios({
    //     method: "POST",
    //     url: baseUrl,
    //     headers: {
    //         'Authorization': `Bearer ${accessToken}`,
    //         'Content-Type': 'application/json'
    //     },
    //     data: {
    //         procedure_name: "dbo.sp_manage_status",
    //         action: "SELECT"
    //     }
    // })
    return new Promise((resolve, reject) =>{
        setTimeout(() => {
            resolve({
                data: {
                    result: [   
                        {
                            active: "Y",
                            color: "#579259",
                            created: "2022-11-30T14:12:25",
                            created_by: "admin",
                            id_status: 1,
                            name: "run",
                            updated: "2022-11-30T14:12:25",
                            updated_by: "admin"
                        },
                        {
                            active: "Y",
                            color: "#ba3e35",
                            created: "2022-11-30T14:12:25",
                            created_by: "admin",
                            id_status: 2,
                            name: "stop",
                            updated: "2022-11-30T14:12:25",
                            updated_by: "admin"
                        },
                        {
                            active: "N",
                            color: "#c2842a",
                            created: "2022-11-30T14:12:25",
                            created_by: "admin",
                            description: "",
                            id_status: 3,
                            name: "pause",
                            updated: "2022-12-09T19:36:00.327",
                            updated_by: "admin"
                        }
                    ]
                }
            });
        }, 2000);
    })
}

export const updatedDataStatus = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            procedure_name: "dbo.sp_manage_status",
            action: "UPDATE",
            status: data
        }        
    })
}

export const insertDataStatus = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            procedure_name: "dbo.sp_manage_status",
            action: "INSERT",
            status: data
        }        
    })
}

export const deleteDataStatus = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            procedure_name: "dbo.sp_manage_status",
            action: "DELETE",
            status: data
        }
    })
}