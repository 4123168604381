
export const labelCronExpression = [
    // {
    //     valueTab: 0,
    //     tab: 'seconds',
    //     label: 'Segundos',
    //     visible: true,
    //     childrenComponent: <CronSeconds />,
    //     secondsLabel: [
    //         '00','01','02','03','04','05','06','07','08','09',
    //         '10','11','12','13','14','15','16','17','18','19',
    //         '20','21','22','23','24','25','26','27','28','29',
    //         '30','31','32','33','34','35','36','37','38','39',
    //         '40','41','42','43','44','45','46','47','48','49',
    //         '50','51','52','53','54','55','56','57','58','59' 
    //     ],
    //     seconds: [
    //         1, 2, 3, 4, 5, 6, 7, 8, 9,
    //         10,11,12,13,14,15,16,17,18,19,
    //         20,21,22,23,24,25,26,27,28,29,
    //         30,31,32,33,34,35,36,37,38,39,
    //         40,41,42,43,44,45,46,47,48,49,
    //         50,51,52,53,54,55,56,57,58,59 
    //     ]
    // },
    {
        valueTab: 0,
        tab: 'minutes',
        label: 'Minutos',
        visible: true,
        minutesLabel: [
            '00','01','02','03','04','05','06','07','08','09',
            '10','11','12','13','14','15','16','17','18','19',
            '20','21','22','23','24','25','26','27','28','29',
            '30','31','32','33','34','35','36','37','38','39',
            '40','41','42','43','44','45','46','47','48','49',
            '50','51','52','53','54','55','56','57','58','59' 
        ],
        minutes: [
            1, 2, 3, 4, 5, 6, 7, 8, 9,
            10,11,12,13,14,15,16,17,18,19,
            20,21,22,23,24,25,26,27,28,29,
            30,31,32,33,34,35,36,37,38,39,
            40,41,42,43,44,45,46,47,48,49,
            50,51,52,53,54,55,56,57,58,59 
        ]
    },
    {
        valueTab: 1,
        tab: 'hours',
        label: 'Horas',
        visible: true,
        hoursLabel: [
            '00','01','02','03','04','05','06','07','08','09',
            '10','11','12','13','14','15','16','17','18','19',
            '20','21','22','23'
        ],
        hours: [
            1, 2, 3, 4, 5, 6, 7, 8, 9,
            10,11,12,13,14,15,16,17,18,19,
            20,21,22,23, 24
        ]
    },
    {
        valueTab: 2,
        tab: 'days',
        label: 'Dias',
        visible: true,
        daysOfWeek: [
            {
                label: 'Domingo',
                value1: 1,
                value2: 'SUN'
            },
            {
                label: 'Lunes',
                value1: 2,
                value2: 'MON'
            },
            {
                label: 'Martes',
                value1: 3,
                value2: 'TUE'
            },
            {
                label: 'Miercoles',
                value1: 4,
                value2: 'WED'
            },
            {
                label: 'Jueves',
                value1: 5,
                value2: 'THU'
            },
            {
                label: 'Viernes',
                value1: 6,
                value2: 'FRI'
            },
            {
                label: 'Sabado',
                value1: 7,
                value2: 'SAT'
            }
        ],
        daysOfMonth: {
            1: '1° primer',
            2: '2° segundo',
            3: '3° tercer',
            4: '4° cuarto',
            5: '5° quinto',
            6: '6° sexto',
            7: '7° septimo',
            8: '8° octavo',
            9: '9° noveno',
            10: '10° decimo',
            11: '11° undécimo',
            12: '12° duodécimo',
            13: '13° decimotercero',
            14: '14° decimocuarto',
            15: '15° decimoquinto',
            16: '16° decimosexto',
            17: '17° decimoséptimo',
            18: '18° decimoctavo',
            19: '19° decimonoveno',
            20: '20° vigésimo',
            21: '21° vigésimoprimer',
            22: '22° vigésimosegundo',
            23: '23° vigésimotercer',
            24: '24° vigésimocuarto',
            25: '25° vigésimoquinto',
            26: '26° vigésimosexto',
            27: '27° vigésimooctavo',
            28: '28° vigésimooctavo',
            29: '29° vigésimonoveno',
            30: '30° trigésimo',
            31: '31° trigésimoprimer'
        },
    },
    {
        valueTab: 3,
        tab: 'months',
        label: 'Meses',
        visible: true,
        months: [
            {
                label: 'Enero',
                value: 'JAN',
                value2: 1
            },
            {
                label: 'Febrero',
                value: 'FEB',
                value2: 2
            },
            {
                label: 'Marzo',
                value: 'MAR',
                value2: 3
            },
            {
                label: 'Abril',
                value: 'APR',
                value2: 4
            },
            {
                label: 'Mayo',
                value: 'MAY',
                value2: 5
            },
            {
                label: 'Junio',
                value: 'JUN',
                value2: 6
            },
            {
                label: 'Julio',
                value: 'JUL',
                value2: 7
            },
            {
                label: 'Agosto',
                value: 'AUG',
                value2: 8
            },
            {
                label: 'Septiembre',
                value: 'SEP',
                value2: 9
            },
            {
                label: 'Octubre',
                value: 'OCT',
                value2: 10
            },
            {
                label: 'Noviembre',
                value: 'NOV',
                value2: 11
            },
            {
                label: 'Diciembre',
                value: 'DEC',
                value2: 12
            },
        ]
    },
    {
        valueTab: 4,
        tab: 'years',
        label: 'Años',
        visible: true,
        years: [
            {
                label: '2022',
                value: 1
            },
            {
                label: '2023',
                value: 2
            },
            {
                label: '2024',
                value: 3
            },
            {
                label: '2025',
                value: 4
            },
            {
                label: '2026',
                value: 5
            },
            {
                label: '2027',
                value: 6
            },
            {
                label: '2028',
                value: 7
            },
            {
                label: '2029',
                value: 8
            },
            {
                label: '2030',
                value: 9
            },
            {
                label: '2031',
                value: 10
            },
            {
                label: '2032',
                value: 11
            },
            {
                label: '2033',
                value: 12
            },
            {
                label: '2034',
                value: 13
            },
            {
                label: '2035',
                value: 14
            },
            {
                label: '2036',
                value: 15
            },
            {
                label: '2037',
                value: 16
            },
            {
                label: '2038',
                value: 17
            },
            {
                label: '2039',
                value: 18
            },
            {
                label: '2040',
                value: 19
            },
            {
                label: '2041',
                value: 20
            },
        ]
    },
]