import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { EditItem } from '../../../../components/editItem/EditItem';
import { DetailsSummaryTypesExecute } from './DetailsSummaryTypesExecute';

export const DetailsTypesExecute = ({
    settingsManager
}) => {

    const {
        flowVariables,
        isChangedSettings,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        addingNewSettingsFlows,
        handleUpdatingSettingsFlows,
        isErrorFetch,
        messageErrorFetch,
        handleDeleteTypeExecute,
        handleRefreshSettingsFlow
    } = settingsManager;
    const params = useParams();
    const [typeExecuteSelected, setTypeExecuteSelected] = useState({});
    const [isLoadingTypeExecute, setIsLoadingTypeExecute] = useState(false);

    useEffect(() => {
        setIsLoadingTypeExecute(true);
        setTimeout(() => {
            const typeExecuteSelected = flowVariables?.find(typeExecute => typeExecute.id === parseInt(params.idTypeExecute));
            setTypeExecuteSelected(typeExecuteSelected);
            setIsLoadingTypeExecute(false);
        }, 500);
    }, []);
   
    useEffect(() => {
        const typeExecuteSelected = flowVariables?.find(typeExecute => typeExecute.id === parseInt(params.idTypeExecute));
        setTypeExecuteSelected(typeExecuteSelected);
    }, [flowVariables]);

    return (
        <EditItem
            isLoadingItems={isLoadingTypeExecute}
            titleEditItem={{
                label: `Id Type Execute: ${typeExecuteSelected.id}`,
                isEditable: false
            }}
            nameItem={"Type Execute"}
            isSaving={updatingSettingsFlows}
            statusButtonSave={isChangedSettings}
            handleSaveChanges={handleUpdatingSettingsFlows}
            isDeleting={deletingSettingsFlows}
            statusButtonDelete={true}
            handleClickDelete={handleDeleteTypeExecute}
            contentSummary={<DetailsSummaryTypesExecute
                typeExecuteSelected={typeExecuteSelected}
                settingsManager={settingsManager}
            />}
        />
    )
}
