import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { labelCronExpression } from './utils/labelCronExpression';
import { useForm } from '../../hooks/useForm';

export const CronYears = ({setValue, value}) => {
    
    const [listChecked, setListChecked] = useState(value.split(',').length > 1 ? value.split(',') : !isNaN(value) ? [value] : []);
    const [formValues, handleSelectChange] = useForm({
        selectEveryYears: value.split('/').length > 1 ? value.split('/')[1] : 1,
        selectStartYears: value.split('/').length > 1 ? value.split('/')[0] : new Date().getFullYear().toString(),
        selectBetweenYear1: value.split('-').length > 1 ? value.split('-')[0] : new Date().getFullYear().toString(),
        selectBetweenYear2: value.split('-').length > 1 ? value.split('-')[1] : new Date().getFullYear().toString()
    });
    const {selectEveryYears, selectStartYears, selectBetweenYear1, selectBetweenYear2} = formValues;

    const [valueRadio, setValueRadio] = useState('1');
    useEffect(() => {
        if(value === '*'){setValueRadio('1');}
        if(value.split(',').length > 1 || !isNaN(value)){setValueRadio('3');}
        if(value.split('/').length > 1){setValueRadio('2');}
        if(value.split('-').length > 1 ){setValueRadio('4');}
    }, [])
    
    useEffect(() => {
        if(valueRadio === '2'){
            setValue(`${selectStartYears}/${selectEveryYears}`);
        }
    }, [selectEveryYears, selectStartYears])
    
    useEffect(() => {
        if(valueRadio === '3'){
            setValue(listChecked.length === 0 ? '2022' : listChecked.sort((a,b)=>a-b).toString());
        }
    }, [listChecked])

    useEffect(() => {
        if(valueRadio === '4'){
            setValue(`${selectBetweenYear1}-${selectBetweenYear2}`);
        }
    }, [selectBetweenYear1, selectBetweenYear2])

    const handleChange = (e) => {
        setValueRadio(e.target.value);
        if(e.target.value === '1'){
            setValue('*');
        }else if(e.target.value === '2'){
            setValue(`${selectStartYears}/${selectEveryYears}`);
        }else if(e.target.value === '3'){
            setValue(`${moment().toDate().getFullYear()}`);
        }else if(e.target.value === '4'){
            setValue(`${selectBetweenYear1}-${selectBetweenYear2}`);
        }else{
            setValue('0');
        }
        handleReset();
    }

    const handleChangeCkecked = (e) => {
        if(!listChecked.includes(e.target.value)){
          setListChecked([...listChecked, e.target.value])
        }else{
            setListChecked(listChecked.filter((x, i) => x !== e.target.value))
        }
    }

    const handleReset = () => {
        setListChecked([])
    }

    return (
        <Paper 
            elevation={3} 
            sx={{
                px: '4%', 
                pb: '17%',
                bgcolor: (theme) => theme.palette.paper.background
            }}
        >
            <FormControl>
                <RadioGroup
                    aria-labelledby="years-radio-buttons-group-label"
                    value={valueRadio}
                    onChange={handleChange} 
                    name="radio-buttons-group"
                >
                    {/*Todos los Years*/}
                    <FormControlLabel 
                        value="1"
                        control={<Radio size="small"/>} 
                        label="Todos los años"
                    />

                    {/*Apartir del*/}                    
                    <FormControlLabel 
                        value="2"
                        control={<Radio size="small"/>}
                        sx={{mt: 2}}
                        label={
                            <Box 
                                display="flex"
                                alignItems="center" 
                                justifyContent="start"
                            >
                                <Typography>
                                    {'Cada\u00A0'}
                                </Typography>
                                <FormControl 
                                    variant="outlined" 
                                    sx={{ m: 0}}
                                >
                                    <Select
                                        value={selectEveryYears}
                                        size="small"
                                        name="selectEveryYears"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "2"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'years')
                                                .years.map(({value}, i) => {
                                                return (
                                                    <MenuItem key={i} value={value}>{value}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography>
                                    {'\u00A0 año(s), a partir del año \u00A0'}
                                </Typography>
                                <FormControl variant="outlined" sx={{ m: 0}}>
                                    <Select
                                        value={selectStartYears}
                                        size="small"
                                        name="selectStartYears"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "2"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'years')
                                                .years.map(({label}, i) => {
                                                return (
                                                    <MenuItem key={i}  value={label}>{label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />

                    {/*Years Especificos*/}
                    <FormControlLabel 
                        value="3"
                        control={<Radio size="small" sx={{pt: 0}}/>} 
                        sx={{
                            mt: 2,
                            alignItems: 'flex-start'
                        }}
                        label={
                            <Box>
                                <Typography>
                                    Años especificos (selecciona uno o varios)
                                </Typography>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'years')
                                                .years.map(({label}, i) => {
                                                return (
                                                    <FormControlLabel
                                                        key={i} 
                                                        control={
                                                            <Checkbox 
                                                                checked={
                                                                    listChecked.includes(label)
                                                                } 
                                                                size="small" 
                                                                value={label}
                                                                onChange={handleChangeCkecked}
                                                                disabled={valueRadio !== "3"}
                                                            />
                                                        }
                                                        label={label}
                                                        value={label}
                                                    />
                                                )
                                            })
                                        }                                
                                    </FormGroup>
                                </FormControl>
                            </Box>   
                        }
                    />

                    {/*Years Entre*/}
                    <FormControlLabel 
                        value="4"
                        control={<Radio size="small"/>} 
                        sx={{mt: 2}}
                        label={
                            <Box
                                display="flex"
                                alignItems="center" 
                                justifyContent="start"
                            >
                                <Typography>
                                    {'Todos los años entre el año \u00A0'}
                                </Typography>
                                <FormControl 
                                    variant="outlined" 
                                    sx={{ m: 0}}
                                >
                                    <Select
                                        value={selectBetweenYear1}
                                        size="small"
                                        name="selectBetweenYear1"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "4"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'years')
                                                .years.map(({label}, i) => {
                                                return (
                                                    <MenuItem key={i} value={label}>{label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography>
                                    {'\u00A0 y el año \u00A0'}
                                </Typography>
                                <FormControl variant="outlined" sx={{ m: 0}}>
                                    <Select
                                        value={selectBetweenYear2}
                                        size="small"
                                        name="selectBetweenYear2"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "4"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'years')
                                                .years.map(({label}, i) => {
                                                return (
                                                    <MenuItem key={i}  value={label}>{label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />

                </RadioGroup>
            </FormControl>
        </Paper>
  )
}
