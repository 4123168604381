import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TableUI from 'quill-table-ui';
import 'quill-table-ui/dist/index.css';

const modules = {
    toolbar: [
        [{ 'font': [] }, { 'size': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['link', 'image', 'video', 'formula'],
        ['clean'],
        ['table']
    ],
    tableUI: true
};

const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
    'table'
];
Quill.register('modules/tableUI', TableUI);

const ReactQuillEditor = forwardRef((props, ref) => {
    const { html, handleChange } = props;
    const [value, setValue] = useState(html);

    useImperativeHandle(ref, () => ({
        ResetChanges() {
            setValue(html);
        },
        AcceptChanges(){
            handleChange(value);
        }
    }));

    const OnChange = (data) => {
        setValue(data);
        // if (value !== data)
        //     disabled('two');
        // else
        //     disabled('');
    };

    return (
        <ReactQuill
            id={'field.id'}
            theme="snow"
            value={value}
            onChange={OnChange}
            formats={formats}
            modules={modules}
        />
    )
})

ReactQuillEditor.displayName = 'ReactQuillEditor';
export default ReactQuillEditor;