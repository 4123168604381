import { TextField } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';

export function JSONTextEditor({ value, onChange }) {
    const [textareaValue, setTextareaValue] = useState(JSON.stringify(value, null, '    '));
    const [isValid, setIsValid] = useState(true);

    const updateValue = (newValue) => {
        setTextareaValue(newValue);

        try {
            const parsedResult = JSON.parse(newValue);
            setIsValid(true);
            onChange(parsedResult);
        } catch (err) {
            setIsValid(false);
        }
    };

    useEffect(() => {
        setTextareaValue(JSON.stringify(value, null, '    '));
        setIsValid(true);
    }, [value]);

    const inputRef = useRef(null);
    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            const { current } = inputRef;
            const start = current.selectionStart;
            const end = current.selectionEnd;
            const value = current.value;
            current.value = value.substring(0, start) + '\t' + value.substring(end);
            current.selectionStart = current.selectionEnd = start + 1;
        }
    };

    return (
        <TextField
            id='IdTextFieldJsonEditor'
            onKeyDown={handleKeyDown}
            ref={inputRef}
            sx={{
                '& .MuiInputBase-root': {
                    flex: 1,
                },
            }}
            margin="dense"
            fullWidth
            style={{ flex: 1, margin: 0, height: '87%' }}
            inputProps={{ style: { maxHeight: 350, overflow: 'auto' } }}
            error={!isValid}
            hiddenLabel
            autoCorrect="off"
            spellCheck="false"
            autoComplete="off"
            multiline
            variant="outlined"
            onChange={(e) => updateValue((e.target).value)}
            value={textareaValue}
        // disabled={true}
        />
    );
}
