import { useState } from "react"


export const useFocusInputs = ( initialState = {} ) => {
    
    const [focusInputs, setFocusInputs] = useState(initialState);

    const handleFocusInputs = ( event ) => {
        const {name} = event.target;
        setFocusInputs({
            ...focusInputs,
            [ `is${(name).charAt(0).toUpperCase()+(name).slice(1)}Focus` ]: event.type === "focus" ? true : false
        });

    }

    return [ focusInputs, handleFocusInputs ];

}