import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import FilePresent from '@mui/icons-material/FilePresent';
import FileUpload from '@mui/icons-material/FileUpload';
import { JsonTree } from '../jsonEditorV2/jsontree/JsonTree';
import { HtmlEditor } from '../htmlEditor/HtmlEditor';
import { ArrayTextEditor } from '../arrayTextEditor/ArrayTextEditor';

const DynamicForm = ({ values, scheme, onChange, handleFileChange, handleUploadClick, setChanges }) => {
    const [formData, setFormData] = useState(values);
    console.log('values ', values);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'switch' ? checked : value });
        onChange(e);
    };

    useEffect(() => {
        setFormData(values);
    }, [values])

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleChanges = (key, value) => {
        setFormData({ ...formData, [key]: value });
        setChanges(key, value);
    }

    return (
        <form onSubmit={handleSubmit}>
            {
                scheme.map((field, index) => (
                    <Box
                        display="flex"
                        flexDirection="row"
                    >
                        <FormGroup
                            sx={{ my: 1 }}
                            style={{ width: '100%' }}
                            fullWidth
                            className="mt-2"
                        >
                            {field.type === 'switch' ? (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            id={field.id}
                                            name={field.id}
                                            checked={formData[field.id] || false}
                                            required={field.required}
                                            onChange={handleChange}
                                        />
                                    }
                                    label={field.label}
                                />
                            ) : field.type === 'select' ? (
                                <TextField
                                    id={field.id}
                                    name={field.id}
                                    select
                                    label={field.label}
                                    variant="outlined"
                                    value={formData[field.id] ? formData[field.id] : ''}
                                    onChange={handleChange}
                                    required
                                    size="small"
                                    defaultValue="none"
                                    placeholder="select an option"
                                >
                                    <MenuItem
                                        key=""
                                        value=""
                                        disabled
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        select an option
                                    </MenuItem>
                                    {field.values?.map((option) => (
                                        <MenuItem
                                            key={option}
                                            value={option}
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            ) : field.type === 'key_value' ? (
                                <JsonTree
                                    keyParent={field.id}
                                    contentJson={!formData[field.id] ? {} : formData[field.id]}
                                    handleChange={handleChanges}
                                />
                            ) : field.type === 'upload' ? (
                                <FormGroup

                                    style={{ height: '30px', display: 'flex' }}
                                >
                                    <TextField
                                        id={field.id}
                                        name={field.id}
                                        size="small"
                                        fullWidth
                                        type={field.type}
                                        label={field.label}
                                        value={formData[field.id] || ''}
                                        InputLabelProps={{ shrink: true }}
                                        aria-readonly
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    <label htmlFor="raised-button-file">
                                                        <Tooltip title={'Seleccionar archivo'}>
                                                            <FilePresent style={{ fontSize: 25, color: 'gray' }} />
                                                        </Tooltip>
                                                    </label>
                                                    <label>
                                                        <Tooltip title={'Subir archivo'}>
                                                            <IconButton
                                                                id={`id_${field.accion}`}
                                                                size="small"
                                                                sx={{ p: 0 }}
                                                                onClick={() => handleUploadClick(field.buket, field.path)}
                                                            >
                                                                <FileUpload style={{ fontSize: 25, color: 'blue' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </label>
                                                </>
                                            ),
                                        }}
                                    />
                                    <input
                                        accept=".pdf, .xml, .eml, .zip"
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        type="file"
                                        onChange={(e) => handleFileChange(e, field.id)}
                                    />
                                </FormGroup>
                            ) : field.type === 'html' ? (
                                <HtmlEditor
                                    field={field}
                                    html={formData[field.id] || ''}
                                    handleChange={handleChanges}
                                />
                            ) : field.type === 'textArray' ? (
                                <ArrayTextEditor
                                    field={field}
                                    arrayText={formData[field.id] || []}
                                    handleChange={handleChanges}
                                />
                            ) : (
                                <TextField
                                    id={field.id}
                                    name={field.id}
                                    size="small"
                                    fullWidth
                                    type={field.type}
                                    label={field.label}
                                    required={field.required}
                                    value={formData[field.id] || ''}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handleChange}
                                />
                            )}
                        </FormGroup>
                    </Box>
                ))
            }
        </form >
    );
};
export default DynamicForm;