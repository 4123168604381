import { Today } from '@mui/icons-material';
import { Grid, InputAdornment, InputLabel, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { parse } from 'jsoneditor/dist/jsoneditor-minimalist';
import React, { useEffect, useState } from 'react';
import { CronDays } from './CronDays';

import { CronHours } from './CronHours';
import { CronMinutes } from './CronMinutes';
import { CronMonths } from './CronMonths';
import { CronYears } from './CronYears';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            {children}
          </Box>
        )}
      </div>
    );
}
  
// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

export const CronExpression = ({cronExpression, handleChange}) => {
    
    const [valueCron, setValueCron] = useState(!cronExpression ? '* * * * ? *' : cronExpression);
    const [valueTabs, setValueTabs] = useState(0);
    const [valueMinutes, setValueMinutes] = useState(valueCron.split(' ')[0]);
    const [valueHours, setValueHours] = useState(valueCron.split(' ')[1]);
    const [valueDaysOfMonth, setValueDaysOfMonth] = useState(valueCron.split(' ')[2]);
    const [valueMonths, setValueMonths] = useState(valueCron.split(' ')[3]);
    const [valueDaysOfWeek, setValueDaysOfWeek] = useState(valueCron.split(' ')[4]);
    const [valueYears, setValueYears] = useState(valueCron.split(' ')[5]);
    
    useEffect(() => {
        setValueCron(`${valueMinutes} ${valueHours} ${valueDaysOfMonth} ${valueMonths} ${valueDaysOfWeek} ${valueYears}`);
    }, [valueMinutes, valueHours, 
        valueDaysOfMonth, valueMonths, valueDaysOfWeek, valueYears])

    useEffect(() => {
        handleChange(valueCron);
    }, [valueCron])        

    const handleChangeTabs = (event, newValue) => {
        setValueTabs(newValue);
    };

    const handleInputChange = (event) => {   
        try{
            const parsedExpr = parse(event.target.value);
            setValueCron(parsedExpr);
        }catch(error){
        }
    }

    return (
        <Box
            sx={{ flexGrow: 1, display: 'flex', px: 0}}
        >
            <Paper 
                elevation={5}
                sx={{
                    bgcolor: (theme) => theme.palette.paper.background
                }}
            >
                <Tabs
                    orientation="vertical"
                    value={valueTabs}
                    onChange={handleChangeTabs}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider', height: '100%' }}
                >
                    <Tab label={
                        <Box>
                            <Typography sx={{fontWeight: 'bold'}} component="div">
                                Minutes
                            </Typography>
                        </Box>
                    } {...a11yProps(0)} />
                    <Tab label={
                        <Box>
                            <Typography sx={{fontWeight: 'bold'}} component="div">
                                Hours
                            </Typography>
                        </Box>
                    } {...a11yProps(1)} />
                    <Tab label={
                        <Box>
                            <Typography sx={{fontWeight: 'bold'}} component="div">
                                Days
                            </Typography>
                        </Box>
                    } {...a11yProps(2)} />
                    <Tab label={
                        <Box>
                            <Typography sx={{fontWeight: 'bold'}} component="div">
                                Months
                            </Typography>
                        </Box>
                    } {...a11yProps(3)} />
                    <Tab label={
                        <Box>
                            <Typography sx={{fontWeight: 'bold'}} component="div">
                                Years
                            </Typography>
                        </Box>
                    } {...a11yProps(4)} />
                </Tabs>
            </Paper>
            <Box>
                <Grid container>
                    <Grid 
                        item 
                        md={4} 
                        sx={{
                            pt: 2, 
                            px: 1                            
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            sx={{
                                pl: 3,
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: 'column'
                            }}
                        >
                            <InputLabel id="cronExpressionManual">
                                <Typography color="primary" sx={{fontSize: '13px', fontWeight: 'bold'}}>
                                    Cron Expression
                                </Typography>
                            </InputLabel>
                            <TextField
                                required
                                fullWidth
                                id="cronExpressionManual"
                                variant="outlined"
                                name="nameFlow"
                                placeholder="Cron expression"
                                size="small"
                                autoComplete="off"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {/* <Badge color={(isUsuarioFocus) ? "primary" : (usuarioError.isError) ? "error" : "third"}/> */}
                                            <Today color="primary"/>
                                        </InputAdornment>
                                    ),
                                }}                        
                                value={valueCron}
                                sx={{mb: 1}}
                                helperText={"Enter a cron expression."}
                                onChange={handleInputChange}
                            />
                        </Box>
                    </Grid>
                    <Grid 
                        item 
                        md={8} 
                        sx={{px:1}}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <TableContainer
                            sx={{
                                pl: 3,
                                borderLeft: '2px solid',
                                borderLeftColor: (theme) => theme.palette.primary.main
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell  sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography color="primary" sx={{fontSize: '13px', fontWeight: 'bold'}}>
                                                Minutes
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography color="primary" sx={{fontSize: '13px', fontWeight: 'bold'}}>
                                                Hours
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography color="primary"  sx={{fontSize: '13px', fontWeight: 'bold'}}>
                                                Days of month
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography color="primary" sx={{fontSize: '13px', fontWeight: 'bold'}}>
                                                Months
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography color="primary" sx={{fontSize: '13px', fontWeight: 'bold'}}>
                                                Days of week
                                            </Typography>
                                        </TableCell>
                                        <TableCell  sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography color="primary" sx={{fontSize: '13px', fontWeight: 'bold'}}>
                                                Years
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography sx={{fontSize: '13px'}}>
                                                {valueMinutes}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography sx={{fontSize: '13px'}}>
                                                {valueHours}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography sx={{fontSize: '13px'}}>
                                                {valueDaysOfMonth}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography sx={{fontSize: '13px'}}>
                                                {valueMonths}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography sx={{fontSize: '13px'}}>
                                                {valueDaysOfWeek}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{py: 0.5, textAlign: 'center'}}>
                                            <Typography sx={{fontSize: '13px'}}>
                                                {valueYears}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>                    
                </Grid>
                
                <TabPanel value={valueTabs} index={0}>
                    <CronMinutes
                        value={valueMinutes}
                        setValue={setValueMinutes}
                    />
                </TabPanel>
                <TabPanel value={valueTabs} index={1}>
                    <CronHours
                        value={valueHours}
                        setValue={setValueHours}
                    />
                </TabPanel>
                <TabPanel value={valueTabs} index={2}>
                    <CronDays
                        valueDaysOfMonth={valueDaysOfMonth}
                        setValueDaysOfMonth={setValueDaysOfMonth}
                        valueDaysOfWeek={valueDaysOfWeek}
                        setValueDaysOfWeek={setValueDaysOfWeek}
                    />
                </TabPanel>
                <TabPanel value={valueTabs} index={3}>
                    <CronMonths
                        value={valueMonths}
                        setValue={setValueMonths}
                    />
                </TabPanel>
                <TabPanel value={valueTabs} index={4}>
                    <CronYears
                        value={valueYears}
                        setValue={setValueYears}
                    />
                </TabPanel>
            </Box>
        </Box>
    )
}
