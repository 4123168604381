import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import React, { createContext } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { themeDark, themeLight } from '../helpers/themeSystem';

const ColorModeContext = createContext({ toggleColorMode: () => {} });

const getDesignTokens = (mode) => (
  mode === 'dark' ? themeDark : themeLight
);

export const ColorModeProvider = ({children}) => {

  const [mode, setMode] = useState(localStorage.getItem('mode') === 'dark' ? 'dark' : 'light');    
  const colorMode = useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));   
        },
      }),
      [],
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]); 
  useMemo(() => localStorage.setItem('mode', mode), [mode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default ColorModeContext;
