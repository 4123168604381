import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { EditItem } from '../../../components/editItem/EditItem';
import { DetailsSummaryComponentFlow } from './DetailsSummaryComponentFlow';
import { ListDragDrop } from '../../../components/lists/ListDragDrop';
import { ItemListSubComponentFlow } from './ItemListSubComponentFlow';
import { JsonEditorV2 } from '../../../components/jsonEditorV2/JsonEditorV2';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useNotification } from '../../../hooks/useNotification';
import { useDispatch } from 'react-redux';
import { changeComponentFlowInEdition } from '../../../redux/actions/flows';

export const DetailsComponentsFlow = ({
    flowManager
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [componentFlowSelected, setComponentFlowSelected] = useState({});
    const [sendNotification] = useNotification();
    const params = useParams();
    const {
        // loading: isLoadingFlows,
        updatingFlows: isUpdatingFlows,
        deletingComponentFlow: isDeletingComponentFlow,
        listFlows,
        isChangeDataComponentFlows,
        handleDeleteComponentFlow, 
        handleChangeComponentFlow,
        handleUpdatedFlow,
        handleAddSubComponentFlow,
        handleDeleteSubComponentFlow,
        setCurrentBreadCrumb
    } = flowManager;
    const [isLoadingFlows, setIsLoadingFlows] = useState(false);
    const [{username}]  = useDataAuth();

    useEffect(() => {
        setIsLoadingFlows(true);
        const flowSelected = listFlows.find(flow => flow.id_flow === parseInt(params.idFlow));        
        const idComponentFlow = !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow;

        setTimeout(() => {
            const componentFlowSelected = flowSelected?.flow_components.find(componentFlow => componentFlow.id_flow_component === idComponentFlow);
            setComponentFlowSelected(componentFlowSelected);
            dispatch(changeComponentFlowInEdition(componentFlowSelected));
            setIsLoadingFlows(false);
            setCurrentBreadCrumb(2);
        }, 500);
    }, [])

    useEffect(() => {
        const flowSelected = listFlows.find(flow => flow.id_flow === parseInt(params.idFlow));        
        const idComponentFlow = !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow;
        const componentFlowSelected = flowSelected?.flow_components?.find(componentFlow => componentFlow.id_flow_component === idComponentFlow);
        setComponentFlowSelected(componentFlowSelected);
    }, [listFlows])

    const handleChangeSelect = ({target}) => {
        handleChangeComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            target.name,
            target.value
        );
    }

    const handleChangeEditor = (key, value) => {
        handleChangeComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            key,
            value
        );
    }

    const handleClickNewSubComponentFlow = () => { 
        const idSubFlowComponent = `NSC-${componentFlowSelected?.sub_flow_components ? componentFlowSelected.sub_flow_components.length+1 : 1}`;
        const newSubFlowComponentObject = {
            is_new_component: true,
            id_flow_component: idSubFlowComponent,
            type: "",
            order_in_flow: componentFlowSelected.order_in_flow,
            order_in_flow_component: componentFlowSelected?.sub_flow_components ? componentFlowSelected.sub_flow_components.length+1 : 1,
            type_execute: "",
            process_input: {},
            acceptance_process: {},
            process_fail: {},
            created: moment().toDate(),
            created_by: username,
            updated: moment().toDate(),
            updated_by: username
        };
        handleAddSubComponentFlow(
            parseInt(params.idFlow),
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            newSubFlowComponentObject
        );
        navigate(`/flows/flow-${params.idFlow}/component-flow-${params.idComponentFlow}/sub-component-flow-${idSubFlowComponent}`)
    }

    const handleChangeOrderSubComponentFlows = (newArrayComponentFlows) => {
        handleChangeComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            'sub_flow_components',
            newArrayComponentFlows
        );
    }

    const handleClickDeleteComponentFlow = () => {
        handleDeleteComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            false
        )
        setTimeout(() => {        
            navigate(`/flows/${params.idFlow}`)
        }, 1000);
    }

    const handleClickSaveChanges = () => {
        if(!componentFlowSelected?.type){
            sendNotification({msg: "The field 'Type Component Flow' is required", variant: 'error'})
        }

        if(!componentFlowSelected?.type_execute){
            sendNotification({msg: "The field 'Type Execute' is required", variant: 'error'})
        }

        if(!componentFlowSelected?.process_input ||  Object.keys(componentFlowSelected?.process_input).length === 0){
            sendNotification({msg: "The field 'Process Input' is required", variant: 'error'})
        }
        handleUpdatedFlow(!isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow);
    }

    return (
        <Box>
            {
                !isLoadingFlows && !componentFlowSelected ?
                    <Navigate to={`/flows/${params.idFlow}`}/>
                : <EditItem
                    isLoadingItems={isLoadingFlows}
                    titleEditItem={{
                        label: `Id Component Flow: ${!isNaN(componentFlowSelected?.id_flow_component) ? componentFlowSelected.id_flow_component: 'Unassigned' }`,
                        isEditable: false
                    }}
                    nameItem={"Component Flow"}
                    isSaving={isUpdatingFlows}
                    statusButtonSave={isChangeDataComponentFlows}
                    handleSaveChanges={handleClickSaveChanges}
                    isDeleting={isDeletingComponentFlow}
                    statusButtonDelete={true}
                    handleClickDelete={handleClickDeleteComponentFlow}
                    contentSummary={<DetailsSummaryComponentFlow
                        componentFlowSelected={componentFlowSelected}
                        handleChangeSelect={handleChangeSelect}
                    />}
                    tabs={[
                        {
                            label: 'Process Input',
                            value: '1',
                            bodyTab: <Box sx={{p: 3}}>
                                <JsonEditorV2
                                    titleKey={'processInput'}
                                    contentJson={componentFlowSelected?.process_input}
                                    handleChange={(event) => handleChangeEditor("process_input", event)}
                                    viewCheckBoxSelect={true}
                                />
                            </Box>
                        },
                        {
                            label: 'Acceptance Process',
                            value: '2',
                            bodyTab: <Box sx={{p: 3}}>
                                <JsonEditorV2
                                    titleKey={'acceptanceProcess'}
                                    contentJson={componentFlowSelected?.acceptance_process}
                                    handleChange={(event) => handleChangeEditor("acceptance_process", event)}
                                    viewCheckBoxSelect={true}
                                />
                            </Box>
                        },
                        {
                            label: 'Process Fail',
                            value: '3',
                            bodyTab: <Box sx={{p: 3}}>
                                <JsonEditorV2
                                    titleKey={'processFail'}
                                    contentJson={componentFlowSelected?.process_fail}
                                    handleChange={(event) => handleChangeEditor("process_fail", event)}
                                    viewCheckBoxSelect={true}
                                />
                            </Box>
                        },
                        {
                            label: 'Sub Components Flow',
                            value: '4',
                            bodyTab: <Box sx={{p: 3}}>
                                <ListDragDrop
                                    numberGridList={6}
                                    nameItem="sub component flow"
                                    keyItem="subComponentFlow"
                                    keyItemOrder="order_in_flow"
                                    handleClickNewItem={handleClickNewSubComponentFlow}
                                    listItems={componentFlowSelected?.sub_flow_components ? componentFlowSelected.sub_flow_components: []}
                                    itemComponent={
                                        <ItemListSubComponentFlow
                                            handleDeleteSubComponentFlow={handleDeleteSubComponentFlow}
                                        />
                                    }
                                    handleChangeOrderItems={handleChangeOrderSubComponentFlows}
                                />
                            </Box>
                        }
                    ]}
                />
            }
        </Box>
    )
}