import React from 'react'
import { FileDownload } from '@mui/icons-material'
import axios from 'axios';
import { useDataAuth } from '../../hooks/useDataAuth';
import { Tooltip, IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';

export const DownloadFile = ({ path, bucket, idFlow = null, data = null }) => {
    const [{ userSession }] = useDataAuth();
    const DownloadClick = async (path, bucket) => {
        const bodyData = {
            'id_flow': idFlow ?? '8',
            'bucket': bucket,
            'path': path
        }

        let name = path.split('/').pop();
        let ext = name.split('.').pop();

        const baseUrlFlow = process.env.REACT_APP_AWS_API_GATEWAY_URL_EXECUTE_FLOW;
        let config = {
            method: 'POST',
            url: baseUrlFlow,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                const byteCharacters = atob(response.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: `application/${ext}` });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else
                console.error('R_ ', response.status);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <React.Fragment>
            {
                data ?
                    <Tooltip title={data.accion}>
                        <IconButton
                            id={`id_${data.accion}`}
                            size="small"
                            sx={{ p: 0 }}
                            onClick={() => DownloadClick(path, bucket)}
                        >
                            <span style={{ fontSize: 25, color: data.color }}>
                                {React.createElement(Icons[data.icon])}
                            </span>
                        </IconButton>
                    </Tooltip>
                    :
                    path &&
                    <FileDownload
                        style={{ fontSize: 25, color: 'black', cursor: 'pointer' }}
                        onClick={() => DownloadClick(path, bucket)}
                    />
            }
        </React.Fragment>
    )
}