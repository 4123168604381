import React, { useState } from 'react';
import { Tooltip, Dialog, Typography, DialogContent, DialogTitle, IconButton, CircularProgress, Container, Box } from '@mui/material';
import * as Icons from '@mui/icons-material';
import axios from 'axios';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { Close } from '@mui/icons-material';

export const ShowDocument = ({ data }) => {
    const [{ userSession }] = useDataAuth();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [pdfBase64, setPdfBase64] = useState(null);

    const DownloadClick = async (param) => {
        setPdfBase64(null);
        let bodyData = param[0]?.bodyData;
        const url = param[0]?.url;
        setName(url.split('/').pop())
        const method = param[0]?.method;

        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200)
                handleFile(response.data)
            // setPdfBase64(`data:application/pdf;base64,${response.data}`);
            else
                console.error('R_ ', response.status);
        } catch (error) {
            console.error(error);
        }
    };

    const MAX_SIZE_MB = 1.8; // Tamaño máximo en MB
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
    function handleFile(base64String) {
        const padding = (base64String.match(/=/g) || []).length;
        const base64Length = base64String.length - padding;
        const fileSizeBytes = (base64Length * 3) / 4;

        if (fileSizeBytes > MAX_SIZE_BYTES) {
            // Si el archivo es demasiado grande, se descarga
            console.log('Descarga el pdf');
            downloadFile(base64String);
        } else {
            // Renderiza el archivo o realiza otra acción
            renderFile(base64String);
        }
    }

    function downloadFile(base64String) {
        setOpen(false);
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${base64String}`;
        link.download = name;
        link.click();
    }

    function renderFile(base64String) {
        // Lógica para renderizar el archivo PDF
        setPdfBase64(`data:application/pdf;base64,${base64String}`);
        console.log('Renderizando archivo PDF...');
    }

    return (
        <React.Fragment>
            <Tooltip title={data.accion}>
                <IconButton
                    id={'id_' + Math.floor(Math.random() * 615161)}
                    size="small"
                    sx={{ p: 0 }}
                    onClick={() => {
                        setOpen(true);
                        DownloadClick(data.trigger);
                    }}
                >
                    <span style={{ fontSize: 25, color: data.color }}>
                        {React.createElement(Icons[data.icon])}
                    </span>
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                fullScreen
            // PaperProps={{
            //     style: { margin: 0, width: 1300, height: 550 },
            // }}
            >
                <DialogTitle>
                    <Typography variant="h5" component="h5">
                        Vista pdf
                    </Typography>

                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {
                        pdfBase64 ?
                            <iframe
                                title='View PDF'
                                className='iframe-size-view-pdf'
                                // style={{ 
                                //     width: 100%, 
                                //     height: 450 
                                // }}
                                src={pdfBase64}
                            />
                            :
                            <Container
                                sx={{
                                    width: 1250,
                                    height: 450,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <CircularProgress color='primary' />
                                <Typography>Cargando...</Typography>
                            </Container>
                    }
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}