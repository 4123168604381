import { actionTypes } from "../contants/actionTypes";

const initialState = {
    menu: []
}

export const menuReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.loadMenu:
            return {
                ...state,
                menu: action.payload,
            }
        default:
            return state;
    }
}