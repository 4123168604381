import React, { useState, useEffect, useRef } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';
import { Box } from '@mui/material';

export const JsonEditor = ({ value, onChange, propertyPath }) => {
    const editorRef = useRef(null);
    const monaco = useMonaco();
    const [jsonValue, setJsonValue] = useState(JSON.stringify(value, null, '    '));
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setJsonValue(JSON.stringify(value, null, '    '));
        setIsValid(true);
    }, [value]);

    const handleEditorChange = (value) => {
        setJsonValue(value);
        try {
            const parsedResult = JSON.parse(value);
            setIsValid(true);
            onChange(parsedResult);
        } catch {
            setIsValid(false);
        }
    };

    const handleEditorDidMount = (editor) => {
        editorRef.current = editor;
        positionCursor(propertyPath);
    };

    const positionCursor = (path) => {
        try {
            const jsonObject = JSON.parse(jsonValue);
            const { lineNumber, column } = findPosition(jsonObject, path);
            if (lineNumber !== -1 && column !== -1) {
                editorRef.current.setPosition({ lineNumber, column });
                editorRef.current.focus();
            }
        } catch (error) {
            console.error('Failed to parse JSON or find position:', error);
        }
    };

    const findPosition = (jsonObject, path) => {
        const pathParts = path.split('.');
        let current = jsonObject;
        let line = 1;
        let column = 1;
        for (let part of pathParts) {
            if (Array.isArray(current)) {
                part = parseInt(part, 10);
            }
            if (current.hasOwnProperty(part)) {
                const jsonString = JSON.stringify(current, null, 2);
                const lines = jsonString.split('\n');
                let found = false;

                for (let i = 0; i < lines.length; i++) {
                    const index = lines[i].indexOf(`"${part}"`);
                    if (index !== -1) {
                        line += i;
                        column = index + 1;
                        found = true;
                        break;
                    }
                }
                if (!found) return { lineNumber: -1, column: -1 };
                current = current[part];
            } else {
                return { lineNumber: -1, column: -1 };
            }
        }

        return { lineNumber: line, column };
    };

    useEffect(() => {
        if (monaco && editorRef.current) {
            positionCursor(propertyPath);
        }
    }, [monaco, propertyPath]);

    return (
        <Box style={{ border: isValid ? '1px solid #ccc' : '1px solid red', borderRadius: '4px', height: '87%' }}>
            <Editor
                // height="100%"
                height="600px"
                defaultLanguage="json"
                value={jsonValue}
                theme="vs-dark"
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                options={{
                    minimap: { enabled: false },
                    scrollbar: {
                        vertical: 'auto',
                        horizontal: 'auto',
                    },
                }}
            />
        </Box>
    );
};
