import { Box, Paper, Table, TableContainer } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { CustomTableBody } from './CustomTableBody';
import { CustomTableFooter } from './CustomTableFooter';
import { CustomTableHead } from './CustomTableHead';
import { CustomTableToolbar } from './CustomTableToolbar';

//FormatHeader
// {
//   id: 'api column identifier',
//   label: 'column text',
//   type: 'type header',
//   align: 'align',
//   width: int,
//   disabled: true/false,
// },

export const CustomTable = ({
  nameItems,
  nameItem,
  valueOrder,
  keyItemSearch,
  headers,
  rows,
  initialOrderSort,
  initialOrderSortDetail,
  idRow,
  idRowDetail,
  toNavigateRow,
  toNavigateCreateItem,
  isLoadingItems,
  isSavingItems,
  isDeletingItems,
  isErrorLoad,
  messageError,
  handleDeleteItems,
  isCollapsible = false,
  headersDetails, //new
  keyDetails, //new
  isManageable = true,
  handleClickReferesh,
  rowsByPage = 5,
  elevationTable = 3,
  ...props
}) => {

  const [listRows, setListRows] = useState(rows);
  const [order, setOrder] = useState(valueOrder);
  const [orderBy, setOrderBy] = useState(initialOrderSort);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsByPage);
  const [openCollapseIdx, setOpenCollapseIdx] = useState(-1);
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listRows.length) : 0;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listRows.map((n) => n[idRow]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startOpenCollapse = (index) => {
    setOpenCollapseIdx(index);
  };

  useEffect(() => {
    setListRows(rows);
  }, [rows]);
  
  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper 
        sx={{ 
          width: '100%', 
          mb: 2,
          boxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
          WebkitBoxShadow:  (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
          bgcolor: (theme) => theme.palette.paper.background
        }}
        elevation={elevationTable}
      >
        {
          isManageable &&
            <CustomTableToolbar 
              selected={selected}
              setSelected={setSelected}
              headers={headers}
              count={listRows.length}
              keyItemSearch={keyItemSearch}
              setListRowsTable={setListRows}
              listRowsOriginal={rows}
              toNavigateCreateItem={toNavigateCreateItem}
              nameItems={nameItems}
              nameItem={nameItem}
              isSavingItems={isSavingItems}
              isDeletingItems={isDeletingItems}
              handleDeleteItems={handleDeleteItems}
              handleClickReferesh={handleClickReferesh}
              {...props}
            />
        }
        
        <TableContainer ref={elementRef}>
          <Table
            aria-labelledby="tableTitle"
            size="small"
          >
            <CustomTableHead
              headCells={headers}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={listRows?.length}
              isManageable={isManageable}
              isCollapsible={isCollapsible}
              {...props}
            />
            <CustomTableBody
              headers={headers}
              rows={listRows}
              isLoadingItems={isLoadingItems}
              isErrorLoad={isErrorLoad}
              messageError={messageError}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              isSelected={isSelected}
              idRow={idRow}
              idRowDetail={idRowDetail}
              initialOrderSortDetail={initialOrderSortDetail}
              isManageable={isManageable}
              handleClick={handleClick}
              toNavigateRow={toNavigateRow}
              emptyRows={emptyRows}
              isCollapsible={isCollapsible}
              headersDetails={headersDetails}
              keyDetails={keyDetails}
              startOpenCollapse={startOpenCollapse}
              openCollapseIdx={openCollapseIdx}
              widthParent={width}
              {...props}
            />
          </Table>
        </TableContainer>

        <CustomTableFooter
          countRows={listRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          {...props}
        />
      </Paper>
    </Box>
  )
}
