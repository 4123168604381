import React from "react";

const JsonRenderer = ({ data }) => {
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <div style={{ color: "green" }}>
          [
          {value.map((item, index) => (
            <div key={index} style={{ paddingLeft: "20px" }}>
              {renderValue(item)}
            </div>
          ))}
          ]
        </div>
      );
    } else if (typeof value === "object" && value !== null) {
      return (
        <>
          {
            <div style={{ color: "blue" }}>
              {"{"}
              {
                Object.keys(value).map((key) => {
                  if (value[key].hasOwnProperty('field_value')) {
                    return <div key={key} style={{ paddingLeft: "20px" }}>
                      <strong>{key}</strong>:<input type="button" value={'Editar'} />
                    </div>
                  }
                  else
                    return <div key={key} style={{ paddingLeft: "20px" }}>
                      <strong>{key}</strong>:{renderValue(value[key])}
                    </div>
                })
              }
              {"}"}
            </div >
          }
        </>
      );
    } else if (typeof value === "string") {
      return <span style={{ color: "red" }}>"{value}"</span>;
    } else if (typeof value === "number") {
      return <span style={{ color: "purple" }}>{value}</span>;
    } else if (typeof value === "boolean") {
      return <span style={{ color: "orange" }}>{value ? "true" : "false"}</span>;
    } else {
      return <span>{String(value)}</span>;
    }
  };

  return <div>{renderValue(data)}</div>;
};

export const ParserEditor = (data) => {
  return (
    <>
      <JsonRenderer data={data} />
    </>
  );
};
