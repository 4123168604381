import axios from 'axios';

const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;


export const fetchDataDocs = (accessToken, variables, config) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'id_user_action': config?.id_user_action ?? config.id_user_action,
            'variables': variables
        })
    })
}

export const fetchHistory = (accessToken, id) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            "process": "reception_flow_select",
            "action": "SELECT",
            "variables": `{"id_reception":${id}}`
        })
    })
}

export const updatedDataDocs = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
            // 'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            process: "flow_alter",
            action: "UPDATE_COMPONENT",
            variables: data
        }
    })
}


export const deleteDataDocs = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            procedure_name: "dbo.sp_manage_flows",
            action: "DELETE_FLOWS",
            flows: data
        }
    })
}


//#region SHARED DETAILS TABLE
export const fetchColumns = (accessToken, id) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: JSON.stringify({
            "process": "user_select",
            "action": "MENU_ID",
            "variables": JSON.stringify({ id_user_action: id })
        })
    })
}

export const fetchDetails = (accessToken, id, variables) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: JSON.stringify({
            'id_user_action': id,
            'variables': JSON.stringify(variables)
        })
    })
}
//#endregion