import styled from '@emotion/styled';
import { Check, CheckCircleOutlineOutlined, SkipNextOutlined, NavigateNextOutlined, ChevronLeftOutlined  } from '@mui/icons-material';
import { Box, Button, darken, Paper, Stack, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 15,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: darken(theme.palette.primary.main, 0.2),
        opacity: 0.9
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: darken(theme.palette.primary.main, 0.2),
        opacity: 0.9
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.grey[700],
      borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.grey[700],
    zIndex: 1,
    color: '#fff',
    width: ownerState.completed ? 30 : 35,
    height: ownerState.completed ? 30 : 35,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: darken(theme.palette.primary.main, 0.1),
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: darken(theme.palette.primary.main, 0.2),
        opacity: 0.9
    })
}));

const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;
    
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {completed ? <Check fontSize="small"/> : props.iconType}
      </ColorlibStepIconRoot>
    );
}

export const StepperCreateNewItem = ({
    isValidStep,
    steps,
    handleClickAdd,
    isAdding,
    valueInitial
}) => {

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    // const [skipped, setSkipped] = useState(new Set());
    const [valueNewItem, setValueNewItem] = useState(valueInitial);

    const handleChangeItem = (key, value) => {
        setValueNewItem(valueNewItem => ({
            ...valueNewItem,
            [key]: value
        }));
    }

    const isStepOptional = (step) => {
        return steps[step].isOptional;
    };

    // const isStepSkipped = (step) => {
    //     return skipped.has(step);
    // };

    const handleNext = () => {
        if(activeStep === steps.length-1){
            handleClickAdd(valueNewItem);
            setTimeout(() => {        
                navigate(`/flows/`)
            }, 1000);
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };  

    return (
        <Box sx={{ width: '100%', mb: 3, }}>
            <Stack sx={{ width: '100%' }} spacing={4}>
                <Stepper 
                    alternativeLabel 
                    connector={<ColorlibConnector />}
                    activeStep={activeStep}
                    sx={{mb:2, px: 4}}
                >
                    {
                        steps.map(({nameStep, iconType}, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography
                                        color={activeStep === index && "primary"}
                                        sx={{
                                            width: '100%', 
                                            textAlign: 'center', 
                                            fontSize: 9,
                                            fontWeight: activeStep === index && "bold"
                                        }} 
                                    >
                                        Optional
                                    </Typography>
                                );
                              }
                            // if (isStepSkipped(index)) {
                            //     stepProps.completed = false;
                            // }
                            return (
                                <Step key={nameStep} {...stepProps}>
                                    <StepLabel 
                                        {...labelProps}
                                        StepIconProps={{ 
                                            iconType: iconType
                                        }}
                                        StepIconComponent={ColorlibStepIcon}
                                        sx={{
                                            "&.MuiStepLabel-root .MuiStepLabel-labelContainer .Mui-completed .MuiTypography-root ": {
                                                fontSize: 13,
                                                color: (theme) => theme.palette.primary.main
                                            }
                                        }}                                  
                                    >
                                        <Typography 
                                            color={activeStep === index && "primary"}
                                            sx={{
                                                fontWeight: activeStep === index && "bold"
                                            }}
                                        >
                                            {nameStep}
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            );
                        })
                    }
                </Stepper>
            </Stack>
            <Paper 
                variant="outlined"
                square={true}
                sx={{
                    pb:2,
                    border: 0,
                    borderRadius: 4,
                    bgcolor: (theme) => theme.palette.paper.background
                }}
            >
                <>
                    <Box 
                        variant="outlined"
                        square={true}
                        sx={{
                            borderRadius: 4
                        }}
                    >
                        {React.cloneElement(steps[activeStep].contentStep, {
                            valueItem: valueNewItem,
                            handleChange: handleChangeItem
                        })}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:'center', pt: 2, justifyContent: 'center' }}>
                        {
                            activeStep > 0 && (
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ 
                                        mr: 2, 
                                        color: (theme) => theme.palette.text.main
                                    }}
                                    size="small"
                                    startIcon={<ChevronLeftOutlined/>}
                                >
                                    Back
                                </Button>
                            )
                        }                                
                        {isStepOptional(activeStep) && activeStep < steps.length-1 &&(
                            <Button 
                                color="inherit" 
                                onClick={handleSkip} 
                                sx={{ 
                                    ml: 4, 
                                    mr: 4, 
                                    color: (theme) => theme.palette.text.main
                                }}
                                variant="contained"
                                size="small"
                                endIcon={<SkipNextOutlined/>}
                            >
                                Skip
                            </Button>
                        )}
                        <LoadingButton
                            loading={isAdding}
                            onClick={handleNext}
                            disabled={!isValidStep}
                            variant="contained"
                            size="small"
                            endIcon={
                                activeStep < steps.length-1 ? (
                                    <NavigateNextOutlined/>
                                ) : (
                                    <CheckCircleOutlineOutlined/>
                                )
                            }
                        >
                            
                            {   activeStep === steps.length - 1 
                                ? 'Finish' 
                                : 'Next'
                            }
                        </LoadingButton>
                    </Box>
                </>
            </Paper>
        </Box>
    )
}
