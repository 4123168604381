import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Close, TableRows } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const EditParser = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        customFunction(dto) {
            setOpen(true);
        }
    }));

    const {
        data,
        disabled
    } = props;
    const [dataPropertys, setDataPropertys] = useState([]);
    const [propertyPath, setPropertyPath] = useState('');
    const [open, setOpen] = useState(false);

    const findKeyWithValue = useCallback((dto, targetValue, currentPath, keyParent) => {
        try {
            if (Array.isArray(dto)) {
                for (let i = 0; i < dto.length; i++) {
                    const newPath = `${currentPath}[${i}]`;
                    findKeyWithValue(dto[i], targetValue, newPath, keyParent);
                }
            }
            else if (typeof dto === 'object' && dto !== null) {
                for (const key in dto) {
                    if (dto.hasOwnProperty(key)) {
                        const newPath = `${currentPath}.${key}`;
                        findKeyWithValue(dto[key], targetValue, newPath, key);
                    }
                }
            }
            else {
                if (keyParent === targetValue) {
                    let curent = currentPath.replace(`.${keyParent}`, '');
                    const dataFields = dto.split('+');
                    for (const dataField of dataFields) {
                        setDataPropertys(prevData => [...prevData, (
                            {
                                /* eslint-disable */
                                'functions': getProperty(data, curent.replace(/[\[\]]/g, '')),
                                'name': curent.replace(/[\[\]]/g, ''),
                                /* eslint-enable */
                                'value': dataField
                            }
                        )]);
                    }
                }
            }
        } catch (ex) {
            console.log('ex', ex);
        }
    }, [data]);

    useEffect(() => {
        setDataPropertys([]);
        findKeyWithValue(data, 'field_value', '', 'Root');
    }, [data, findKeyWithValue])

    const getProperty = (obj, path) => {
        const property = path.split('.').reduce((acc, part) => acc && acc[part], obj);
        return property?.functions;
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                fullWidth
                maxWidth='sm'
                PaperProps={{
                    style: { height: '90%' },
                }}
            >
                <DialogTitle>
                    <Typography variant='h5' component='h5'>
                        Formulario parser
                    </Typography>

                    <IconButton
                        aria-label='close'
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        item
                        style={{ display: 'flex', alignItems: 'stretch' }}
                    >
                        <FormGroup
                            sx={{ my: 1 }}
                            style={{ width: '100%' }}
                            fullWidth
                            className="mt-2"
                        >
                            <TextField
                                id='field_value'
                                name='field_value'
                                size="small"
                                fullWidth
                                type='text'
                                label='field_value'
                                value={''}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormGroup>

                        <FormGroup
                            sx={{ my: 1 }}
                            style={{ width: '100%' }}
                            fullWidth
                            className="mt-2"
                        >
                            <TextField
                                id='field_value'
                                name='field_value'
                                size="small"
                                fullWidth
                                type='text'
                                label='field_value'
                                value={''}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormGroup>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button
                                onClick={() => setOpen(false)}
                                variant='contained'
                                color='primary'
                                sx={{
                                    mx: 3
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant='contained'
                                color='success'
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </Box>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
})

EditParser.displayName = 'EditParser';
export default EditParser;