import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import * as Icons from '@mui/icons-material';
import { useDispatch } from "react-redux";
import { useManageFlows } from "../../hooks/useManageFlows";
import { useManageStatus } from "../../hooks/useManageStatus";
import { useDataAuth } from "../../hooks/useDataAuth";
import { DetailsModal } from "../../screens/flows/detailsFlow/DetailsModal";
import { updateConfig } from '../../redux/actions/flows';
import { useNotification } from '../../hooks/useNotification';


export const Configuration = ({ item, data }) => {
    const dispatch = useDispatch();
    const [flowManager] = useManageFlows();
    const [statusManager] = useManageStatus();
    const [{ userSession }] = useDataAuth();
    const [modalData, setModalData] = useState(null);
    const [sendNotification] = useNotification();

    // const [selectedConfig, setSelectedConfig] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const {
        getAllConfig,
        configFlowData
    } = flowManager;

    const updateConfiguration = (dto) => {
        dispatch(updateConfig(data.id_flow, dto, userSession.token));
        setTimeout(() => {
            sendNotification({ msg: 'Registro actualizado con exito!', variant: 'succes' });
            setModalOpen(false);
        }, 300);
    };

    const handleOpenModal = () => {
        console.log("data44 ", data)
        // setSelectedConfig(data.id_flow);
        dispatch(getAllConfig(data.id_flow, userSession.token));

        setTimeout(() => {
            setModalOpen(true);
        }, 300);
    };

    const handleCloseModal = () => {
        // setModalData([]);
        setModalOpen(false);
    };

    // useEffect(() => {
    //     if (configFlowData) {
    //         console.log('Se ejecuto ');
    //         setModalData(configFlowData);
    //     }
    // }, [configFlowData]);


    return (
        <>
            <Tooltip title={"Ver configuración"}>
                <IconButton
                    id={`id_${item.accion}`}
                    size="small"
                    sx={{ p: 0 }}
                    onClick={handleOpenModal}
                >
                    <span style={{ fontSize: 25, color: item.color }}>
                        {React.createElement(Icons.Settings)}
                    </span>
                </IconButton>
            </Tooltip>

            {configFlowData &&
                <DetailsModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    // flowManager={flowManager}
                    statusManager={statusManager}
                    updateConfiguration={updateConfiguration}
                // data={modalData}
                />
            }
        </>
    );
};

