import { Add, Delete, FilterList, Refresh, Search, SearchRounded, ViewColumn } from '@mui/icons-material';
import { Box, Grid, IconButton, InputAdornment, Stack, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from '../../../hooks/useForm';
import { Link } from 'react-router-dom';
import { PopoverOptionsList } from '../PopoverOptionsList';
import { CustomTableFilter } from './CustomTableFilter';

export const CustomTableToolbar = ({
  selected,
  setSelected,
  headers,
  count,
  keyItemSearch,
  setListRowsTable,
  listRowsOriginal,
  toNavigateCreateItem,
  nameItems,
  nameItem,
  isSavingItems,
  isDeletingItems,
  handleDeleteItems,
  handleClickReferesh
}) => {

  const [hideFilterPopover, setHideFilterPopover] = useState(false);
  const [isVisibleSearch, setIsVisibleSearch] = useState(false);
  const [copyList, setCopyList] = useState(listRowsOriginal)
  const numSelected = selected.length;
  const [formValues, handleInputChange] = useForm({
    search: ""
  });
  const {search} = formValues;

  useEffect(() => {
    if(!search){
      setListRowsTable(listRowsOriginal);
    }else{
      setListRowsTable(copyList);
    }
  }, [copyList]);
  
  useEffect(() => {
    requestSearch(search);
  }, [search]);
  
  const handleDelete = () => {
    handleDeleteItems(selected);
    setSelected([]);
  }

  const requestSearch = (searched) => {
    setCopyList(listRowsOriginal.filter((item) => {
      return item[keyItemSearch]?.toLowerCase().includes(searched);
    }))
  }

  const filterPopoverExit = () => {
    setHideFilterPopover(false);
    
    this.setActiveIcon();
  };

  const closeFilterPopover = () => {
    setHideFilterPopover(true);
    this.setState({ hideFilterPopover: true });
  };

  return (
    <Toolbar
      sx={{
        py: 2
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid 
            item
            md={3}
          >
            <Typography
                sx={{ flex: '1 1 100%' }}
                color="text.main"
                id="tableTitle"
                component="span"
            >
              {`${nameItems} `}
            </Typography>
            {numSelected > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="text.main"
                component="span"
              >
                ({`${numSelected}/${count}`})
              </Typography>
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="gray"
                component="span"
              >
                ({`${count}`})
              </Typography>
            )}
          </Grid>
          <Grid 
            item
            md={9}
            display="flex"
            justifyContent="flex-end"
          >
            <Stack direction="row" spacing={0.5}>
              
              <Tooltip title="Search">
                <IconButton
                  onClick={() => setIsVisibleSearch(!isVisibleSearch)}
                  size="small"
                >
                  <Search fontSize="medium" color="primary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Columns">
                <IconButton>
                  <ViewColumn color="primary" />
                </IconButton>
              </Tooltip>
              
              {/* <PopoverOptionsList
                refExit={filterPopoverExit}
                hide={hideFilterPopover}
                trigger={
                  <Tooltip title="Filter List" disableFocusListener>
                    <IconButton
                      data-testid={"Filter List-iconButton"}
                      aria-label={"Filter List"}
                      // onClick={setActiveIcon.bind(null, 'filter')}
                    >
                      <FilterList color="primary" />
                    </IconButton>
                  </Tooltip>
                }
                content={
                  <CustomTableFilter
                    customFooter={options.customFilterDialogFooter}
                    columns={headers}
                    options={options}
                    filterList={filterList}
                    filterData={filterData}
                    onFilterUpdate={filterUpdate}
                    onFilterReset={resetFilters}
                    handleClose={closeFilterPopover}
                    updateFilterByType={updateFilterByType}
                    components={components}
                  />
                }
              /> */}
              <Tooltip title="Filter List" disableFocusListener>
                <IconButton
                  data-testid={"Filter List-iconButton"}
                  aria-label={"Filter List"}
                  // onClick={setActiveIcon.bind(null, 'filter')}
                >
                  <FilterList color="primary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Refresh">
                  <IconButton
                      onClick={handleClickReferesh}
                  >
                      <Refresh color="primary" fontSize="inherit"/>
                  </IconButton>
              </Tooltip>  

              {numSelected > 0 && (
                <LoadingButton
                  loading={isDeletingItems}
                  loadingPosition="start"
                  startIcon={<Delete />}                  
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={handleDelete}
                >
                  Delete {nameItem}(s)
                </LoadingButton>
              )}                    
              <LoadingButton
                loading={false}
                loadingPosition="start"
                startIcon={<Add />}
                size="small"
                variant="contained"
                color="primary"
                component={Link}
                to={toNavigateCreateItem}
              >
                  Create new {nameItem}
              </LoadingButton>
            </Stack>
          </Grid>
          
          <Grid 
            item
            md={8}
            sx={{pt: 2, pb: 1}}
          > {
              isVisibleSearch && 
                <TextField        
                  variant="outlined"
                  size="small"
                  label="Buscar"
                  name="search"
                  type="search"
                  placeholder="Search"
                  value={search}
                  onChange={handleInputChange}
                  fullWidth
                  color="text"
                  InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                              <SearchRounded color="text"/>
                          </InputAdornment>
                      ),
                  }}
                />              
            }
            
          </Grid>
        </Grid>
      </Box>

      

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Delete />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterList />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}
