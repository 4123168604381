import { useSelector } from 'react-redux';
import {
    getAllPermissions, deletePermissions, getCompaniesAcces, getGroupsAcces, addPermissions, updateUser, saveUser, loadData
} from '../redux/actions/users';
import { RecoverPassword } from '../redux/actions/auth';
import { useDispatch } from 'react-redux';
import { actionTypes } from "../redux/contants/actionTypes";

export const useManageUsers = () => {
    const dispatch = useDispatch();
    const {
        listAllUsers,
        isLoading,
        isLoadingPermissions,
        listAllPermissions,
        listCompaniesPermissions,
        listGroupPermissions,
        isNotification,
        msg,
        typeMsg
    } = useSelector(state => state.users);

    const DeletePermission = (accessToken, id, isCreate) => {
        let listDto = listAllPermissions.filter(it => it.id_user_access !== id);
        if (!isCreate) {
            dispatch(deletePermissions(accessToken, id));
            setTimeout(() => {
                dispatch(loadData(actionTypes.loadAllPermissions, listDto));
            }, 500);
        }
        else {
            dispatch(loadData(actionTypes.loadAllPermissions, listDto));
        }
    }

    const AddPermissions = (accessToken, dto, id, isCreate) => {
        if (!isCreate) {
            dispatch(addPermissions(accessToken, dto));
            setTimeout(() => {
                dispatch(getAllPermissions(accessToken, id));
            }, 500);
        }
        else {
            dto['company_name'] = listCompaniesPermissions.find(it => it.id_company === dto.id_company)?.name
            dto['name'] = listGroupPermissions.find(it => it.id_user_group_access === dto.id_access)?.name
            dto['permission_type'] = 'group';
            dto['id_user_access'] = Math.random();
            let listDto = listAllPermissions;
            listDto.push(dto);
        }
    }

    const SaveData = (accessToken, dto, isCreate) => {
        if (!isCreate) {
            const userToUpdate = listAllUsers.find(user => user.id_user === dto.id_user);
            if (userToUpdate) {
                userToUpdate.last_name = dto.last_name;
                userToUpdate.name = dto.name;
                userToUpdate.status = dto.status;
            }
            dispatch(updateUser(accessToken, dto));
        }
        else {
            dto.access = listAllPermissions;
            dispatch(saveUser(accessToken, dto));
            setTimeout(() => {
                dispatch(RecoverPassword(dto.user_name));
            }, 500);
        }
    }

    return [{
        listAllUsers,
        isLoading,
        getAllPermissions,
        DeletePermission,
        getCompaniesAcces,
        getGroupsAcces,
        AddPermissions,
        SaveData,
        loadData,
        isLoadingPermissions,
        listAllPermissions,
        listCompaniesPermissions,
        listGroupPermissions,
        isNotification,
        msg,
        typeMsg
    }];
}