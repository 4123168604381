import axios from 'axios';

const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;

export const fetchDataMenu = (accessToken) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_select',
            'action': 'MENU',
            'variables': '{}'
        })
    })
}