import React from 'react';
import { CronExpression } from '../../../../components/cronExpression/CronExpression';

export const StepCronExpression = ({
  valueItem,
  handleChange
}) => {

  

  return (
    <CronExpression
        cronExpression={valueItem.cron_expression}
        handleChange={(value) => handleChange('cron_expression', value)}
    />
  )
}
