import { actionTypes } from "../contants/actionTypes";

const initialState = {
    listAllStatus: [],
    loadingStatus: false,
    updatingStatus: false,
    deletingStatus: false,
    addingNewStatus: false,
    isErrorFetch: false,
    messageErrorFetch: ''
}

export const statusReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.staStartLoadingStatus:
            return {
                ...state,
                loadingStatus: true
            }
        case actionTypes.staFinishLoadingStatus:
            return {
                ...state,
                loadingStatus: false
            }
        case actionTypes.staLoadStatus:
            return {
                ...state,
                listAllStatus: action.payload
            }
        case actionTypes.staStartUpdatingStatus:
            return {
                ...state,
                updatingStatus: true
            }
        case actionTypes.staFinishUpdatingStatus:
            return {
                ...state,
                updatingStatus: false
            }
        case actionTypes.staStartDeletingStatus:
            return {
                ...state,
                deletingStatus: true
            }
        case actionTypes.staFinishDeletingStatus:
            return {
                ...state,
                deletingStatus: false
            }
        case actionTypes.staStartAddingStatus:
            return {
                ...state,
                addingNewStatus: true
            }
        case actionTypes.staFinishAddingStatus:
            return {
                ...state,
                addingNewStatus: false
            }
        case actionTypes.staErrorStatus:
            return {
                ...state,
                isErrorFetch: action.payload.error,
                messageErrorFetch: action.payload.messageError
            }
        default:
            return state;
    }

}