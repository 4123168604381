import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import { alpha, Box, Grid, IconButton, List, ListItem, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TreeItemListArray } from './keysType/TreeItemListArray';
import { TreeItemString } from './keysType/TreeItemString';
import { TreeItemNumber } from './keysType/TreeItemNumber';
import { TreeItemObject } from './keysType/TreeItemObject';
import { TreeItemBoolean } from './keysType/TreeItemBoolean';
import { TreeItemNullUndefined } from './keysType/TreeItemNullUndefined';
import { ModalEditor } from '../modalEditor/ModalEditor'
import deleteChild from '../modalEditor/utils/deleteChild';

export const JsonTree = ({
    keyParent,
    contentJson,
    handleChange
}) => {

    const indentation = 1;
    const [obj, setObj] = useState(contentJson);
    const [showActions, setShowActions] = useState(false);
    const [isCollapse, setIsCollapse] = useState(true);

    const handleChangeDeleteObject = (path) => {
        const newObj = deleteChild(obj, path);
        setObj(newObj);
        // handleChange('param', newObj);
        handleChange(newObj);
    };

    useEffect(() => {
        setObj(contentJson);
    }, [contentJson]);

    return (
        <Box>
            <List>
                <ListItem
                    disableGutters
                    sx={{
                        py: 0.3,
                        marginLeft: 0.5
                    }}
                >
                    <Grid container >
                        <Grid
                            item
                            md={12}
                            onMouseEnter={() => setShowActions(true)}
                            onMouseLeave={() => setShowActions(false)}
                            alignItems="center"
                            display="inline-flex"
                            justifyContent="flex-start"
                        >
                            <Box
                                alignItems="center"
                                display="inline-flex"
                                justifyContent="flex-end"
                            >
                                <IconButton
                                    sx={{ p: 0 }}
                                    onClick={() => setIsCollapse(!isCollapse)}
                                >
                                    {
                                        isCollapse ? <ArrowRight
                                            sx={{ fontSize: 17 }}
                                            color="disabled"
                                        />
                                            : <ArrowDropDown
                                                sx={{ fontSize: 17 }}
                                                color="disabled"
                                            />
                                    }
                                </IconButton>
                            </Box>
                            <Box
                                alignItems="center"
                                display="inline-flex"
                                justifyContent="flex-start"
                            >
                                <Typography
                                    component="span"
                                    variant="console"
                                    color="primary"
                                >
                                    {`${keyParent}:`}
                                </Typography>
                                <Typography
                                    component="span"
                                    variant="console"
                                    sx={{ fontSize: 12, cursor: 'pointer', px: 0.5 }}
                                    onClick={() => setIsCollapse(!isCollapse)}
                                >
                                    {
                                        !isCollapse && '{...}'
                                    }
                                </Typography>
                                <Typography
                                    component="span"
                                    color="darkgray"
                                    variant="console"
                                    sx={{ fontSize: 12, px: 0.5 }}
                                >
                                    {`${Object.keys(obj).length} items`}
                                </Typography>
                            </Box>
                            <Box>
                                {
                                    showActions && (
                                        <Stack direction="row" spacing={1}>
                                            <ModalEditor
                                                arrayKeysPath={[keyParent, '']}
                                                isEditorAdd={true}
                                                isEditorEdit={false}
                                                value={{
                                                    [keyParent]: obj
                                                }}
                                                onChange={(newObject) => handleChange(newObject[keyParent])}
                                            />
                                        </Stack>
                                    )
                                }
                            </Box>
                        </Grid>
                        {
                            isCollapse && (
                                <Grid
                                    item
                                    md={12}
                                    sx={{ ml: 1 }}
                                >
                                    <List
                                        sx={{
                                            p: 0,
                                            pl: 1.1,
                                            borderLeft: (theme) => `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
                                        }}
                                    >
                                        {
                                            Object.entries(obj).map(([key, value], index) => {

                                                if (Array.isArray(value)) {
                                                    return <TreeItemListArray
                                                        key={index}
                                                        value={obj}
                                                        indentation={indentation}
                                                        keyItem={key}
                                                        valueItem={value}
                                                        arrayKeysPath={[]}
                                                        onChange={handleChange}
                                                        onDelete={handleChangeDeleteObject}
                                                    />;
                                                } else {
                                                    switch (typeof (value)) {
                                                        case 'object':
                                                            return <TreeItemObject
                                                                key={index}
                                                                value={obj}
                                                                indentation={indentation}
                                                                keyItem={key}
                                                                valueItem={value}
                                                                arrayKeysPath={[]}
                                                                onChange={handleChange}
                                                                onDelete={handleChangeDeleteObject}
                                                            />;
                                                        case 'string':
                                                            return <TreeItemString
                                                                key={index}
                                                                value={obj}
                                                                indentation={indentation}
                                                                keyItem={key}
                                                                valueItem={value}
                                                                arrayKeysPath={[]}
                                                                onChange={handleChange}
                                                                onDelete={handleChangeDeleteObject}
                                                            />;
                                                        case 'number':
                                                            return <TreeItemNumber
                                                                key={index}
                                                                value={obj}
                                                                indentation={indentation}
                                                                keyItem={key}
                                                                valueItem={value}
                                                                arrayKeysPath={[]}
                                                                onChange={handleChange}
                                                                onDelete={handleChangeDeleteObject}
                                                            />;
                                                        case 'boolean':
                                                            return <TreeItemBoolean
                                                                key={index}
                                                                value={obj}
                                                                indentation={indentation}
                                                                keyItem={key}
                                                                valueItem={value}
                                                                arrayKeysPath={[]}
                                                                onChange={handleChange}
                                                                onDelete={handleChangeDeleteObject}
                                                            />;
                                                        default:
                                                            return <TreeItemNullUndefined
                                                                key={index}
                                                                value={obj}
                                                                indentation={indentation}
                                                                keyItem={key}
                                                                valueItem={value}
                                                                arrayKeysPath={[]}
                                                                onChange={handleChange}
                                                                onDelete={handleChangeDeleteObject}
                                                            />
                                                    }
                                                }
                                            })
                                        }
                                    </List>
                                </Grid>
                            )
                        }
                        {
                            isCollapse &&
                            <Grid item md={12}>
                                <Typography
                                    component="span"
                                    variant="console"
                                    sx={{ fontSize: 12, ml: 1 }}
                                    onClick={() => setIsCollapse(!isCollapse)}
                                >
                                    {'}'}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </ListItem>
            </List>
        </Box>
    )
}
