import { config } from '../helpers/config';

export const fetchDataGeneralSettingsFlowsByFile = (keyVariableSettings, accessToken) => {
    return new Promise((resolve, reject) =>{
        setTimeout(() => {
            resolve({
                data: {
                    result: !config.flowVariables[keyVariableSettings] ? [] : config.flowVariables[keyVariableSettings]
                }
            });
        }, 1500);
    })
}


