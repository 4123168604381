import React, { useState } from 'react';
import { Button, TextField, FormGroup, Box, Grid, IconButton, Switch, FormControlLabel, MenuItem } from '@mui/material';
import { config } from '../../../../src/helpers/config';
// import typeFromValue from '../../utils/typeFromValue';
// import valueFromType from '../../utils/valueFromType';
// import { ArrayEditor } from './ArrayEditor';
// import { BooleanEditor } from './BooleanEditor';
// import { NumberEditor } from './NumberEditor';
// import { ObjectEditor } from './ObjectEditor';
// import { StringEditor } from './StringEditor';

const types = [
    { name: 'And', value: 'and' },
    { name: 'Or', value: 'or' },
];

const typeByValue = types.reduce((acc, item) => {
    acc[item.value] = item;
    return acc;
}, {});

export function Control({ }) {
    const [formData, setFormData] = useState({});
    var schema = config.schemes.find(s => s.type === 'sql').scheme

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // onSubmit(formData);
    };
    //   const updateValue = (type) => {
    //     onChange(valueFromType(type));
    //   };

    //   const valueType = typeFromValue(value);

    //   const valueEditor = (() => {
    //     switch (valueType) {
    //       case 'number':
    //         return <Grid item md={4} sx={{px:1}}>
    //           <NumberEditor label="Value" value={value} onChange={onChange} />
    //         </Grid>;
    //       case 'string':
    //         return <Grid item md={4} sx={{px:1}}>
    //           <StringEditor label="Value" value={value} onChange={onChange} />
    //         </Grid>;
    //       case 'boolean':
    //         return <Grid item md={4} sx={{px:1}}>
    //           <BooleanEditor label="Value" value={value} onChange={onChange} />
    //         </Grid>;
    //       case 'array':
    //         return (
    //           <Grid item md={12}>
    //               <Stack
    //                 display="flex"
    //                 direction="column"
    //                 justifyContent="flex-start"
    //                 alignItems="flex-start"
    //                 width="100%"
    //                 flex="1"
    //                 spacing={1}
    //                 sx={{px: 1, pt: 2}}
    //               >
    //                 <InputLabel>Items ({(value).length})</InputLabel>

    //                 <Paper elevation={3} sx={{width: '100%'}}>
    //                   <ArrayEditor
    //                     newItemValue={newArrayItemValue}
    //                     onEdit={onEdit}
    //                     value={value}
    //                     onChange={onChange}
    //                   />
    //                 </Paper>
    //               </Stack>
    //           </Grid>
    //         );
    //       case 'object':
    //         return (
    //           <Grid item md={12}>
    //             <Stack
    //               display="flex"
    //               direction="column"
    //               justifyContent="flex-start"
    //               alignItems="flex-start"
    //               width="100%"
    //               flex="1"
    //               spacing={1}
    //               sx={{px: 1, pt: 2}}
    //             >
    //               <InputLabel>Properties ({Object.keys(value).length})</InputLabel>

    //               <Paper
    //                 sx={{
    //                   width: '100%',
    //                   bgcolor: (theme) => theme.palette.paper.background
    //                 }}
    //               >
    //                 <ObjectEditor
    //                   newItemValue={newObjectItemValue}
    //                   newItemKey={newObjectItemKey}
    //                   value={value}
    //                   onEdit={onEdit}
    //                   onChange={onChange}
    //                 />
    //               </Paper>
    //             </Stack>
    //           </Grid>
    //         );
    //       default:
    //         return '';
    //     }
    //   })();

    return (
        <Grid container>
            <form onSubmit={handleSubmit}>
                <div className="form-row">
                    <Grid item md={3} sx={{ px: 1 }}>
                        <FormGroup sx={{ my: 2 }}>
                            <TextField
                                select
                                label="Type"
                                size="small"
                                fullWidth
                            //   value={typeByValue[valueType].value}
                            //   onChange={(e) => updateValue(e.target.value)}
                            >
                                {types.map((typeObj) => (
                                    <MenuItem key={typeObj.value} value={typeObj.value}>
                                        {typeObj.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormGroup>
                    </Grid>
                    <Grid item md={3} sx={{ px: 1 }}>
                        <FormGroup sx={{ my: 2 }}>
                            <TextField
                                id='id2'
                                size="small"
                                className="mt-2"
                                fullWidth
                                label='value2'
                                // value={formData[field.id] || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                            />
                            {/* )} */}
                        </FormGroup>
                    </Grid>
                    <Grid item md={3} sx={{ px: 1 }}>
                        <FormGroup sx={{ my: 2 }}>

                            <TextField
                                select
                                label="Type"
                                size="small"
                                fullWidth
                            //   value={typeByValue[valueType].value}
                            //   onChange={(e) => updateValue(e.target.value)}
                            >
                                {types.map((typeObj) => (
                                    <MenuItem key={typeObj.value} value={typeObj.value}>
                                        {typeObj.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormGroup>
                    </Grid>
                    <Grid item md={3} sx={{ px: 1 }}>
                        <FormGroup sx={{ my: 2 }}>
                            <TextField
                                id='id2'
                                size="small"
                                className="mt-2"
                                fullWidth
                                label='value2'
                                // value={formData[field.id] || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={handleChange}
                            />
                            {/* )} */}
                        </FormGroup>
                    </Grid>
                </div>
            </form>
        </Grid>
    );
}
