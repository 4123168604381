import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { labelCronExpression } from './utils/labelCronExpression';
import { useForm } from '../../hooks/useForm';

export const CronMinutes = ({setValue, value}) => {
    
    const [listChecked, setListChecked] = useState(value.split(',').length > 1 ? value.split(',').map((x, i) => (parseInt(x))) : !isNaN(value) ? [parseInt(value)] : []);
    
    const [formValues, handleSelectChange] = useForm({
        selectEveryMinutes: value.split('/').length > 1 ? value.split('/')[1] : 1,
        selectStartMinutes: value.split('/').length > 1 ? (value.split('/')[0] < 10 ? `0${value.split('/')[0]}` : value.split('/')[0]) : '00',
        selectBetweenMinute1: value.split('-').length > 1 ? (value.split('-')[0] < 10 ? `0${value.split('-')[0]}` : value.split('-')[0]) : '00',
        selectBetweenMinute2: value.split('-').length > 1 ? (value.split('-')[1] < 10 ? `0${value.split('-')[1]}` : value.split('-')[1]) : '01'
    });
    const {selectEveryMinutes, selectStartMinutes, selectBetweenMinute1, selectBetweenMinute2} = formValues;

    const [valueRadio, setValueRadio] = useState('1');
    useEffect(() => {
        if(value === '*'){setValueRadio('1');}
        if(value.split(',').length > 1 || !isNaN(value)){setValueRadio('3');}
        if(value.split('/').length > 1){setValueRadio('2');}
        if(value.split('-').length > 1 ){setValueRadio('4');}
    }, [])
        
    
    useEffect(() => {
        if(valueRadio === '2'){
            setValue(`${parseInt(selectStartMinutes)}/${selectEveryMinutes}`);
        }
    }, [selectEveryMinutes, selectStartMinutes])
    
    useEffect(() => {
        if(valueRadio === '3'){
            setValue(listChecked.length === 0 ? '0' : listChecked.sort((a,b)=>a-b).toString());
        }
    }, [listChecked])

    useEffect(() => {
        if(valueRadio === '4'){
            if(parseInt(selectBetweenMinute1) >= parseInt(selectBetweenMinute2)){
                if(parseInt(selectBetweenMinute1) === 59){
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenMinute1',
                            value: '58'
                        }
                    });
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenMinute2',
                            value: '59'
                        }
                    });
                }else{
                    const value = parseInt(selectBetweenMinute1)+1;
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenMinute2',
                            value: value < 10 ? `0${value}` : `${value}`
                        }
                    })
                }
            } else{
                setValue(`${parseInt(selectBetweenMinute1)}-${parseInt(selectBetweenMinute2)}`);
            }
        }
    }, [selectBetweenMinute1, selectBetweenMinute2])

    const handleChange = (e) => {
        setValueRadio(e.target.value);
        if(e.target.value === '1'){
            setValue('*');
        }else if(e.target.value === '2'){
            setValue(`${parseInt(selectStartMinutes)}/${selectEveryMinutes}`);
        }else if(e.target.value === '4'){
            setValue(`${parseInt(selectBetweenMinute1)}-${parseInt(selectBetweenMinute2)}`);
        }else{
            setValue('0');
        }
        handleReset();
    }

    const handleChangeCkecked = (e) => {
        if(!listChecked.includes(parseInt(e.target.name))){
            setListChecked([...listChecked, parseInt(e.target.name)])
        }else{
            setListChecked(listChecked.filter((x, i) => x !== parseInt(e.target.name)))
        }
    }

    const handleReset = () => {
        setListChecked([])
    }

    return (
        <Paper 
            elevation={3} 
            sx={{
                px: '4%', 
                height: '100%', 
                pb: '10%',
                bgcolor: (theme) => theme.palette.paper.background
            }}
        >
            <FormControl>
                <RadioGroup
                    aria-labelledby="minutes-radio-buttons-group-label"
                    value={valueRadio}
                    onChange={handleChange} 
                    name="radio-buttons-group"
                >
                    {/*Todos los minutos*/}
                    <FormControlLabel 
                        value="1"
                        control={<Radio size="small"/>} 
                        label="Todos los minutos"
                    />

                    {/*Apartir del*/}                    
                    <FormControlLabel 
                        value="2"
                        control={<Radio size="small"/>}
                        sx={{mt: 2}}
                        label={
                            <Box 
                                display="flex"
                                alignItems="center" 
                                justifyContent="start"
                            >
                                <Typography>
                                    {'Cada\u00A0'}
                                </Typography>
                                <FormControl 
                                    variant="outlined" 
                                    sx={{ m: 0}}
                                >
                                    <Select
                                        value={selectEveryMinutes}
                                        size="small"
                                        name="selectEveryMinutes"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "2"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'minutes')
                                                .minutes.map((x, i) => {
                                                return (
                                                    <MenuItem key={i} value={x}>{x}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography>
                                    {'\u00A0 minuto(s), a partir del minuto \u00A0'}
                                </Typography>
                                <FormControl variant="outlined" sx={{ m: 0}}>
                                    <Select
                                        value={selectStartMinutes}
                                        size="small"
                                        name="selectStartMinutes"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "2"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'minutes')
                                                .minutesLabel.map((x, i) => {
                                                return (
                                                    <MenuItem key={i}  value={x}>{x}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />

                    {/*Minutos Especificos*/}
                    <FormControlLabel 
                        value="3"
                        control={<Radio size="small" sx={{pt: 0}}/>} 
                        sx={{
                            mt: 2,
                            alignItems: 'flex-start'
                        }}
                        label={
                            <Box>
                                <Typography>
                                    Minutos especificos (selecciona uno o varios)
                                </Typography>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'minutes')
                                                .minutesLabel.map((x, i) => {
                                                return (
                                                    <FormControlLabel
                                                        key={i} 
                                                        control={
                                                            <Checkbox 
                                                                checked={
                                                                    listChecked.includes(parseInt(x))
                                                                } 
                                                                size="small" 
                                                                name={x}
                                                                onChange={handleChangeCkecked}
                                                                disabled={valueRadio !== "3"}
                                                            />
                                                        }
                                                        label={x}
                                                        value={x}
                                                    />
                                                )
                                            })
                                        }                                
                                    </FormGroup>
                                </FormControl>
                            </Box>   
                        }
                    />

                    {/*Minutos Entre*/}
                    <FormControlLabel 
                        value="4"
                        control={<Radio size="small"/>} 
                        sx={{mt: 2}}
                        label={
                            <Box
                                display="flex"
                                alignItems="center" 
                                justifyContent="start"
                            >
                                <Typography>
                                    {'Todos los minutos entre el minuto \u00A0'}
                                </Typography>
                                <FormControl 
                                    variant="outlined" 
                                    sx={{ m: 0}}
                                >
                                    <Select
                                        value={selectBetweenMinute1}
                                        size="small"
                                        name="selectBetweenMinute1"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "4"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'minutes')
                                                .minutesLabel.map((x, i) => {
                                                return (
                                                    <MenuItem key={i} value={x}>{x}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography>
                                    {'\u00A0 y el minuto \u00A0'}
                                </Typography>
                                <FormControl variant="outlined" sx={{ m: 0}}>
                                    <Select
                                        value={selectBetweenMinute2}
                                        size="small"
                                        name="selectBetweenMinute2"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "4"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'minutes')
                                                .minutesLabel.map((x, i) => {
                                                return (
                                                    <MenuItem 
                                                        key={i} 
                                                        value={x}
                                                        disabled={
                                                            parseInt(selectBetweenMinute1) >= parseInt(x)
                                                        }
                                                    >
                                                        {x}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />

                </RadioGroup>
            </FormControl>
        </Paper>
  )
}
