import { Delete, ExpandMore, MoreVert, SchemaOutlined, SyncAltOutlined } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Grid, IconButton, InputAdornment, InputLabel, lighten, Menu, MenuItem, TextField, Toolbar, Typography } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { config } from '../../../../helpers/config';
import { useDataAuth } from '../../../../hooks/useDataAuth';
import { EdiItemTabs } from '../../../../components/editItem/EdiItemTabs';
import { JsonEditorV2 } from '../../../../components/jsonEditorV2/JsonEditorV2';
import { ListDragDrop } from '../../../../components/lists/ListDragDrop';
import { Dialog } from '../../../../components/others/Dialog';
import { Control } from '../../../../components/jsonEditorV2/control/control';
import { ItemAccordionListSubComponentFlow } from './ItemAccordionListSubComponentFlow';
import DynamicForm from '../../../../components/dynamicForm/DynamicForm';

export const ItemAccordionListComponentFlow = ({
    componentFlow,
    handleDeleteComponentFlow,
    expanded,
    handleChangeExpanded,
    handleChange
}) => {

    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
    const [expandedSubComponentFlows, setExpandedSubComponentFlows] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [typeFlow, setTypeFlow] = useState();
    const open = Boolean(anchorEl);
    const [{username}]  = useDataAuth();

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmDeleteComponentFlow = () => {
        handleDeleteComponentFlow(componentFlow.id_flow_component);
        setOpenDialogConfirmDelete(false);
    }


    const handleClickNewSubComponentFlow = () =>{
        const newSubComponentFlowObject = {
            is_new_component: true,      
            id_flow_component: `NSC-${componentFlow.sub_flow_components.length+1}`,
            type: "",     
            order_in_flow_component: componentFlow.sub_flow_components.length+1,
            type_execute: "",
            process_input: {},
            acceptance_process: {},
            process_fail: {},
            created: moment().toDate(),
            created_by: username,
            updated: moment().toDate(),
            updated_by: username
        };
        handleChange(componentFlow.id_flow_component, "sub_flow_components", [
            ...componentFlow.sub_flow_components,
            newSubComponentFlowObject
        ])
    }

    const handleChangeOrderSubComponentFlows= (newArraySubComponentFlows) => {
        handleChange(componentFlow.id_flow_component, "sub_flow_components", newArraySubComponentFlows);
    }

    const handleDeleteSubComponentFlow = (idSubComponentFlow) => {
        const newFilterArrayComponent = componentFlow.sub_flow_components.filter(subComponentFlow => subComponentFlow.id_flow_component !== idSubComponentFlow);
        const newArrayComponent = newFilterArrayComponent?.map((subComponentFlow, index) => (
        {
            ...subComponentFlow,
            id_flow_component: `NSC-${index+1}`,
            order_in_flow: index+1
        }
        ));
        handleChange(componentFlow.id_flow_component, "sub_flow_components", newArrayComponent);
    }

    const handleChangeExpandedSubComponentFlow = (panel) => (event, isExpanded) => {
        setExpandedSubComponentFlows(isExpanded ? panel : false);
    };

    const handleChangeSubComponentFlow = (idSubComponentFlow, name, value) => {
        if(componentFlow.sub_flow_components.length > 0){
            handleChange(componentFlow.id_flow_component, "sub_flow_components", 
                componentFlow.sub_flow_components.map(subComponentFlow => {
                    if(subComponentFlow.id_flow_component === idSubComponentFlow){
                        return {
                            ...subComponentFlow,
                            [name]: value
                        }
                    }else{
                        return subComponentFlow;
                    }
                })
            );
        }
    }

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        console.log("componentFlow.type __ ", componentFlow);
    }, [componentFlow]);

    return (
        <Accordion 
            expanded={expanded === `panel${componentFlow.order_in_flow}`} 
            onChange={handleChangeExpanded(`panel${componentFlow.order_in_flow}`)}
        >
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls={`panel${componentFlow.order_in_flow}bh-content`}
                id={`panel${componentFlow.order_in_flow}bh-header`}
                sx={{
                    flexDirection: 'row-reverse',
                    bgcolor: (theme) => lighten(theme.palette.paper.background, 0.090)
                }}
            >
                <Box sx={{width: '100%'}}>
                    <Toolbar variant="dense">
                        <Avatar
                            sizes="small"
                            sx={{
                                mr: 2, 
                                bgcolor: (theme) => theme.palette.primary.main
                            }}
                        >
                            {}
                            {/* {componentFlow.order_in_flow}  */}
                        </Avatar>
                        <Box>
                            <Typography 
                                color="text.main" 
                                sx={{fontWeight: 'bold'}}
                            >
                                {`Id Flow Component: ${componentFlow.id_flow_component}`}
                            </Typography>
                            {
                                (componentFlow.type && expanded === `panel${componentFlow.id_flow_component}`) && 
                                <Typography 
                                    color="primary" 
                                    component="small"
                                    sx={{fontSize: 11}}
                                >
                                    {`Type Component: ${componentFlow.type}`}
                                </Typography>
                            }
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ 
                            display: { 
                                xs: 'none',
                                md: 'flex'
                            } 
                        }}>
                            <IconButton 
                                size="small" 
                                color="inherit"
                                onClick={handleClick}
                                aria-controls={open ? 'component-flow-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <MoreVert />
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="component-flow-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                            elevation: 3,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem
                                onClick={() => setOpenDialogConfirmDelete(true)}
                            >
                                <Delete /> Eliminar component flow
                            </MenuItem>
                        </Menu>
                        <Dialog
                            openDialog={openDialogConfirmDelete}
                            widthDialog="xs"
                            content={
                                <Typography
                                    variant="h7"
                                >
                                    Are you sure you want to remove this component?
                                </Typography>
                            }
                            styleContent={{px: 3, py: 3}}
                            actions={
                                <Grid
                                    item md={12} 
                                    sx={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        py: 2
                                    }}
                                    >
                                    <Button
                                        variant="outlined" 
                                        color="error" 
                                        autoFocus
                                        size="small"
                                        onClick={() => setOpenDialogConfirmDelete(false)}
                                        sx={{
                                            color: (theme) => theme.palette.text.main,
                                            mx: 3
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        color="error"
                                        onClick={handleConfirmDeleteComponentFlow}
                                        size="small"
                                    >
                                        Delete component flow
                                    </Button>
                                </Grid>
                            }
                        />
                    </Toolbar>
                </Box>
            </AccordionSummary>
            <AccordionDetails 
                sx={{
                    p:0, 
                    bgcolor: (theme) => lighten(theme.palette.paper.background, 0.090)
                }}
            >
                <Box          
                    noValidate
                    autoComplete="off"
                >
                    <Grid 
                        container
                        sx={{px: 4, mb: 3}}
                    >
                        <Grid item md={6} sx={{pr: 1}}>
                            <InputLabel id="label-type-component">
                                <Typography 
                                    color="text.main"
                                    sx={{fontWeight: 'bold'}}
                                >
                                    Type Component Flow
                                </Typography>
                            </InputLabel>                                                        
                            <TextField
                                id="type-component-flow"
                                name="type"
                                value={!componentFlow.type ? "" : componentFlow.type}
                                color="text"
                                select
                                fullWidth
                                size="small"
                                onChange={(e) => handleChange(componentFlow.id_flow_component, "type", e.target.value)}
                                // onChange={(e) => setTypeFlow(e.target.value)}
                                autoComplete="off"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SchemaOutlined color="primary"/>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{mb: 1}}
                            >
                                {/* <MenuItem value="" disabled>
                                    <em>Type Component Flow</em>
                                </MenuItem> */}
                                {
                                    config.flowVariables.typesComponentFlows.map(({type}, index) => (
                                        <MenuItem
                                            key={index}
                                            value={type}
                                        >
                                            {type}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item md={6} sx={{pl: 1}}>
                            <InputLabel id="label-type-execute">
                                <Typography 
                                    color="text.main"
                                    sx={{fontWeight: 'bold'}}
                                >
                                    Type Execute
                                </Typography>
                            </InputLabel> 
                            <TextField
                                id="type-execute-flow"
                                name="type_execute"
                                value={!componentFlow.type_execute ? "" : componentFlow.type_execute}
                                color="text"
                                select
                                fullWidth
                                size="small"
                                onChange={(e) => handleChange(componentFlow.id_flow_component, "type_execute", e.target.value)}
                                autoComplete="off"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SyncAltOutlined color="primary"/>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{mb: 1}}
                            >
                                {/* <MenuItem value="" disabled>
                                    <em>Type Execute</em>
                                </MenuItem> */}
                                {
                                    config.flowVariables.typesExecute.map(({type}, index) => (
                                        <MenuItem
                                            key={index}
                                            value={type}
                                        >
                                            {type}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>

                    {/* <div>
                        <h1>Formulario Dinámico</h1>
                        <DynamicForm schema={sql} />
                    </div> */}

                    <Grid container>
                        <EdiItemTabs
                            isLoadingItems={false}
                            elevation={8}
                            tabs={[
                                {
                                    label: 'Process Input',
                                    value: '1',
                                    bodyTab: <Box sx={{p: 3}}>

                                        {/* ------------------------------------ */}
                                        {componentFlow.type ?
                                        <>
                                            <DynamicForm 
                                            // schema={componentFlow.type !== '' ? config.schemes.find(s => s.type === 'sql').scheme : ''} 
                                            schema={componentFlow.type !== '' ? config.schemes.find(s => s.type === componentFlow.type).scheme : ''}
                                        />
                                        
                                        </>
                                        :
                                        null}
                                        {/* ------------------------------------ */}
                                        <Control/>
                                        <JsonEditorV2
                                            titleKey={"processInput"}
                                            contentJson={componentFlow.process_input}
                                            handleChange={(event) => handleChange(componentFlow.id_flow_component, "process_input", event)}
                                            viewEditorResult={true}
                                            viewCheckBoxSelect={true}
                                        />
                                    </Box>
                                },
                                // {
                                //     label: 'Acceptance Process',
                                //     value: '2',
                                //     bodyTab: <Box sx={{p: 3}}>
                                //         <JsonEditorV2
                                //             titleKey={"acceptanceProcess"}
                                //             contentJson={componentFlow.process_input}
                                //             handleChange={(event) => handleChange(componentFlow.id_flow_component, "acceptance_process", event)}
                                //             viewEditorResult={true}
                                //             viewCheckBoxSelect={true}
                                //         />
                                //     </Box>
                                // },
                                // {
                                //     label: 'Process Fail',
                                //     value: '3',
                                //     bodyTab: <Box sx={{p: 3}}>
                                //         <JsonEditorV2
                                //             titleKey={"processFail"}
                                //             contentJson={componentFlow.process_input}
                                //             handleChange={(event) => handleChange(componentFlow.id_flow_component, "process_fail", event)}
                                //             viewEditorResult={true}
                                //             viewCheckBoxSelect={true}
                                //         />
                                //     </Box>
                                // },
                                // {
                                //     label: 'Sub Components Flow',
                                //     value: '4',
                                //     bodyTab: <Box sx={{p: 3}}>
                                //         <ListDragDrop
                                //             numberGridList={10}
                                //             nameItem="sub component flow"
                                //             keyItem="subComponentFlow"
                                //             keyItemOrder="order_in_flow_component"
                                //             handleClickNewItem={handleClickNewSubComponentFlow}
                                //             listItems={!componentFlow?.sub_flow_components ? [] : componentFlow.sub_flow_components}
                                //             itemComponent={
                                //                 <ItemAccordionListSubComponentFlow
                                //                     handleDeleteComponentFlow={handleDeleteSubComponentFlow}
                                //                     expanded={expandedSubComponentFlows}
                                //                     handleChangeExpanded={handleChangeExpandedSubComponentFlow}
                                //                     handleChange={handleChangeSubComponentFlow}
                                //                 />
                                //             }
                                //             handleChangeOrderItems={handleChangeOrderSubComponentFlows}
                                //         />
                                //     </Box>
                                // }
                            ]}
                        />
                    </Grid>                  
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}
