import { Typography } from "@mui/material";
// import moment from "moment";

// const ItemDate = ({value}) => {
//     return <Typography>
//         {moment(value).format('DD/MM/YYYY h:mm A')}
//     </Typography>
// }

const ItemText = ({value}) => {
    return <Typography>
        {value}
    </Typography>
}

export const headersTypesStatus = [
    {
        id: 'id_status',
        label: 'Id Status',
        type: 'id',
        align: 'center',
        customComponent: <ItemText/>,
        disabled: true,
        visible: true,
        orderable: true,
        filterType: 'textField',
        filter: true,
    },
    {
        id: 'name',
        label: 'Name Status',
        type: 'string',
        align: 'left',
        customComponent: <ItemText/>,
        disabled: false,
        visible: true,
        orderable: true,
        isLinkeable: true,
        filterType: 'multiselect',
        options: { 
            filterOptions: { 
                fullWidth: true 
            } 
        },
        filter: true,
    },
    {
        id: 'description',
        label: 'Description',
        type: 'string',
        align: 'left',
        customComponent: <ItemText/>,
        disabled: false,
        visible: true,
        orderable: true,
        isLinkeable: true,
        filterType: 'textField',
        options: { 
            filterOptions: { 
                fullWidth: true 
            } 
        },
        filter: true,
    },
    {
        id: 'color',
        label: 'Color Status',
        type: 'string',
        align: 'left',
        customComponent: <ItemText/>,
        disabled: false,
        visible: true,
        orderable: true,
        isLinkeable: false,
    }
];