import { KeyboardArrowDown, KeyboardArrowUp, VisibilityOutlined } from '@mui/icons-material';
import { Box, Checkbox, Chip, CircularProgress, Collapse, IconButton, Paper, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useState } from 'react'
import { CustomTable } from './CustomTable';
import { CustomTableRowBody } from './CustomTableRowBody';

export const CustomTableBody = ({
    headers,
    rows,
    isLoadingItems,
    isErrorLoad,
    messageError,
    order,
    orderBy,
    page,
    rowsPerPage,
    isSelected,
    idRow,
    idRowDetail,
    initialOrderSortDetail,
    isManageable,
    handleClick,
    toNavigateRow,
    emptyRows,
    isCollapsible,
    headersDetails,
    keyDetails,
    startOpenCollapse,
    openCollapseIdx,
    widthParent,
    ...props
}) => {    

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) {
            return order;
          }
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const getComparator = (order, orderBy) => {
        const headerOrder = headers.find(row => row.id === orderBy);

        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, headerOrder, orderBy)
          : (a, b) => -descendingComparator(a, b, headerOrder, orderBy);
    }

    const descendingComparator = (a, b, headerOrder, orderBy) => {
        
        if(headerOrder?.type && headerOrder.type === "date"){
            if (moment(b[orderBy]).toDate() < moment(a[orderBy]).toDate()) {
                return -1;
            }
            if (moment(b[orderBy]).toDate() > moment(a[orderBy]).toDate()) {
                return 1;
            }
                return 0;
        }else{
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
                return 0;
        }
    }

    

    return (
        <TableBody>
            {
                isLoadingItems ? 
                    <TableRow sx={{ height: 200, py: 2 }}>
                        <TableCell 
                            colSpan={headers.length+2}
                            align="center"
                        >
                            <CircularProgress 
                                color="primary"
                            />
                        </TableCell>
                    </TableRow>
                : !isLoadingItems && (!rows || rows.length <= 0) ?
                    <TableRow sx={{ height: 200, py: 2 }}>
                        <TableCell 
                            colSpan={headers.length+2}
                            align="center"
                        >
                            Data empty
                        </TableCell>
                    </TableRow>
                : isErrorLoad ?
                    <TableRow sx={{ height: 200, py: 2 }}>
                        <TableCell 
                            colSpan={headers.length+2}
                            align="center"
                        >
                            Load Failed 
                        </TableCell>
                    </TableRow>
                : stableSort(rows,  getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const isItemSelected = isSelected(row[idRow]);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        
                        return (
                            <CustomTableRowBody
                                key={index}
                                headers={headers}
                                headersDetails={headersDetails}
                                keyDetails={keyDetails}
                                initialOrderSortDetail={initialOrderSortDetail}
                                idRowDetail={idRowDetail}
                                row={row}
                                idRow={idRow}
                                handleClick={handleClick}
                                widthParent={widthParent}
                                isItemSelected={isItemSelected}
                                labelId={labelId}
                                index={index}
                                startOpenCollapse={startOpenCollapse}
                                toNavigateRow={toNavigateRow}
                                isCollapsible={isCollapsible}
                                isManageable={isManageable}
                                {...props}
                            />
                        );
                    })
            }
            {
                emptyRows > 0 && (
                    <TableRow
                        style={{
                        height: (33) * emptyRows,
                        }}
                    >
                        <TableCell colSpan={6} />
                    </TableRow>
                    )
            }
        </TableBody>
    )
}
