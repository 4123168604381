import { LoadingButton } from '@mui/lab';
import { Add, DataObject, ExpandMore, Save } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import React, {  useState } from 'react';
import { useForm } from '../../../../hooks/useForm';
import { useErrorInputs } from '../../../../hooks/useErrorInputs';
import { useFocusInputs } from '../../../../hooks/useFocusInputs';
import { Dialog } from '../../../../components/others/Dialog';
import { JsonEditorV2 } from '../../../../components/jsonEditorV2/JsonEditorV2';
import { useManageSettings } from '../../../../hooks/useManageSettings';
import { Constants } from '../../../../helpers/constants';
    
export const TemplatesJson = () => {
    
    const [{
        loadingSettingsFlows,
        flowVariables,
        isChangedSettings,
        updatingSettingsFlows,
        handleUpdatingSettingsFlows,
        handleChangeTemplatesJson,
        handleClickAddNewTemplate
    }] = useManageSettings(Constants.TEMPLATES_JSON);
    
    const [expandedTemplate, setExpandedTemplate] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const[
        {nameTypeTemplateJson}, 
        handleInputChange, 
        resetFormValues
    ] = useForm({
        nameTypeTemplateJson: ''
    });
    const [
        {nameTypeTemplateJsonError}, 
        handleErrorInput
    ] = useErrorInputs({
        nameTypeTemplateJsonError:{
            isError: false,
            messageError: ''
        },
    });  
    const [
        {nameTypeTemplateJsonFocus}
    ] = useFocusInputs({
        nameTypeTemplateJsonFocus: false
    });

    const handleClose = () => {
        resetFormValues();
        setOpenDialog(false);
    };

    const handleAddNewTemplate = () =>{
        setOpenDialog(true);
    }    

    const handleChangeExpanded = (panel) => (event, isExpanded) => {
        setExpandedTemplate(isExpanded ? panel : false);
    };

    const handleConfirmAddNewTemplate = (e) => {
        e.preventDefault();
        if(nameTypeTemplateJson.length <= 0){
            handleErrorInput('nameTypeComponentError', {
                isError: true,
                messageError: 'The name of type component is required'
            });
            return;
        }
        handleClickAddNewTemplate(nameTypeTemplateJson);
        resetFormValues();
        setOpenDialog(false);
    }

    return loadingSettingsFlows ? (
            <Stack spacing={1}>
                <Skeleton variant="rectangular" height={30}/>
                <Skeleton variant="rectangular" height={70}/>
            </Stack>
        ) : (
            <>
                <Grid container>
                    <Grid
                        item
                        md={12}
                        sx={{mb: 3}}
                        display="flex"
                        alignItems="flex-end"
                        justifyContent="flex-end"
                    >
                        <Stack direction="row" spacing={0.5}>
                            <LoadingButton
                                loading={false}
                                loadingPosition="start"
                                startIcon={<Add />}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={handleAddNewTemplate}
                            >
                                Create new template
                            </LoadingButton>
                            <LoadingButton
                                loading={updatingSettingsFlows}
                                loadingPosition="start"
                                startIcon={<Save />}
                                size="small"
                                variant="contained"
                                color="success"
                                sx={{ml:1}}
                                onClick={handleUpdatingSettingsFlows}
                                disabled={!isChangedSettings}
                            >
                                Save changes
                            </LoadingButton>
                        </Stack>
                    </Grid>
                    {
                        flowVariables?.map(({idTemplate, contentTemplate, typeTemplate}, index) => {
                            return <Grid
                                key={index}
                                item
                                md={12}
                                sx={{
                                    mb: 0.8
                                }}
                            >
                                <Accordion 
                                    expanded={expandedTemplate === `panel${index}`} 
                                    onChange={handleChangeExpanded(`panel${index}`)}
                                >
                                    <Paper 
                                        elevation={1}
                                        sx={{
                                            bgcolor: (theme) => theme.palette.paper.background                                        
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls={`panel${index}bh-content`}
                                            id={`panel${index}bh-header`}
                                        >
                                            <>
                                                <Grid 
                                                    item 
                                                    md={0.7}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="flex-start"
                                                >
                                                    <DataObject/>
                                                </Grid>
                                                <Grid item md={11.3}>
                                                    <Typography 
                                                        sx={{width: '100%'}}
                                                    >
                                                        <span style={{fontWeight: 'bold'}}>Id Template: </span> {idTemplate} 
                                                    </Typography>
                                                    <Typography 
                                                        sx={{width: '100%'}}
                                                    >
                                                        <span style={{fontWeight: 'bold'}}>Type Template: </span> {typeTemplate}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        </AccordionSummary>
                                    </Paper>
                                    <AccordionDetails>
                                        <JsonEditorV2
                                            titleKey={typeTemplate}
                                            contentJson={contentTemplate}
                                            handleChange={(event) => handleChangeTemplatesJson(idTemplate, event)}
                                            viewCheckBoxSelect={false}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        })
                    }                
                </Grid>
                <Dialog
                    openDialog={openDialog}
                    widthDialog="md"
                    content={
                        <TextField
                            autoFocus
                            error={!nameTypeTemplateJsonFocus && nameTypeTemplateJsonError.isError}
                            margin="dense"
                            id="typeComponent"
                            label="Name Type Component"
                            type="text"
                            name="nameTypeTemplateJson"
                            value={nameTypeTemplateJson}
                            onChange={handleInputChange}
                            fullWidth
                            variant="standard"
                        />
                    }
                    actions={
                        <Grid 
                            item md={12} 
                            sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'flex-end',
                                mt: 1
                            }}
                            >
                            <Button
                                variant="outlined" 
                                color="error" 
                                autoFocus
                                size="small"
                                onClick={handleClose}
                                sx={{
                                    color: (theme) => theme.palette.text.main,
                                    mx: 3
                                }}
                            >
                                Cancel
                            </Button>
                            <Button 
                                variant="contained" 
                                onClick={handleConfirmAddNewTemplate}
                                size="small"
                            >
                                Add Template
                            </Button>
                        </Grid>
                    }
                />
            </>
        )
}
