import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { Dashboard } from '../dashboard/Dashboard';
import { getMenu } from '../../../redux/actions/menu';

export const LayoutHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [{ userSession }] = useDataAuth();

    const patharray = location.pathname.split('/').filter((x) => x);
    const pathnames = patharray.slice(1, patharray.length + 1);

    useEffect(() => {
        dispatch(getMenu(userSession.token));
        navigate("/home/dashboard");
    }, []);

    const handleLinkBreadcrumbClick = (event, path) => {
        navigate(path);
    }

    const validateChangesPending = (path) => {
        handleLinkBreadcrumbClick(null, path);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 2, pt: 2, pl: 2, pr: 2 }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator="›"
                    sx={{ mb: 2 }}
                >
                    <Link
                        underline="hover"
                        color="text.main"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => validateChangesPending('/settings')}
                    >
                        <Typography
                            sx={{ fontWeight: pathnames.length === 0 && "bold" }}
                        >
                            Home
                        </Typography>
                    </Link>

                    {
                        pathnames.length > 0 && pathnames.map((value, index) => {
                            const last = index === pathnames.length - 1;
                            const to = `/home/${pathnames.slice(0, index + 1).join('/')}`;

                            return last ? (
                                <Typography
                                    sx={{ fontWeight: "bold" }}
                                    color="primary"
                                    key={to}
                                >
                                    {/* { 
                                        index === 0 && parseInt(value) ? `Flow: ${value}` 
                                        : index === 1 && parseInt(value) ? `Component Flow: ${value}` 
                                        : index === 2 && parseInt(value) ? `Sub Component Flow: ${value}` 
                                        : value
                                    } */}
                                    {
                                        value.split("-").map(item => (
                                            item.charAt(0).toUpperCase() + item.slice(1)
                                        )).join(" ")
                                    }
                                </Typography>
                            ) : (
                                <Link
                                    key={to}
                                    underline="hover"
                                    color="text.main"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => validateChangesPending(to)}
                                >
                                    {
                                        value.split("-").map(item => (
                                            item.charAt(0).toUpperCase() + item.slice(1)
                                        )).join(" ")
                                    }
                                </Link>
                            );
                        })
                    }
                </Breadcrumbs>
                {/* <Box
                    sx={{ mb: 2 }}
                >
                    <Typography
                        color="text.main"
                        variant="h4"
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        Home
                    </Typography>
                </Box> */}
                <Box>
                    <Routes>
                        <Route path="/dashboard" exact="true" element={
                            <></>
                            // <Dashboard />
                        } />
                    </Routes>
                </Box>
            </Box>
        </Box>
    )

}