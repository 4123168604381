import React, { useState } from 'react';
import { Box, Breadcrumbs, Typography, Link } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FileSender } from '../createSignatureTemplete/FileSender';
import { FileManageSignature } from '../createSignatureTemplete/FileManageSignature';

export const LayoutSignatureDigital = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [recipients, setRecipients] = useState([]);
    const [pdfFile, setPdfFile] = useState(null); //por defaul
    const [valueTab, setValueTab] = useState(
        localStorage.getItem('lastTabSettings') ? parseInt(localStorage.getItem('lastTabSettings')) : false
    );
    const patharray = location.pathname.split('/').filter((x) => x);
    const pathnames = patharray.slice(1, patharray.length + 1);

    const handleLinkBreadcrumbClick = (event, path) => {
        navigate(path);
    }

    const validateChangesPending = (path) => {
        handleLinkBreadcrumbClick(null, path);
    }

    const handleChangeTab = (event, newValue) => {
        localStorage.setItem('lastTabSettings', newValue);
        setValueTab(newValue);
    };

    const handleNavigate = (pathNavigate, recipients, pdfFile) => {
        setPdfFile(pdfFile);
        setRecipients(recipients);
        navigate(pathNavigate);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 2, pt: 2, pl: 2, pr: 2 }}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator="›"
                    sx={{ mb: 2 }}
                >
                    <Link
                        underline="hover"
                        color="text.main"
                        sx={{ cursor: 'pointer' }}
                    // onClick={() => validateChangesPending('/settings')}
                    >
                        <Typography>
                            Settings
                        </Typography>
                    </Link>
                </Breadcrumbs>

                <Box>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <FileSender
                                    handleNavigate={handleNavigate}
                                />
                            }
                        />
                        <Route
                            exact="true"
                            path="/template-manage"
                            element={
                                <FileManageSignature
                                    recipients={recipients}
                                    pdfFile={pdfFile}
                                />
                            }
                        />
                    </Routes>
                </Box>
            </Box>
        </Box>
    )

}
