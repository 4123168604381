import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { labelCronExpression } from './utils/labelCronExpression';
import { useForm } from '../../hooks/useForm';

export const CronHours = ({setValue, value}) => {

    const [listChecked, setListChecked] = useState(value.split(',').length > 1 ? value.split(',').map((x, i) => (parseInt(x))) : !isNaN(value) ? [parseInt(value)] : []);

    const [formValues, handleSelectChange] = useForm({
        selectEveryHours: value.split('/').length > 1 ? value.split('/')[1] : 1,
        selectStartHours: value.split('/').length > 1 ? (value.split('/')[0] < 10 ? `0${value.split('/')[0]}` : value.split('/')[0]) : '00',
        selectBetweenHour1: value.split('-').length > 1 ? (value.split('-')[0] < 10 ? `0${value.split('-')[0]}` : value.split('-')[0]) : '00',
        selectBetweenHour2: value.split('-').length > 1 ? (value.split('-')[1] < 10 ? `0${value.split('-')[1]}` : value.split('-')[1]) : '01'
    });
    const {selectEveryHours, selectStartHours, selectBetweenHour1, selectBetweenHour2} = formValues;

    const [valueRadio, setValueRadio] = useState('1');
    useEffect(() => {
        if(value === '*'){setValueRadio('1');}
        if(value.split(',').length > 1 || !isNaN(value)){setValueRadio('3');}
        if(value.split('/').length > 1){setValueRadio('2');}
        if(value.split('-').length > 1 ){setValueRadio('4');}
    }, [])

    useEffect(() => {
        if(valueRadio === '2'){
            setValue(`${parseInt(selectStartHours)}/${selectEveryHours}`);
        }
    }, [selectEveryHours, selectStartHours])
    
    useEffect(() => {
        if(valueRadio === '3'){
            setValue(listChecked.length === 0 ? '0' : listChecked.sort((a,b)=>a-b).toString());
        }
    }, [listChecked])

    useEffect(() => {
        if(valueRadio === '4'){
            if(parseInt(selectBetweenHour1) >= parseInt(selectBetweenHour2)){
                if(parseInt(selectBetweenHour1) === 23){
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenHour1',
                            value: '22'
                        }
                    });
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenHour2',
                            value: '23'
                        }
                    });
                }else{
                    const value = parseInt(selectBetweenHour1)+1;
                    handleSelectChange({
                        target:{
                            name: 'selectBetweenHour2',
                            value: value < 10 ? `0${value}` : `${value}`
                        }
                    })
                }
            } else{
                setValue(`${parseInt(selectBetweenHour1)}-${parseInt(selectBetweenHour2)}`);
            }
        }
    }, [selectBetweenHour1, selectBetweenHour2])

    const handleChange = (e) => {
        setValueRadio(e.target.value);
        if(e.target.value === '1'){
            setValue('*');
        }else if(e.target.value === '2'){
            setValue(`${parseInt(selectStartHours)}/${selectEveryHours}`);
        }else if(e.target.value === '4'){
            setValue(`${parseInt(selectBetweenHour1)}-${parseInt(selectBetweenHour2)}`);
        }else{
            setValue('0');
        }
        handleReset();
    }

    const handleChangeCkecked = (e) => {
        if(!listChecked.includes(parseInt(e.target.name))){
            setListChecked([...listChecked, parseInt(e.target.name)])
        }else{
            setListChecked(listChecked.filter((x, i) => x !== parseInt(e.target.name)))
        }
    }

    const handleReset = () => {
        setListChecked([])
    }

    return (
        <Paper 
            elevation={3} 
            sx={{
                px: '4%', 
                height: '100%', 
                pb: '10%',
                bgcolor: (theme) => theme.palette.paper.background
            }}
        >
            <FormControl>
                <RadioGroup
                    aria-labelledby="hours-radio-buttons-group-label"
                    value={valueRadio}
                    onChange={handleChange} 
                    name="radio-buttons-group"
                >
                    {/*Todos las horas*/}
                    <FormControlLabel 
                        value="1"
                        control={<Radio size="small"/>} 
                        label="Todos las horas"
                    />

                    {/*Apartir del*/}                    
                    <FormControlLabel 
                        value="2"
                        control={<Radio size="small"/>}
                        sx={{mt: 2}}
                        label={
                            <Box 
                                display="flex"
                                alignItems="center" 
                                justifyContent="start"
                            >
                                <Typography>
                                    {'Cada\u00A0'}
                                </Typography>
                                <FormControl 
                                    variant="outlined" 
                                    sx={{ m: 0}}
                                >
                                    <Select
                                        value={selectEveryHours}
                                        size="small"
                                        name="selectEveryHours"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "2"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'hours')
                                                .hours.map((x, i) => {
                                                return (
                                                    <MenuItem key={i} value={x}>{x}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography>
                                    {'\u00A0 hora(s), a partir de la hora \u00A0'}
                                </Typography>
                                <FormControl variant="outlined" sx={{ m: 0}}>
                                    <Select
                                        value={selectStartHours}
                                        size="small"
                                        name="selectStartHours"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "2"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'hours')
                                                .hoursLabel.map((x, i) => {
                                                return (
                                                    <MenuItem key={i}  value={x}>{x}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />

                    {/*Horas Especificos*/}
                    <FormControlLabel 
                        value="3"
                        control={<Radio size="small" sx={{pt: 0}}/>} 
                        sx={{
                            mt: 2,
                            alignItems: 'flex-start'
                        }}
                        label={
                            <Box>
                                <Typography>
                                    Horas especificos (selecciona uno o varios)
                                </Typography>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'hours')
                                                .hoursLabel.map((x, i) => {
                                                return (
                                                    <FormControlLabel
                                                        key={i} 
                                                        control={
                                                            <Checkbox 
                                                                checked={
                                                                    listChecked.includes(parseInt(x))
                                                                } 
                                                                size="small" 
                                                                name={x}
                                                                onChange={handleChangeCkecked}
                                                                disabled={valueRadio !== "3"}
                                                            />
                                                        }
                                                        label={x}
                                                        value={x}
                                                    />
                                                )
                                            })
                                        }                                
                                    </FormGroup>
                                </FormControl>
                            </Box>   
                        }
                    />

                    {/*Horas Entre*/}
                    <FormControlLabel 
                        value="4"
                        control={<Radio size="small"/>} 
                        sx={{mt: 2}}
                        label={
                            <Box
                                display="flex"
                                alignItems="center" 
                                justifyContent="start"
                            >
                                <Typography>
                                    {'Todos las horas entre la hora \u00A0'}
                                </Typography>
                                <FormControl 
                                    variant="outlined" 
                                    sx={{ m: 0}}
                                >
                                    <Select
                                        value={selectBetweenHour1}
                                        size="small"
                                        name="selectBetweenHour1"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "4"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'hours')
                                                .hoursLabel.map((x, i) => {
                                                return (
                                                    <MenuItem key={i} value={x}>{x}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Typography>
                                    {'\u00A0 y el hora \u00A0'}
                                </Typography>
                                <FormControl variant="outlined" sx={{ m: 0}}>
                                    <Select
                                        value={selectBetweenHour2}
                                        size="small"
                                        name="selectBetweenHour2"
                                        sx={{py:0}}
                                        onChange={handleSelectChange}
                                        disabled={valueRadio !== "4"}
                                    >
                                        {
                                            labelCronExpression.find(tab => tab.tab === 'hours')
                                                .hoursLabel.map((x, i) => {
                                                return (
                                                    <MenuItem 
                                                        key={i}  
                                                        value={x}
                                                        disabled={
                                                            parseInt(selectBetweenHour1) >= parseInt(x)
                                                        }
                                                    >
                                                        {x}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    />

                </RadioGroup>
            </FormControl>
        </Paper>
  )
}