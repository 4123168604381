import { ArrowBack, Check, Delete, Edit, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, IconButton, Skeleton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogConfirmAction } from '../dialogs/DialogConfirmAction';

export const EditItemToolbar = ({
    isLoadingItems,
    titleEditItem,
    nameItem,
    isSaving,
    statusButtonSave,
    handleSaveChanges,
    isDeleting,
    statusButtonDelete,
    handleClickDelete
}) => {

    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
    const navigate = useNavigate();
    const [isEditName, setIsEditName] = useState(false);
    const params = useParams();

    const handleConfirmeDeleteFlow = () => {
        setOpenDialogConfirmDelete(false);
        handleClickDelete()
    }

    const handleClickEditName = (isEdit) => {
        setIsEditName(isEdit);
    }

    const handleClickBack = () => {
        navigate(-1);
    }

    return (
        <>
            <Grid
                item
                md={0.5}
            >
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{height: '100%'}}
                >
                    <IconButton
                        sx={{p: 0}}
                        size="small"
                        onClick={handleClickBack}
                    >
                        <ArrowBack color="primary"/>
                    </IconButton>
                </Box>
            </Grid>
            <Grid
                item
                md={4.5}
            >
                {
                    isLoadingItems 
                    ?   <Skeleton variant="text" width={200}/> : 
                    (
                        titleEditItem.isEditable ?
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{height: '100%'}}
                            >
                                {
                                    !isEditName ? 
                                        <Typography 
                                            variant="display4" 
                                            sx={{fontWeight: 'bold', mr: 1}} 
                                            color="text.main"
                                        >
                                            {titleEditItem.label} 
                                            {titleEditItem.value}
                                        </Typography>
                                    : <>
                                        <Typography 
                                            variant="display4" 
                                            sx={{fontWeight: 'bold', mr: 1}} 
                                            color="primary"
                                        >
                                                {titleEditItem.label}
                                        </Typography>
                                        <TextField
                                            name={titleEditItem.name}
                                            variant="standard"
                                            onChange={(e) => titleEditItem.handleChange(
                                                !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
                                                e.target.name, 
                                                e.target.value
                                            )}
                                            value={titleEditItem.value}
                                            autoComplete="off"
                                            InputProps={{ style: { fontSize: 16, fontWeight: 'bold', width: 'auto'} }}
                                            sx={{mr: 1}}                                           
                                        />
                                    </>
                                }                                   
                                <IconButton
                                    sx={{p: 0}}
                                    size="small"
                                    onClick={() => handleClickEditName(!isEditName)}
                                >
                                    {
                                        !isEditName 
                                            ? <Edit color="primary"/>
                                            : <Check color="primary"/>

                                    }
                                </IconButton>
                            </Box>
                        : <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{height: '100%'}}
                        >
                            <Typography 
                                variant="display4" 
                                sx={{fontWeight: 'bold'}} 
                                color="text.main"
                            >
                                {titleEditItem.label}
                            </Typography>
                        </Box>
                    )
                }
            </Grid>
            <Grid
                item
                md={7}
                display="flex"
                justifyContent="flex-end"
            >
                <LoadingButton
                    loading={isDeleting}
                    loadingPosition="start"
                    startIcon={<Delete />}
                    size="small"
                    variant="contained"
                    color="error"
                    disabled={!statusButtonDelete}
                    onClick={() => setOpenDialogConfirmDelete(true)}
                >
                    {`Delete ${nameItem && nameItem}`}
                </LoadingButton>
                <LoadingButton
                    loading={isSaving}
                    loadingPosition="start"
                    startIcon={<Save />}
                    size="small"
                    variant="contained"
                    color="success"
                    sx={{ml:1}}
                    disabled={!statusButtonSave}
                    onClick={handleSaveChanges}
                >
                    Save changes
                </LoadingButton>
            </Grid>
            <DialogConfirmAction
                openDialogConfirm={openDialogConfirmDelete}
                setOpenDialogConfirm={setOpenDialogConfirmDelete}
                textConfirmAction={`Are you sure you want to remove this ${nameItem ? nameItem : 'item'}`}
                textButtonConfirm={`Delete component ${nameItem ? nameItem : 'item'}`}
                textButtonCancel={"Cancel"}
                handleConfirmAction={handleConfirmeDeleteFlow}
            />
        </>
    )
}
