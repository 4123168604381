import { Add, Description, Dns, Numbers } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormControlLabel, FormGroup, Grid, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useErrorInputs } from '../../../../hooks/useErrorInputs';
import { useFocusInputs } from '../../../../hooks/useFocusInputs';
import { useForm } from '../../../../hooks/useForm';

export const CreateTypeStatus = ({
  settingsManager
}) => {

  const {
    handleAddTypeStatus,
    addingNewSettingsFlows
  } = settingsManager;

  const[{name, description, color, active}, handleInputChange] = useForm({
    name: '',
    description: '',
    color: '',
    active: 'N'
  });

  const [{nameError, descriptionError, colorError}, handleErrorInput] = useErrorInputs({
    nameError:{
        isError: false,
        messageError: ''
    },
    descriptionError:{
        isError: false,
        messageError: ''
    },
    colorError:{
        isError: false,
        messageError: ''
    }
  }); 
  const [{isNameFocus, isDescriptionFocus, isColorFocus}, handleFocusInputs] = useFocusInputs({
    isNameFocus: false,
    isDescriptionFocus: false,
    isColorFocus: false
});

  const handleSubmit = (e) =>{
    e.preventDefault();
    if(name.length <= 0){
        handleErrorInput('nameError', {
            isError: true,
            messageError: 'The field name is required'
        });
        return;
    }

    if(description.length <= 0){
        handleErrorInput('descriptionError', {
            isError: true,
            messageError: 'The field description is required'
        });
        return;
    }

    if(color.length <= 0){
      handleErrorInput('colorError', {
          isError: true,
          messageError: 'The field color is required'
      });
      return;
  }

    handleAddTypeStatus({
      name,
      description,
      color, 
      active
    })
  }

  return (
    <Grid container>
      <Grid 
        item
        md={12}
        sx={{
          mb: 2
        }}
      >
        <Typography 
          variant="h5"
          color="primary"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold'
          }}
        >
          Create New Type Status
        </Typography>
      </Grid>
      <Grid item md={12}>
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item md={6} sx={{p:2}}>
              <FormGroup>
                <TextField
                    error={!isNameFocus && nameError.isError}
                    color={(isNameFocus) ? "text" : (nameError.isError) ? "error" : "text"}
                    label="Name Status"
                    variant="outlined"
                    name="name"
                    placeholder="Enter name status"
                    size="small"
                    autoComplete="off"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Dns color={(isNameFocus) ? "text" : (nameError.isError) ? "error" : "text"}/>
                            </InputAdornment>
                        ),
                    }}                        
                    value={name}
                    helperText={nameError.messageError}
                    onChange={handleInputChange}
                    onFocus={handleFocusInputs}
                    onBlur={handleFocusInputs}
                />
              </FormGroup>

              <FormGroup sx={{my: 2}}>
                <TextField
                    multiline
                    rows={2}
                    error={!isDescriptionFocus && descriptionError.isError}
                    color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}
                    label="Description"
                    variant="outlined"
                    name="description"
                    placeholder="Enter description"
                    size="small"
                    autoComplete="off"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Description color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}/>
                            </InputAdornment>
                        )
                    }}                        
                    value={description} 
                    helperText={descriptionError.messageError}
                    onChange={handleInputChange}
                    onFocus={handleFocusInputs}
                    onBlur={handleFocusInputs}
                />
              </FormGroup>
            </Grid>
            <Grid item md={6} sx={{p:2}}>
              <FormGroup>
                  <TextField
                      error={!isColorFocus && colorError.isError}
                      color={(isColorFocus) ? "text" : (colorError.isError) ? "error" : "text"}
                      label="Color"
                      variant="outlined"
                      name="color"
                      placeholder="Enter color"
                      size="small"
                      autoComplete="off"
                      InputProps={{
                          startAdornment: (
                              <InputAdornment position="start">
                                  <Numbers color={(isColorFocus) ? "text" : (colorError.isError) ? "error" : "text"}/>
                              </InputAdornment>
                          )
                      }}                        
                      value={color} 
                      helperText={colorError.messageError}
                      onChange={handleInputChange}
                      onFocus={handleFocusInputs}
                      onBlur={handleFocusInputs}
                  />
              </FormGroup>
              <FormGroup sx={{my: 2}}>
                <FormControlLabel
                    labelPlacement="top"
                    control={
                        <Switch 
                            name="active"
                            onChange={(e) => handleInputChange(
                                    {
                                      target: {
                                        name: e.target.name, 
                                        value: e.target.checked ? 'Y' : 'N'
                                      }
                                    }
                                )
                            } 
                            checked={active === 'Y'} 
                        />
                    }
                    label="Active"
                />
              </FormGroup>
            </Grid>

            <Grid item md={12}>
                <Box sx={{
                    mt: 2,
                    textAlign: 'center'
                  }}
                >
                  <LoadingButton
                      loading={addingNewSettingsFlows}
                      type="submit"
                      loadingPosition="start"
                      startIcon={<Add />}
                      size="small"
                      variant="contained"
                      color="primary"
                  >
                      Add New Status
                  </LoadingButton>
                </Box>
            </Grid>
          </Grid>

        </form>
      </Grid>
    </Grid>
  )
}
