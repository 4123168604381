import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { EditItem } from '../../../../components/editItem/EditItem';
import { JsonEditorV2 } from '../../../../components/jsonEditorV2/JsonEditorV2';
import { DetailsSummaryTypesComponentFlow } from './DetailsSummaryTypesComponentFlow';

export const DetailsTypesComponentFlow = ({
    settingsManager
}) => {

    const {
        flowVariables,
        isChangedSettings,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        addingNewSettingsFlows,
        handleUpdatingSettingsFlows,
        isErrorFetch,
        messageErrorFetch,
        handleChangeTypeComponentFlow,
        handleDeleteTypeComponentFlow,
        handleRefreshSettingsFlow
    } = settingsManager;
    const params = useParams();
    const [typeComponentFlowSelected, setTypeComponentFlowSelected] = useState({});
    const [isLoadingTypeComponentFlow, setIsLoadingTypeComponentFlow] = useState(false);

    useEffect(() => {
        setIsLoadingTypeComponentFlow(true);
        setTimeout(() => {
            const typeComponentFlowSelected = flowVariables?.find(typeComponentFlow => typeComponentFlow.id === parseInt(params.idTypeComponentFlow));
            setTypeComponentFlowSelected(typeComponentFlowSelected);
            setIsLoadingTypeComponentFlow(false);
        }, 500);
    }, []);
   
    useEffect(() => {
        const typeComponentFlowSelected = flowVariables?.find(typeComponentFlow => typeComponentFlow.id === parseInt(params.idTypeComponentFlow));
        setTypeComponentFlowSelected(typeComponentFlowSelected);
    }, [flowVariables]);

    return (
        <EditItem
            isLoadingItems={isLoadingTypeComponentFlow}
            titleEditItem={{
                label: `Id Type Component Flow: ${typeComponentFlowSelected.id}`,
                isEditable: false
            }}
            nameItem={"Type Component Flow"}
            isSaving={updatingSettingsFlows}
            statusButtonSave={isChangedSettings}
            handleSaveChanges={handleUpdatingSettingsFlows}
            isDeleting={deletingSettingsFlows}
            statusButtonDelete={true}
            handleClickDelete={handleDeleteTypeComponentFlow}
            contentSummary={<DetailsSummaryTypesComponentFlow
                typeComponentFlowSelected={typeComponentFlowSelected}
                settingsManager={settingsManager}
            />}
            tabs={[
                {
                    label: 'Input Default',
                    value: '1',
                    bodyTab: <Box sx={{p: 3}}>
                        <JsonEditorV2
                            titleKey={'inputDefault'}
                            contentJson={typeComponentFlowSelected.inputDefault}
                            handleChange={(event) => handleChangeTypeComponentFlow({
                                name: "inputDefault",
                                value: event
                            }, typeComponentFlowSelected.id)}
                            viewCheckBoxSelect={true}
                        />
                    </Box>
                },
                {
                    label: 'Ouput Default',
                    value: '2',
                    bodyTab: <Box sx={{p: 3}}>
                        <JsonEditorV2
                            titleKey={'outputDefault'}
                            contentJson={typeComponentFlowSelected.outputDefault}
                            handleChange={(event) => handleChangeTypeComponentFlow({
                                name: "outputDefault",
                                value: event
                            }, typeComponentFlowSelected.id)}
                            viewCheckBoxSelect={true}
                        />
                    </Box>
                },
                {
                    label: 'Fail Default',
                    value: '3',
                    bodyTab: <Box sx={{p: 3}}>
                        <JsonEditorV2
                            titleKey={'failDefault'}
                            contentJson={typeComponentFlowSelected.failDefault}
                            handleChange={(event) => handleChangeTypeComponentFlow({
                                name: "failDefault",
                                value: event
                            }, typeComponentFlowSelected.id)}
                            viewCheckBoxSelect={true}
                        />
                    </Box>
                },
            ]}
        />
    )
}
