import { Box } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Constants } from '../../../../helpers/constants';
import { useManageSettings } from '../../../../hooks/useManageSettings';
import { CustomTable } from '../../../../components/tables/tableNavigate/CustomTable';
import { headersTypesComponentFlow } from '../../utils/headersTypesComponentFlow';
import { CreateTypeComponentFlow } from './CreateTypeComponentFlow';
import { DetailsTypesComponentFlow } from './DetailsTypesComponentFlow';

export const TypesComponentFlowV2 = () => {

    const [settingsManager] = useManageSettings(Constants.TYPES_COMPONENT_FLOWS);
    const {
        flowVariables,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        isErrorFetch,
        messageErrorFetch,
        handleDeleteTypeComponentFlow,
        handleRefreshSettingsFlow
    } = settingsManager;

    
    return (
        <Box>
            <Routes>
                <Route path="/" exact="true" element={
                    <CustomTable
                        nameItems="Types Component Flow"
                        nameItem="type component flow"
                        valueOrder={"asc"}
                        keyItemSearch="type"
                        headers={headersTypesComponentFlow} 
                        rows={flowVariables}
                        initialOrderSort="id"
                        idRow="id"
                        toNavigateRow="/settings/flows/types-component-flow/types-component-flow-:id"
                        toNavigateCreateItem="/settings/flows/types-component-flow/create-type-component-flow"
                        isLoadingItems={loadingSettingsFlows}
                        isSavingItems={updatingSettingsFlows}
                        isDeletingItems={deletingSettingsFlows}
                        isErrorLoad={isErrorFetch}
                        messageError={messageErrorFetch}
                        handleDeleteItems={handleDeleteTypeComponentFlow}
                        handleClickReferesh={handleRefreshSettingsFlow}
                        rowsByPage={7}
                    />
                }/>
                <Route path="/types-component-flow-:idTypeComponentFlow" element={
                    <DetailsTypesComponentFlow settingsManager={settingsManager}/>
                }/>
                <Route  exact="true" path="/create-type-component-flow" element={
                    <CreateTypeComponentFlow settingsManager={settingsManager}/>
                }/>
            </Routes>
        </Box>
    )
}
