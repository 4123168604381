import React, { useEffect, useState, useCallback, useRef } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import EditParser from '../tables/EditParser';
import { ParserEditor } from '../htmlEditor/ParserEditor';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';

export const TableParser = ({ data, disabled, onChange }) => {
    const childRef = useRef();
    const [dataPropertys, setDataPropertys] = useState([]);
    const [propertyPath, setPropertyPath] = useState('');
    const [openPath, setOpenPath] = useState(false);
    const [properties, setProperties] = useState(false);
    const [open, setOpen] = useState(false);

    const findKeyWithValue = useCallback((dto, targetValue, currentPath, keyParent) => {
        if (Array.isArray(dto)) {
            for (let i = 0; i < dto.length; i++) {
                const newPath = `${currentPath}[${i}]`;
                findKeyWithValue(dto[i], targetValue, newPath, keyParent);
            }
        }
        else if (typeof dto === 'object' && dto !== null) {
            for (const key in dto) {
                if (dto.hasOwnProperty(key)) {
                    const newPath = `${currentPath}.${key}`;
                    findKeyWithValue(dto[key], targetValue, newPath, key);
                }
            }
        }
        else {
            if (keyParent === targetValue) {
                const dataFields = dto.split('+');
                for (const dataField of dataFields) {
                    setDataPropertys(prevData => [...prevData, (
                        {
                            'property': getProperty(data, dataField.replace(/[{}]/g, '')),
                            'path': dataField.replace(/[{}]/g, ''),
                            'name': dataField.replace(/[{}]/g, '').split('.').pop(),
                            'value': dataField
                        }
                    )]);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        setDataPropertys([]);
        findKeyWithValue(data, 'field_value', '', 'Root');
    }, [data, findKeyWithValue])

    const getProperty = (obj, path) => {
        return path.split('.').reduce((acc, part) => {
            const arrayMatch = part.match(/(.+?)(\[(\d+)\])?$/);
            if (!arrayMatch)
                return undefined;

            const key = arrayMatch[1];
            const index = arrayMatch[3];

            acc = acc && acc[key];
            if (acc && index !== undefined) {
                acc = acc[Number(index)];
            }
            return acc;
        }, obj);
    }

    const OpenModalEdit = (item) => {
        if (childRef.current) {
            childRef.current.customFunction(item);
        }
    }

    const setFuntions = (item) => {
        setOpenPath(true);
        console.log('pathComplete ', item.path.replace(`.${item.name}`, ''));
        let obj = getProperty(data, item.path.replace(`.${item.name}`, ''));
        console.log('obj ', obj);
        setProperties(obj);
    }

    return (
        <React.Fragment>
            <Grid
                container
                item
                style={{ display: 'flex', alignItems: 'stretch' }}
            >
                {!openPath ?
                    <Grid
                        item
                        // md={6}
                        elevation={4}
                        sx={{
                            bgcolor: (theme) => theme.palette.paper.background
                        }}
                        style={{ overflow: 'auto', height: 400, flex: 1 }}
                    >
                        <Table
                            aria-labelledby='tableTitle'
                            size='small'
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Path</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    dataPropertys.length > 0 && (
                                        dataPropertys.map((item) => (
                                            <TableRow>
                                                <TableCell>
                                                    <Link
                                                        sx={{ cursor: 'pointer' }}
                                                        color='text.primary'
                                                        onClick={() => OpenModalEdit(item)}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link
                                                        sx={{ cursor: 'pointer' }}
                                                        color='text.primary'
                                                        onClick={() => setFuntions(item)}
                                                    >
                                                        {item.path}
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                    :
                    <Grid
                        item
                        // md={6}
                        elevation={4}
                        sx={{
                            bgcolor: (theme) => theme.palette.paper.background
                        }}
                        style={{ overflow: 'auto', height: 400, flex: 1 }}
                    >
                        <Grid
                            item
                            md={0.5}
                        >
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{ height: '100%' }}
                            >
                                <IconButton
                                    sx={{ p: 0 }}
                                    size="small"
                                    onClick={() => setOpenPath()}
                                >
                                    <ArrowBack color="primary" />
                                </IconButton>
                            </Box>
                        </Grid>
                        <ParserEditor data={properties} />
                    </Grid>
                }
            </Grid>
            <EditParser
                ref={childRef}
            />
        </React.Fragment>
    );
}