import { ArrowDropDown, ArrowRight, Delete } from '@mui/icons-material';
import { alpha, Box, Grid, IconButton, List, ListItem, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ModalEditor } from '../../modalEditor/ModalEditor';
import { TreeItemBoolean } from './TreeItemBoolean';
import { TreeItemListArray } from './TreeItemListArray';
import { TreeItemNullUndefined } from './TreeItemNullUndefined';
import { TreeItemNumber } from './TreeItemNumber';
import { TreeItemString } from './TreeItemString';

export const TreeItemObject = ({
    value,
    keyItem,
    valueItem,
    indentation,
    arrayKeysPath,
    onChange,
    onDelete
}) => {

    const [showActions, setShowActions] = useState(false);
    const [isCollapse, setIsCollapse] = useState(true);

    return (
        <>
            <ListItem
                disableGutters
                sx={{
                    py: 0.3,
                    marginLeft: indentation
                }}
            >    
                <Grid container>
                    <Grid
                        item
                        md={12}
                        onMouseEnter={() => setShowActions(true)}
                        onMouseLeave={() => setShowActions(false)}
                        alignItems="center" 
                        display="inline-flex" 
                        justifyContent="flex-start"
                    >
                        <Box
                            alignItems="center" 
                            display="inline-flex" 
                            justifyContent="flex-end"
                        >
                            <IconButton
                                sx={{p:0}}
                                onClick={() => setIsCollapse(!isCollapse)}
                            >
                                {
                                    isCollapse ? <ArrowRight 
                                        sx={{fontSize: 17}}
                                        color="disabled"
                                    /> 
                                    : <ArrowDropDown
                                        sx={{fontSize: 17}}
                                        color="disabled"
                                    />
                                }
                            </IconButton>
                        </Box>
                        <Box
                            alignItems="center"
                            display="inline-flex"
                            justifyContent="flex-start"
                            sx={{overflow: 'hidden'}}
                        >
                            <Typography 
                                component="span"
                                variant="console"
                                sx={{
                                    height: '100%',
                                    display: 'inline-block',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start'
                                }}
                            >
                                {`${keyItem}:`}
                            </Typography>
                            {
                                isCollapse ? <Typography 
                                        component="span"
                                        variant="console"
                                        sx={{fontSize: 12, cursor: 'pointer', px: 0.5}}
                                        onClick={() => setIsCollapse(!isCollapse)}
                                    >
                                        {'{'}
                                    </Typography>  
                                :  <Typography 
                                        component="span"
                                        variant="console"
                                        color="darkgray"
                                        sx={{fontSize: 12, cursor: 'pointer', px: 0.5}}
                                        onClick={() => setIsCollapse(!isCollapse)}
                                    >
                                        {'{...}'}
                                    </Typography>
                            }
                            <Typography 
                                component="span" 
                                color="darkgray"
                                variant="console"
                                sx={{fontSize: 12, px: 0.5}}
                            >
                                {`${Object.entries(valueItem).length} items`}
                            </Typography>                   
                        </Box> 
                        <Box>
                            {
                                showActions && (
                                    <Stack direction="row" spacing={1}>
                                        <ModalEditor
                                            arrayKeysPath={[...arrayKeysPath, keyItem, '']}
                                            isEditorAdd={true}
                                            isEditorEdit={false}
                                            value={value}
                                            onChange={onChange}
                                        />
                                        <Tooltip title="Delete">
                                            <IconButton
                                                size="small"
                                                sx={{p:0}}
                                                onClick={() => onDelete([...arrayKeysPath, keyItem])}
                                            >
                                                <Delete sx={{fontSize: 17}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )
                            }
                        </Box>
                    </Grid>
                    {
                        isCollapse && (
                            <Grid 
                                item 
                                md={12}
                                sx={{ml: 1}}
                            >
                                <List
                                    sx={{
                                        p: 0,
                                        borderLeft: (theme) => `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
                                    }}
                                >
                                    {         
                                        Object.entries(valueItem).map(([keyChild, valueChild], index) =>{

                                            if(valueChild === null || valueChild === undefined){
                                                return <TreeItemNullUndefined
                                                    key={index}
                                                    value={value}
                                                    indentation={indentation+1}
                                                    keyItem={keyChild}
                                                    valueItem={valueChild}
                                                    arrayKeysPath={[...arrayKeysPath, keyItem]}
                                                    onChange={onChange}
                                                    onDelete={onDelete}
                                                />
                                            }else if(Array.isArray(valueChild)){
                                                return <TreeItemListArray
                                                    key={index}
                                                    value={value}
                                                    indentation={indentation+1}
                                                    keyItem={keyChild}
                                                    valueItem={valueChild}
                                                    arrayKeysPath={[...arrayKeysPath, keyItem]}
                                                    onChange={onChange}
                                                    onDelete={onDelete}
                                                />
                                            } else {
                                                switch(typeof(valueChild)){
                                                    case 'string':
                                                        return <TreeItemString
                                                            key={index}
                                                            value={value}
                                                            indentation={indentation+1}
                                                            keyItem={keyChild}
                                                            valueItem={valueChild}
                                                            arrayKeysPath={[...arrayKeysPath, keyItem]}
                                                            onChange={onChange}
                                                            onDelete={onDelete}
                                                        />
                                                    case 'number':
                                                        return <TreeItemNumber
                                                            key={index}
                                                            value={value}
                                                            indentation={indentation+1}
                                                            keyItem={keyChild}
                                                            valueItem={valueChild}
                                                            arrayKeysPath={[...arrayKeysPath, keyItem]}
                                                            onChange={onChange}
                                                            onDelete={onDelete}
                                                        />
                                                    case 'object':
                                                        return <TreeItemObject
                                                            key={index}
                                                            value={value}
                                                            indentation={indentation+1}
                                                            keyItem={keyChild}
                                                            valueItem={valueChild}
                                                            arrayKeysPath={[...arrayKeysPath, keyItem]}
                                                            onChange={onChange}
                                                            onDelete={onDelete}
                                                        />
                                                    case 'boolean':
                                                        return <TreeItemBoolean
                                                            key={index}
                                                            value={value}
                                                            indentation={indentation+1}
                                                            keyItem={keyChild}
                                                            valueItem={valueChild}
                                                            arrayKeysPath={[...arrayKeysPath, keyItem]}
                                                            onChange={onChange}
                                                            onDelete={onDelete}
                                                        />
                                                    default:
                                                        return <TreeItemNullUndefined
                                                            key={index}
                                                            value={value}
                                                            indentation={indentation+1}
                                                            keyItem={keyChild}
                                                            valueItem={valueChild}
                                                            arrayKeysPath={[...arrayKeysPath, keyItem]}
                                                            onChange={onChange}
                                                            onDelete={onDelete}
                                                        />
                                                }
                                            }
                                        })
                                    }
                                </List>
                            </Grid>
                        )
                    }
                    {
                        isCollapse && 
                            <Grid item md={12}>
                                <Typography 
                                    component="span"
                                    variant="console"
                                    sx={{fontSize: 12, ml: 1}}
                                    onClick={() => setIsCollapse(!isCollapse)}
                                >
                                {'}'}
                                </Typography>                   
                            </Grid>
                    }
                </Grid>                
            </ListItem>
        </>
    )
}
