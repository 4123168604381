import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Paper, Skeleton, Tab, Typography } from '@mui/material';
import React, { useState } from 'react';

export const EdiItemTabs = ({
    isLoadingItems,
    elevation,
    tabs
}) => {

    const [valueTabs, setValueTabs] = useState('1');

    const handleChangeTabs = (event, newValue) => {
        setValueTabs(newValue);
    };

    return (
        <Grid
            item
            md={12}
        >
            {
                isLoadingItems
                    ? <Skeleton variant="rectangular" height={200} />
                    : <Paper
                        elevation={elevation}
                        sx={{
                            bgcolor: (theme) => theme.palette.paper.background
                        }}
                    >
                        <Box>
                            <TabContext
                                value={valueTabs}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        bgcolor: (theme) => theme.palette.paper.background
                                    }}
                                >
                                    <TabList
                                        onChange={handleChangeTabs}
                                        aria-label="tabs flows"
                                    >
                                        {
                                            tabs.map(({ label, value }) => {
                                                return <Tab
                                                    key={value}
                                                    sx={{
                                                        '&.Mui-selected': {
                                                            bgcolor: (theme) => theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.light.main
                                                        }
                                                    }}
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                color: (theme) => theme.palette.text.main
                                                            }}
                                                        >
                                                            {label}
                                                        </Typography>
                                                    }
                                                    value={value}
                                                />
                                            })
                                        }
                                    </TabList>
                                </Paper>
                                {
                                    tabs.map(({ bodyTab, value }) => {
                                        return <TabPanel
                                            key={value}
                                            value={value}
                                            sx={{ p: 0 }}
                                        >
                                            {bodyTab}
                                        </TabPanel>
                                    })
                                }
                            </TabContext>
                        </Box>
                    </Paper>
            }
        </Grid>
    )
}
