import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Box, darken, TablePagination, TableCell, TableHead as MuiTableHead, TableRow, Typography, TableContainer, Table, Breadcrumbs, Link, TableBody, Paper, CircularProgress
} from '@mui/material';
import styled from '@emotion/styled';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useManageUsers } from '../../../hooks/useManageUsers';
import { getAllUsers, DeleteUser, setNotificaction } from '../../../redux/actions/users';
import { FilterTable } from '../../../components/others/FilterTable';
import { useNotification } from '../../../hooks/useNotification';
import { LoadActions } from '../../../components/users/LoadActions';
import { colTableUsers } from '../../../helpers/tableConfigs';
import { ModalFormsEditCreate } from '../../../components/users/ModalFormsEditCreate';

const TableHead = styled(MuiTableHead)(({ theme }) => ({
    '& .css-x6proc-MuiTableCell-root': {
        backgroundColor: '#b4b4b4',
        borderRight: `thin dotted   ${darken(theme.palette.text.main, 0.8)}`,
    },
    '& .MuiTableCell-root': {
        borderRight: `thin dotted   ${darken(theme.palette.text.main, 0.8)}`,
    },
    color: theme.palette.primary.main,
    '& .MuiTableCell-root span': {
        fontWeight: 'bold',
        '&.Mui-inactive, .MuiTableSortLabel-icon ': {
            color: theme.palette.text.main,
            opacity: 0.2,
        },
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    '& .MuiTableCell-root span.MuiSvgIcon-root:hover': {
        color: theme.palette.text.main,
    }
}));

export const LayoutUsers = () => {
    const dispatch = useDispatch();
    const [sendNotification] = useNotification();
    const [{ userSession }] = useDataAuth();
    const [filters] = useState({});
    const [pagination] = useState(
        {
            pageNumber: 1,
            pageSize: 25
        }
    );
    const [UsersManager] = useManageUsers();
    const {
        listAllUsers,
        isLoading,
        isNotification,
        msg,
        typeMsg,
        getAllPermissions
    } = UsersManager;

    useEffect(() => {
        if (isNotification) {
            sendNotification({ msg: msg, variant: typeMsg });
            dispatch(setNotificaction({}));
        }
    }, [isNotification, msg, typeMsg, dispatch, sendNotification]);

    const GetAllUsers = useCallback((variables) => {
        dispatch(getAllUsers(userSession.token, variables));
    }, [dispatch, userSession.token]);

    useEffect(() => {
        GetAllUsers(JSON.stringify({ filters, pagination }));
    }, [GetAllUsers, filters, pagination]);

    const handleChangePage = (event, newPage) => {
        pagination['pageNumber'] = newPage + 1;
        GetAllUsers(JSON.stringify({ filters, pagination }));
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        pagination['pageNumber'] = 1;
        pagination['pageSize'] = newRowsPerPage;
        GetAllUsers(JSON.stringify({ filters, pagination }));
    };

    const Search = (param, param2) => {
        filters[param2] = param;
        pagination['pageNumber'] = 1;
        GetAllUsers(JSON.stringify({ filters, pagination }));
    }

    const ResetSearch = (param) => {
        delete filters[param];
        GetAllUsers(JSON.stringify({ filters, pagination }));
    }

    return (
        <Box sx={{ width: '100%' }} className='custom-hard-table-zd-nix'>
            <Box sx={{ mb: 2, pt: 2, pl: 2, pr: 2 }}>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                    <Breadcrumbs
                        aria-label='breadcrumb'
                        separator='›'
                        sx={{ mb: 2 }}
                    >
                        <Link
                            underline='hover'
                            color='text.main'
                            sx={{ cursor: 'pointer' }}
                        >
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                            >
                                {'Administrar usuarios'}
                            </Typography>
                        </Link>
                    </Breadcrumbs>

                    <ModalFormsEditCreate
                        data={null}
                        isCreate={true}
                    />
                </Box>

                <Box sx={{ width: '100%' }}>
                    <Paper
                        sx={{
                            width: '100%',
                            mb: 2,
                            boxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                            WebkitBoxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                            bgcolor: (theme) => theme.palette.paper.background
                        }}
                        elevation={3}
                    >
                        <TableContainer
                            style={{ height: '72vh', overflow: 'auto', borderBottom: 'solid 1px' }}
                        >
                            <Table
                                aria-labelledby='tableTitle'
                                size='small'
                                stickyHeader
                            >
                                <TableHead>
                                    <TableRow sx={{ px: 2 }}>
                                        <TableCell
                                            padding='normal'
                                            align='center'
                                            style={{ minWidth: 120 }}
                                        >
                                            <Typography sx={{ fontWeight: 'bold' }} component='span'>
                                                Actions
                                            </Typography>
                                        </TableCell>
                                        {
                                            colTableUsers.map((col, index) => (
                                                col.visible &&
                                                <TableCell
                                                    padding='normal'
                                                    align='center'
                                                    key={`tc-${index}`}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Typography sx={{ fontWeight: 'bold', marginRight: '8px' }} component='span'>
                                                            {col.label}
                                                        </Typography>
                                                        {col.filterable && (
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <FilterTable colum={col} Search={Search} ResetSearch={ResetSearch} />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        isLoading ? (
                                            <TableRow sx={{ height: 200, py: 2 }}>
                                                <TableCell
                                                    colSpan={colTableUsers.length + 2}
                                                    align='center'
                                                >
                                                    <CircularProgress
                                                        color='primary'
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : !isLoading && !listAllUsers.length > 0 ? (
                                            <TableRow sx={{ height: 200, py: 2 }}>
                                                <TableCell
                                                    colSpan={colTableUsers.length + 2}
                                                    align='center'
                                                >
                                                    <Typography>No hay registros para mostrar</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            listAllUsers.map((item) => (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={item.id}
                                                >
                                                    <TableCell padding='normal' align='center'>
                                                        <Typography>
                                                            <LoadActions
                                                                data={item}
                                                                handleChange={getAllPermissions}
                                                                handleDelete={DeleteUser}
                                                            />
                                                        </Typography>
                                                    </TableCell>
                                                    {colTableUsers.map((col, index) => (
                                                        col.visible &&
                                                        <TableCell
                                                            key={`tc-${index}`}
                                                            padding='normal'
                                                            align='center'
                                                        >
                                                            <Typography>
                                                                {item[col.id]}
                                                            </Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <TablePaginations /> */}
                        <TablePagination
                            sx={{ display: 'table-row', verticalAlign: 'bottom' }}
                            rowsPerPageOptions={[25, 50, 100]}
                            component='Box'
                            count={10000}
                            rowsPerPage={pagination.pageSize}
                            page={pagination.pageNumber - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}