import { actionTypes } from "../contants/actionTypes";

const initialState = {
    settingsFlows: {
        listFlowVariables: [],
        loadingSettingsFlows: false,
        updatingSettingsFlows: false,
        deletingSettingsFlows: false,
        addingNewSettingsFlows: false,
        isErrorFetch: false,
        messageErrorFetch: ''
    }
}

export const settingsReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case actionTypes.settiStartLoadingSettingsFlows:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    loadingSettingsFlows: true
                }
            }
        case actionTypes.settiFinishLoadingSettingsFlows:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    loadingSettingsFlows: false
                }
            }
        case actionTypes.settiLoadAllSettingsFlows:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    listFlowVariables: action.payload,
                    error: false
                }
            }
        case actionTypes.settiStartUpdatingSettingsFlows:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    updatingSettingsFlows: true
                }
            }
        case actionTypes.settiFinishUpdatingSettingsFlows:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    updatingSettingsFlows: false
                }
            }
        case actionTypes.settiStartDeletingSettingsFlow:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    deletingSettingsFlows: true,
                }
            }
        case actionTypes.settiFinishDeletingSettingsFlows:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    deletingSettingsFlows: false,
                }
            }
        case actionTypes.settiStartAddingSettingsFlows:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    addingNewSettingsFlows: true,
                }
            }
        case actionTypes.settiFinishAddingSettingsFlow:
            return {
                ...state,
                settingsFlows: {
                    ...state.flows,
                    addingNewSettingsFlows: false,
                }
            }
        case actionTypes.settiErrorSettingFlows:
            return {
                ...state,
                isErrorFetch: action.payload.error,
                messageErrorFetch: action.payload.messageError
            }
        default:
            return state;
    }

}