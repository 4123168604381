import { Description, Dns } from '@mui/icons-material';
import { FormControlLabel, FormGroup, Grid, InputAdornment, Switch, TextField } from '@mui/material';
import React from 'react'
import { useErrorInputs } from '../../../../hooks/useErrorInputs';
import { useFocusInputs } from '../../../../hooks/useFocusInputs';

export const DetailsSummaryTypesStatus = ({
    typeStatusSelected,
    settingsManager
}) => {

    const {
        handleChangeTypeStatus
    } = settingsManager;

    const [{nameError, descriptionError, colorError}, handleErrorInput] = useErrorInputs({
        nameError:{
            isError: false,
            messageError: ''
        },
        descriptionError:{
            isError: false,
            messageError: ''
        },
        colorError:{
            isError: false,
            messageError: ''
        }
    });  
    const [{isNameFocus, isDescriptionFocus, isColorFocus}, handleFocusInputs] = useFocusInputs({
        isNameFocus: false,
        isDescriptionFocus: false,
        isColorFocus: false
    });

    return (
        <Grid container>

            <Grid item md={6} sx={{p:2}}>
                <FormGroup>
                    <TextField
                        error={!isNameFocus && nameError.isError}
                        color={(isNameFocus) ? "text" : (nameError.isError) ? "error" : "text"}
                        label="Name Type Status"
                        variant="outlined"
                        name="type"
                        placeholder="Enter name type status"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Dns color={(isNameFocus) ? "text" : (nameError.isError) ? "error" : "text"}/>
                                </InputAdornment>
                            ),
                        }}                        
                        value={typeStatusSelected.name}
                        helperText={nameError.messageError}
                        onChange={(e) => handleChangeTypeStatus(e.target, typeStatusSelected.id_status)}
                        onFocus={handleFocusInputs}
                        onBlur={handleFocusInputs}
                    />
                </FormGroup>

                <FormGroup sx={{my: 2}}>
                    <TextField
                        multiline
                        rows={2}
                        error={!isDescriptionFocus && descriptionError.isError}
                        color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}
                        label="Description"
                        variant="outlined"
                        name="description"
                        placeholder="Enter description"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Description color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}/>
                                </InputAdornment>
                            )
                        }}                        
                        value={typeStatusSelected.description} 
                        helperText={descriptionError.messageError}
                        onChange={(e) => handleChangeTypeStatus(e.target, typeStatusSelected.id_status)}
                        onFocus={handleFocusInputs}
                        onBlur={handleFocusInputs}
                    />
                </FormGroup>
            </Grid>

            <Grid item md={6} sx={{p:2}}>

                <FormGroup>
                    <TextField
                        error={!isColorFocus && colorError.isError}
                        color={(isColorFocus) ? "text" : (colorError.isError) ? "error" : "text"}
                        label="Color"
                        variant="outlined"
                        name="color"
                        placeholder="Enter color"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Description color={(isColorFocus) ? "text" : (colorError.isError) ? "error" : "text"}/>
                                </InputAdornment>
                            )
                        }}                        
                        value={typeStatusSelected.color} 
                        helperText={colorError.messageError}
                        onChange={(e) => handleChangeTypeStatus(e.target, typeStatusSelected.id_status)}
                        onFocus={handleFocusInputs}
                        onBlur={handleFocusInputs}
                    />
                </FormGroup>
                <FormGroup sx={{my: 2}}>
                    <FormControlLabel
                        labelPlacement="top"
                        control={
                            <Switch 
                                name="active"
                                onChange={(e) => handleChangeTypeStatus(
                                        {
                                            name: e.target.name, 
                                            value: e.target.checked ? 'Y' : 'N'
                                        }, 
                                        typeStatusSelected.id_status
                                    )
                                } 
                                checked={typeStatusSelected?.active === 'Y'} 
                            />
                        }
                        label="Active"
                    />
                </FormGroup>
            </Grid>
        </Grid>
    )
}
