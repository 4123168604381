import { CircularProgress, Divider, Grid, Paper } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import logoImage from '../../../assets/images/IaAutomationLogo.png';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { FormAuthV2 } from '../forms/FormAuthV2';
import { FormNewPassword } from '../forms/FormNewPassword';
import { FormRecoverPassword } from '../forms/FormRecoverPassword';

export const LayoutLogin = () => {
    const [authData] = useDataAuth();
    const { checkingSession } = authData;

    return checkingSession ? (
        <Grid
            item
            md={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress />
        </Grid>
    ) : (
        <>
            <Grid item md={1} />
            <Grid
                item
                xs={false} sm={5} md={5} lg={5}
                display={{ xs: "none", sm: "flex" }}
                alignItems="center"
                justifyContent="flex-end"
                sx={{ pr: '10vw', height: '100%' }}
            >
                {/* <img src={logoImage} alt="imagen" width='230' height='230' /> */}
            </Grid>
            {/* <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{
                    my: '20vh',
                    borderRightWidth: 5,
                    bgcolor: (theme) => theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.main
                }}
            /> */}
            <Grid
                item
                xs={12} sm={4} md={4} lg={4}
                display={{ xs: "flex", sm: "flex" }}
                alignItems="center"
                justifyContent="flex-start"
                sx={{ pl: '6vw', height: '100%' }}
            >
                <Paper
                    elevation={4}
                    sx={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        minHeight: '40vh',
                        width: '100%',
                        borderRadius: 4,
                        opacity: 0.9,
                        px: 3,
                        py: 5
                    }}
                >
                    <Routes>
                        <Route path="/" exact="true" element={
                            <FormAuthV2 authData={authData} />
                        } />
                        <Route path="/newPassword" exact="true" element={
                            <FormNewPassword authData={authData} />
                        } />
                        <Route path="/recoverPassword" exact="true" element={
                            <FormRecoverPassword authData={authData} />
                        } />
                    </Routes>
                </Paper>
            </Grid>
            <Grid item md={2} />
        </>
    )
}