import { Box } from '@mui/system';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDataAuth } from '../../../../hooks/useDataAuth';
import { ListDragDrop } from '../../../../components/lists/ListDragDrop';
import { ItemAccordionListComponentFlow } from './ItemAccordionListComponentFlow';

export const StepComponentsFlow = ({
  valueItem,
  handleChange
}) => {

  const [componentsFlow, setComponentsFlow] = useState(valueItem.flow_components);
  const [expanded, setExpanded] = useState(false);
  const [{username}]  = useDataAuth();

  const handleClickNewComponentFlow = () =>{
    const newFlowComponentObject = {
      is_new_component: true,      
      id_flow_component: `NC-${componentsFlow.length+1}`,
      type: "",     
      order_in_flow: componentsFlow.length+1,
      type_execute: "",
      process_input: {},
      acceptance_process: {},
      process_fail: {},
      created: moment().toDate(),
      created_by: username,
      updated: moment().toDate(),
      updated_by: username,
      sub_flow_components: []
    }; 
    setComponentsFlow([
      ...componentsFlow,
      newFlowComponentObject
    ])
  }

  const handleChangeOrderComponentFlows= (newArrayComponentFlows) => {
    setComponentsFlow(newArrayComponentFlows);
  }

  const handleDeleteComponentFlow = (idComponentFlow) => {
    const newFilterArrayComponent = componentsFlow.filter(componentFlow => componentFlow.id_flow_component !== idComponentFlow);
    const newArrayComponent = newFilterArrayComponent?.map((componentFlow, index) => (
      {
        ...componentFlow,
        id_flow_component: `NC-${index+1}`,
        order_in_flow: index+1
      }
    ));
    setComponentsFlow(newArrayComponent);
  }

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const handleChangeComponentFlow = (idComponentFlow, name, value) => {
    setComponentsFlow(componentsFlow.map(componentFlow => {
      if(componentFlow.id_flow_component === idComponentFlow){
        return {
          ...componentFlow,
          [name]: value
        }
      }else{
        return componentFlow;
      }
    }));
  }

  useEffect(() => {
    handleChange("flow_components", componentsFlow);
  }, [componentsFlow]) 

  return (
    <Box
      sx={{p: 4}}
    >
      <ListDragDrop
          numberGridList={8}
          nameItem="component flow"
          keyItem="componentFlow"
          keyItemOrder="order_in_flow"
          handleClickNewItem={handleClickNewComponentFlow}
          listItems={componentsFlow}
          itemComponent={
              <ItemAccordionListComponentFlow
                  handleDeleteComponentFlow={handleDeleteComponentFlow}
                  expanded={expanded}
                  handleChangeExpanded={handleChangeExpanded}
                  handleChange={handleChangeComponentFlow}
              />
          }
          handleChangeOrderItems={handleChangeOrderComponentFlows}
      />
    </Box>
  )
}
