import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { html as beautifyHtml } from 'js-beautify';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';

export const AceEditorHtml = forwardRef((props, ref) => {
    const { html, handleChange } = props;
    const [value, setValue] = useState(html);

    useImperativeHandle(ref, () => ({
        ResetChanges() {
            const formattedHtml = beautifyHtml(html, {
                indent_size: 2,
                space_in_empty_paren: true,
            });
            setValue(formattedHtml);
        },
        AcceptChanges() {
            // const minifiedHtml = value.replace(/\s+/g, '');
            // console.log({ minifiedHtml });
            handleChange(value);
        }
    }));

    useEffect(() => {
        const formattedHtml = beautifyHtml(html, {
            indent_size: 2,
            space_in_empty_paren: true,
        });
        setValue(formattedHtml);
    }, [html]);

    const OnChange = (data) => {
        setValue(data);
    };

    return (
        <AceEditor
            mode='html'
            theme='monokai'
            name='html_editor'
            value={value}
            onChange={OnChange}
            fontSize={16}
            width='100%'
            height='480px'
            editorProps={{ $blockScrolling: true }}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
            }}
        />
    )
})

AceEditorHtml.displayName = 'AceEditorHtml';
export default AceEditorHtml;
