import { TextField } from '@mui/material';
import React from 'react';

export function StringEditor({ label, value, onChange }) {
  return (
    <TextField
      sx={{
        '& .MuiInputBase-root': {
          flex: 1,
        },
      }}
      size="small"
      label={label}
      fullWidth
      style={{ flex: 1 }}
      InputLabelProps={{ shrink: true }}
      hiddenLabel
      autoCorrect="off"
      spellCheck="false"
      autoComplete="off"
      variant="outlined"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
