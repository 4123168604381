import { Delete, ExpandMore, MoreVert, SchemaOutlined, SyncAltOutlined } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Grid, IconButton, InputAdornment, InputLabel, lighten, Menu, MenuItem, TextField, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react';
import { config } from '../../../../helpers/config';
import { EdiItemTabs } from '../../../../components/editItem/EdiItemTabs';
import { Dialog } from '../../../../components/others/Dialog';
import { JsonEditorV2 } from '../../../../components/jsonEditorV2/JsonEditorV2';

export const ItemAccordionListSubComponentFlow = ({
    subComponentFlow,
    handleDeleteComponentFlow,
    expanded,
    handleChangeExpanded,
    handleChange
}) => {

    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmDeleteComponentFlow = () => {
        handleDeleteComponentFlow(subComponentFlow.id_flow_component);
        setOpenDialogConfirmDelete(false);
    }

    return (
        <Accordion 
            expanded={expanded === `panel${subComponentFlow.order_in_flow}`} 
            onChange={handleChangeExpanded(`panel${subComponentFlow.order_in_flow}`)}
        >
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls={`panel${subComponentFlow.order_in_flow}bh-content`}
                id={`panel${subComponentFlow.order_in_flow}bh-header`}
                sx={{
                    flexDirection: 'row-reverse',
                    bgcolor: (theme) => lighten(theme.palette.paper.background, 0.090)
                }}
            >
                <Box 
                    sx={{
                        width: '100%',
                    }}
                >
                    <Toolbar variant="dense">
                        <Avatar
                            sizes="small"
                            sx={{
                                mr: 2,
                                bgcolor: (theme) => theme.palette.primary.main
                            }}
                        >
                            {subComponentFlow.order_in_flow_component} 
                        </Avatar>
                        <Box>
                            <Typography 
                                color="text.main" 
                                sx={{fontWeight: 'bold'}}
                            >
                                {`Id Sub Flow Component: ${subComponentFlow.id_flow_component}`}
                            </Typography>
                            {
                                (subComponentFlow.type && expanded === `panel${subComponentFlow.id_flow_component}`) && 
                                <Typography 
                                    color="primary" 
                                    component="small"
                                    sx={{fontSize: 11}}
                                >
                                    {`Type Component: ${subComponentFlow.type}`}
                                </Typography>
                            }
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ 
                            display: { 
                                xs: 'none',
                                md: 'flex'
                            } 
                        }}>
                            <IconButton 
                                size="small" 
                                color="inherit"
                                onClick={handleClick}
                                aria-controls={open ? 'sub-component-flow-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <MoreVert />
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="sub-component-flow-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                            elevation: 3,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem
                                onClick={() => setOpenDialogConfirmDelete(true)}
                            >
                                <Delete /> Eliminar sub component flow
                            </MenuItem>
                        </Menu>
                        <Dialog
                            openDialog={openDialogConfirmDelete}
                            widthDialog="xs"
                            content={
                                <Typography
                                    variant="h7"
                                >
                                    Are you sure you want to remove this component?
                                </Typography>
                            }
                            styleContent={{px: 3, py: 3}}
                            actions={
                                <Grid
                                    item md={12} 
                                    sx={{
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'center',
                                        py: 2
                                    }}
                                    >
                                    <Button
                                        variant="outlined" 
                                        color="error" 
                                        autoFocus
                                        size="small"
                                        onClick={() => setOpenDialogConfirmDelete(false)}
                                        sx={{
                                            color: (theme) => theme.palette.text.main,
                                            mx: 3
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant="contained"
                                        color="error"
                                        onClick={handleConfirmDeleteComponentFlow}
                                        size="small"
                                    >
                                        Delete sub component flow
                                    </Button>
                                </Grid>
                            }
                        />
                    </Toolbar>
                </Box>
            </AccordionSummary>
            <AccordionDetails 
                sx={{
                    p:0, 
                    bgcolor: (theme) => lighten(theme.palette.paper.background, 0.090)
                }}
            >
                <Box
                    component="form"            
                    noValidate
                    autoComplete="off"
                >
                    <Grid 
                        container
                        sx={{px: 4, mb: 3}}
                    >
                        <Grid item md={6} sx={{pr: 1}}>
                            <InputLabel id="label-type-component">
                                <Typography 
                                    color="text.main"
                                    sx={{fontWeight: 'bold'}}
                                >
                                    Type Sub Component Flow
                                </Typography>
                            </InputLabel>                                                        
                            <TextField
                                id="type-sub-component-flow"
                                name="type"
                                value={!subComponentFlow.type ? "" : subComponentFlow.type}
                                color="text"
                                select
                                fullWidth
                                size="small"
                                onChange={(e) => handleChange(subComponentFlow.id_flow_component, "type", e.target.value)}
                                autoComplete="off"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SchemaOutlined color="primary"/>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{mb: 1}}
                            >
                                <MenuItem value="" disabled>
                                    <em>Type Sub Component Flow</em>
                                </MenuItem>
                                {
                                    config.flowVariables.typesComponentFlows.map(({type}, index) => (
                                        <MenuItem
                                            key={index}
                                            value={type}
                                        >
                                            {type}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item md={6} sx={{pl: 1}}>
                            <InputLabel id="label-type-execute">
                                <Typography 
                                    color="text.main"
                                    sx={{fontWeight: 'bold'}}
                                >
                                    Type Execute
                                </Typography>
                            </InputLabel> 
                            <TextField
                                id="type-execute-flow"
                                name="type_execute"
                                value={!subComponentFlow.type_execute ? "" : subComponentFlow.type_execute}
                                color="text"
                                select
                                fullWidth
                                size="small"
                                onChange={(e) => handleChange(subComponentFlow.id_flow_component, "type_execute", e.target.value)}
                                autoComplete="off"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SyncAltOutlined color="primary"/>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{mb: 1}}
                            >
                                <MenuItem value="" disabled>
                                    <em>Type Execute</em>
                                </MenuItem>
                                {
                                    config.flowVariables.typesExecute.map(({type}, index) => (
                                        <MenuItem
                                            key={index}
                                            value={type}
                                        >
                                            {type}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <EdiItemTabs
                            isLoadingItems={false}
                            elevation={8}
                            tabs={[
                                {
                                    label: 'Process Input',
                                    value: '1',
                                    bodyTab: <Box sx={{p: 3}}>
                                        <JsonEditorV2
                                            titleKey={"processInput"}
                                            contentJson={subComponentFlow.process_input}
                                            handleChange={(event) => handleChange(subComponentFlow.id_flow_component, "process_input", event)}
                                            viewEditorResult={true}
                                            viewCheckBoxSelect={true}
                                        />
                                    </Box>
                                },
                                {
                                    label: 'Acceptance Process',
                                    value: '2',
                                    bodyTab: <Box sx={{p: 3}}>
                                        <JsonEditorV2
                                            titleKey={"acceptanceProcess"}
                                            contentJson={subComponentFlow.process_input}
                                            handleChange={(event) => handleChange(subComponentFlow.id_flow_component, "acceptance_process", event)}
                                            viewEditorResult={true}
                                            viewCheckBoxSelect={true}
                                        />
                                    </Box>
                                },
                                {
                                    label: 'Process Fail',
                                    value: '3',
                                    bodyTab: <Box sx={{p: 3}}>
                                        <JsonEditorV2
                                            titleKey={"processFail"}
                                            contentJson={subComponentFlow.process_input}
                                            handleChange={(event) => handleChange(subComponentFlow.id_flow_component, "process_fail", event)}
                                            viewEditorResult={true}
                                            viewCheckBoxSelect={true}
                                        />
                                    </Box>
                                }
                            ]}
                        />
                    </Grid>                  
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}
