import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { ModalFormsEditCreate } from '../users/ModalFormsEditCreate';
import { DialogConfirmAction } from '../dialogs/DialogConfirmAction';
import { useDispatch } from 'react-redux';
import { useDataAuth } from '../../hooks/useDataAuth';

export const LoadActions = ({ data, handleChange, handleDelete }) => {
    const [{ userSession }] = useDataAuth();
    const dispatch = useDispatch();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [idUser, setIdUser] = useState(false);
    const DeleteUser = () => {
        setOpenConfirmation(false);
        dispatch(handleDelete(userSession.token, idUser));
    }

    return (
        <React.Fragment>
            <Box>
                <ModalFormsEditCreate
                    data={data}
                    isCreate={false}
                    handleClick={handleChange}
                />
                <Tooltip title={'Eliminar'}>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setOpenConfirmation(true);
                            setIdUser(data.id_user);
                        }}
                        sx={{
                            color: 'red',
                        }}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            </Box>

            <DialogConfirmAction
                openDialogConfirm={openConfirmation}
                setOpenDialogConfirm={() => setOpenConfirmation(false)}
                titleDialog={'Eliminar usuario'}
                textConfirmAction={"¿Esta seguro que desea eliminar el registro?"}
                textButtonConfirm={"Aceptar"}
                textButtonCancel={"Cancelar"}
                handleConfirmAction={DeleteUser}
            />
        </React.Fragment>
    )
}