import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { EditItem } from '../../../../components/editItem/EditItem';
import { DetailsSummaryTypesFlow } from './DetailsSummaryTypesFlow';

export const DetailsTypesFlow = ({
    settingsManager
}) => {

    const {
        flowVariables,
        isChangedSettings,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        addingNewSettingsFlows,
        handleUpdatingSettingsFlows,
        isErrorFetch,
        messageErrorFetch,
        handleDeleteTypeFlow,
        handleRefreshSettingsFlow
    } = settingsManager;
    const params = useParams();
    const [typeFlowSelected, setTypeFlowSelected] = useState({});
    const [isLoadingTypeFlow, setIsLoadingTypeFlow] = useState(false);

    useEffect(() => {
        setIsLoadingTypeFlow(true);
        setTimeout(() => {
            const typeFlowSelected = flowVariables?.find(typeFlow => typeFlow.id === parseInt(params.idTypeFlow));
            setTypeFlowSelected(typeFlowSelected);
            setIsLoadingTypeFlow(false);
        }, 500);
    }, []);
   
    useEffect(() => {
        const typeFlowSelected = flowVariables?.find(typeFlow => typeFlow.id === parseInt(params.idTypeFlow));
        setTypeFlowSelected(typeFlowSelected);
    }, [flowVariables]);

    return (
        <EditItem
            isLoadingItems={isLoadingTypeFlow}
            titleEditItem={{
                label: `Id Type Flow: ${typeFlowSelected.id}`,
                isEditable: false
            }}
            nameItem={"Type Flow"}
            isSaving={updatingSettingsFlows}
            statusButtonSave={isChangedSettings}
            handleSaveChanges={handleUpdatingSettingsFlows}
            isDeleting={deletingSettingsFlows}
            statusButtonDelete={true}
            handleClickDelete={handleDeleteTypeFlow}
            contentSummary={<DetailsSummaryTypesFlow
                typeFlowSelected={typeFlowSelected}
                settingsManager={settingsManager}
            />}
        />
    )
}
