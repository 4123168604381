import { Chip, LinearProgress, Typography } from "@mui/material";
import moment from "moment";

const ItemStatus = ({value, statusManager}) => {
   
    const {
        listStatus,
        loadingStatus
    } = statusManager;   

    const status = listStatus.length > 0 ? listStatus.find(status => status.id_status === value) : {};

    return loadingStatus ? (
        <LinearProgress color="inherit" />
    ) : listStatus.length > 0 && (
        <Chip 
            label={!status || !status?.name ? "not info..." : status.name} 
            variant="outlined" 
            sx={{
                width: '100%', 
                my: 0,
                border: `2px solid ${!status || !status?.color ? "white" : status.color}`,
                color: !status || !status?.color ? "white" : status.color
            }}
        />
    )
}

const ItemDate = ({value}) => {
    return <Typography>
        {moment(value).format('DD/MM/YYYY h:mm A')}
    </Typography>
}

const ItemText = ({value}) => {
    return <Typography>
        {value}
    </Typography>
}

export const headersFlowsTable = [
    {
        id: 'id_flow',
        label: 'Id Flow',
        type: 'number',
        align: 'center',
        customComponent: <ItemText/>,
        disabled: true,
        visible: true,
        orderable: true,
        filterType: 'textField',
        filter: true,
    },
    {
        id: 'name',
        label: 'Name Flow',
        type: 'string',
        align: 'left',
        customComponent: <ItemText/>,
        disabled: false,
        isLinkeable: true,
        visible: true,
        orderable: true,
        filterType: 'textField',
        options: { 
            filterOptions: { 
                fullWidth: true 
            } 
        },
        filter: true,
    },
    {
        id: 'type',
        label: 'Flow Type',
        type: 'string',
        align: 'left',
        customComponent: <ItemText/>,
        disabled: false,
        visible: true,
        orderable: true,
        filterType: 'multiselect',
        options: { 
            filterOptions: { 
                fullWidth: true 
            } 
        },
        filter: true,
    },
    {
        id: 'cron_expression',
        label: 'Cron',
        type: 'string',
        align: 'left',
        customComponent: <ItemText/>,
        disabled: false,
        visible: false,
        orderable: true,
        filterType: 'textField'
    },
    {
        id: 'next_execute',
        label: 'Next Execute',
        type: 'date',
        align: 'left',
        customComponent: <ItemDate/>,
        disabled: false,
        visible: false,
        orderable: true,
        filterType: 'textField'
    },
    {
        id: 'id_status',
        label: 'Status',
        type: 'status',
        align: 'center',
        customComponent: <ItemStatus/>,       
        disabled: false,
        visible: true,
        orderable: false,
        filterType: 'multiselect',
        options: { 
            filterOptions: { 
                fullWidth: true 
            } 
        },
        filter: true
    },
    {
        id: 'created',
        label: 'Created',
        type: 'date',
        align: 'left',
        customComponent: <ItemDate/>,
        disabled: false,
        visible: false,
        orderable: true,
        filterType: 'date',
        filter: true
    },
    {
        id: 'created_by',
        label: 'Created BY',
        type: 'string',
        align: 'left',
        customComponent: <ItemText/>,
        disabled: false,
        visible: false,
        orderable: true,
        filterType: 'date',
        filter: true
    },
    {
        id: 'updated',
        label: 'Last Updated',
        type: 'date',
        align: 'left',
        customComponent: <ItemDate/>,
        disabled: false,
        visible: true,
        orderable: true,
        filterType: 'date',
        filter: true
    },
    {
        id: 'updated_by',
        label: 'Updated By',
        type: 'string',
        align: 'left',
        customComponent: <ItemText/>,
        disabled: false,
        visible: true,
        orderable: true,
        filterType: 'date',
        filter: true
    }
    // {
    //     id: 'configuration',
    //     label: 'Configuration',
    //     type: 'status',
    //     align: 'left',
    //     width: 10,
    //     disabled: false,
    // }
];