import React, { useEffect, useState } from 'react';
import {
    Dialog, Typography, DialogContent, DialogTitle, IconButton, Tooltip, Box, TextField, FormGroup
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close, FlipCameraAndroid, Check, Add, DeleteForever } from '@mui/icons-material';

export const ArrayTextEditor = ({ field, arrayText, handleChange }) => {
    const [open, setOpen] = useState(false);
    const [array, setArray] = useState(arrayText);

    useEffect(() => {
        setArray(arrayText);
    }, [arrayText])

    const handleUpdateItem = (value, index) => {
        if (index >= 0 && index < array.length) {
            setArray(prevArray => {
                const newArray = [...prevArray];
                newArray[index] = value;
                return newArray;
            });
        }
    };

    const AcceptChanges = () => {
        arrayText = array;
        handleChange(field.id, array)
    }

    return (
        <React.Fragment>
            <LoadingButton
                size='small'
                variant='outlined'
                color='info'
                onClick={() => setOpen(true)}
            >
                Text array
            </LoadingButton>
            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                PaperProps={{
                    style: { height: '95%' },
                }}
            >
                <DialogTitle>
                    <Typography variant='h5' component='h5'>
                        Editor de questions
                    </Typography>
                    <Box
                        display='flex'
                        sx={{ mb: 2, position: 'sticky', top: 0, zIndex: 1 }}
                        justifyContent='flex-end'
                    >
                        <Tooltip title='Actualizar cambios'>
                            <IconButton
                                color='success'
                                onClick={() => AcceptChanges()}
                            >
                                <Check />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Desacer cambios'>
                            <IconButton
                                color='error'
                                onClick={() => (
                                    setArray(arrayText)
                                )}
                            >
                                <FlipCameraAndroid />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <IconButton
                        aria-label='close'
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                    <hr />
                </DialogTitle>
                <DialogContent>
                    {
                        array.map((item, index) => (
                            <Box sx={{ marginTop: 1 }}>
                                <FormGroup
                                    key={index}
                                    style={{ width: '100%' }}
                                    fullWidth
                                >
                                    <Box sx={{ display: 'flex' }}>
                                        <Box sx={{ width: '100%' }}>
                                            <TextField
                                                multiline
                                                minRows={1}
                                                variant='outlined'
                                                fullWidth
                                                value={item}
                                                onChange={(e) => handleUpdateItem(e.target.value, index)}
                                            />
                                        </Box>
                                        <Tooltip title='Eliminar'>
                                            <IconButton
                                                color='error'
                                                onClick={() => (
                                                    setArray(prevArray => prevArray.filter((_, i) => i !== index))
                                                )}
                                            >
                                                <DeleteForever />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </FormGroup>
                            </Box>
                        ))
                    }
                    <Box
                        display='flex'
                        sx={{ mb: 2, position: 'sticky', top: 0, zIndex: 1 }}
                        justifyContent='center'
                    >
                        <Tooltip title='Añadir'>
                            <IconButton
                                color='info'
                                onClick={() => (
                                    setArray(prevArray => [...prevArray, ''])
                                )}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}