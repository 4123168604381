import { actionTypes } from "../contants/actionTypes";

const initialState = {
    isLoadingCompanies: false,
    isErrorLoaded: false,
    listCompanies: [],
    // companySelected: null
    companySelected: localStorage.getItem('companySelected') 
    // ? JSON.parse(localStorage.getItem('companySelected')) 
    // : {
    //     idCompanySelected: 0,
    //     nameCompanySelected: ''
    // }
}

export const companyReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case actionTypes.compLoadingStart:
            return {
                ...state,
                isLoadingCompanies: true,
            }
        case actionTypes.compLoadingFinish:
            return {
                ...state,
                isLoadingCompanies: false,
            }
        case actionTypes.compLoadCompanies:
            return {
                ...state,
                listCompanies: action.payload.listCompanies,
                isErrorLoaded: action.payload.isErrorLoaded
            }
        case actionTypes.compSaveCompanySelected:
            localStorage.setItem('companySelected', JSON.stringify(action.payload));
            return {
                ...state,
                companySelected: action.payload
            }
        default:
            return state;
    }

}