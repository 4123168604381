import React, { useState } from 'react';
import { Box, Breadcrumbs, Link, Paper, Tab, Tabs, Typography } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SettingsFlows } from '../flows/SettingsFlows';
import { TemplatesJson } from '../templatesJson/flows/TemplatesJson';

// const LinkRouter = (props) => <Link {...props} component={RouterLink} />;
 
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        style={{height: '100%'}}
        {...other}
    >
        {value === index && (
        <Box sx={{ p: 3 }}  style={{height: '100%', padding: 0}}>
            {children}
        </Box> 
        )}
    </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
        style: {fontSize: 14 }
    };
}

export const LayoutSettings = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const [valueTab, setValueTab] = useState(
        localStorage.getItem('lastTabSettings') ? parseInt(localStorage.getItem('lastTabSettings')) : false
    );
    const patharray = location.pathname.split('/').filter((x) => x);
    const pathnames = patharray.slice(1, patharray.length + 1);

    // useEffect(() => {
    //     navigate(localStorage.getItem('lastPathSettings') || "/settings/flows")
    // }, []);

    const handleLinkBreadcrumbClick = (event, path) => {
        navigate(path);
    }

    const validateChangesPending = (path) => {
        handleLinkBreadcrumbClick(null, path);
    }
  
    const handleChangeTab = (event, newValue) => {
        localStorage.setItem('lastTabSettings', newValue);
        setValueTab(newValue);
    };

    const handleClickTabs = (tabPathNavigate) => {
        navigate(tabPathNavigate);
        localStorage.setItem('lastPathSettings', tabPathNavigate);
    }

    return (    
        <Box sx={{ width: '100%' }}>
            <Box sx={{  mb: 2, pt: 2, pl: 2, pr: 2 }}>
                <Breadcrumbs 
                    aria-label="breadcrumb"
                    separator="›"
                    sx={{mb:2}}
                >
                    <Link
                        underline="hover" 
                        color="text.main"
                        sx={{cursor: 'pointer'}}
                        onClick={() => validateChangesPending('/settings')}
                    >
                        <Typography 
                            sx={{fontWeight: pathnames.length === 0 && "bold"}}
                        >
                            Settings
                        </Typography>                        
                    </Link>
                    {
                        pathnames.length > 0 && pathnames.map((value, index) => {
                            const last = index === pathnames.length - 1;
                            const to = `/settings/${pathnames.slice(0, index + 1).join('/')}`;

                            return last ? (
                                <Typography 
                                    sx={{fontWeight: "bold"}}
                                    color="primary"
                                    key={to}
                                >
                                    {/* { 
                                        index === 0 && parseInt(value) ? `Flow: ${value}` 
                                        : index === 1 && parseInt(value) ? `Component Flow: ${value}` 
                                        : index === 2 && parseInt(value) ? `Sub Component Flow: ${value}` 
                                        : value
                                    } */}
                                    {
                                        value.split("-").map(item => (
                                            item.charAt(0).toUpperCase()+item.slice(1)
                                        )).join(" ")
                                    }
                                </Typography>
                            ) : (
                                <Link
                                    key={to}
                                    underline="hover" 
                                    color="text.main"
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => validateChangesPending(to)}
                                >
                                    {
                                        value.split("-").map(item => (
                                            item.charAt(0).toUpperCase()+item.slice(1)
                                        )).join(" ")
                                    }
                                </Link>
                            );
                        })
                    }
                </Breadcrumbs>
                <Box
                    sx={{mb:2}}
                >
                    <Typography
                        color="text.main"
                        variant="h4"
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        Settings
                    </Typography>
                </Box>
                <Box>
                    <Paper 
                        elevation={1}
                        sx={{
                            py: 1,
                            px: 2,
                            bgcolor: (theme) => theme.palette.paper.background
                        }}
                    >
                        <Paper 
                            variant="outlined"
                            sx={{
                                mb: 3
                            }}
                        >
                            <Tabs
                                value={valueTab}
                                onChange={handleChangeTab}
                                textColor="inherit"
                                variant="scrollable"                                
                            >
                                <Tab 
                                    label="Flows"
                                    onClick={() => handleClickTabs("/settings/flows")}
                                    {...a11yProps(0)} 
                                />
                                <Tab 
                                    label="Templates Json"
                                    onClick={() => handleClickTabs("/settings/templates-json")}
                                    {...a11yProps(1)} 
                                />
                            </Tabs>
                        </Paper>
                        <Routes>
                            {/* <Route path="/" exact="true" element={
                                <Paper 
                                    elevation={1}
                                    style={{
                                        height: '50px',
                                        bgcolor: (theme) => theme.palette.paper.background
                                    }}
                                >
                                    <div
                                        index={valueTab}
                                        style={{height: '50px'}}
                                    >
                                        <TabPanel value={valueTab} index={0}></TabPanel>
                                    </div>
                                </Paper>
                            }/> */}
                            <Route path="/flows/*" element={
                                <TabPanel value={valueTab} index={0}>
                                    <SettingsFlows/>
                                </TabPanel>
                            }/>
                            <Route path="/templates-json/*" element={
                                <TabPanel value={valueTab} index={1}>
                                    <TemplatesJson />
                                </TabPanel>
                            }/>
                            {/* <Route path="/flow-:idFlow" element={
                                <DetailsFlow 
                                    flowManager={flowManager}
                                />
                            }/> */}
                        </Routes>
                    </Paper>
                </Box>
            </Box>
        </Box>
    )

}
