import { fetchDataMenu } from "../../services/menu.service";
import { actionTypes } from "../contants/actionTypes"

export const getMenu = (accessToken) => {
    return (dispatch) => {
        fetchDataMenu(accessToken)
            .then(({ data }) => {
                dispatch(loadMenu(transformData(data)));
            }).catch((e) => {
                console.log('error', e)
            }).finally(() => {
            });
    }
};

export const loadMenu = (data) => ({
    type: actionTypes.loadMenu,
    payload: !data ? [] : data
});

const transformData = (data) => {
    let result = [];
    let listItems = {};

    data.forEach(item => {
        const key = item.menu;

        if (listItems[key])
            listItems[key].push(item);
        else
            listItems[key] = [item];
    });

    for (let key in listItems) {
        if (listItems[key].length > 1)
            result.push({
                type: "list",
                menu: key,
                body: listItems[key]
            });
        else
            result.push({
                type: "unique",
                ...listItems[key][0]
            });
    }

    return result;
}