import axios from 'axios';
import { rowsComponentFlowsTable } from '../screens/flows/utils/rowsComponentFlowsTable';
import { rowsFlowsTable } from '../screens/flows/utils/rowsFlowsTable';

const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;

export const fetchDataFlows = (idCompany, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            process: "flow_select",
            action: "SELECT",
            variables: "{}"
        }
    })

    // return new Promise((resolve, reject) =>{
    //     setTimeout(() => {
    //         resolve({
    //             data: {
    //                 result: rowsFlowsTable
    //             }
    //         });
    //     }, 2000);
    // })
}

export const fetcSchemeFlows = (accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            process: "component_schema_select",
            action: "SELECT",
            variables: "{}"
        }
    })

    // return new Promise((resolve, reject) =>{
    //     setTimeout(() => {
    //         resolve({
    //             data: {
    //                 result: rowsFlowsTable
    //             }
    //         });
    //     }, 2000);
    // })
}

export const insertDataFlows = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            procedure_name: "dbo.sp_manage_flows",
            action: "INSERT",
            flows: data
        }
    })
    // return new Promise((resolve, reject) =>{
    //     setTimeout(() => {
    //         resolve({
    //             data: null
    //         });
    //     }, 2000);
    // })
}

export const updatedDataFlows = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
            // 'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            process: "flow_alter",
            action: "UPDATE_COMPONENT",
            variables: data
        }
    })
    // return new Promise((resolve, reject) =>{
    //     setTimeout(() => {
    //         resolve({
    //             data: null
    //         });
    //     }, 2000);
    // })
}


export const deleteDataFlows = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            procedure_name: "dbo.sp_manage_flows",
            action: "DELETE_FLOWS",
            flows: data
        }
    })
}

export const deleteDataComponentFlow = (data, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            procedure_name: "dbo.sp_manage_flows",
            action: "DELETE_FLOW_COMPONENT",
            flows: data
        }
    })
}

export const fetchDataExecutionsByIdFlow = (idFlow, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: {
            procedure_name: "dbo.sp_manage_executions",
            action: "SELECT",
            flow: {
                id_flow: idFlow
            }
        }
    })
}

export const GetDataFlow = (idFlow, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        data: {
            'process': "flow_select",
            'action': "SELECT_ID_FLOW",
            'variables': JSON.stringify({ 'id_flow': idFlow })
        }
    })
}

export const fetchDataConfig = (idFlow, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            // 'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: JSON.stringify({
            'process': 'flow_select',
            'action': 'SELECT_ID_FLOW_CONFIGURATION',
            'variables': JSON.stringify({ 'id_flow': idFlow })
        })
    })
}

export const UpdateConfig = (idFlow, dto, accessToken) => {
    return axios({
        method: "POST",
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            // 'X-Api-Key': 'zBnH4QVGG9dGmTcErSYPdD5eKPgSIaRQ'
        },
        data: JSON.stringify({
            'process': 'flow_alter',
            'action': "UPDATE_CONFIGURATION",
            'variables': JSON.stringify({ 'id_flow': idFlow, 'configuration': dto })
        })
    })
}