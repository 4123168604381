import { useSelector } from 'react-redux';

export const useManageDocQuery = () => {
    const {
        listAllDocs,
        isLoading,
        historyDoc
    } = useSelector(state => state.docs);

    return [{
        listAllDocs,
        isLoading,
        historyDoc
    }];
}