import { Box, Checkbox, darken, TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import styled from '@emotion/styled';

const TableHead = styled(MuiTableHead)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.secondary : darken(theme.palette.light.secondary, 0.2),
    '& .MuiTableCell-root':{
        borderRight: `thin dotted   ${darken(theme.palette.text.main, 0.8)}`,
    },  
    // color: theme.palette.primary.main,
    '& .MuiTableCell-root span':{
    //   color: darken(theme.palette.primary.main, 0.1),
      fontWeight: 'bold',
      '&.Mui-inactive, .MuiTableSortLabel-icon ':{
        color: theme.palette.text.main,
        opacity: 0.2,
      },    
      '&:hover':{
        color: theme.palette.primary.main,
      }
    },
    '& .MuiTableCell-root span.MuiSvgIcon-root:hover':{
      color: theme.palette.text.main,
    }
}));

export const CustomTableHead = ({
    headCells,
    onSelectAllClick, 
    order, 
    orderBy, 
    numSelected, 
    rowCount, 
    onRequestSort,
    isManageable,
    isCollapsible
}) => {

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{px: 2}}>
                {
                    isCollapsible && 
                        <TableCell 
                            padding="normal"
                            align="center"                        
                        >
                            <Typography sx={{fontWeight: 'bold'}} component="span">
                                Expand
                            </Typography>
                        </TableCell>                
                }
                {
                    isManageable && 
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            padding="normal"
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                }                
                {headCells.map((headCell) => (
                    headCell.visible && <TableCell
                        key={headCell.id}
                        align={headCell.align} 
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {
                            headCell.orderable ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            ): (
                                <Typography sx={{fontWeight: 'bold'}} component="span">
                                    {headCell.label}
                                </Typography>
                            )
                        }
                    </TableCell>
                ))}
                {
                    isManageable && 
                    <TableCell 
                        padding="normal"
                        align="center"                        
                    >
                        <Typography sx={{fontWeight: 'bold'}} component="span">
                            Actions
                        </Typography>
                    </TableCell>                
                }                
                
            </TableRow>
        </TableHead>
    );
}

CustomTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
