import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { companyReducer } from './companyReducer';
import { flowReducer } from './flowReducer';
import { settingsReducer } from './settingsReducer';
import { statusReducer } from './statusReducer';
import { traceabilityReducer } from './traceabilityReducer';
import { boardsReducer } from './boardsReducer';
import { docQueryReducer } from './docQueryReducer';
import { menuReducer } from './menuReducer';
import { usersReducer } from './usersReducer';
import { sharedViewsReducer } from './sharedViewsReducer';

export const rootReducer = combineReducers({
    auth: authReducer,
    company: companyReducer,
    flows: flowReducer,
    settings: settingsReducer,
    status: statusReducer,
    traceability: traceabilityReducer,
    board: boardsReducer,
    docs: docQueryReducer,
    users: usersReducer,
    sharedViews: sharedViewsReducer,
    menu: menuReducer,
})