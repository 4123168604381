import { actionTypes } from "../contants/actionTypes";

const initialState = {
    loadingStatusAllFlows: false,
    listStatusAllFlows: [],
    loadingExecutionsByFlow: false,
    listExecutionsByFlow: [],
    isErrorFetch: false,
    messageErrorFetch: ''
}

export const traceabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.tracStartLoadingStatusAllFlows:
            return {
                ...state,
                loadingStatusAllFlows: true
            }
        case actionTypes.tracFinishLoadingStatusAllFlows:
            return {
                ...state,
                loadingStatusAllFlows: false
            }
        case actionTypes.tracLoadStatusAllFlows:
            return {
                ...state,
                listStatusAllFlows: action.payload
            }
        case actionTypes.tracStartLoadingExecutionsByFlow:
            return {
                ...state,
                loadingExecutionsByFlow: true
            }
        case actionTypes.tracFinishLoadingExecutionsByFlow:
            return {
                ...state,
                loadingExecutionsByFlow: false
            }
        case actionTypes.tracLoadExecutionsByFlow:
            return {
                ...state,
                listExecutionsByFlow: action.payload
            }
        case actionTypes.tracErrorTraceability:
            return {
                ...state,
                isErrorFetch: action.payload.error,
                messageErrorFetch: action.payload.messageError
            }
        default:
            return state;
    }

}