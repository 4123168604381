import React from "react";
import { useMsal } from "@azure/msal-react";
import { LoadingButton } from '@mui/lab';

export const SignInButton = ({ callRegularLogin }) => {
    const { instance } = useMsal();
    const handleLogin = async () => {
        try {
            const loginResponse = await instance.loginPopup();
            getLogin(loginResponse);
        } catch (error) {
            console.error("Error de autenticación:", error);
        }
    };
    const getLogin = (dataAZ) => {
        callRegularLogin(dataAZ.account.username, dataAZ.idToken);
    }

    return (
        <LoadingButton
            // loading={checkingLogin}
            variant="contained"
            type="submit"
            size="small"
            fullWidth
            style={{ marginTop: "2rem" }}
            onClick={() => handleLogin("popup")}
        >
            Login AD
        </LoadingButton>
    );
};