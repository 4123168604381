import { Add, Description, Dns } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormGroup, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useErrorInputs } from '../../../../hooks/useErrorInputs';
import { useFocusInputs } from '../../../../hooks/useFocusInputs';
import { useForm } from '../../../../hooks/useForm';
import { EdiItemTabs } from '../../../../components/editItem/EdiItemTabs';
import { JsonEditorV2 } from '../../../../components/jsonEditorV2/JsonEditorV2';

export const CreateTypeComponentFlow = ({
  settingsManager
}) => {

  const {
    loadingSettingsFlows,
    handleAddTypeComponentFlow,
    addingNewSettingsFlows
  } = settingsManager;

  const [jsonVariables, setJsonVariables] = useState({
    inputDefault: {},
    outputDefault: {},
    failDefault: {}
  });

  const[{type, description}, handleInputChange] = useForm({
    type: '',
    description: ''
  });

  const [{typeError, descriptionError}, handleErrorInput] = useErrorInputs({
    typeError:{
        isError: false,
        messageError: ''
    },
    descriptionError:{
        isError: false,
        messageError: ''
    },
  });  
  const [{isTypeFocus, isDescriptionFocus}, handleFocusInputs] = useFocusInputs({
      isTypeFocus: false,
      isDescriptionFocus: false
  });

  const handleClickAddNewTypeComponentFlow = (e) =>{
    e.preventDefault();
    if(type.length <= 0){
        handleErrorInput('typeError', {
            isError: true,
            messageError: 'The field type is required'
        });
        return;
    }

    if(description.length <= 0){
        handleErrorInput('descriptionError', {
            isError: true,
            messageError: 'The field description is required'
        });
        return;
    }

    handleAddTypeComponentFlow({
      type,
      description,
      inputDefault: jsonVariables.inputDefault,
      outputDefault: jsonVariables.outputDefault,
      failDefault: jsonVariables.failDefault
    })
  }

  return (
    <Grid container>
      <Grid 
        item
        md={12}
        sx={{
          mb: 2
        }}
      >
        <Typography 
          variant="h5"
          color="primary"
          sx={{
            textAlign: 'center',
            fontWeight: 'bold'
          }}
        >
          Create New Type Component Flow
        </Typography>
      </Grid>

      <Grid 
        item 
        md={12}
        sx={{
          mb: 2
        }}
      >
        <Grid container>
          <Grid item md={2}></Grid>
          <Grid item md={8}>
            <FormGroup sx={{my: 2}}>
              <TextField
                error={!isTypeFocus && typeError.isError}
                color={(isTypeFocus) ? "text" : (typeError.isError) ? "error" : "text"}
                label="Name Type Component Flow"
                variant="outlined"
                name="type"
                placeholder="Enter name type component flow"
                size="small"
                autoComplete="off"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Dns color={(isTypeFocus) ? "text" : (typeError.isError) ? "error" : "text"}/>
                        </InputAdornment>
                    ),
                }}                        
                value={type}
                helperText={typeError.messageError}
                onChange={handleInputChange}
                onFocus={handleFocusInputs}
                onBlur={handleFocusInputs}
              />
            </FormGroup>

            <FormGroup>
              <TextField
                multiline
                rows={2}
                error={!isDescriptionFocus && descriptionError.isError}
                color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}
                label="Description"
                variant="outlined"
                name="description"
                placeholder="Enter description"
                size="small"
                autoComplete="off"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Description color={(isDescriptionFocus) ? "text" : (descriptionError.isError) ? "error" : "text"}/>
                        </InputAdornment>
                    )
                }}                        
                value={description} 
                helperText={descriptionError.messageError}
                onChange={handleInputChange}
                onFocus={handleFocusInputs}
                onBlur={handleFocusInputs}
              />
            </FormGroup>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
      </Grid>

      <Grid item md={12}>
        <EdiItemTabs
          isLoadingItems={loadingSettingsFlows}
          elevation={4}
          tabs={[
              {
                  label: 'Input Default',
                  value: '1',
                  bodyTab: <Box sx={{p: 3}}>
                      <JsonEditorV2
                          titleKey={'inputDefault'}
                          contentJson={jsonVariables.inputDefault}
                          handleChange={(event) => setJsonVariables({
                              ...jsonVariables,
                              inputDefault: event
                          })}
                          viewCheckBoxSelect={true}
                      />
                  </Box>
              },
              {
                  label: 'Ouput Default',
                  value: '2',
                  bodyTab: <Box sx={{p: 3}}>
                      <JsonEditorV2
                          titleKey={'outputDefault'}
                          contentJson={jsonVariables.outputDefault}
                          handleChange={(event) => setJsonVariables({
                            ...jsonVariables,
                            outputDefault: event
                          })}
                          viewCheckBoxSelect={true}
                      />
                  </Box>
              },
              {
                  label: 'Fail Default',
                  value: '3',
                  bodyTab: <Box sx={{p: 3}}>
                      <JsonEditorV2
                          titleKey={'failDefault'}
                          contentJson={jsonVariables.failDefault}
                          handleChange={(event) => setJsonVariables({
                            ...jsonVariables,
                            failDefault: event
                          })}
                          viewCheckBoxSelect={true}
                      />
                  </Box>
              },
          ]}
        />
      </Grid>

      <Grid item md={12}>
        <Box sx={{
          mt: 2,
          textAlign: 'center'
        }}>
          <LoadingButton
              loading={addingNewSettingsFlows}
              type="submit"
              loadingPosition="start"
              startIcon={<Add />}
              size="small"
              variant="contained"
              color="primary"
              onClick={handleClickAddNewTypeComponentFlow}
          >
              Add New Type Component Flow
          </LoadingButton>
        </Box>
      </Grid>
      
    </Grid>
  )
}
