
import { config } from "../../helpers/config";
import { Constants } from "../../helpers/constants";
import { uploadToS3 } from "../../services/aws.service";
import { fetchDataGeneralSettingsFlowsByFile } from "../../services/settings.service";
import { deleteDataStatus, fetchDataStatus, insertDataStatus, updatedDataStatus } from "../../services/status.service";
import { actionTypes } from "../contants/actionTypes"
import { logout } from "./auth";

//#region LOAD_SETTINGS_FLOWS
export const startLoadingSettingsFlows = () => ({
    type: actionTypes.settiStartLoadingSettingsFlows
});
export const finishLoadingSettingsFlows = () => ({
    type: actionTypes.settiFinishLoadingSettingsFlows
});
export const getDataSettingsFlows = (keyVariableSettings, accessToken) => {
    console.log('keyVariableSettings', keyVariableSettings)
    switch (keyVariableSettings) {
        case Constants.TYPES_FLOWS:
        case Constants.TYPES_COMPONENT_FLOWS:
        case Constants.TYPES_EXECUTE:
        case Constants.TEMPLATES_JSON:
            return (dispatch) => {
                dispatch(startLoadingSettingsFlows());
                fetchDataGeneralSettingsFlowsByFile(keyVariableSettings, accessToken)
                    .then(({data}) => {
                        console.log('data', data)
                        dispatch(loadSettingsFlows(data));
                    }).catch((e) => {
                        dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                        dispatch(loadSettingsFlows(null));
                    }).finally( () => {
                        dispatch(finishLoadingSettingsFlows());
                    });
            }
        case Constants.TYPES_STATUS:
            return (dispatch) => {
                dispatch(startLoadingSettingsFlows());
                fetchDataStatus(accessToken)
                    .then(({data}) => {
                        console.log('data', data)
                        dispatch(loadSettingsFlows(data));
                    }).catch((e) => {
                        if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)){
                            dispatch(logout());
                        }else{
                            dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                            dispatch(loadSettingsFlows(null));
                        }
                    }).finally( () => {
                        dispatch(finishLoadingSettingsFlows());
                    });
            }
        default:
            break;
    }
    
};
export const loadSettingsFlows = (data) => ({
    type: actionTypes.settiLoadAllSettingsFlows,
    payload: !data || !data?.result ? [] : data.result
});
//#endregion

//#region UPDATED_SETTINGS_FLOWS
export const startUpdatingSettingsFlows = () => ({
    type: actionTypes.settiStartUpdatingSettingsFlows
});

export const finishUpdatingSettingsFlows = () => ({
    type: actionTypes.settiFinishUpdatingSettingsFlows
});

export const updateSettingsFlows = (keyVariableSettings, accessToken, flowVariables) => {
    switch (keyVariableSettings) {
        case Constants.TYPES_FLOWS:
        case Constants.TYPES_COMPONENT_FLOWS:
        case Constants.TYPES_EXECUTE:
        case Constants.TEMPLATES_JSON:
            const configTypeComponents = {
                ...config,
                flowVariables: {
                    ...config.flowVariables,
                    [keyVariableSettings]: flowVariables
                }
            }
            var configFile = `var config = ${JSON.stringify(configTypeComponents)}`;
            var blob = new Blob([configFile], {type: 'text/plain'});
            const file = new File([blob], 'config.js');

            return (dispatch) => {
                dispatch(startUpdatingSettingsFlows());
                uploadToS3(file)
                    .then(({$response : response}) => {
                        console.log('response', response)
                        const statusCode = response.httpResponse.statusCode;
                        if(statusCode === 200){
                            dispatch(finishUpdatingSettingsFlows());
                            dispatch(getDataSettingsFlows(keyVariableSettings, accessToken));
                            // window.location.reload();
                        }else{
                            dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                        }
                    }).catch((e) => {
                        console.log('error', e);
                        dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                    }).finally( () => {
                        dispatch(finishUpdatingSettingsFlows());
                    });
            }
        case Constants.TYPES_STATUS:
            return (dispatch) => {
                dispatch(startUpdatingSettingsFlows());
                updatedDataStatus(flowVariables, accessToken)
                    .then((response) => {
                        console.log('responseUpdateSettingsFlows', response);                        
                    }).catch((e) => {
                        if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)){
                            dispatch(logout());
                        }else{
                            dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                        }
                    }).finally( () => {
                        dispatch(finishUpdatingSettingsFlows());
                        dispatch(getDataSettingsFlows(keyVariableSettings, accessToken));
                        // window.history.go(-1);
                    });
            }
        default:
            break;
    }
    
};
//#endregion

//#region DELETE_SETTINGS_FLOWS
export const startDeletingSettingsFlows = () => ({
    type: actionTypes.settiStartDeletingSettingsFlow
});
export const finishDeletingSettingsFlows = () => ({
    type: actionTypes.settiFinishDeletingSettingsFlows
});
export const deleteSettingsFlows = (keyVariableSettings, accessToken, flowVariables) => {
    switch (keyVariableSettings) {
        case Constants.TYPES_FLOWS:
        case Constants.TYPES_COMPONENT_FLOWS:
        case Constants.TYPES_EXECUTE:
        case Constants.TEMPLATES_JSON:
            const configTypeComponents = {
                ...config,
                flowVariables: {
                    ...config.flowVariables,
                    [keyVariableSettings]: flowVariables
                }
            }
            var configFile = `var config = ${JSON.stringify(configTypeComponents)}`;
            var blob = new Blob([configFile], {type: 'text/plain'});
            const file = new File([blob], 'config.js');

            return (dispatch) => {
                dispatch(startDeletingSettingsFlows());
                uploadToS3(file)
                    .then(({$response : response}) => {
                        const statusCode = response.httpResponse.statusCode;
                        if(statusCode === 200){
                            dispatch(finishDeletingSettingsFlows());
                            dispatch(getDataSettingsFlows(keyVariableSettings, accessToken));                            
                            window.location.reload();
                        }else{
                            dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                        }
                    }).catch((e) => {
                        console.log('error', e);
                        dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                    }).finally( () => {
                        dispatch(finishDeletingSettingsFlows());
                    });
            }
        case Constants.TYPES_STATUS:
            return (dispatch) => {
                dispatch(startDeletingSettingsFlows());
                deleteDataStatus(flowVariables, accessToken)
                    .then((response) => {
                        console.log('responseDeleteSettingsFlows', response);
                    }).catch((e) => {
                        if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)){
                            dispatch(logout());
                        }else{
                            dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                        }
                    }).finally( () => {
                        dispatch(finishDeletingSettingsFlows());
                        dispatch(getDataSettingsFlows(keyVariableSettings, accessToken));
                        // window.history.go(-1);
                    });
            }
        default:
            break;
    }
    
};
//#endregion

//#region ADD_NEW_SETTING_FLOW
export const startAddingNewSettingsFlow = () => ({
    type: actionTypes.settiStartAddingSettingsFlows
});

export const finishAddingNewSettingsFlow = () => ({
    type: actionTypes.settiFinishAddingSettingsFlow
});
export const addNewSettingsFlow = (keyVariableSettings, accessToken, flowVariables) => {
    
    switch (keyVariableSettings) {
        case Constants.TYPES_FLOWS:
        case Constants.TYPES_COMPONENT_FLOWS:
        case Constants.TYPES_EXECUTE:
        case Constants.TEMPLATES_JSON:
            const configTypeComponents = {
                ...config,
                flowVariables: {
                    ...config.flowVariables,
                    [keyVariableSettings]: flowVariables
                }
            }
            var configFile = `var config = ${JSON.stringify(configTypeComponents)}`;
            var blob = new Blob([configFile], {type: 'text/plain'});
            const file = new File([blob], 'config.js');

            return (dispatch) => {
                dispatch(startAddingNewSettingsFlow());
                uploadToS3(file)
                .then(({$response : response}) => {
                    console.log('response', response);
                    const statusCode = response.httpResponse.statusCode;
                    if(statusCode === 200){
                        dispatch(finishAddingNewSettingsFlow());
                        dispatch(getDataSettingsFlows(keyVariableSettings, accessToken));
                        // window.location.reload();
                    }else{
                        dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                    }
                }).catch((e) => {
                    console.log('error', e);
                    dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                }).finally( () => {
                    dispatch(finishAddingNewSettingsFlow());
                });
            }
        case Constants.TYPES_STATUS:
            return (dispatch) => {
                dispatch(startAddingNewSettingsFlow());
                insertDataStatus(flowVariables, accessToken)
                    .then((response) => {
                        console.log('responseAddingSettingsFlows', response)
                    }).catch((e) => {
                        if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)){
                            dispatch(logout());
                        }else{
                            dispatch(errorManageSettingsFlows(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                        }
                    }).finally( () => {
                        dispatch(finishAddingNewSettingsFlow());
                        dispatch(getDataSettingsFlows(keyVariableSettings, accessToken));
                        window.history.go(-1);
                    });
            }
        default:
            break;
    }

   
};
//#endregion

//#region ERROR_GENERAL_SETTINGS_FLOWS
export const errorManageSettingsFlows = (isError, messageError) => ({
    type: actionTypes.settiErrorSettingFlows,
    payload: {
        messageError: !messageError ? '' : messageError,
        error: isError
    }
});
//#endregion
