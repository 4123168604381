import React from 'react';
import ReactDOM from 'react-dom/client';
import { AdminRpaApp } from './AdminRpaApp';
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./helpers/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <AdminRpaApp />
  </MsalProvider>
  // </React.StrictMode>
);

reportWebVitals();
