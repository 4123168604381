import { deleteDataDocs, fetchDataDocs, fetchHistory, updatedDataDocs, fetchColumns, fetchDetails } from "../../services/sharedViews.service";
import { actionTypes } from "../contants/actionTypes";
import { logout } from "./auth";

//#region LOAD_ALL_DOCS
export const getAllDocs = (accessToken, config, filters, pagination) => {
    return (dispatch) => {
        dispatch(setIsLoading(true));
        dispatch(LoadData(actionTypes.filterAndPagination, { filters, pagination }));
        fetchDataDocs(accessToken, JSON.stringify({ filters, pagination }), config)
            .then(({ data }) => {
                dispatch(loadAllDocs(data));
            }).catch((e) => {
                console.log('error', e)
            }).finally(() => {
                dispatch(setIsLoading(false));
            });
    }
};

export const loadAllDocs = (data) => ({
    type: actionTypes.loadAllDocs,
    payload: data ?? []
});
//#endregion


//#region CHANGES_PENDING_DOCS
export const changesPendingComponent = (isChangesPending) => ({
    type: actionTypes.floIsChangesPendingComponentFlow,
    payload: isChangesPending
});

export const changesPendingSubComponent = (isChangesPending) => ({
    type: actionTypes.floIsChangesPendingSubComponentFlow,
    payload: isChangesPending
});

//#endregion


//#region UPDATED_FLOWS



export const startUpdatingDocs = () => ({
    type: actionTypes.floStartUpdatingFlow
});

export const finishUpdatingDocs = () => ({
    type: actionTypes.floFinishUpdatingFlow
});

export const updateDocs = (data, idCompany, accessToken) => {
    console.log('dataDocs', data)
    return (dispatch) => {
        dispatch(startUpdatingDocs());
        updatedDataDocs(data, accessToken)
            .then((response) => {
                console.log("updateDocs_response __", response);
            }).catch((e) => {
                console.log('error', e);
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageDocs(true, 'Ha ocurrido un error guardando los cambios, intentálo de nuevo más tarde'));
                }
            }).finally(() => {
                dispatch(finishUpdatingDocs());
                dispatch(getAllDocs(idCompany, accessToken));
            });
    }
};

//#endregion


//#region DELETE_DOCS

export const startDeletingDocs = () => ({
    type: actionTypes.floStartDeletingFlows
});

export const finishDeletingDocs = () => ({
    type: actionTypes.floFinishDeletingFlows
});

export const deleteDocs = (data, accessToken) => {
    return (dispatch) => {
        dispatch(startDeletingDocs());
        deleteDataDocs(data, accessToken)
            .then((response) => {
                console.log(response);
            }).catch((e) => {
                console.log('error', e);
                if (e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)) {
                    dispatch(logout());
                } else {
                    dispatch(errorManageDocs(true, 'Ha ocurrido un error eliminando el(los) flujo(s), intentálo de nuevo más tarde'));
                }
            }).finally(() => {
                dispatch(finishDeletingDocs());
                // dispatch(getAllFlows(idCompany, accessToken));
            });
    }
};


//#endregion
export const GetHistoryDoc = (accessToken, id) => {
    return (dispatch) => {
        fetchHistory(accessToken, id)
            .then(({ data }) => {
                dispatch(LoadHistory(data));
            }).catch((e) => {
                console.log('error', e)
            }).finally(() => {
            });
    }
};

export const LoadHistory = (data) => ({
    type: actionTypes.loadHistory,
    payload: data ?? []
});

const setIsLoading = (data) => ({
    type: actionTypes.isLoadingLoadAllDocs,
    payload: data
});

//#region ERROR_GENERAL_FLOWS
export const errorManageDocs = (isError, messageError) => ({
    type: actionTypes.floErrorManageFlows,
    payload: {
        messageError: !messageError ? '' : messageError,
        error: isError
    }
});
//#endregion

//#region SHARED DETAILS TABLE
export const getColumns = (accessToken, id) => {
    return (dispatch) => {
        fetchColumns(accessToken, id)
            .then(({ data }) => {
                let configuration = data ? data[0].configuration : [];
                let table = configuration ? JSON.parse(configuration)?.table : [];
                console.log('table ', table);
                dispatch(loadColumsTable(table));
            }).catch((e) => {
                console.log('error', e)
            }).finally(() => {
            });
    }
};

export const getDetails = (accessToken, id, variables) => {
    return (dispatch) => {
        dispatch(setIsLoadingDetails(true));
        fetchDetails(accessToken, id, variables)
            .then(({ data }) => {
                dispatch(LoadData(actionTypes.getListDetails, data ?? []));
            }).catch((e) => {
                console.log('error', e)
            }).finally(() => {
                dispatch(setIsLoadingDetails(false));
            });
    }
};

export const LoadData = (action, data) => ({
    type: action,
    payload: data
});

export const loadColumsTable = (data) => ({
    type: actionTypes.getColumnsTableDetails,
    payload: data
});

const setIsLoadingDetails = (data) => ({
    type: actionTypes.isLoadingListDetails,
    payload: data
});
//#endregion