import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addNewSettingsFlow, deleteSettingsFlows, errorManageSettingsFlows, getDataSettingsFlows, updateSettingsFlows } from '../redux/actions/settings';
import { useDataAuth } from './useDataAuth';
import { useNotification } from './useNotification';

export const useManageSettings = (keyVariableSettings) => {

    const dispatch = useDispatch();
    const [sendNotification] = useNotification();
    const {
        settingsFlows
    } = useSelector(state => state.settings);
    const {
        listFlowVariables,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        addingNewSettingsFlows,
        isErrorFetch,
        messageErrorFetch
    } = settingsFlows;
    const [useData] = useDataAuth();
    const { userSession } = useData;
    const [isChangedSettings, setIsChangedSettings] = useState(false);
    const [flowVariables, setFlowVariables] = useState([]);

    useEffect(() => {
        handleGetSettingsFlows();
    }, []);

    useEffect(() => {
        setFlowVariables(listFlowVariables);
    }, [listFlowVariables]);

    useEffect(() => {
        if (isErrorFetch) {
            sendNotification({ msg: messageErrorFetch, variant: 'error' });
            dispatch(errorManageSettingsFlows(false));
        }
    }, [isErrorFetch]);

    //#region SETTINGS_FLOWS
    const handleGetSettingsFlows = () => {
        dispatch(getDataSettingsFlows(keyVariableSettings, userSession.token));
    }

    //#region UPDATE_SETTINGS_BY_FILE
    const handleUpdatingSettingsFlows = async () => {
        setIsChangedSettings(false);
        dispatch(updateSettingsFlows(keyVariableSettings, userSession.token, flowVariables));
    }

    const handleDeletingSettingsFlows = async (newFlowVariables) => {
        dispatch(deleteSettingsFlows(keyVariableSettings, userSession.token, newFlowVariables));
    }

    const handleAddingSettingsFlows = async (newFlowVariables) => {
        dispatch(addNewSettingsFlow(keyVariableSettings, userSession.token, newFlowVariables));
    }
    //#endregion

    //#region TEMPLATE_JSON
    const handleChangeTemplatesJson = (idTemplate, event) => {
        setFlowVariables({
            ...flowVariables,
            jsonTemplates: flowVariables.jsonTemplates.map(template => {
                if (template.idTemplate === idTemplate) {
                    return {
                        ...template,
                        contentTemplate: event
                    };
                } else {
                    return template;
                }
            })
        });
        setIsChangedSettings(true);
    }

    const handleClickAddNewTemplate = (nameTypeTemplateJson) => {
        setFlowVariables({
            ...flowVariables,
            jsonTemplates: [
                ...flowVariables.jsonTemplates,
                {
                    "idTemplate": (flowVariables.jsonTemplates.sort((a, b) => b.idTemplate - a.idTemplate)[0].idTemplate + 1),
                    "typeTemplate": nameTypeTemplateJson,
                    "contentTemplate": {}
                }
            ]
        });
        setIsChangedSettings(true);
    }
    //#endregion

    //#region TYPES_FLOW
    const handleChangeTypeFlow = ({ name, value }, idTypeFlow) => {
        setFlowVariables(flowVariables => flowVariables.map((item, index) => (
            item.id === idTypeFlow ? {
                ...item,
                [name]: value
            } : item
        ))
        );
        setIsChangedSettings(true);
    }
    const handleAddTypeFlow = (typeFlow) => {
        const newFlowVariables = [
            ...flowVariables,
            {
                ...typeFlow,
                id: flowVariables.length + 1
            }
        ]
        handleAddingSettingsFlows(newFlowVariables);
    }
    const handleDeleteTypeFlow = (idsTypeFlow) => {
        const newFlowVariables = flowVariables.filter(typeFlow => (
            !idsTypeFlow.includes(typeFlow.id)
        ))
        handleDeletingSettingsFlows(newFlowVariables);
    }
    const handleRefreshSettingsFlow = () => {
        handleGetSettingsFlows(keyVariableSettings);
    }
    //#endregion

    //#region TYPES_COMPONENT_FLOW
    const handleChangeTypeComponentFlow = ({ name, value }, idTypeComponentFlow) => {
        setFlowVariables(flowVariables => flowVariables.map((item, index) => (
            item.id === idTypeComponentFlow ? {
                ...item,
                [name]: value
            } : item
        )))
        setIsChangedSettings(true);
    }

    const handleDeleteTypeComponentFlow = (idsTypeComponentFlow) => {
        const newFlowVariables = flowVariables.filter(typeComponentFlow => (
            !idsTypeComponentFlow.includes(typeComponentFlow.id)
        ));
        handleDeletingSettingsFlows(newFlowVariables);
    }

    const handleAddTypeComponentFlow = (typeComponentFlow) => {
        const newFlowVariables = [
            ...flowVariables,
            {
                ...typeComponentFlow,
                "id": flowVariables.length + 1
            }
        ];
        handleAddingSettingsFlows(newFlowVariables);
    }
    //#endregion

    //#region TYPES_EXECUTE
    const handleChangeTypeExecute = ({ name, value }, idTypeExecute) => {
        setFlowVariables(flowVariables => flowVariables.map((item, index) => (
            item.id === idTypeExecute ? {
                ...item,
                [name]: value
            } : item
        ))
        );
        setIsChangedSettings(true);
    }
    const handleAddTypeExecute = (typeExecute) => {
        const newFlowVariables = [
            ...flowVariables,
            {
                ...typeExecute,
                id: flowVariables.length + 1
            }
        ];
        handleAddingSettingsFlows(newFlowVariables);
    }
    const handleDeleteTypeExecute = (idsTypeExecute) => {
        const newFlowVariables = flowVariables.filter(typeExecute => (
            !idsTypeExecute.includes(typeExecute.id)
        ));
        handleDeletingSettingsFlows(newFlowVariables);
    }
    //#endregion

    //#region TYPES_STATUS
    const handleChangeTypeStatus = ({ name, value }, idTypeStatus) => {
        setFlowVariables(flowVariables => flowVariables.map((item, index) => (
            item.id_status === idTypeStatus ? {
                ...item,
                [name]: value,
                updated: moment().toDate(),
                updated_by: userSession.username
            } : item
        ))
        );
        setIsChangedSettings(true);
    }
    const handleAddTypeStatus = (typeStatus) => {
        const newStatus = [
            {
                ...typeStatus,
                created: moment().toDate(),
                created_by: userSession.username,
                updated: moment().toDate(),
                updated_by: userSession.username
            }
        ];
        handleAddingSettingsFlows(newStatus);
    }
    const handleDeleteTypeStatus = (idsTypeStatus) => {
        console.log('idsTypeStatus', idsTypeStatus)
        handleDeletingSettingsFlows(idsTypeStatus);
    }
    //#endregion

    //#endregion


    return [{
        handleUpdatingSettingsFlows,
        handleDeletingSettingsFlows,
        handleAddingSettingsFlows,
        flowVariables,
        isChangedSettings,
        loadingSettingsFlows,
        updatingSettingsFlows,
        deletingSettingsFlows,
        addingNewSettingsFlows,
        isErrorFetch,
        messageErrorFetch,
        handleRefreshSettingsFlow,
        //TemplatesJson
        handleChangeTemplatesJson,
        handleClickAddNewTemplate,
        //TypesFlow
        handleChangeTypeFlow,
        handleAddTypeFlow,
        handleDeleteTypeFlow,
        //TypeComponentFlow
        handleChangeTypeComponentFlow,
        handleDeleteTypeComponentFlow,
        handleAddTypeComponentFlow,
        //TypesExecute
        handleChangeTypeExecute,
        handleAddTypeExecute,
        handleDeleteTypeExecute,
        //TypesStatus
        handleChangeTypeStatus,
        handleAddTypeStatus,
        handleDeleteTypeStatus
    }]
}
