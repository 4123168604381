import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { EditItem } from '../../../components/editItem/EditItem';
import { DetailsSummarySubComponentFlow } from './DetailsSummarySubComponentFlow';
import { JsonEditorV2 } from '../../../components/jsonEditorV2/JsonEditorV2';
import { useNotification } from '../../../hooks/useNotification';
import { useDispatch } from 'react-redux';
import { changeSubComponentFlowInEdition } from '../../../redux/actions/flows';

export const DetailsSubComponentFlow = ({flowManager}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [subComponentFlowSelected, setSubComponentFlowSelected] = useState({});
    const [sendNotification] = useNotification();
    const params = useParams();
    const {
        updatingFlows: isUpdatingFlows,
        deletingComponentFlow: isDeletingSubComponentFlow,
        listFlows,
        isChangeDataSubComponentFlows,
        handleDeleteSubComponentFlow, 
        handleChangeSubComponentFlow,
        handleUpdatedFlow,
        setCurrentBreadCrumb
    } = flowManager;
    const [isLoadingFlows, setIsLoadingFlows] = useState(false);

    useEffect(() => {
        setIsLoadingFlows(true);
        const flowSelected = listFlows.find(flow => flow.id_flow === parseInt(params.idFlow));        
        const idComponentFlow = !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow;
        const componentFlowSelected = flowSelected?.flow_components
            .find(componentFlow =>  componentFlow.id_flow_component === idComponentFlow);
        const idSubComponentFlow = !isNaN(params.idSubComponentFlow) ? parseInt(params.idSubComponentFlow) : params.idSubComponentFlow;
        
        setTimeout(() => {
            const subComponentFlowSelected = componentFlowSelected?.sub_flow_components?.find(subComponentFlow => subComponentFlow.id_flow_component === idSubComponentFlow);
            setSubComponentFlowSelected(subComponentFlowSelected);
            dispatch(changeSubComponentFlowInEdition(subComponentFlowSelected));
            setIsLoadingFlows(false);
            setCurrentBreadCrumb(3);
        }, 500);
    }, []);

    useEffect(() => {
        const flowSelected = listFlows.find(flow => flow.id_flow === parseInt(params.idFlow));        
        const idComponentFlow = !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow;
        const componentFlowSelected = flowSelected?.flow_components
            .find(componentFlow =>  componentFlow.id_flow_component === idComponentFlow);
        const idSubComponentFlow = !isNaN(params.idSubComponentFlow) ? parseInt(params.idSubComponentFlow) : params.idSubComponentFlow;
        const subComponentFlowSelected = componentFlowSelected?.sub_flow_components?.find(subComponentFlow => subComponentFlow.id_flow_component === idSubComponentFlow);
        setSubComponentFlowSelected(subComponentFlowSelected);
    }, [listFlows]);

    const handleChangeSelect = ({target}) => {
        handleChangeSubComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            !isNaN(params.idSubComponentFlow) ? parseInt(params.idSubComponentFlow) : params.idSubComponentFlow,
            target.name,
            target.value
        );
    }

    const handleChangeEditor = (key, value) => {
        handleChangeSubComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            !isNaN(params.idSubComponentFlow) ? parseInt(params.idSubComponentFlow) : params.idSubComponentFlow,
            key,
            value
        );
    }

    const handleClickDeleteSubComponentFlow = () => {
        handleDeleteSubComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(params.idComponentFlow) ? parseInt(params.idComponentFlow) : params.idComponentFlow,
            !isNaN(params.idSubComponentFlow) ? parseInt(params.idComponentFlow) : params.idSubComponentFlow,
            false
        )
        setTimeout(() => {        
            navigate(`/flows/${params.idFlow}/${params.idComponentFlow}`)
        }, 500);
    }

    const handleClickSaveChanges = () => {
        if(!subComponentFlowSelected?.type){
            sendNotification({msg: "The field 'Type Component Flow' is required", variant: 'error'})
        }

        if(!subComponentFlowSelected?.type_execute){
            sendNotification({msg: "The field 'Type Execute' is required", variant: 'error'})
        }

        if(!subComponentFlowSelected?.process_input ||  Object.keys(subComponentFlowSelected?.process_input).length === 0){
            sendNotification({msg: "The field 'Process Input' is required", variant: 'error'})
        }

        handleUpdatedFlow(!isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow);
    }

    return (
        <Box>
            {
                !isLoadingFlows && !subComponentFlowSelected ?
                    <Navigate to={`/flows/${params.idFlow}/${params.idComponentFlow}`}/>
                : <EditItem
                    isLoadingItems={isLoadingFlows}
                    titleEditItem={{
                        label: `Id Sub Component Flow: ${!isNaN(subComponentFlowSelected?.id_flow_component) ? subComponentFlowSelected?.id_flow_component: 'Unassigned' }`,
                        isEditable: false    
                    }}
                    nameItem={"Sub Component Flow"}
                    isSaving={isUpdatingFlows}
                    statusButtonSave={isChangeDataSubComponentFlows}
                    handleSaveChanges={handleClickSaveChanges}
                    isDeleting={isDeletingSubComponentFlow}
                    statusButtonDelete={true}
                    handleClickDelete={handleClickDeleteSubComponentFlow}
                    contentSummary={<DetailsSummarySubComponentFlow
                        subComponentFlowSelected={subComponentFlowSelected}
                        handleChangeSelect={handleChangeSelect}
                    />}
                    tabs={[
                        {
                            label: 'Process Input',
                            value: '1',
                            bodyTab: <Box sx={{p: 3}}>
                                <JsonEditorV2
                                    titleKey={'processInput'}
                                    contentJson={subComponentFlowSelected?.process_input}
                                    handleChange={(event) => handleChangeEditor("process_input", event)}
                                    viewCheckBoxSelect={true}
                                />
                            </Box>
                        },
                        {
                            label: 'Acceptance Process',
                            value: '2',
                            bodyTab: <Box sx={{p: 3}}>
                                <JsonEditorV2
                                    titleKey={'acceptanceProcess'}
                                    contentJson={subComponentFlowSelected?.acceptance_process}
                                    handleChange={(event) => handleChangeEditor("acceptance_process", event)}
                                    viewCheckBoxSelect={true}
                                />
                            </Box>
                        },
                        {
                            label: 'Process Fail',
                            value: '3',
                            bodyTab: <Box sx={{p: 3}}>
                                <JsonEditorV2
                                    titleKey={'processFail'}
                                    contentJson={subComponentFlowSelected?.process_fail}
                                    handleChange={(event) => handleChangeEditor("process_fail", event)}
                                    viewCheckBoxSelect={true}
                                />
                            </Box>
                        }
                    ]}
                />
            }
        </Box>
    )
}