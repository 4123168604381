import { deleteDataStatus, fetchDataStatus, insertDataStatus, updatedDataStatus } from "../../services/status.service";
import { actionTypes } from "../contants/actionTypes";
import { logout } from "./auth";

//#region LOAD_STATUS
export const startLoadingStatus = () => ({
    type: actionTypes.staStartLoadingStatus
});
export const finishLoadingStatus = () => ({
    type: actionTypes.staFinishLoadingStatus
});
export const getDataStatus = (accessToken) => {
    return (dispatch) => {
        dispatch(startLoadingStatus());
        fetchDataStatus(accessToken)
            .then(({data}) => {
                dispatch(loadStatus(data));
            }).catch((e) => {
                if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)){
                    dispatch(logout());
                }else{
                    dispatch(errorManageStatus(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                    dispatch(loadStatus(null));
                }
            }).finally( () => {
                dispatch(finishLoadingStatus());
            });
    }    
};
export const loadStatus = (data) => ({
    type: actionTypes.staLoadStatus,
    payload: !data || !data?.result ? [] : data.result
});
//#endregion

//#region UPDATED_SETTINGS_FLOWS
export const startUpdatingStatus = () => ({
    type: actionTypes.staStartUpdatingStatus
});

export const finishUpdatingStatus = () => ({
    type: actionTypes.staFinishUpdatingStatus
});

export const updateStatus = (accessToken, statusToUpdate) => {
    return (dispatch) => {
        dispatch(startUpdatingStatus());
        updatedDataStatus(statusToUpdate, accessToken)
            .then((response) => {
                console.log('responseUpdateSettingsFlows', response);                        
            }).catch((e) => {
                if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)){
                    dispatch(logout());
                }else{
                    dispatch(errorManageStatus(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                }
            }).finally( () => {
                dispatch(finishUpdatingStatus());
                dispatch(getDataStatus(accessToken));
                // window.history.go(-1);
            });
    }    
};
//#endregion

//#region DELETE_SETTINGS_FLOWS
export const startDeletingStatus = () => ({
    type: actionTypes.staStartDeletingStatus
});
export const finishDeletingStatus = () => ({
    type: actionTypes.staFinishDeletingStatus
});
export const deleteStatus = (accessToken, statusToDelete) => {
    return (dispatch) => {
        dispatch(startDeletingStatus());
        deleteDataStatus(statusToDelete, accessToken)
            .then((response) => {
                console.log('responseDeleteSettingsFlows', response);
            }).catch((e) => {
                if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)){
                    dispatch(logout());
                }else{
                    dispatch(errorManageStatus(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                }
            }).finally( () => {
                dispatch(finishDeletingStatus());
                dispatch(getDataStatus(accessToken));
                // window.history.go(-1);
            });
    }    
};
//#endregion

//#region ADD_NEW_SETTING_FLOW
export const startAddingNewStatus = () => ({
    type: actionTypes.staStartAddingStatus
});

export const finishAddingNewStatus = () => ({
    type: actionTypes.staFinishAddingStatus
});
export const addNewStatus = (accessToken, statusToAdd) => {    
    return (dispatch) => {
        dispatch(startAddingNewStatus());
        insertDataStatus(statusToAdd, accessToken)
            .then((response) => {
                console.log('responseAddingSettingsFlows', response)
            }).catch((e) => {
                if(e.response && e.response.status && (e.response.status === 403 || e.response.status === 401)){
                    dispatch(logout());
                }else{
                    dispatch(errorManageStatus(true, 'Ha ocurrido un error, intentálo de nuevo más tarde'));
                }
            }).finally( () => {
                dispatch(finishAddingNewStatus());
                dispatch(getDataStatus(accessToken));
            });
    }   
};
//#endregion

//#region ERROR_GENERAL_SETTINGS_FLOWS
export const errorManageStatus = (isError, messageError) => ({
    type: actionTypes.staErrorStatus,
    payload: {
        messageError: !messageError ? '' : messageError,
        error: isError
    }
});
//#endregion