import { fetchDataDocs, fetchHistory } from "../../services/docQuery.service";
import { actionTypes } from "../contants/actionTypes";

export const getAllDocs = (accessToken, variables, config) => {
    return (dispatch) => {
        dispatch(setIsLoading(true));
        fetchDataDocs(accessToken, variables, config)
            .then(({ data }) => {
                dispatch(loadAllDocs(data));
            }).catch((e) => {
                console.log('error', e)
            }).finally(() => {
                dispatch(setIsLoading(false));
            });
    }
};

export const loadAllDocs = (data) => ({
    type: actionTypes.loadAllDocs,
    payload: data ?? []
});

export const GetHistoryDoc = (accessToken, id) => {
    return (dispatch) => {
        fetchHistory(accessToken, id)
            .then(({ data }) => {
                dispatch(LoadHistory(data));
            }).catch((e) => {
                console.log('error', e)
            }).finally(() => {
            });
    }
};

export const LoadHistory = (data) => ({
    type: actionTypes.loadHistory,
    payload: data ?? []
});

const setIsLoading = (data) => ({
    type: actionTypes.isLoadingLoadAllDocs,
    payload: data
});