import axios from 'axios';

const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;

export const fetchDataUsers = (accessToken, variables) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_select',
            'action': 'USER',
            'variables': variables
        })
    })
}

export const Delete = (accessToken, id) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_alter',
            'action': 'DELETEUSER',
            'variables': JSON.stringify({ 'id_user': id })
        })
    })
}

export const fetchUserAcces = (accessToken, id) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_select',
            'action': 'USER_ACCESS',
            'variables': JSON.stringify({ 'id_user': id })
        })
    })
}

export const DeletePermission = (accessToken, id) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_alter',
            'action': 'DELETEPERMISSIONUSER',
            'variables': JSON.stringify({ 'id_user_access': id })
        })
    })
}

export const fetchCompaniesAcces = (accessToken) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_select',
            'action': 'SELECTCOMPANIES',
            'variables': '{}'
        })
    })
}

export const fetchGroupsAcces = (accessToken, idCom) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_select',
            'action': 'SELECTPERMISSIONS',
            'variables': JSON.stringify({ 'id_company': idCom })
        })
    })
}

export const AddPermission = (accessToken, dto) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_alter',
            'action': 'ADDPERMISSIONUSER',
            'variables': JSON.stringify(dto)
        })
    })
}

export const UpdateUser = (accessToken, dto) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_alter',
            'action': 'UPDATEUSER',
            'variables': JSON.stringify(dto)
        })
    })
}

export const SaveUser = (accessToken, dto) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': 'user_alter',
            'action': 'INSERTUSERS',
            'variables': JSON.stringify(dto)
        })
    })
}
