import { actionTypes } from "../contants/actionTypes";

const initialState = {
    listAllUsers: [],
    isLoading: true,
    isLoadingPermissions: true,
    listAllPermissions: [],
    listCompaniesPermissions: [],
    listGroupPermissions: [],
    isNotification: false,
    msg: '',
    typeMsg: 'info'
}

export const usersReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.isLoadingLoadAllUsers:
            return {
                ...state,
                isLoading: action.payload,
            }
        case actionTypes.loadAllUsers:
            return {
                ...state,
                listAllUsers: action.payload,
            }
        case actionTypes.loadAllPermissions:
            return {
                ...state,
                listAllPermissions: action.payload,
            }
        case actionTypes.isLoadingAllPermissions:
            return {
                ...state,
                isLoadingPermissions: action.payload
            }
        case actionTypes.loadCompaniesPermissions:
            return {
                ...state,
                listCompaniesPermissions: action.payload,
            }
        case actionTypes.loadGroupsPermissions:
            return {
                ...state,
                listGroupPermissions: action.payload,
            }
        case actionTypes.notificaction:
            return {
                ...state,
                isNotification: action.payload?.isNotification || false,
                msg: action.payload?.msg,
                typeMsg: action.payload?.typeMsg
            }
        default:
            return state;
    }
}