import React, { useState, useEffect, useCallback } from "react";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";

export function FileManageSignature({ recipients = [], pdfFile }) {
    const [selectedRecipient, setSelectedRecipient] = useState("");
    const [backgroundImages, setBackgroundImages] = useState([]);
    const [backgroundSize, setBackgroundSize] = useState({ width: 0, height: 0 });
    const [predesignedObjects, setPredesignedObjects] = useState([]);
    const [draggingObjectId, setDraggingObjectId] = useState(null);
    const [resizingObjectId, setResizingObjectId] = useState(null);
    const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 });
    const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
    const [originalPosition, setOriginalPosition] = useState({ x: 0, y: 0 });
    const [selectedObjectId, setSelectedObjectId] = useState(null);

    const handlePdfUpload = useCallback(() => {
        if (pdfFile && pdfFile.type === "application/pdf") {
            const fileReader = new FileReader();

            fileReader.onload = async () => {
                const pdfData = new Uint8Array(fileReader.result);
                const pdf = await pdfjsLib.getDocument(pdfData).promise;

                const totalPages = pdf.numPages;
                const images = [];

                for (let i = 1; i <= totalPages; i++) {
                    const page = await pdf.getPage(i);
                    const viewport = page.getViewport({ scale: 1.5 });
                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

                    await page.render({ canvasContext: context, viewport }).promise;

                    const imageUrl = canvas.toDataURL();
                    images.push({ imageUrl, viewport });
                }

                setBackgroundImages(images);
                if (totalPages > 0) {
                    setBackgroundSize({ width: images[0].viewport.width, height: images[0].viewport.height });
                }
            };
            fileReader.readAsArrayBuffer(pdfFile); // Leemos pdfFile en lugar de un archivo desde el evento
        }
    }, [pdfFile]);

    useEffect(() => {
        handlePdfUpload();
    }, [pdfFile, handlePdfUpload]);


    //crea nuevos recuadros
    const handleDragStart = (type, id = null) => {
        if (id) {
            setDraggingObjectId(id);
            const obj = predesignedObjects.find(o => o.id === id);
            if (obj) {
                setOriginalPosition({ x: obj.x, y: obj.y });
                setSelectedObjectId(id); // Selecciona el recuadro
            }
        } else {
            const newObject = {
                id: Math.random(),
                type: type,
                recipient: selectedRecipient || (recipients.length > 0 ? recipients[0].email : ""),
                width: 120,
                height: 50,
                x: 0,
                y: 0,
            };
            setPredesignedObjects([...predesignedObjects, newObject]);
            setDraggingObjectId(newObject.id);
            setSelectedObjectId(newObject.id); // Selecciona el nuevo recuadro
        }
    };

    const handleDrop = (event, index) => {
        event.preventDefault();
        if (draggingObjectId !== null) {
            const updatedObjects = predesignedObjects.map((obj) => {
                if (obj.id === draggingObjectId) {
                    return {
                        ...obj,
                        x: Math.min(event.nativeEvent.offsetX, backgroundSize.width - obj.width),
                        y: Math.min(event.nativeEvent.offsetY, backgroundSize.height - obj.height),
                        pageIndex: index
                    };
                }
                return obj;
            });
            setPredesignedObjects(updatedObjects);
            setDraggingObjectId(null);
            setSelectedObjectId(null); // Reinicia la selección
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleMouseDownResize = (id, event) => {
        event.stopPropagation();
        setResizingObjectId(id);
        setMouseStart({ x: event.clientX, y: event.clientY });
        const obj = predesignedObjects.find(o => o.id === id);
        if (obj) {
            setOriginalSize({ width: obj.width, height: obj.height });
        }
    };

    const handleMouseMove = (event) => {
        if (draggingObjectId !== null) {
            const dx = event.clientX - mouseStart.x;
            const dy = event.clientY - mouseStart.y;

            const updatedObjects = predesignedObjects.map((obj) => {
                if (obj.id === draggingObjectId) {
                    return {
                        ...obj,
                        x: Math.max(0, Math.min(originalPosition.x + dx, backgroundSize.width - obj.width)),
                        y: Math.max(0, Math.min(originalPosition.y + dy, backgroundSize.height - obj.height)),
                    };
                }
                return obj;
            });
            setPredesignedObjects(updatedObjects);
        } else if (resizingObjectId !== null) {
            const dx = event.clientX - mouseStart.x;
            const dy = event.clientY - mouseStart.y;

            const updatedObjects = predesignedObjects.map((obj) => {
                if (obj.id === resizingObjectId) {
                    return {
                        ...obj,
                        width: Math.max(20, originalSize.width + dx),
                        height: Math.max(20, originalSize.height + dy),
                    };
                }
                return obj;
            });
            setPredesignedObjects(updatedObjects);
        }
    };

    const handleMouseUp = () => {
        setResizingObjectId(null);
        setDraggingObjectId(null);
    };

    const getObjectContent = (type) => {
        switch (type) {
            case "Firma":
                return { text: "Firmar", icon: "⬇️" };
            case "Nombre":
                return { text: "Nombre", icon: "👤" };
            case "Sello":
                return { text: "Sello", icon: "📜" };
            default:
                return { text: "", icon: "" };
        }
    };

    const handleFinalize = () => {
        predesignedObjects.forEach((obj, index) => {
            const content = getObjectContent(obj.type);
            console.log({
                Recuadro: index + 1,
                Página: obj.pageIndex + 1,
                Posición1: `(esquina superior izquierda): x = ${obj.x}, y = ${obj.y}`,
                Posición2: `(esquina inferior derecha): y = ${obj.x + obj.width}, y = ${obj.y + obj.height}`,
                Tamaño: `ancho = ${obj.width}, alto = ${obj.height}`,
                Remitente: obj.recipient,
                Tipo: content.text
            });
        });
    };

    const handleDelete = () => {
        setPredesignedObjects(predesignedObjects.filter(obj => obj.id !== selectedObjectId));
        setSelectedObjectId(null); // Reinicia la selección
    };

    return (
        <div onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
            <div style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "200px",
                        border: "1px solid gray",
                        padding: "10px",
                        marginRight: "20px",
                    }}
                >
                    <select
                        id="recipients"
                        value={selectedRecipient}
                        onChange={(e) => setSelectedRecipient(e.target.value)}
                    >
                        {recipients.map((recipient, index) => (
                            <option key={index} value={recipient.email}>
                                {recipient.name}
                            </option>
                        ))}
                    </select>

                    <h3>Opciones:</h3>
                    {["Firma", "Nombre", "Sello"].map((type) => {
                        const content = getObjectContent(type);
                        return (
                            <div
                                key={type}
                                style={{
                                    marginBottom: "10px",
                                    width: "120px",
                                    height: "50px",
                                    cursor: "grab",
                                    backgroundColor: "#F5DEB3",
                                    border: "2px solid #ADD8E6",
                                    borderRadius: "8px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)",
                                    textAlign: "center",
                                }}
                                draggable
                                onDragStart={() => handleDragStart(type)}
                            >
                                <div>
                                    <div>{content.icon}</div>
                                    <div>{content.text}</div>
                                </div>
                            </div>
                        );
                    })}
                    {selectedObjectId && (
                        <button onClick={handleDelete} style={{ marginTop: "10px", padding: "10px", fontSize: "16px", backgroundColor: "red", color: "white" }}>
                            Eliminar Recuadro
                        </button>
                    )}
                </div>

                <div style={{ display: "flex", flexDirection: "column", overflowY: "auto", overflowX: "hidden", height: "500px", width: "800px" }}>
                    {backgroundImages.length > 0 ? (
                        backgroundImages.map((bg, index) => (
                            <div
                                key={index}
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: `${bg.viewport.height}px`,
                                    textAlign: "center",
                                    border: "2px solid black",
                                }}
                                onDrop={(event) => handleDrop(event, index)}
                                onDragOver={handleDragOver}
                            >
                                <img
                                    src={bg.imageUrl}
                                    alt={`page-${index}`}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        pointerEvents: "none",
                                    }}
                                />
                                {predesignedObjects
                                    .filter((obj) => obj.pageIndex === index)
                                    .map((obj) => {
                                        const content = getObjectContent(obj.type);
                                        return (
                                            <div
                                                key={obj.id}
                                                style={{
                                                    position: "absolute",
                                                    top: `${obj.y}px`,
                                                    left: `${obj.x}px`,
                                                    width: `${obj.width}px`,
                                                    height: `${obj.height}px`,
                                                    cursor: "move",
                                                    backgroundColor: "#F5DEB3",
                                                    border: "2px solid #ADD8E6",
                                                    borderRadius: "8px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                    boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)",
                                                    textAlign: "center",
                                                }}
                                                onMouseDown={(event) => {
                                                    setMouseStart({
                                                        x: event.clientX,
                                                        y: event.clientY,
                                                    });
                                                    handleDragStart("existing", obj.id);
                                                }}
                                            >
                                                <div>
                                                    <div>{content.icon}</div>
                                                    <div>{content.text}</div>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor: "#D1A684",
                                                        position: "absolute",
                                                        right: "-5px",
                                                        bottom: "-5px",
                                                        cursor: "nwse-resize",
                                                    }}
                                                    onMouseDown={(event) => handleMouseDownResize(obj.id, event)}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        ))
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                border: "2px dashed #ccc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#888",
                            }}
                        >
                            <p>Sube un archivo PDF para verlo aquí</p>
                        </div>
                    )}
                    <button onClick={handleFinalize} style={{ marginTop: "20px", padding: "10px", fontSize: "16px" }}>
                        Terminar
                    </button>
                </div>
            </div>
        </div>
    );
}
