import { FilePresent, FileUpload } from '@mui/icons-material';
import { Box, Button, FormGroup, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

export const FileSender = ({ handleNavigate }) => {

    const [recipients, setRecipients] = useState([]);
    const [pdfFile, setPdfFile] = useState([]);
    const [newRecipient, setNewRecipient] = useState({ name: '', email: '' });

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setPdfFile(file);
        } else {
            alert("Por favor selecciona un archivo PDF.");
        }
    };

    const handleRecipientChange = (e) => {
        const { name, value } = e.target;
        setNewRecipient((prev) => ({ ...prev, [name]: value }));
    };

    const addRecipient = () => {
        if (newRecipient.name && newRecipient.email) {
            setRecipients([...recipients, newRecipient]);
            setNewRecipient({ name: '', email: '' });
        } else {
            alert("Por favor completa ambos campos: Nombre y Correo.");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pdfFile && recipients.length > 0) {
            handleNavigate('/signatureDigital/template-manage', recipients, pdfFile);
        } else {
            alert("Por favor selecciona un archivo PDF y agrega al menos un remitente.");
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper
                sx={{
                    width: '50%',
                    mb: 2,
                    boxShadow: (theme) =>
                        theme.palette.dark === 'dark'
                            ? '0px 0px 8px 0px rgba(0,0,0,0.75)'
                            : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                    WebkitBoxShadow: (theme) =>
                        theme.palette.dark === 'dark'
                            ? '0px 0px 8px 0px rgba(0,0,0,0.75)'
                            : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                    bgcolor: (theme) => theme.palette.paper.background,
                }}
                elevation={3}
            >
                <Box sx={{
                    minHeight: '80vh', backgroundColor: '#f5f5f5', padding: 3
                }}>
                    <Typography variant='h5'>Subir archivo PDF y agregar remitentes</Typography>
                    <form onSubmit={handleSubmit}>
                        {/* <div>
                        <label>Archivo PDF:</label>
                        <input type="file" accept="application/pdf" onChange={handleFileChange} />
                        </div> */}

                        <Box sx={{ marginTop: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={10}>
                                    {/* <TextField
                                        name={'pdf'}
                                        accept="application/pdf"
                                        size="small"
                                        type="file"
                                        InputLabelProps={{ shrink: true }}
                                        aria-readonly
                                        InputProps={{
                                            endAdornment: (
                                                <label htmlFor="raised-button-file">
                                                    <Tooltip title={'Seleccionar archivo'}>
                                                        <FilePresent style={{ fontSize: 25, color: 'gray' }} />
                                                    </Tooltip>
                                                </label>
                                            ),
                                        }}
                                    />
                                    <input
                                        accept=".pdf, .xml, .eml, .zip"
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        type="file"
                                        onChange={handleFileChange}
                                    /> */}
                                    <input type="file" accept="application/pdf" onChange={handleFileChange} />
                                </Grid>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="text"
                                        name="name"
                                        size='small'
                                        placeholder="Nombre"
                                        value={newRecipient.name}
                                        onChange={handleRecipientChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="email"
                                        name="email"
                                        size='small'
                                        placeholder="Correo"
                                        value={newRecipient.email}
                                        onChange={handleRecipientChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <Button
                                            variant='contained'
                                            color='success'
                                            type="button"
                                            onClick={addRecipient}
                                        >
                                            Agregar remitente
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid className='mt-3'>
                            <Typography variant='body2'>Lista de Remitentes</Typography>
                            <ul>
                                {recipients.map((recipient, index) => (
                                    <li key={index}>{recipient.name} ({recipient.email})</li>
                                ))}
                            </ul>
                        </Grid>

                        <Button
                            variant='contained'
                            color='success'
                            type="submit"
                        >
                            Enviar
                        </Button>
                        {/* <button type="submit">Enviar</button> */}
                    </form>
                </Box>

            </Paper >
        </Box >
    );
};
